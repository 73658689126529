import React from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

import { StyledEllipse } from './styled';

type Props = {
  list: Array<{ item: string; key: string | number }>;
} & Pick<StackProps, 'alignSelf'> &
  TypographyProps;

const InlineList: React.FC<Props> = ({ list, alignSelf = 'center', ...typographyProps }) => (
  <Stack display="inline" alignSelf={alignSelf}>
    {list.map(({ item, key }, index) => (
      <React.Fragment key={key}>
        {index !== 0 && <StyledEllipse />}
        <Typography variant="subtitle2" component="span" display="inline" {...typographyProps}>
          {item}
        </Typography>
      </React.Fragment>
    ))}
  </Stack>
);

export default InlineList;
