import { useTranslation } from 'react-i18next';
import { PATHS } from 'navigation/constants';
import { useMatch } from 'react-router';
import { Master } from 'types';
import getRouteWithParams from '../../utils/getRouteWithParams';

export const useBreadcrumbs = ({ id, contactName }: Master) => {
  const { t } = useTranslation();

  const COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE = useMatch(
    PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE,
  );

  if (COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE && !id) {
    return {};
  }

  const getBreadcrumbsSegments = () => [
    { path: PATHS.MAIN_PAGE, label: t('breadcrumbs.home') },
    { path: PATHS.MASTER_CABINET_SERVICES_TAB, label: t('breadcrumbs.cabinet') },
    ...(COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE
      ? [
          {
            path: getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB, { id: id?.toString()! }),
            label: contactName,
          },
        ]
      : []),
  ];

  return {
    breadcrumbsSegments: getBreadcrumbsSegments(),
    currentPageLabel: t('breadcrumbs.addService'),
  };
};
