import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Company } from 'types';
import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import { useCompanyState } from 'contexts/company';
import { setCompany } from 'contexts/company/actions';
import { GET_COMPANY } from 'api/requests/company';

const useCompanyCabinetPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const {
    state: { company },
    dispatch,
  } = useCompanyState();
  const [fetchCompany] = useLazyQuery<{ company: Company }>(GET_COMPANY, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    fetchCompany()
      .then(response => {
        if (response.data?.company) {
          dispatch(setCompany(response.data.company));
        }
      })
      .catch(error => {
        if (error?.graphQLErrors[0]?.extensions?.code === 'AUTH_NOT_AUTHORIZED') {
          navigate(PATHS.MAIN_PAGE);
        }
      });
  }, [i18n.language, navigate]);
};

export default useCompanyCabinetPage;
