import React, { useState } from 'react';
import { AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { StyledItemList, StyledAccordion } from './styled';
import { useBreadcrumbs } from './useBreadcrumbs';

const TermsAndConditionsPage: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbs = useBreadcrumbs();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const items = [
    {
      title: t('termsAndConditionsPage.item1Title'),
      details: (
        <Trans
          i18nKey="termsAndConditionsPage.item1Details"
          components={{
            ul: <ul />,
            li: <li />,
          }}
        />
      ),
    },
    {
      title: t('termsAndConditionsPage.item2Title'),
      details: (
        <Trans
          i18nKey="termsAndConditionsPage.item2Details"
          components={{
            ul: <ul />,
            li: <li />,
          }}
        />
      ),
    },
    {
      title: t('termsAndConditionsPage.item3Title'),
      details: (
        <Trans
          i18nKey="termsAndConditionsPage.item3Details"
          components={{
            ul: <ul />,
            li: <li />,
          }}
        />
      ),
    },
    {
      title: t('termsAndConditionsPage.item4Title'),
      details: (
        <Trans
          i18nKey="termsAndConditionsPage.item4Details"
          components={{
            ul: <ul />,
            li: <li />,
          }}
        />
      ),
    },
    {
      title: t('termsAndConditionsPage.item5Title'),
      details: (
        <Trans
          i18nKey="termsAndConditionsPage.item5Details"
          components={{
            ul: <ul />,
            li: <li />,
          }}
        />
      ),
    },
    {
      title: t('termsAndConditionsPage.item6Title'),
      details: (
        <Trans
          i18nKey="termsAndConditionsPage.item6Details"
          components={{
            ul: <ul />,
            li: <li />,
          }}
        />
      ),
    },
  ];

  const [expanded, setExpanded] = useState<string | false>(items[0].title);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  return (
    <Container>
      <Breadcrumbs {...breadcrumbs} />
      <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
        {t('termsAndConditionsPage.title')}
      </Typography>
      <Gap size={upMedium ? 56 : 24} />
      <StyledItemList>
        {items.map(item => (
          <StyledAccordion
            key={item.title}
            expanded={expanded === item.title}
            disableGutters
            onChange={handleChange(item.title)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id={item.title}>
              <Typography variant={upMedium ? 'h5' : 'subtitle1'} color="primary.main" component="h5">
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="primary.main" sx={{ opacity: 0.7 }}>
                {item.details}
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </StyledItemList>
    </Container>
  );
};

export default TermsAndConditionsPage;
