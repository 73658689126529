import { Location } from 'types';
import { getCookie } from './cookie';

const getLocationData = (): Location | null => {
  const location = getCookie('location');

  try {
    return JSON.parse(location) as Location;
  } catch (e) {
    return null;
  }
};

export default getLocationData;
