import { Category } from 'types';
import { CategoryEnum } from 'constatns';
import getCategoryLocalId from './getCategoryLocalId';

export const getGroupedCategoryList = (list: Category[]) => {
  return Object.values(
    list.reduce<
      Partial<
        Record<
          CategoryEnum,
          {
            id: CategoryEnum;
            localizedName: string;
            attributeGroupList: Array<{ id: string; attributeList: string[] }>;
          }
        >
      >
    >((acc, item) => {
      if (!item.id) {
        return acc;
      }

      const localId = item.localId || getCategoryLocalId(item);

      if (acc[item.id]) {
        return {
          ...acc,
          [item.id]: {
            id: item.id,
            localizedName: item.localizedName,
            attributeGroupList: [
              ...(acc[item.id]?.attributeGroupList || []),
              { id: localId, attributeList: item.attributeGroups.map(({ attribute }) => attribute.localizedName) },
            ],
          },
        };
      }
      return {
        ...acc,
        [item.id]: {
          id: item.id,
          localizedName: item.localizedName,
          attributeGroupList: [
            {
              id: localId,
              attributeList: item.attributeGroups.map(({ attribute }) => attribute.localizedName),
            },
          ],
        },
      };
    }, {}),
  );
};
