import React from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import CategoryLabel from 'components/@common/CategoryLabel';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import TruncatedText from 'components/@shared/TruncatedText';
import IconButton from 'components/@shared/IconButton';
import convertTimeSpanToDayjs from 'utils/convertTimeSpanToDayjs';
import mapServiceModelToMasterCardViewModel from 'utils/mapServiceModelToMasterCardViewModel';
import FavoriteIcon from '@mui/icons-material/Favorite';
import useFavorite from 'components/@common/ServiceCard/useFavorite';
import { useTranslation } from 'react-i18next';
import mapServiceModelToContactsSectionViewModel from 'utils/mapServiceModelToContactsSectionViewModel';
import MasterCard from 'components/@common/MasterCard';
import ExamplesSection from 'components/@common/ExamplesSection';
import LoadingState from 'components/@common/LoadingState';
import mapServicesToExamplesViewModel from 'utils/mapServicesToExamplesViewModel';

import { useBookServiceModal, BookServiceModal } from 'components/@common/BookButton';
import Button from 'components/@shared/Button';
import useServiceDetailsPage from './useServiceDetailsPage';
import {
  StyledMainContainer,
  StyledAttributeRow,
  StyledAttributeSection,
  StyledContactsSection,
  StyledMasterSection,
  StyledInfoSection,
  StyledIconButton,
  StyledAvatar,
  StyledButton,
} from './styled';
import { useBreadcrumbs } from './useBreadcrumbs';

const ServiceDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { onBook, ...bookServiceModalProps } = useBookServiceModal();
  const { serviceDetails, loading, handleCompanyClick, handleMasterClick } = useServiceDetailsPage();
  const breadcrumbs = useBreadcrumbs(serviceDetails);

  const shareData = {
    title: serviceDetails?.name || '',
    text: serviceDetails?.description || '',
    url: window.location.href,
  };

  const handleShare = () => {
    if (navigator.share && navigator.canShare(shareData)) {
      navigator.share(shareData);
    }
  };

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const priceText = serviceDetails?.price ? t('price', { val: serviceDetails.price }) : t('priceFree');
  const { favorite, toggleFavorite } = useFavorite(serviceDetails?.id);

  if (loading) {
    return <LoadingState />;
  }

  if (!serviceDetails) {
    return null;
  }

  return (
    <>
      <Container>
        <Breadcrumbs {...breadcrumbs} />
        <StyledMainContainer>
          <StyledInfoSection>
            <Box display="flex" flexDirection="column" gap="12px">
              <Box display="flex" gap="12px" justifyContent="space-between" alignItems="start">
                <Box display="flex" gap="12px" flexWrap="wrap">
                  <CategoryLabel category={serviceDetails.category.id} variant="grey" />
                </Box>
                {!upMedium && (
                  <IconButton onClick={handleShare}>
                    <ShareIcon height="40px" width="40px" />
                  </IconButton>
                )}
              </Box>
              <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
                {serviceDetails.name}
              </Typography>
              <StyledAttributeSection>
                {!!serviceDetails?.company && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="8px"
                    sx={{ cursor: 'pointer' }}
                  >
                    <StyledAttributeRow>
                      <Typography variant="subtitle1" color="primary.dark2">
                        {t('name')}:
                      </Typography>
                      <Stack
                        gap="8px"
                        alignItems="center"
                        direction="row"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleCompanyClick}
                      >
                        {!!serviceDetails.company.profileImageUri && (
                          <StyledAvatar imgUrl={serviceDetails.company.profileImageUri} />
                        )}
                        <TruncatedText variant="subtitle1" color="primary.main">
                          {serviceDetails.company.name}
                        </TruncatedText>
                      </Stack>
                    </StyledAttributeRow>
                    <StyledButton
                      variant="text"
                      typographyProps={{
                        variant: 'subtitle1',
                      }}
                      onClick={handleCompanyClick}
                    >
                      {t('moreDetails')}
                    </StyledButton>
                  </Stack>
                )}
                {!!serviceDetails?.master?.town && (
                  <StyledAttributeRow>
                    <Typography variant="subtitle1" color="primary.dark2">
                      {t('town')}:
                    </Typography>
                    <TruncatedText variant="subtitle1" color="primary.main">
                      {serviceDetails.master.town?.localizedName}
                    </TruncatedText>
                  </StyledAttributeRow>
                )}
                <StyledAttributeRow>
                  <Typography variant="subtitle1" color="primary.dark2">
                    {t('duration')}:
                  </Typography>
                  <TruncatedText variant="subtitle1" color="primary.main">
                    {convertTimeSpanToDayjs(serviceDetails.duration).format(
                      `H [${t('hoursShort')}] mm [${t('minutesShort')}]`,
                    )}
                  </TruncatedText>
                </StyledAttributeRow>
              </StyledAttributeSection>
            </Box>

            {!!serviceDetails.description && (
              <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                <Typography variant="body1" color="primary.main" sx={{ opacity: 0.7 }}>
                  {serviceDetails.description}
                </Typography>
              </pre>
            )}
          </StyledInfoSection>

          <StyledMasterSection>
            <MasterCard {...mapServiceModelToMasterCardViewModel(serviceDetails)} onClick={handleMasterClick} />
            <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between">
              <TruncatedText variant="h3" color="accent.main" component="h4">
                {priceText}
              </TruncatedText>
              <StyledIconButton favorite={favorite} onClick={toggleFavorite} disableRipple>
                <FavoriteIcon />
              </StyledIconButton>
            </Stack>
            <Button size="large" variant="contained" onClick={() => onBook(serviceDetails?.id)}>
              {t('bookButtonLabel')}
            </Button>
          </StyledMasterSection>
          <StyledContactsSection {...mapServiceModelToContactsSectionViewModel(serviceDetails)} withTitle />
        </StyledMainContainer>
      </Container>
      <ExamplesSection {...mapServicesToExamplesViewModel(serviceDetails)} />
      <BookServiceModal {...bookServiceModalProps} />
    </>
  );
};

export default ServiceDetailsPage;
