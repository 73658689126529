import { Category } from 'types';

const getUniqueCategories = (
  list: Array<{
    category: Category;
  }>,
): Array<Omit<Category, 'attributeGroups' | 'localId'>> => {
  return list.reduce<Array<Omit<Category, 'attributeGroups' | 'localId'>>>(
    (acc, item) =>
      acc.find(c => c.id === item.category.id)
        ? acc
        : [
            ...acc,
            {
              id: item.category.id,
              localizedName: item.category.localizedName,
            },
          ],
    [],
  );
};

export default getUniqueCategories;
