import { css, styled } from '@mui/material/styles';
import Slider from 'react-slick';
import preventForwardProps from 'utils/preventForwardProps';

const getSlideIndentation = (slidePosition: number, slideCount: number) => {
  const position = slidePosition - 2;
  return position > 0 ? Math.min(position, slideCount - 5) * -24 : 0;
};

export const StyledSlider = styled(
  Slider,
  preventForwardProps(['slidePosition', 'slideCount']),
)<{ slidePosition: number; slideCount: number }>(
  ({ theme, slidePosition, slideCount }) => css`
    & .slick-track {
      display: flex !important;
      gap: 8px;
    }

    & .slick-list {
      margin: 0 -8px;

      ${theme.breakpoints.down('md')} {
        overflow: inherit;
      }
    }

    & .slick-slide {
      aspect-ratio: 1 / 1;

      & img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 12px;
      }
    }

    & .slick-prev {
      left: -40px;
    }

    & .slick-next {
      right: -40px;
    }

    & .slick-arrow {
      color: ${theme.palette.primary.main};

      &:hover {
        color: ${theme.palette.accent.light};
      }

      &:active {
        color: ${theme.palette.accent.dark};
      }
    }

    & .slick-dots {
      display: flex !important;
      width: 120px;
      left: calc(50% - 60px);
      overflow: hidden;

      bottom: -34px;

      ${theme.breakpoints.up('md')} {
        bottom: -50px;
      }

      & button:before {
        color: ${theme.palette.primary.dark3};
        opacity: 1;
        font-size: 10px;
      }

      & .slick-active button:before {
        color: ${theme.palette.accent.main};
        opacity: 1;
      }

      & li {
        margin: 0 2px;
        left: ${getSlideIndentation(slidePosition, slideCount)}px;
        transition: all 0.3s;
      }
    }
  `,
);
