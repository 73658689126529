import React from 'react';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/material/styles';
import { StyledImg, StyledImgContainer, StyledImgPlaceholder, StyledImgPlaceholderWhiteBg } from './styled';

type Props = {
  src?: string;
  alt?: string;
  whiteBgPlaceholder?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
};

const Image: React.FC<Props> = ({ src, alt, whiteBgPlaceholder, className, sx }) => {
  const imageStatus = useLoadImage(src || '');

  const imgPlaceholder = whiteBgPlaceholder ? <StyledImgPlaceholderWhiteBg /> : <StyledImgPlaceholder />;

  return (
    <StyledImgContainer className={className} sx={sx}>
      {src && imageStatus === ImageStatus.Loaded ? <StyledImg src={src} alt={alt} /> : imgPlaceholder}
    </StyledImgContainer>
  );
};

export default Image;
