import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';

import { StyledBgContainer, StyledTopSection } from './styled';
import EmailSection from './EmailSection';
import BottomSection from './BottomSection';
import NavSection from './NavSection';

type Props = {
  className?: string;
};

const Footer: React.FC<Props> = ({ className }) => {
  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container>
      <StyledBgContainer className={className}>
        <StyledTopSection>
          <EmailSection />
          {upMedium && <NavSection />}
        </StyledTopSection>
        <BottomSection />
      </StyledBgContainer>
    </Container>
  );
};
export default Footer;
