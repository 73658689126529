import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Button from 'components/@shared/Button';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledButtonsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const StyledButton = styled(
  Button,
  preventForwardProps(['active']),
)<{ active: boolean }>(
  ({ active, theme }) => css`
    display: flex;
    justify-content: flex-start;
    background-color: ${theme.palette.primary.light2};
    padding: 12px 10px;
    font-size: 16px;
    border: 2px ${active ? theme.palette.accent.main : 'transparent'} solid;

    h6 {
      display: flex;
      align-items: center;
      position: relative;
      gap: 12px;
      width: 100%;
      font-weight: 700;
      color: ${active ? theme.palette.accent.main : 'unset'};
    }

    &:hover {
      background-color: ${theme.palette.primary.light2};
      border: 2px ${theme.palette.accent.main} solid;
    }
  `,
);

export const CheckIconWrapper = styled(CheckIcon, preventForwardProps(['active']))<{ active: boolean }>`
  display: ${props => (props.active ? 'block' : 'none')};
  position: absolute;
  right: 0;
`;
