import React, { useEffect, useRef } from 'react';

import { StyledClear, StyledHamburgerIcon, StyledIcon1, StyledIcon2, StyledIcon3 } from './styled';

type Props = {
  open: boolean;
  onToggle: () => void;
};

const HamburgerMenu: React.FC<Props> = ({ open, onToggle }) => {
  const icon1Ref = useRef<HTMLDivElement>(null);
  const icon2Ref = useRef<HTMLDivElement>(null);
  const icon3Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (icon1Ref.current && icon2Ref.current && icon3Ref.current) {
      if (open) {
        icon1Ref.current.classList.add('a');
        icon2Ref.current.classList.add('c');
        icon3Ref.current.classList.add('b');
      } else {
        icon1Ref.current.classList.remove('a');
        icon2Ref.current.classList.remove('c');
        icon3Ref.current.classList.remove('b');
      }
    }
  }, [open]);

  return (
    <StyledHamburgerIcon onClick={onToggle}>
      <StyledIcon1 ref={icon1Ref} />
      <StyledIcon2 ref={icon2Ref} />
      <StyledIcon3 ref={icon3Ref} />
      <StyledClear className="clear" />
    </StyledHamburgerIcon>
  );
};

export default HamburgerMenu;
