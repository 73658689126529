import { css, styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';
import { generateUniqColors } from 'utils/generateUniqColors';

export const StyledImgContainer = styled(
  Box,
  preventForwardProps(['size']),
)<{ size: string }>(
  ({ size }) => css`
    position: relative;
    width: ${size};
    aspect-ratio: 1 / 1;
  `,
);

export const StyledImg = styled('img')`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
`;

export const StyledDefaultAvatar = styled(
  Stack,
  preventForwardProps(['hash', 'fontSize']),
)<{
  hash: string;
  fontSize: number;
}>(({ hash, fontSize }) => {
  const [background, color] = generateUniqColors(hash);

  return css`
    background: ${background};
    color: ${color};
    width: 100%;
    aspect-ratio: 1 / 1;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: ${fontSize}px;
    font-weight: 600;
  `;
});
