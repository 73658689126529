import { useLazyQuery } from '@apollo/client';
import { useAppState } from 'contexts/app';
import { CityItem } from 'types';
import { GET_CITY_LIST_QUERY } from 'api/requests/filter';
import { useEffect } from 'react';
import { setDefaultFilterCityList, setFilterCityList } from 'contexts/app/actions';

const useFilterCityList = () => {
  const {
    state: { filterCityList, defaultFilterCityList },
    dispatch,
  } = useAppState();

  const [townSearch, { loading }] = useLazyQuery<{ towns_search: CityItem[] }, { input: string }>(GET_CITY_LIST_QUERY, {
    variables: { input: '' },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!defaultFilterCityList.length) {
      townSearch().then(res => {
        if (res.data) {
          dispatch(setFilterCityList(res.data.towns_search));
          dispatch(setDefaultFilterCityList(res.data.towns_search));
        }
      });
    } else {
      dispatch(setFilterCityList(defaultFilterCityList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchCity = (input: string) => {
    if (!input && defaultFilterCityList.length) {
      dispatch(setFilterCityList(defaultFilterCityList));
    } else {
      townSearch({ variables: { input } }).then(res => {
        if (res.data) {
          dispatch(setFilterCityList(res.data.towns_search));
        }
      });
    }
  };

  return { filterCityList, filterCityListLoading: loading, searchCity };
};

export default useFilterCityList;
