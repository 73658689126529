import { Accordion, Typography } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledAccordion = styled(
  Accordion,
  preventForwardProps(['error', 'disabled', 'isFilled']),
)<{ error?: boolean; disabled?: boolean; isFilled?: boolean }>(({ theme, error, disabled, expanded, isFilled }) => {
  let color = theme.palette.primary.dark3;

  if (error) {
    color = theme.palette.error.main;
  }

  if (expanded) {
    color = theme.palette.accent.main;
  }

  if (disabled) {
    color = theme.palette.primary.dark2;
  }

  return css`
    margin: 0;
    padding: 0;

    box-shadow: none;

    & select {
      visibility: hidden;
      height: 0;
      width: 0;
    }

    & .MuiAccordionSummary-root {
      border: 1px solid ${isFilled ? theme.palette.primary.light2 : color};
      border-radius: 12px;
      min-height: auto;
      padding: 16px 20px;
      background: ${isFilled ? theme.palette.primary.light2 : theme.palette.primary.light};
      cursor: ${disabled ? 'initial' : 'pointer'} !important;
    }

    & .MuiAccordionSummary-expandIconWrapper {
      color: ${disabled ? theme.palette.primary.dark2 : theme.palette.primary.main};
    }

    & .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      gap: 12px;

      margin: 0;
      min-height: auto;
      min-width: 0;

      &.Mui-expanded {
        margin: 0;
        min-height: auto;
      }
    }

    & .MuiCollapse-root {
      border: 1px solid ${isFilled ? theme.palette.primary.light2 : color};
      border-radius: 12px;
      background: ${isFilled ? theme.palette.primary.light2 : theme.palette.primary.light};
      margin-top: 8px;

      opacity: ${expanded ? 1 : 0};

      transition: all 0.25ms;

      &.MuiCollapse-hidden {
        margin-top: 0;
      }
    }

    & .MuiCollapse-wrapper {
      margin: 20px;
      width: auto;
    }

    & .MuiAccordionDetails-root {
      padding: 0;
    }

    & ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  `;
});

export const StiledLabel = styled(
  Typography,
  preventForwardProps(['focused, disabled']),
)<{ focused?: boolean; disabled?: boolean }>(
  ({ theme, disabled }) => css`
    color: ${disabled ? theme.palette.primary.dark2 : theme.palette.primary.dark3};
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;

    background: linear-gradient(
      180deg,
      transparent 0%,
      transparent 50%,
      ${theme.palette.primary.light} 50%,
      ${theme.palette.primary.light} 100%
    );
    position: absolute;
    top: -9px;
    left: 12px;
    padding: 0 4px;
  `,
);

export const StiledLi = styled(
  'li',
  preventForwardProps(['selected']),
)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    cursor: pointer;
    color: ${selected ? theme.palette.accent.main : theme.palette.primary.main};

    &:hover {
      color: ${theme.palette.accent.light};
    }

    &:focus {
      color: ${theme.palette.accent.dark};
    }
  `,
);
