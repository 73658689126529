import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Gap from 'components/@shared/Gap';
import Button from 'components/@shared/Button';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import filterMasters from 'utils/filterMasters';

import useMastersTab from './useMastersTab';
import MasterCard from './MasterCard';
import { StyledInput, StyledList } from './styled';

const MastersTab: React.FC = () => {
  const { t } = useTranslation();
  const { masters, loading, addMaster, onMasterClick } = useMastersTab();

  const [searchMasterQuery, setSearchMasterQuery] = useState<string>('');

  const filteredMasters = filterMasters(masters, searchMasterQuery);

  return (
    <>
      <Stack direction="row" gap="8px">
        <StyledInput
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          placeholder={t('companyCabinetPage.masterSearchPlaceholder')}
          onChange={e => setSearchMasterQuery(e.target.value)}
        />
        <Button sx={{ flex: '1 0 33%' }} variant="outlined" onClick={addMaster}>
          {t('companyCabinetPage.addMasterButtonLabel')}
        </Button>
      </Stack>
      <Gap size={20} />
      <StyledList>
        {!!filteredMasters.length &&
          filteredMasters.map(master => (
            <MasterCard key={master.id} {...master} onClick={() => master.id && onMasterClick(master.id)} />
          ))}
        {!loading && !filteredMasters.length && !!masters.length && (
          <Typography variant="body2" color="primary.dark2">
            {t('noOptionsText')}
          </Typography>
        )}
        {!loading && !filteredMasters.length && !masters.length && (
          <Typography variant="body2" color="primary.dark2">
            {t('companyCabinetPage.mastersEmptyStateMessage')}
          </Typography>
        )}
      </StyledList>
    </>
  );
};

export default MastersTab;
