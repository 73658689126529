import { Box, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';
import expandMoreIcon from 'assets/icons/expandMore.svg';

export const StyledDropdownBackground = styled(
  Box,
  preventForwardProps(['isDropdownOpen']),
)<{ isDropdownOpen?: boolean }>(
  ({ isDropdownOpen }) => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: ${isDropdownOpen ? 'block' : 'none'};
    background-color: transparent;
    opacity: 0;
    z-index: 10;
  `,
);

export const StyledUpperContainer = styled(Box)`
  width: 100%;
  position: static;
`;

export const StyledContainer = styled(
  Box,
  preventForwardProps(['error']),
)<{
  error?: boolean;
}>(
  ({ theme, error }) => css`
    & .react-tel-input {
      width: 100%;

      & .special-label {
        top: -10px;
        left: 0;

        color: ${error ? theme.palette.error.main : theme.palette.primary.dark3};
        background-color: ${theme.palette.background.default};
        pointer-events: none;
        // TODO: check that nothing is broken
        //z-index: 3;

        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        transform: scale(0.75);
      }

      &:focus-within {
        & .special-label {
          color: ${error ? theme.palette.error.main : theme.palette.primary.main};
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${theme.palette.background.default} inset !important;
      }

      & .form-control {
        width: 100%;
        height: 42px;
        padding: 10px 16px 10px 64px;

        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        background-color: ${theme.palette.background.default};
        border: 1px solid ${error ? theme.palette.error.main : theme.palette.primary.light3};
        border-radius: 8px;
        color: ${error ? theme.palette.error.main : theme.palette.primary.main};
        z-index: 2;
        -webkit-tap-highlight-color: transparent;

        &:hover {
          border-color: ${error ? theme.palette.error.main : theme.palette.accent.main};
          box-shadow: none;
        }

        &:focus {
          border-color: ${error ? theme.palette.error.main : theme.palette.accent.main};
          box-shadow: none;
        }

        &::placeholder {
          color: ${theme.palette.primary.dark3};
        }

        &.open {
          border-color: ${error ? theme.palette.error.dark2 : theme.palette.accent.main};
          border-radius: 8px;
        }
      }

      & .selected-flag {
        position: static;
        -webkit-tap-highlight-color: transparent;

        & .flag {
          top: 10px;
          margin: 0;
          // TODO: check that nothing is broken
          //z-index: 3;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            box-sizing: content-box;
            border-right: 30px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 12px solid transparent;
            border-top: 4px solid transparent;
            margin: -4px 0 0 -12px;
            border-radius: 12px;
          }

          & .arrow {
            left: 28px;
            top: 1px;
            width: 24px;
            height: 24px;
            background-color: ${theme.palette.grey[600]};
            mask-image: url(${expandMoreIcon});
            mask-size: contain;
            mask-repeat: no-repeat;
            border: none;

            &.up {
              background-color: ${theme.palette.grey[600]};
              mask-image: url(${expandMoreIcon});
              transform: rotate(180deg);
              mask-size: contain;
              mask-repeat: no-repeat;
            }
          }
        }
      }

      & .flag-dropdown {
        position: static;
        width: 100%;
      }

      & .country-list {
        width: 100%;
        max-height: 280px;
        margin: 0;
        border-top: none;
        border-radius: 8px;
        padding: 8px 0;
        box-shadow: 0 4px 8px #7070701a;
        z-index: 1000;

        & .country {
          font-family: Montserrat, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: ${theme.palette.primary.main};
          padding: 11px 13px 11px 50px;

          &:hover {
            background-color: ${theme.palette.primary.light3};
          }

        ,
        & . highlight {
          background-color: ${theme.palette.primary.light};

          &:hover {
            background-color: ${theme.palette.primary.light3};
          }
        }

          & .dial-code {
            color: ${theme.palette.grey[600]};

            &::before {
              content: '(';
            }

            &::after {
              content: ')';
            }
          }
        }
      }
    }
  `,
);

export const StyledHelperText = styled(
  Typography,
  preventForwardProps(['error']),
)<{
  error?: boolean;
}>(
  ({ theme, error }) => css`
    color: ${error ? theme.palette.error.main : theme.palette.primary.dark2};
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    margin: 3px 3px 3px 16px;
    white-space: pre-line;

    &.Mui-disabled {
      border: none !important;
    }
  `,
);
