import { css, styled } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';
import { CircularProgress, Stack } from '@mui/material';

export const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    box-shadow: inset 0 0 0 1px ${theme.palette.primary.light3};
    border-radius: 20px;
    padding: 16px 16px 12px;
  `,
);

export const StyledCell = styled(
  Stack,
  preventForwardProps(['active', 'disabled', 'selected']),
)<{
  active?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>(
  ({ theme, active, disabled, selected }) => css`
    border-radius: 8px;
    display: flex;
    aspect-ratio: 1;
    flex: 1 1 calc(100% / 7);
    align-items: center;
    justify-content: center;
    cursor: ${active && !disabled ? 'pointer' : 'default'};

    ${!disabled &&
    !selected &&
    css`
      color: ${theme.palette.primary.main};
    `};
    ${!disabled &&
    selected &&
    css`
      color: ${theme.palette.primary.light};
      background: ${theme.palette.accent.main};
    `};
    ${disabled &&
    css`
      color: ${theme.palette.primary.dark3};
    `};

    &:hover {
      ${active &&
      !disabled &&
      !selected &&
      css`
        color: ${theme.palette.accent.light};
      `};
      ${active &&
      !disabled &&
      selected &&
      css`
        background: ${theme.palette.accent.light};
      `};
    }

    &:active {
      ${active &&
      !disabled &&
      !selected &&
      css`
        color: ${theme.palette.accent.dark};
      `};
      ${active &&
      !disabled &&
      selected &&
      css`
        background: ${theme.palette.accent.dark};
      `};
    }

    &:focus {
      background: none;
    }
  `,
);

export const StyledLoadingContainer = styled(Stack)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => css`
    color: ${theme.palette.accent.main};
  `,
);
