export const getInitials = (str: string): string => {
  if (!str) return '';

  const initials = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const word of str.split(' ')) {
    initials.push(word[0].toUpperCase());
  }
  return initials.slice(0, 2).join('');
};
