const generateIncrementalArray = (length = 4) => {
  const array = [];
  for (let i = 1; i <= length; i++) {
    array.push(i);
  }

  return array;
};

export default generateIncrementalArray;
