import React, { useEffect, useState } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'components/@shared/Button';
import Container from 'components/@shared/Container';

import { StyledDrawer } from './styled';

type Props = {
  open: boolean;
  loading?: boolean;
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
};

const SaveChangesModal: React.FC<Props> = ({ open, loading, onDiscardChanges, onSaveChanges }) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const { t } = useTranslation();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledDrawer
      variant="persistent"
      anchor="bottom"
      open={openModal}
      onOpen={() => setOpenModal(true)}
      onClose={() => setOpenModal(false)}
    >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
          <Button
            variant="outlined"
            onClick={onDiscardChanges}
            sx={{ flex: upMedium ? '0 0 440px !important' : '1 1 50% !important' }}
            disabled={loading}
          >
            {t('discard')}
          </Button>
          <Button
            variant="contained"
            onClick={onSaveChanges}
            sx={{ flex: '1 1 50% !important' }}
            loading={loading}
          >
            {t('saveChanges')}
          </Button>
        </Stack>
      </Container>
    </StyledDrawer>
  );
};

export default SaveChangesModal;
