import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContainerWithBorder = styled(
  Stack,
  preventForwardProps(['error']),
)<{ error?: boolean }>(
  ({ theme, error }) => css`
    align-items: stretch;
    justify-content: start;
    padding: 20px;
    border: 1px solid ${error ? theme.palette.error.main : theme.palette.primary.light3};
    border-radius: 16px;
    width: 100%;
  `,
);
