import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import Gap from 'components/@shared/Gap';
import { FormHelperText, Stack, Typography } from '@mui/material';
import Switch from 'components/@shared/Switch';
import Input from 'components/@shared/Input';
import PhoneInput, { PhoneMetaData } from 'components/@shared/PhoneInput';
import SocialMedia, { SocialMediaType } from 'components/@common/SocialMedia';
import SaveChangesModal from 'components/@common/SaveChangesModal';
import ChipsSection from 'components/@common/ChipsSection';
import Autocomplete from 'components/@shared/Autocomplete';
import Button from 'components/@shared/Button';
import Select from 'components/@shared/Select';
import { ABOUT_MAXIMUM_LENGTH, useYupRules } from 'hooks/useYupRules';
import useForm from 'hooks/useForm';

import { CityItem } from 'types/filter';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AutocompleteChangeDetails } from '@mui/base/useAutocomplete/useAutocomplete';
import TimePicker from 'components/@shared/TimePicker';
import AddressAutocomplete from 'components/@shared/AddressAutocomplete';
import { DayOfWeekEnum } from 'constatns';
import { getScheduleChipList } from 'utils/getScheduleChipList';
import convertDayjsToTimespan from 'utils/convertDayjsToTimespan';
import Slug from 'components/@common/Slug';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
import { Address } from 'types';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';
import useDayOfWeekList from 'hooks/useDayOfWeekList';

import useInfoTab from './useInfoTab';
import {
  StyledActivateContainer,
  StyledLeftContainer,
  StyledMainContainer,
  StyledRightContainer,
  StyledInput,
  StyledActivateDescription,
} from './styled';
import { StyledImg, StyledImgContainer, StyledImgPlaceholder } from '../styled';

type CallbackFunction<T extends any[] = any[]> = (...args: T) => void;

export type CompanyFormValues = {
  name: string;
  contactName: string;
  city: CityItem | null;
  address: Address | null;
  phoneNumber: string;
  phoneMetaData: PhoneMetaData | {};
  email: string;
  about?: string;
  instagramLink?: string;
  facebookLink?: string;
  telegramLink?: string;
  _scheduleRequired: boolean;
};

const InfoTab: React.FC = () => {
  const { t } = useTranslation();

  const {
    company,
    cityList,
    cityListLoading,
    citySelectInputValue,
    switchAccountLoading,
    manageCompanyLoading,
    onCitySelectInputChange,
    onSwitchActivateCompany,
    onSaveChanges,
    onChangeScheduleDay,
    onDeleteSchedule,
    handleImgInputChange,
    handleSlugUpdate,
  } = useInfoTab();

  const [fromDateSchedule, setFromDateSchedule] = useState<Dayjs | null>(null);
  const [toDateSchedule, setToDateSchedule] = useState<Dayjs | null>(null);
  const [scheduleDay, setScheduleDay] = useState<DayOfWeekEnum | null>(null);
  const [draftFormValues, setDraftFormValues] = useState<CompanyFormValues | null>();
  const [validateOnChange, setValidateOnChange] = useState(company.activated);

  const ref = useRef<HTMLInputElement>(null);

  const imageStatus = useLoadImage(company.profileImageUri || '');

  const {
    requiredStringRule,
    cityRule,
    addressRule,
    phoneNumberRule,
    phoneMetaDataRule,
    emailRule,
    aboutRule,
    socialMediaLinkRule,
    scheduleRequiredRule,
  } = useYupRules();

  const initialValues: CompanyFormValues = {
    name: company.name || '',
    contactName: company.contactName || '',
    city: company.town || null,
    address: company.address || null,
    phoneNumber: company.phone || '',
    phoneMetaData: {},
    email: company.email || '',
    about: company.about || '',
    instagramLink: company.instagramLink || '',
    facebookLink: company.facebookLink || '',
    telegramLink: company.telegramLink || '',
    _scheduleRequired:
      !company.schedule || !Object.entries(company.schedule).find(([key, value]) => !key.startsWith('__') && !!value),
  };

  const {
    formik,
    setFieldValue,
    formikHelpers: { getFormikProps, withOnChangeStringParameter },
  } = useForm<CompanyFormValues>({
    initialValues: {
      ...initialValues,
      ...draftFormValues,
      _scheduleRequired: initialValues._scheduleRequired,
    },
    yupSchema: {
      name: requiredStringRule,
      contactName: requiredStringRule,
      city: cityRule,
      address: addressRule,
      phoneNumber: phoneNumberRule,
      phoneMetaData: phoneMetaDataRule,
      email: emailRule,
      about: aboutRule,
      instagramLink: socialMediaLinkRule,
      facebookLink: socialMediaLinkRule,
      telegramLink: socialMediaLinkRule,
      _scheduleRequired: scheduleRequiredRule,
    },
    enableReinitialize: true,
    validateOnChange,
    onSubmit: async () => {
      await onSwitchActivateCompany();
    },
  });

  useEffect(() => {
    if (!Object.keys(formik.errors).length && !company.activated) {
      setValidateOnChange(false);
    }
    if (company.activated) {
      setValidateOnChange(true);
    }
  }, [formik.errors, company.activated]);

  const handleCitySelectChange = useCallback(
    (event: any, value: any, reason: any, details?: AutocompleteChangeDetails<unknown>) => {
      const city = (details?.option as CityItem) || null;
      setFieldValue('city', city);
      onCitySelectInputChange((details?.option as CityItem)?.localizedName ?? '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dayOfWeekList = useDayOfWeekList();

  const handleChangeCitySelectInput = (event: any, value: string, reason: string) => {
    if (reason === 'reset') {
      onCitySelectInputChange(formik.values.city?.localizedName ?? '');
    }
    if (reason === 'input') {
      onCitySelectInputChange(value);
      if (!value) {
        setFieldValue('city', null);
      }
    }
  };

  const handleAddScheduleDay = () => {
    if (scheduleDay) {
      onChangeScheduleDay(scheduleDay, {
        from: convertDayjsToTimespan(fromDateSchedule || dayjs().startOf('day')),
        to: convertDayjsToTimespan(toDateSchedule || dayjs().startOf('day')),
      });
      setFromDateSchedule(null);
      setToDateSchedule(null);
      setScheduleDay(null);
    }
  };

  const handleChangeActiveAccount = async () => {
    handleDiscardChanges();
    if (!company.activated) {
      const result = await formik.validateForm(initialValues);
      formik.setErrors(result);
      if (Object.keys(result).length) {
        setValidateOnChange(true);
        setTimeout(() => {
          document.querySelector('.Mui-error')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
        return;
      }
    }
    await onSwitchActivateCompany();
  };

  const handleSaveChanges = async () => {
    if (company.activated) {
      const result = await formik.validateForm();
      formik.setErrors(result);
      if (Object.keys(result).length) {
        return;
      }
    }
    await onSaveChanges(formik.values);
    setDraftFormValues(null);
  };

  const isAnyChanges = () => {
    return (
      initialValues.name !== formik.values.name ||
      initialValues.contactName !== formik.values.contactName ||
      initialValues.city?.id !== formik.values.city?.id ||
      initialValues.address?.addressLine !== formik.values.address?.addressLine ||
      initialValues.phoneNumber !== formik.values.phoneNumber ||
      initialValues.email !== formik.values.email ||
      initialValues.about !== formik.values.about ||
      initialValues.instagramLink !== formik.values.instagramLink ||
      initialValues.facebookLink !== formik.values.facebookLink ||
      initialValues.telegramLink !== formik.values.telegramLink
    );
  };

  const handleDiscardChanges = () => {
    formik.resetForm();
    onCitySelectInputChange(initialValues.city?.localizedName ?? '');
  };

  const handleAddImgClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const scheduleList = dayOfWeekList.filter(i => !company.schedule?.[i.id]);

  const withSavingDraft =
    <T extends CallbackFunction>(callback: T) =>
    (...props: Parameters<T>) => {
      setDraftFormValues(formik.values);
      callback(...props);
    };

  return (
    <>
      <StyledActivateContainer>
        <Stack gap="4px">
          <Typography variant="subtitle1" color="primary.main">
            {company.activated
              ? t('companyCabinetPage.activeAccountTitle')
              : t('companyCabinetPage.notActiveAccountTitle')}
          </Typography>
          <StyledActivateDescription variant="body3" color="primary.dark2" active={!company.activated}>
            {t('companyCabinetPage.notActiveAccountDescription')}
          </StyledActivateDescription>
        </Stack>
        <Switch
          checked={company.activated || false}
          onChange={handleChangeActiveAccount}
          loading={switchAccountLoading}
        />
      </StyledActivateContainer>
      <Gap size={24} />
      <StyledMainContainer>
        <StyledLeftContainer>
          <ContainerWithBorder gap="24px">
            <Typography variant="subtitle1" color="primary.main">
              {t('companyCabinetPage.companyInfoTitle')}
            </Typography>
            <Stack direction="row" gap="16px">
              <StyledInput type="file" onChange={handleImgInputChange} ref={ref} accept="image/png, image/jpeg" />
              <StyledImgContainer>
                {company.profileImageUri && imageStatus === ImageStatus.Loaded ? (
                  <StyledImg src={company.profileImageUri} alt="avatar" />
                ) : (
                  <StyledImgPlaceholder />
                )}
              </StyledImgContainer>
              <Stack justifyContent="space-between" gap="16px" flex="1 1 66%">
                <Stack gap="4px">
                  <Typography variant="subtitle2" color="primary.main">
                    {t('companyCabinetPage.avatarTitle')}
                  </Typography>
                  <Typography variant="body3" color="primary.main">
                    {t('companyCabinetPage.avatarDescription')}
                  </Typography>
                </Stack>
                <Button variant="outlined" sx={{ paddingX: '10px !important' }} onClick={handleAddImgClick}>
                  {t(
                    company.profileImageUri
                      ? 'companyCabinetPage.avatarChangeButtonLabel'
                      : 'companyCabinetPage.avatarAddButtonLabel',
                  )}
                </Button>
              </Stack>
            </Stack>
            <Input
              {...getFormikProps('name')}
              label={t('companyCabinetPage.nameFieldLabel')}
              placeholder={t('companyCabinetPage.nameFieldPlaceholder')}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
            />
            <Input
              {...getFormikProps('contactName')}
              label={t('companyCabinetPage.contactNameFieldLabel')}
              placeholder={t('companyCabinetPage.contactNameFieldPlaceholder')}
              error={!!formik.errors.contactName}
              helperText={formik.errors.contactName}
            />
            <Autocomplete
              value={formik.values.city}
              inputValue={citySelectInputValue}
              defaultValue={company.town}
              options={cityList}
              loading={cityListLoading}
              InputProps={{
                label: t('companyCabinetPage.cityFieldLabel'),
                placeholder: t('companyCabinetPage.cityFieldPlaceholder'),
                error: !!formik.errors.city,
                helperText: formik.errors.city,
              }}
              loadingStateMessage={t('loadingText')}
              emptyStateMessage={t('noOptionsText')}
              onChange={handleCitySelectChange}
              onInputChange={handleChangeCitySelectInput}
            />
            <AddressAutocomplete
              value={formik.values.address}
              onChange={value => setFieldValue('address', value)}
              label={t('companyCabinetPage.addressFieldLabel')}
              placeholder={t('companyCabinetPage.addressFieldPlaceholder')}
              error={!!formik.errors.address}
              helperText={formik.errors.address}
            />
            <PhoneInput
              {...getFormikProps('phoneNumber')}
              onChange={(value, data) => {
                formik.setFieldValue('phoneMetaData', data);
                formik.setFieldValue('phoneNumber', value);
              }}
              label={t('companyCabinetPage.phoneNumberLabel')}
              error={!!formik.errors.phoneNumber}
              helperText={formik.errors.phoneNumber}
            />
            <Input
              {...getFormikProps('email')}
              InputProps={{
                type: 'email',
              }}
              label={t('companyCabinetPage.emailLabel')}
              placeholder={t('companyCabinetPage.emailPlaceholder')}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
            <Input
              {...getFormikProps('about')}
              label={t('companyCabinetPage.aboutLabel')}
              placeholder={t('companyCabinetPage.aboutPlaceholder')}
              multiline
              rows={4}
              inputProps={{ maxLength: ABOUT_MAXIMUM_LENGTH }}
              error={!!formik.errors.about}
              helperText={formik.errors.about}
            />
            <Stack gap="8px">
              <SocialMedia
                {...withOnChangeStringParameter(getFormikProps('telegramLink'))}
                type={SocialMediaType.Telegram}
              />
              <SocialMedia
                {...withOnChangeStringParameter(getFormikProps('instagramLink'))}
                type={SocialMediaType.Instagram}
              />
              <SocialMedia
                {...withOnChangeStringParameter(getFormikProps('facebookLink'))}
                type={SocialMediaType.Facebook}
              />
            </Stack>
          </ContainerWithBorder>
        </StyledLeftContainer>
        <StyledRightContainer>
          {!!company.slug && <Slug value={company.slug} onChange={handleSlugUpdate} />}

          <ContainerWithBorder gap="16px" error={!!formik.errors._scheduleRequired}>
            <Stack gap="16px">
              <Typography variant="subtitle1" color="primary.main">
                {t('companyCabinetPage.scheduleTitle')}
              </Typography>
              <Typography variant="subtitle2" color="primary.main">
                {t('companyCabinetPage.scheduleSubtitle')}
              </Typography>
              <Select
                itemList={scheduleList}
                value={dayOfWeekList.find(i => i.id === scheduleDay) || null}
                label={t('companyCabinetPage.scheduleSelectLabel')}
                shrink={false}
                withIcons
                disabled={!scheduleList.length}
                onChange={item => setScheduleDay((item?.id as DayOfWeekEnum) || null)}
              />
              <Stack direction="row" gap="12px">
                <TimePicker
                  value={fromDateSchedule}
                  label={t('companyCabinetPage.fromLabel')}
                  maxTime={toDateSchedule}
                  format="HH:mm"
                  onChange={setFromDateSchedule}
                  disabled={!scheduleDay}
                  sx={{ flex: '1 1 50%' }}
                />
                <TimePicker
                  value={toDateSchedule}
                  label={t('companyCabinetPage.toLabel')}
                  minTime={fromDateSchedule}
                  format="HH:mm"
                  onChange={setToDateSchedule}
                  disabled={!scheduleDay}
                  sx={{ flex: '1 1 50%' }}
                />
              </Stack>
              <Button
                variant="contained"
                onClick={withSavingDraft(handleAddScheduleDay)}
                disabled={!toDateSchedule || fromDateSchedule === toDateSchedule}
              >
                {t('companyCabinetPage.scheduleAddButtonLabel')}
              </Button>
              {!!formik.errors._scheduleRequired && (
                <FormHelperText error>
                  <Typography variant="body3">{formik.errors._scheduleRequired}</Typography>
                </FormHelperText>
              )}
              {!!company.schedule &&
                !!Object.entries(company.schedule).find(([key, value]) => !key.startsWith('__') && !!value) && (
                  <ChipsSection
                    header={t('companyCabinetPage.scheduleChipListTitle')}
                    chipList={getScheduleChipList(company.schedule)}
                    onDeleteSection={withSavingDraft(onDeleteSchedule)}
                    onDeleteItem={withSavingDraft(({ id }) => onChangeScheduleDay(id as DayOfWeekEnum, undefined))}
                  />
                )}
            </Stack>
          </ContainerWithBorder>
        </StyledRightContainer>
      </StyledMainContainer>
      <SaveChangesModal
        onSaveChanges={handleSaveChanges}
        onDiscardChanges={handleDiscardChanges}
        open={isAnyChanges()}
        loading={manageCompanyLoading}
      />
    </>
  );
};

export default InfoTab;
