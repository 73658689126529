import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchSection from 'components/@common/SearchSection';
import OverviewSection from 'components/@common/OverviewSection';
import AuthModal from 'components/@common/AuthModal';

import Header from './Header';
import Popular from './Popular';

const MainPage: React.FC = () => {
  const { t } = useTranslation();

  const [openAuthModal, setOpenAuthModal] = useState(false);

  return (
    <>
      <Header />
      <SearchSection mainPage />
      <Popular />
      <OverviewSection
        title={t('mainPage.overviewSectionTitle')}
        subtitle={t('mainPage.overviewSectionSubtitle')}
        actionButtonLabel={t('mainPage.overviewSectionActionButtonLabel')}
        onActionButtonClick={() => setOpenAuthModal(true)}
      />
      <AuthModal open={openAuthModal} onClose={() => setOpenAuthModal(false)} />
    </>
  );
};

export default MainPage;
