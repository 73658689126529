import { MasterCardProps } from '../components/@common/MasterCard';
import { OutputServiceDto } from '../types';
import { getScheduleChipList } from './getScheduleList';

const mapServiceModelToMasterCardViewModel = ({
  master: { contactName, profileImageUri, categories, schedule },
  category,
}: OutputServiceDto): Omit<MasterCardProps, 'onClick'> => {
  return {
    name: contactName,
    profileImageUri,
    qualification: categories.find(item => item.id === category.id)?.attributeGroups.find(item => item.isQualification)
      ?.attribute.localizedName,
    schedule: schedule ? getScheduleChipList(schedule) : [],
  };
};

export default mapServiceModelToMasterCardViewModel;
