import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const convertTimeSpanToDayjs = (timespanString: string): Dayjs => {
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
  const match = timespanString.match(regex);

  if (!match) {
    throw new Error('Invalid timespan format');
  }

  const hours = match[1] ? parseInt(match[1], 10) : 0;
  const minutes = match[2] ? parseInt(match[2], 10) : 0;

  return dayjs(0).add(hours, 'hour').add(minutes, 'minute').utc();
};

export default convertTimeSpanToDayjs;
