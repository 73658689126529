import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule.svg';
import { ReactComponent as AddressIcon } from 'assets/icons/address.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import extractNameFromUrl from 'utils/extractNameFromUrl';

import { Stack, Typography } from '@mui/material';
import PhoneContact from './PhoneContact';
import Contact from './Contact';

export type ContactsSectionProps = {
  phone?: string;
  email?: string;
  schedule?: string[];
  address?: string;
  instagram?: string;
  facebook?: string;
  telegram?: string;
  withTitle?: boolean;
  showPhoneLogic?: boolean;
  className?: string;
};

const ContactsSection: React.FC<ContactsSectionProps> = ({
  phone,
  email,
  schedule,
  address,
  instagram,
  facebook,
  telegram,
  withTitle,
  showPhoneLogic,
  className,
}) => {
  const { t } = useTranslation();

  const handlePhoneClick = () => window.open(`tel:${phone}`, '_self');
  const handleEmailClick = () => window.open(`mailto:${email}`, '_self');
  const handleInstagramClick = () => window.open(instagram, '_self');
  const handleFacebookClick = () => window.open(facebook, '_self');
  const handleTelegramClick = () => window.open(telegram, '_self');

  return (
    <Stack gap="20px" className={className}>
      {withTitle && <Typography variant="h4">{t('contactsSection.title')}</Typography>}
      <Stack gap="12px">
        {!!phone && <PhoneContact value={phone} onClick={handlePhoneClick} showLogic={showPhoneLogic} />}
        {!!email && (
          <Contact
            label={t('contactsSection.emailContactLabel')}
            value={email}
            icon={<MailIcon />}
            onClick={handleEmailClick}
          />
        )}
        {!!schedule?.length && (
          <Contact
            label={t('contactsSection.scheduleContactLabel')}
            value={
              <Stack gap="4px">
                {schedule?.map(item => (
                  <Typography variant="subtitle1" key={item}>
                    {item}
                  </Typography>
                ))}
              </Stack>
            }
            icon={<ScheduleIcon />}
          />
        )}
        {!!address && (
          <Contact label={t('contactsSection.addressContactLabel')} value={address} icon={<AddressIcon />} />
        )}
        {!!instagram && (
          <Contact
            label={t('socialMedia.instagram')}
            value={`@${extractNameFromUrl(instagram)}`}
            icon={<InstagramIcon />}
            isLink
            onClick={handleInstagramClick}
          />
        )}
        {!!facebook && (
          <Contact
            label={t('socialMedia.facebook')}
            value={extractNameFromUrl(facebook)}
            icon={<FacebookIcon />}
            isLink
            onClick={handleFacebookClick}
          />
        )}
        {!!telegram && (
          <Contact
            label={t('socialMedia.telegram')}
            value={`@${extractNameFromUrl(telegram)}`}
            icon={<TelegramIcon />}
            isLink
            onClick={handleTelegramClick}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ContactsSection;
