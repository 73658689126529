import { Master, Category, RequiredAttributeGroup, OutputMasterServiceDto } from 'types';
import { CategoryEnum } from 'constatns';

import { ACTION } from './constants';

export const setMaster = (payload: Master) => ({
  type: ACTION.SET_MASTER,
  payload,
});

export const resetMaster = () => ({
  type: ACTION.RESET_MASTER,
});

export const mergeMaster = (payload: Partial<Master>) => ({
  type: ACTION.MERGE_MASTER,
  payload,
});

export const setCurrentCategory = (payload: Category) => ({
  type: ACTION.SET_CURRENT_CATEGORY,
  payload,
});

export const resetCurrentCategory = () => ({
  type: ACTION.RESET_CURRENT_CATEGORY,
});

export const setCurrentService = (payload: OutputMasterServiceDto) => ({
  type: ACTION.SET_CURRENT_SERVICE,
  payload,
});

export const resetCurrentService = () => ({
  type: ACTION.RESET_CURRENT_SERVICE,
});

export const mergeRequiredAttributes = (payload: Partial<Record<CategoryEnum, RequiredAttributeGroup[]>>) => ({
  type: ACTION.MERGE_REQUIRED_ATTRIBUTES,
  payload,
});

export const resetRequiredAttributes = () => ({
  type: ACTION.RESET_REQUIRED_ATTRIBUTES,
});
