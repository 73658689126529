import { StateType } from './types';

export enum ACTION {
  SET_COMPANY = 'SET_COMPANY',
  RESET_COMPANY = 'RESET_COMPANY',
  MERGE_COMPANY = 'MERGE_COMPANY',
}

export const initialState: StateType = {
  company: {},
};
