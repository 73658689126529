import { css, styled } from '@mui/material/styles';
import { ReactComponent as ImgPlaceholderIconWhiteBg } from 'assets/icons/imgPlaceholderWhiteBg.svg';
import { ReactComponent as ImgPlaceholderIcon } from 'assets/icons/imgPlaceholder.svg';

import { Box } from '@mui/material';

export const StyledImgContainer = styled(Box)`
  position: relative;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const StyledImg = styled('img')`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const imgPlaceholderStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledImgPlaceholderWhiteBg = styled(ImgPlaceholderIconWhiteBg)(() => imgPlaceholderStyles);
export const StyledImgPlaceholder = styled(ImgPlaceholderIcon)(() => imgPlaceholderStyles);
