import { useEffect, useState } from 'react';

export enum ImageStatus {
  Loading = 'Loading',
  Error = 'Error',
  Loaded = 'Loaded',
}

const useLoadImage = (src: string) => {
  const [imageStatus, setImageStatus] = useState<ImageStatus>(ImageStatus.Loading);

  useEffect(() => {
    setImageStatus(ImageStatus.Loading);
    const img = new Image();
    img.onload = () => setImageStatus(ImageStatus.Loaded);
    img.onerror = () => setImageStatus(ImageStatus.Error);
    img.src = src;
  }, [src]);

  return imageStatus;
};

export default useLoadImage;
