const stringToNumber = (str?: string): number | undefined => {
  if (str === undefined) {
    return undefined;
  }
  const number = Number(str);
  if (isNaN(number)) {
    return undefined;
  }
  return number;
};

export default stringToNumber;
