import { css, styled } from '@mui/material/styles';
import ReservationCalendar from 'components/@common/ReservationCalendar';
import { APP_BAR_HEIGHT } from 'constatns';
import { Button, Stack } from '@mui/material';

export const StyledLeftSectionContainer = styled(Stack)`
  position: sticky;
  top: ${APP_BAR_HEIGHT}px;
  padding-top: 20px;
  gap: 20px;
  z-index: 1;
`;

export const StyledReservationCalendar = styled(ReservationCalendar)`
  .reservation-calendar--header {
    position: sticky;
    top: ${APP_BAR_HEIGHT}px;
    padding-top: 20px;
    z-index: 1;
  }
`;

export const StyledButton = styled(Button)(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 16px;
    border-radius: 12px;
    text-transform: none;
    flex: 1 1 50%;

    background: ${theme.palette.primary.light2};
    color: ${theme.palette.primary.main};

    svg {
      flex-shrink: 0;
    }

    &:hover {
      color: ${theme.palette.accent.light};
      background: ${theme.palette.primary.light2};
    }

    &:active {
      color: ${theme.palette.accent.dark};
      background: ${theme.palette.primary.light2};
    }
  `;
});
