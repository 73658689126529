import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import { CONTAINER_PADDING_X_MOBILE } from 'styles/constants';
import { APP_BAR_HEIGHT } from 'constatns';

export const StyledDrawer = styled(Drawer)`
  top: ${APP_BAR_HEIGHT}px;

  & .MuiBackdrop-root {
    display: none;
  }

  & .MuiDrawer-paper {
    height: calc(100dvh - ${APP_BAR_HEIGHT}px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: ${CONTAINER_PADDING_X_MOBILE}px;
  }
`;

export const StyledItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 40px;
  flex-grow: 1;
`;
