import React, { useCallback, useEffect, useState } from 'react';
import { DayOfWeekEnum } from 'constatns';
import { Company } from 'types';
import useFilterCityList from 'hooks/useFilterCityList';
import useMutation from 'hooks/useMutation';

import { SET_COMPANY_PROFILE_PHOTO, SET_COMPANY_SLUG } from 'api/requests/company';
import { useCompanyState } from 'contexts/company';
import { setCompany } from 'contexts/company/actions';
import { CompanyFormValues } from './index';
import useSwitchActivateCompany from './useSwitchActivateCompany';
import useManageCompany from './useManageCompany';

const useInfoTab = () => {
  const {
    state: { company },
    dispatch,
  } = useCompanyState();

  const { filterCityList, filterCityListLoading, searchCity } = useFilterCityList();

  const [citySearchQuery, setCitySearchQuery] = useState('');

  const [setCompanySlug] = useMutation<
    { setCompanySlug: Company },
    {
      slug: string;
    }
  >(SET_COMPANY_SLUG, { withErrorBanner: false });

  const [setCompanyProfilePhoto] = useMutation<
    { setCompanyProfilePhoto: Company },
    { companyId?: number; photo: File }
  >(SET_COMPANY_PROFILE_PHOTO);

  const { switchAccountLoading, onSwitchActivateCompany } = useSwitchActivateCompany();
  const { manageCompany, loading: manageCompanyLoading } = useManageCompany();

  useEffect(() => {
    searchCity(citySearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySearchQuery]);

  useEffect(() => {
    if (company.town?.localizedName) {
      setCitySearchQuery(company.town?.localizedName);
    }
  }, [company.town?.localizedName]);

  const onCitySelectInputChange = useCallback((value: string) => {
    setCitySearchQuery(value);
  }, []);

  const onSaveChanges = (companyFormValues: CompanyFormValues) => manageCompany({ companyFormValues });

  const onChangeScheduleDay = (day: DayOfWeekEnum, daySchedule: { from: string; to: string } | undefined) =>
    manageCompany({
      schedule: {
        ...company.schedule,
        [day]: daySchedule,
      },
    });

  const onDeleteSchedule = () =>
    manageCompany({
      schedule: Object.values(DayOfWeekEnum).reduce((acc, i) => ({ ...acc, [i]: null }), {}),
    });

  const handleImgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file === undefined) return;
    setCompanyProfilePhoto({ variables: { companyId: company.id, photo: file } })
      .then(res => {
        if (res.data) {
          dispatch(setCompany(res.data.setCompanyProfilePhoto));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSlugUpdate = async (slug: string) => {
    const response = await setCompanySlug({ variables: { slug } });
    if (response.data?.setCompanySlug) {
      dispatch(setCompany(response.data.setCompanySlug));
    }
  };

  return {
    company,
    cityList: filterCityList,
    cityListLoading: filterCityListLoading,
    citySelectInputValue: citySearchQuery,
    switchAccountLoading,
    manageCompanyLoading,
    onCitySelectInputChange,
    onSwitchActivateCompany,
    onSaveChanges,
    onChangeScheduleDay,
    onDeleteSchedule,
    handleImgInputChange,
    handleSlugUpdate,
  };
};

export default useInfoTab;
