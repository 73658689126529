import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import i18n from 'localizations';

dayjs.extend(duration);

const formatDuration = (value: string) =>
  dayjs.duration(value).format(`H[${i18n.t('hoursShort')}] m[${i18n.t('minutesShort')}]`);

export default formatDuration;
