import React, { useEffect, useRef, useState } from 'react';

import { StyledButton, StyledButtonList, StyledButtonsContainer } from './styled';

type Item<T extends string | number> = {
  id: T;
  label: string;
};

export type ScrollableButtonListProps<T extends string | number> = {
  list: Item<T>[];
  selectedItemId: T;
  onClick: (id: T) => void;
};

const ScrollableButtonList = <T extends number | string>({
  list,
  selectedItemId,
  onClick,
}: ScrollableButtonListProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const [blurLeft, setBlurLeft] = useState(false);
  const [blurRight, setBlurRight] = useState(true);

  useEffect(() => {
    if (ref.current) {
      const scrollHandler = (e: Event) => {
        const el = e.target as HTMLDivElement;
        setBlurLeft(el.scrollLeft > 0)
        setBlurRight((el.scrollWidth - el.offsetWidth - el.scrollLeft) > 1)
      };

      ref.current.addEventListener('scroll', scrollHandler);

      return () => {
        ref.current?.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [ref.current]);

  return (
    <StyledButtonsContainer blurLeft={blurLeft} blurRight={blurRight}>
      <StyledButtonList ref={ref}>
        {list.map(({ id, label }) => {
          return (
            <StyledButton key={id} selected={id === selectedItemId} disableRipple onClick={() => onClick(id)}>
              {label}
            </StyledButton>
          );
        })}
      </StyledButtonList>
    </StyledButtonsContainer>
  );
};

export default ScrollableButtonList;
