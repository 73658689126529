import { css, styled } from '@mui/material/styles';
import Button from 'components/@shared/Button';

export const StyledButton = styled(Button)(({ theme }) => {
  return css`
    width: 100%;
    padding: 16px;
    border: 1px ${theme.palette.primary.light3} solid !important;
  `;
});

export const StyledInlineButton = styled(Button)`
  display: inline;
  border: none;
`;
