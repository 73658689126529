import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import formatDuration from 'utils/formatDuration';
import ScrollableButtonList from 'components/@shared/ScrollableButtonList';

import {
  StyledContainer,
  StyledSubcategoryList,
  StyledServiceContainer,
  StyledServiceListContainer,
  StyledIconButton,
} from './styled';

export type Service = {
  id: number;
  name: string;
  duration: string;
  price?: number;
};

export type ServicesByQualification = {
  id: string;
  qualification: string;
  serviceList: Service[];
};

export type ServicesBySubcategory = Record<string, ServicesByQualification[]>;
export type Subcategories = Record<string, string>;

export type AllServicesSectionProps = {
  servicesBySubcategory: ServicesBySubcategory;
  subcategories: Subcategories;
  onOpenService: (id: number) => void;
  onBookService: (id: number) => void;
};

const AllServicesSection: React.FC<AllServicesSectionProps> = ({
  servicesBySubcategory,
  subcategories,
  onOpenService,
  onBookService,
}) => {
  const { t } = useTranslation();

  const subcategoryList = Object.entries(subcategories);

  const [sliderKey, setSliderKey] = useState(subcategoryList[0][0]);

  const qualifications = servicesBySubcategory[sliderKey];

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleClickCategory = (key: string) => {
    setSliderKey(key);
  };

  return (
    <StyledContainer>
      <Typography variant="h4">{t('allServices')}</Typography>

      <ScrollableButtonList
        selectedItemId={sliderKey}
        list={Object.entries(subcategories).map(([key, value]) => ({ id: key, label: value }))}
        onClick={handleClickCategory}
      />

      <StyledSubcategoryList>
        {qualifications.map(q => (
          <Stack key={q.id} gap={upMedium ? '12px' : '8px'}>
            <Typography variant="subtitle1" color="primary.main">
              {q.qualification}
            </Typography>
            <StyledServiceListContainer>
              {q.serviceList.map(s => (
                <StyledServiceContainer key={s.id} onClick={() => onOpenService(s.id)}>
                  <Typography variant="h6" color="primary.main">
                    {s.name}
                  </Typography>
                  <Stack direction="row" gap="8px">
                    <Typography variant="body1" color="primary.dark2">
                      {t('duration')}:
                    </Typography>
                    <Typography variant="body1" color="primary.dark2">
                      {formatDuration(s.duration)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" color="accent.main">
                      {s.price ? t('price', { val: s.price }) : t('priceFree')}
                    </Typography>
                    <StyledIconButton
                      onClick={e => {
                        e.stopPropagation();
                        onBookService(s.id);
                      }}
                      disableRipple
                    >
                      <BookIcon />
                    </StyledIconButton>
                  </Stack>
                </StyledServiceContainer>
              ))}
            </StyledServiceListContainer>
          </Stack>
        ))}
      </StyledSubcategoryList>
    </StyledContainer>
  );
};

export default AllServicesSection;
