import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

const useColumnsCount = () => {
  const theme = useTheme();
  const twoColumns = useMediaQuery(theme.breakpoints.up(790));
  const threeColumns = useMediaQuery(theme.breakpoints.up(1125));
  const fourColumns = useMediaQuery(theme.breakpoints.up(1440));

  return useMemo(() => {
    if (fourColumns) {
      return 4;
    }
    if (threeColumns) {
      return 3;
    }
    if (twoColumns) {
      return 2;
    }
    return 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoColumns, threeColumns]);
};

export default useColumnsCount;
