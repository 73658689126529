import { styled, css } from '@mui/material/styles';
import { Alert, AlertProps, Snackbar } from '@mui/material';

export const StyledAlert = styled(Alert)<AlertProps>(
  ({ theme, severity }) => css`
    width: 540px;
    border-radius: 8px;
    padding: 4px 12px;
    align-items: center;

    & .MuiAlert-icon {
      opacity: 1;
      width: 24px;
      height: 24px;
      padding: 0;
      margin-right: 8px;
    }

    & .MuiAlert-message {
      padding: 0;
    }

    & .MuiAlert-action {
      padding: 0;
      & svg {
        color: ${theme.palette.common.white};
      }
    }

    ${severity === 'info' &&
    `
      background-color: ${theme.palette.grey[700]};
      color: ${theme.palette.common.white};
    `}
    ${severity === 'error' &&
    `
      background-color: ${theme.palette.error.main};
      color: ${theme.palette.common.white};
    `}
    ${severity === 'success' &&
    `
      background-color: ${theme.palette.success.main};
      color: ${theme.palette.common.white};
    `}
    ${severity === 'warning' &&
    `
      background-color: ${theme.palette.warning.main};
      color: ${theme.palette.common.white};
    `}
  `,
);

export const StyledSnackbar = styled(Snackbar)(css`
  top: 40px;
  z-index: 100000;
`);
