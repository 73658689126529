import React, { useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import i18n from 'localizations';
import 'dayjs/locale/uk';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs, { Dayjs } from 'dayjs';

import { OutputBookingDayDto } from 'types';
import { StyledLoadingContainer, StyledCircularProgress, StyledContainer, StyledCell } from './styled';
import Header from './Header';

dayjs.extend(weekday);
dayjs.extend(isoWeek);

const getWeekDays = () => {
  const weekDays = [];
  const startOfWeek = dayjs().locale(i18n.language).weekday(-7);

  for (let i = 0; i < 7; i++) {
    weekDays.push(startOfWeek.add(i, 'day').locale(i18n.language).format('dd'));
  }

  return weekDays;
};

const getMonthMatrix = (date: Dayjs): Array<{ key: number; value: Array<{ key: number; value: number | null }> }> => {
  const row: Array<{ key: number; value: number | null }> = new Array(7).fill(null).map((_, index) => ({
    key: index,
    value: null,
  }));
  const matrix = new Array(6).fill(null).map((_, index) => ({ key: index, value: row.map(item => ({ ...item })) }));

  const daysInMonth = date.daysInMonth();
  let currentWeek = 0;
  let currentDayOfWeek = date.startOf('month').locale(i18n.language).weekday();

  for (let i = 1; i <= daysInMonth; i++) {
    matrix[currentWeek].value[currentDayOfWeek].value = i;

    if (currentDayOfWeek < 6) {
      currentDayOfWeek += 1;
    } else {
      currentDayOfWeek = 0;
      currentWeek += 1;
    }
  }

  return matrix;
};

export type CalendarProps = {
  value: Dayjs;
  availableBookingDays: OutputBookingDayDto[];
  loading?: boolean;
  onChange: (value: Dayjs) => void;
  onMonthChange: (value: Dayjs) => void;
  className?: string;
};

const Calendar: React.FC<CalendarProps> = ({
  value,
  availableBookingDays,
  onMonthChange,
  onChange,
  loading,
  className,
}) => {
  const [month, setMonth] = useState<Dayjs>(dayjs());
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const shouldDisableDate = (date: Dayjs | null) => {
    if (loading || !date) {
      return true;
    }

    if (date.startOf('day').isBefore(dayjs().startOf('day'))) {
      return true;
    }
    return !availableBookingDays.find(item => dayjs(item.date).isSame(date));
  };

  const isSelected = (date: Dayjs | null) => !!date && !!value && value.isSame(date);

  const handleMonthChange = (monthValue: Dayjs) => {
    onMonthChange(monthValue);
    setMonth(monthValue);
  };
  const handleChange = (date: Dayjs | null) => !!date && onChange(date);

  return (
    <Stack position="relative" ref={calendarContainerRef} className={className}>
      <StyledContainer>
        <Header date={month} onChange={handleMonthChange} />
        <Stack direction="row">
          {getWeekDays().map(item => (
            <StyledCell key={item}>
              <Typography variant="subtitle1">{item.toUpperCase()}</Typography>
            </StyledCell>
          ))}
        </Stack>
        {getMonthMatrix(month).map(row => (
          <Stack key={row.key} direction="row">
            {row.value.map(column => {
              const date = column.value === null ? null : dayjs(month).startOf('month').date(column.value);
              const disabled = shouldDisableDate(date);
              return (
                <StyledCell
                  key={column.key}
                  active
                  disabled={disabled}
                  selected={isSelected(date)}
                  onClick={() => !disabled && handleChange(date)}
                >
                  <Typography variant="subtitle1">{column.value}</Typography>
                </StyledCell>
              );
            })}
          </Stack>
        ))}
      </StyledContainer>
      {loading && (
        <StyledLoadingContainer>
          <StyledCircularProgress size={48} />
        </StyledLoadingContainer>
      )}
    </Stack>
  );
};

export default Calendar;
