import React from 'react';
import { Stack, Typography } from '@mui/material';
import TruncatedText from 'components/@shared/TruncatedText';

import { StyledAttributeRow, StyledAttributeSection, StyledCard, StyledCardActions, StyledLink } from './styled';

export type CardAttribute = { key: string; value: string; startAdornment?: React.ReactNode; url?: string };

export type CardProps = {
  id: number;
  header?: React.ReactNode;
  withBorder?: boolean;
  disabled?: boolean;
  attributeList?: CardAttribute[];
  attributeListNode?: React.ReactNode;
  onClick?: (id: number) => void;
  cardActions?: React.ReactNode;
  className?: string;
};

const Card: React.FC<CardProps> = ({
  id,
  header,
  withBorder,
  disabled,
  attributeList,
  attributeListNode,
  className,
  onClick,
  cardActions,
}) => (
  <StyledCard className={className} withBorder={withBorder} disabled={disabled} onClick={() => onClick?.(id)}>
    <Stack gap="12px">
      {header}
      <StyledAttributeSection>
        {attributeListNode}
        {attributeList?.map(item => (
          <StyledAttributeRow key={item.key}>
            <Typography variant="body2" color="primary.dark2">
              {item.key}:
            </Typography>
            {item.startAdornment}
            {item.url ? (
              <StyledLink to={item.url}>
                <TruncatedText variant="body2">{item.value}</TruncatedText>
              </StyledLink>
            ) : (
              <TruncatedText variant="body2" color="primary.main">
                {item.value}
              </TruncatedText>
            )}
          </StyledAttributeRow>
        ))}
      </StyledAttributeSection>
    </Stack>
    <StyledCardActions>{cardActions}</StyledCardActions>
  </StyledCard>
);

export default Card;
