import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useEffect } from 'react';
import { StyledTabs } from './styled';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

type TabData = {
  label: string;
  content: React.ReactNode;
};

type Props = {
  tabList: TabData[];
  activeTabIndex?: number;
  className?: string;
  onTabChange?: (index: number) => void;
};

const Tabs: React.FC<Props> = ({ tabList, activeTabIndex, onTabChange, className }) => {
  const [value, setValue] = React.useState(activeTabIndex || 0);

  useEffect(() => {
    setValue(activeTabIndex || 0);
  }, [activeTabIndex]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange?.(newValue);
  };

  return (
    <Box className={className} sx={{ width: '100%', position: 'relative' }}>
      <StyledTabs
        value={value}
        aria-label="tabs"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        onChange={handleChange}
      >
        {tabList.map(({ label }, index) => (
          <Tab
            key={label}
            label={label}
            {...a11yProps(index)}
            disableRipple
            sx={{ flex: `1 1 ${Math.round(100 / tabList.length)}%` }}
          />
        ))}
      </StyledTabs>
      {tabList.map(({ label, content }, index) => (
        <Box
          key={label}
          role="tabpanel"
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          sx={{ display: index === value ? 'visible' : 'none' }}
        >
          {content}
        </Box>
      ))}
    </Box>
  );
};

export default Tabs;
