import { css, styled } from '@mui/material/styles';
import Image from 'components/@shared/Image';
import ServiceCard from 'components/@common/ServiceCard';
import { CircularProgress, Stack } from '@mui/material';
import Calendar from './Calendar';
import TimeSlotsSection from './TimeSlotsSection';

export const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      border: 1px solid ${theme.palette.primary.light3};
      border-radius: 40px;
      padding: 80px;
      align-items: center;
    }
  `,
);

export const StyledCalendar = styled(Calendar)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: calc(50% - 10px);
    }
  `,
);

export const StyledTimeSlotsSection = styled(TimeSlotsSection)(
  ({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      width: calc(50% - 10px);
    }
  `,
);

export const StyledImage = styled(Image)`
  width: 24px;

  & img {
    border-radius: 50%;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => css`
    color: ${theme.palette.primary.light};
    position: absolute;
    top: calc(50% - 12px);
  `,
);

export const StyledServiceCard = styled(ServiceCard)`
    flex: 1 1 auto;
`
