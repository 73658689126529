import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import { useLocation } from 'react-router-dom';
import { OutputCompanyReservationDto } from 'types/reservation';
import { useEffect } from 'react';
import useMutation from 'hooks/useMutation';
import { APPROVE_COMPANY_RESERVATION, CANCEL_COMPANY_RESERVATION } from 'api/requests/company';
import { useNewReservationsCount } from 'hooks/useNewReservations';

const useCompanyReservationDetailsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state?: { reservation?: OutputCompanyReservationDto } };
  const { fetchNewReservationsCount } = useNewReservationsCount(true);

  const [approveCompanyReservation] = useMutation<void, { reservationId?: number }>(APPROVE_COMPANY_RESERVATION, {
    fetchPolicy: 'no-cache',
  });

  const [cancelCompanyReservation] = useMutation<void, { reservationId?: number }>(CANCEL_COMPANY_RESERVATION, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!state?.reservation) {
      navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
    }
  }, [navigate, state]);

  const handleBack = () => navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);

  const approveReservation = async () => {
    if (!state?.reservation) return;
    await approveCompanyReservation({ variables: { reservationId: state.reservation.id } });
    fetchNewReservationsCount();
    navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
  };

  const cancelReservation = async () => {
    if (!state?.reservation) return;
    await cancelCompanyReservation({ variables: { reservationId: state.reservation.id } });
    fetchNewReservationsCount();
    navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
  };

  return {
    reservation: state?.reservation,
    handleBack,
    approveReservation,
    cancelReservation,
  };
};

export default useCompanyReservationDetailsPage;
