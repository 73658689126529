import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const StyledPopperContainer = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
    border-radius: 12px;
    border: 1px solid ${theme.palette.accent.main};
    padding: 20px;
    gap: 20px;

    * {
      cursor: pointer;

      :hover {
        color: ${theme.palette.accent.light};
      }

      :active {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);
export const StyledMonthButton = styled(Stack)(
  ({ theme }) => css`
    cursor: pointer;

    :hover {
      color: ${theme.palette.accent.light};

      svg {
        color: ${theme.palette.accent.light};
      }
    }

    :active {
      color: ${theme.palette.accent.dark};

      svg {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);
