export const colors = {
  black85: '#292929',
  black40: '#9E9E9F',
  black20: '#CBCBCC',
  black08: '#E7E7E8',
  black02: '#F4F4F5',

  white: '#FDFDFD',

  blueAccent: '#4870FF',
  hoverAccent: '#5E81FF',
  clickAccent: '#2656FF',

  yellow: '#FFD600',
  orange: '#FF9900',
  red: '#FB4E4E',
};

export const CONTAINER_WIDTH = 1400;
export const CONTAINER_PADDING_X_MOBILE = 20;
export const CONTAINER_PADDING_X_DESKTOP = 80;
