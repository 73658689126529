import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';


export const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row-reverse;
    gap: 40px;

    &>* {
      flex: 0 0 calc(50% - 20px);
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;

      &>*:first-child {
        flex: 1 1 100%;
        min-height: 168px;
      }
    }
  `,
);

export const StyledLogoContainer = styled(Stack)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.primary.light3};
    border-radius: 16px;
    align-items: center;
    justify-content: center;
  `,
);

export const StyledLogoIcon = styled(LogoIcon)`
  width: 178px;
`;

export const StyledGreyContainer = styled(Stack)(
  ({ theme }) => css`
    width: 100%;
    background: ${theme.palette.primary.light2};
  `,
);

export const StyledActionsContainer = styled(Stack)(
  ({ theme }) => css`
    flex-direction: row;
    gap: 24px;
    border-radius: 16px;
    width: 100%;
    padding: 80px 0;

    & > * {
      flex: 0 0 calc(50% - 12px);
    }

    ${theme.breakpoints.down('md')} {
      flex-direction: column;

      & > *:first-child {
        flex: 1 1 100%;
      }
    }

    ${theme.breakpoints.down('sm')} {
      padding: 20px 0;
    }
  `,
);
