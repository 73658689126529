import { css, styled } from '@mui/material/styles';
import Tabs from 'components/@shared/Tabs';

export const StyledTabs = styled(Tabs)(
  ({ theme }) => css`
    .MuiTabs-flexContainer,
    .MuiTabs-root {
      margin-bottom: 0 !important;
      min-height: auto;

      ${theme.breakpoints.up('md')} {
        margin-bottom: 0 !important;
      }
    }
  `,
);
