import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as OpenIcon } from 'assets/icons/open.svg';
import formatDuration from 'utils/formatDuration';
import ScrollableButtonList from 'components/@shared/ScrollableButtonList';
import IconButton from 'components/@shared/IconButton';

import {
  StyledAccordion,
  StyledContainer,
  StyledSubcategoryList,
  StyledImage,
  StyledServiceContainer,
  StyledServiceListContainer,
  StyledIconButton,
} from './styled';

export type Service = {
  id: number;
  name: string;
  duration: string;
  price?: number;
};

export type ServicesByQualification = {
  id: string;
  qualification: string;
  serviceList: Service[];
};

export type ServicesByMaster = {
  id: number;
  contactName: string;
  profileImageUri?: string;
  qualification: string;
  servicesByQualificationList: ServicesByQualification[];
};

export type ServicesBySubcategory = Record<string, ServicesByMaster[]>;
export type Subcategories = Record<string, string>;

export type AllServicesSectionProps = {
  servicesBySubcategory: ServicesBySubcategory;
  subcategories: Subcategories;
  onOpenService: (id: number) => void;
  onBookService: (id: number) => void;
  onMasterClick: (id: number) => void;
};

const AllServicesSection: React.FC<AllServicesSectionProps> = ({
  servicesBySubcategory,
  subcategories,
  onOpenService,
  onBookService,
  onMasterClick,
}) => {
  const { t } = useTranslation();

  const subcategoryList = Object.entries(subcategories);

  const [sliderKey, setSliderKey] = useState(subcategoryList[0][0]);

  const [expanded, setExpanded] = useState<number>(0);

  const subcategoryMasters = servicesBySubcategory[sliderKey];

  const handleClickCategory = (key: string) => {
    setSliderKey(key);
    setExpanded(0);
  };

  return (
    <StyledContainer>
      <Typography variant="h4">{t('allServices')}</Typography>

      <ScrollableButtonList
        selectedItemId={sliderKey}
        list={Object.entries(subcategories).map(([key, value]) => ({ id: key, label: value }))}
        onClick={handleClickCategory}
      />

      <StyledSubcategoryList>
        {subcategoryMasters.map((m, index) => (
          <StyledAccordion key={m.id} expanded={expanded === index} disableGutters onChange={() => setExpanded(index)}>
            <AccordionSummary>
              <Stack direction="row" gap="8px" alignItems="center" width="100%">
                <StyledImage src={m.profileImageUri} alt={m.contactName} whiteBgPlaceholder />
                <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between" width="100%">
                  <Stack gap="4px">
                    <Typography variant="subtitle1" color="primary.main">
                      {m.contactName}
                    </Typography>
                    <Typography variant="body3" color="primary.main">
                      {m.servicesByQualificationList.map(q => q.qualification).join('/ ')}
                    </Typography>
                  </Stack>
                  <IconButton onClick={() => onMasterClick(m.id)}>
                    <OpenIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {m.servicesByQualificationList.map(q => (
                <Stack key={q.id} gap="8px">
                  <Typography variant="subtitle1" color="primary.main">
                    {q.qualification}
                  </Typography>
                  <StyledServiceListContainer>
                    {q.serviceList.map(s => (
                      <StyledServiceContainer key={s.id} onClick={() => onOpenService(s.id)}>
                        <Typography variant="h6" color="primary.main">
                          {s.name}
                        </Typography>
                        <Stack direction="row" gap="8px">
                          <Typography variant="body1" color="primary.dark2">
                            {t('duration')}:
                          </Typography>
                          <Typography variant="body1" color="primary.dark2">
                            {formatDuration(s.duration)}
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between">
                          <Typography variant="h4" color="accent.main">
                            {s.price ? t('price', { val: s.price }) : t('priceFree')}
                          </Typography>
                          <StyledIconButton
                            onClick={e => {
                              e.stopPropagation();
                              onBookService(s.id);
                            }}
                            disableRipple
                          >
                            <BookIcon />
                          </StyledIconButton>
                        </Stack>
                      </StyledServiceContainer>
                    ))}
                  </StyledServiceListContainer>
                </Stack>
              ))}
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </StyledSubcategoryList>
    </StyledContainer>
  );
};

export default AllServicesSection;
