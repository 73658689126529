import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as ShowIcon } from 'assets/icons/show.svg';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import hidePhoneNumber from 'utils/hidePhoneNumber';

import { StyledContactContainer, StyledContactTextContainer } from '../styled';
import { StyledButton, StyledContainer } from './styled';

type Props = {
  value?: string;
  showLogic?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  onShow?: () => void;
};

const PhoneContact: React.FC<Props> = ({ value, showLogic = true, onClick, onShow }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const [show, setShow] = useState(!showLogic);

  const handleShow = () => {
    setShow(true);
    if (onShow) {
      onShow();
    }
  };

  if (!value) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledContactContainer clickable={show && !!onClick} onClick={onClick}>
        <PhoneIcon />
        <StyledContactTextContainer>
          <Typography variant="subtitle3" color="primary.dark2">
            {t('contactsSection.phoneContactLabel')}
          </Typography>
          <Typography variant="subtitle1" color="primary.main">
            {formatPhoneNumber(show ? value : hidePhoneNumber(value))}
          </Typography>
        </StyledContactTextContainer>
      </StyledContactContainer>
      {showLogic && !show && (
        <StyledButton disableRipple onClick={handleShow}>
          <ShowIcon />
          {upSmall && <Typography variant="subtitle3">{t('contactsSection.phoneContactShowButtonLabel')}</Typography>}
        </StyledButton>
      )}
    </StyledContainer>
  );
};

export default PhoneContact;
