import { OutputScheduleDayDto, OutputServiceDto } from 'types/service';
import convertTimeSpanToDayjs from 'utils/convertTimeSpanToDayjs';
import { Dayjs } from 'dayjs';
import mapAttributesGroupIdToIcon from 'utils/mapAttributesGroupIdToIcon';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import { ReactComponent as Calendar1Icon } from 'assets/icons/calendar1.svg';
import { ServiceCardProps } from 'components/@common/ServiceCard';

const mapServiceModelToViewModel = (
  service: OutputServiceDto,
  day: Dayjs,
  timeSlot: OutputScheduleDayDto,
): Omit<ServiceCardProps, 'archived' | 'loading' | 'onClick'> => {
  const attributeList = [];

  attributeList.push({
    startAdornment: <Calendar1Icon />,
    value: day.format('D MMMM (dddd)'),
  });

  attributeList.push({
    startAdornment: <TimeIcon />,
    value: `${convertTimeSpanToDayjs(timeSlot.from).format('HH:mm')} - ${convertTimeSpanToDayjs(timeSlot.to).format(
      'HH:mm',
    )}`,
  });

  const qualification = service.category.attributeGroups.find(item => item.isQualification)?.attribute.localizedName;

  service.category.attributeGroups.forEach(item => {
    const Icon = mapAttributesGroupIdToIcon(item.id);
    if (!item.isQualification) {
      attributeList.push({
        startAdornment: <Icon />,
        value: item.attribute.localizedName,
      });
    }
  });

  return {
    id: service.id,
    name: service.name,
    category: service.category.id,
    distanceMeters: service.distanceMeters,
    price: service.price,
    masterDetails: service.master,
    qualification,
    attributeList,
  };
};

export default mapServiceModelToViewModel;
