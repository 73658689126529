import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from 'localizations';
import { StyledSelect } from './styled';

const itemList = Object.values(Locale).map(item => ({ id: item, localizedName: item.toUpperCase() }));

const LanguageSelector: React.FC<{ className?: string }> = ({ className }) => {
  const { i18n } = useTranslation();

  const localeFromStorage = localStorage.getItem('locale');

  const [locale, setLocale] = useState(localeFromStorage || i18n.language);

  useEffect(() => {
    if (locale) {
      localStorage.setItem('locale', locale);
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  const item = itemList.find(({ id }) => locale.toLowerCase().includes(id.toLowerCase()));

  return (
    <StyledSelect
      className={className}
      value={item}
      itemList={itemList}
      onChange={value => {
        setLocale(value!.id);
      }}
    />
  );
};

export default LanguageSelector;
