import { gql } from '@apollo/client';

export const GET_MASTER_SERVICES = gql`
  query MasterServices($masterId: Long!) {
    masterServices(masterId: $masterId) {
      ...MasterServiceFragment
    }
  }
`;

export const ADD_OR_UPDATE_MASTER_SERVICE = gql`
  mutation AddOrUpdateMasterService($input: InputMasterServiceDtoInput!) {
    addOrUpdateMasterService(input: $input) {
      ...MasterServiceFragment
    }
  }
`;

export const REMOVE_MASTER_SERVICE = gql`
  mutation RemoveMasterService($masterId: Long!, $serviceId: Long!) {
    removeMasterService(masterId: $masterId, serviceId: $serviceId) {
      ...MasterServiceFragment
    }
  }
`;
export const CREATE_RESERVATION = gql`
  mutation CreateReservation($serviceId: Long!, $date: Date!, $startTime: TimeSpan!) {
    createReservation(serviceId: $serviceId, date: $date, startTime: $startTime) {
      id
    }
  }
`;

export const CANCEL_RESERVATION = gql`
  mutation CancelReservation($reservationId: Long!) {
    cancelReservation(reservationId: $reservationId) {
      id
    }
  }
`;

export const GROUPED_SERVICES = gql`
  query GroupedServices(
    $filter: InputServiceFilterDtoInput!
    $coordinates: CoordinatesDtoInput
    $first: Int
    $after: String
    $last: Int
    $before: String
    $servicesCountPerGroup: Int
  ) {
    groupedServices(
      filter: $filter
      coordinates: $coordinates
      first: $first
      after: $after
      last: $last
      before: $before
      servicesCountPerGroup: $servicesCountPerGroup
    ) {
      ...GroupedServicesConnectionFragment
    }
  }
`;

export const SERVICES = gql`
  query Services(
    $companyId: Long
    $masterId: Long
    $filter: InputServiceFilterDtoInput!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    services(
      companyId: $companyId
      masterId: $masterId
      filter: $filter
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      ...ServicesConnectionFragment
    }
  }
`;

export const SERVICE = gql`
  query Service($serviceId: Long!) {
    service(serviceId: $serviceId) {
      ...OutputServiceDtoFragment
    }
  }
`;

export const MAX_PRICE = gql`
  query MaxPrice($filter: InputServiceFilterDtoInput!) {
    maxPrice(filter: $filter)
  }
`;

export const POPULAR = gql`
  query Popular {
    popular {
      ...OutputServiceDtoFragment
    }
  }
`;

export const AVAILABLE_BOOKING_DAYS = gql`
  query AvailableBookingDays($serviceId: Long!, $from: Date!, $to: Date!) {
    availableBookingDays(serviceId: $serviceId, from: $from, to: $to) {
      ...OutputBookingDayDtoFragment
    }
  }
`;

export const ADD_MASTER_SERVICE_PHOTOS = gql`
  mutation AddMasterServicePhotos($masterId: Long, $serviceId: Long, $photos: [Upload!]!) {
    addMasterServicePhotos(masterId: $masterId, serviceId: $serviceId, photos: $photos) {
      ...MasterServiceFragment
    }
  }
`;

export const REMOVE_MASTER_SERVICE_PHOTO = gql`
  mutation RemoveMasterServicePhoto($masterId: Long, $serviceId: Long!, $photoId: Long!) {
    removeMasterServicePhoto(masterId: $masterId, serviceId: $serviceId, photoId: $photoId) {
      ...MasterServiceFragment
    }
  }
`;
