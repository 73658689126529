import { css, styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledIconButton = styled(
  IconButton,
  preventForwardProps(['color', 'active']),
)<{ color: 'primary' | 'error'; active?: boolean }>(({ theme, color, active }) => {
  let colorValue;

  if (active) {
    colorValue = color === 'error' ? theme.palette.error.main : theme.palette.accent.main;
  } else {
    colorValue = color === 'error' ? theme.palette.primary.dark2 : theme.palette.primary.main;
  }

  return css`
    border-radius: 12px;
    padding: 0;

    color: ${colorValue};

    @media (hover: hover) {
      &:hover {
        color: ${color === 'error' ? theme.palette.error.light : theme.palette.accent.light};
      }
    }

    @media (hover: none) {
      &:hover {
        color: ${color === 'error' ? theme.palette.primary.dark2 : theme.palette.primary.main};
      }
    }

    &:hover {
      color: ${color === 'error' ? theme.palette.error.light : theme.palette.accent.light};
    }

    &:active {
      color: ${color === 'error' ? theme.palette.error.dark : theme.palette.accent.dark};
    }
  `;
});
