import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.palette.accent.main};
    min-width: 0;
  `,
);

export const StyledClientContainer = styled(Stack)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.primary.light3};
    border-radius: 16px;
    gap: 16px;
    padding: 16px 20px 20px;

    width: 100%;
  `,
);
