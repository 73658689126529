import { useMatch, useNavigate, useParams } from 'react-router';
import { PATHS } from 'navigation/constants';
import { useLazyQuery } from '@apollo/client';
import { GET_MASTER_SERVICES, REMOVE_MASTER_SERVICE } from 'api/requests/service';
import getCategoryWithLocalId from 'utils/getCategoryWithLocalId';
import { OutputMasterServiceDto } from 'types';
import { useEffect, useMemo } from 'react';
import useMutation from 'hooks/useMutation';
import { useMasterState } from 'contexts/master';
import { resetCurrentService, setCurrentCategory, setCurrentService } from 'contexts/master/actions';
import getRouteWithParams from 'utils/getRouteWithParams';
import { useTranslation } from 'react-i18next';

const useServicesTab = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { id } = useParams<'id'>();
  const {
    state: { master },
    dispatch,
  } = useMasterState();

  const [getServicesData, { loading: servicesLoading, data: servicesData }] = useLazyQuery<
    { masterServices: OutputMasterServiceDto[] },
    { masterId: number }
  >(GET_MASTER_SERVICES);

  const [removeMasterService] = useMutation<OutputMasterServiceDto, { masterId: number; serviceId: number }>(
    REMOVE_MASTER_SERVICE,
  );

  useEffect(() => {
    if (master.id) {
      getServicesData({ variables: { masterId: master.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [master.id, i18n.language]);

  const MASTER_CABINET_SERVICES_TAB = useMatch(PATHS.MASTER_CABINET_SERVICES_TAB);
  const COMPANY_CABINET_MASTER_SERVICES_TAB = useMatch(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB);

  const services = useMemo(() => {
    if (servicesData?.masterServices) {
      return servicesData.masterServices.map(i => ({
        ...i,
        category: getCategoryWithLocalId(i.category),
      }));
    }
    return [];
  }, [servicesData]);

  const onAddService = (serviceId: string) => {
    const category = master?.categories.find((c: { localId: string }) => c.localId === serviceId);
    if (category) {
      dispatch(setCurrentCategory(category));
      dispatch(resetCurrentService());

      if (MASTER_CABINET_SERVICES_TAB) {
        navigate(PATHS.MASTER_CABINET_SERVICES_TAB_ADD_SERVICE);
      }

      if (COMPANY_CABINET_MASTER_SERVICES_TAB && id) {
        navigate(getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE, { id }));
      }
    }
  };

  const onDeleteService = async (serviceId: number) => {
    const masterId = master.id;
    if (masterId !== undefined) {
      await removeMasterService({ variables: { masterId, serviceId } });
      await getServicesData({ variables: { masterId } });
    }
  };

  const onEditService = async (service: OutputMasterServiceDto) => {
    dispatch(setCurrentService(service));

    if (MASTER_CABINET_SERVICES_TAB) {
      navigate(
        getRouteWithParams(PATHS.MASTER_CABINET_SERVICES_TAB_EDIT_SERVICE, { serviceId: service.id.toString() }),
      );
    }

    if (COMPANY_CABINET_MASTER_SERVICES_TAB && id) {
      navigate(
        getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_EDIT_SERVICE, {
          id,
          serviceId: service.id.toString(),
        }),
      );
    }
  };

  return {
    categories: master.categories,
    services,
    servicesLoading,
    onAddService,
    onDeleteService,
    onEditService,
  };
};

export default useServicesTab;
