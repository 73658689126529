import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledHamburgerIcon = styled(Box)(
  ({ theme }) => css`
    height: 48px;
    width: 48px;
    cursor: pointer;
    border-radius: 12px;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    background: ${theme.palette.primary.light2};

    div {
      background-color: ${theme.palette.primary.main};
    }

    &:hover {
      div {
        background-color: ${theme.palette.accent.light};
      }
    }

    @media (hover: hover) {
      &:hover {
        div {
          background-color: ${theme.palette.accent.light};
        }
      }
    }

    @media (hover: none) {
      &:hover {
        div {
          background-color: ${theme.palette.primary.main};
        }
      }
    }

    &:active {
      div {
        background-color: ${theme.palette.accent.dark};
      }
    }
  }
  `,
);

const getIconsCommonStyles = () => css`
  position: absolute;
  left: 25%;
  top: 50%;
  width: 24px;
  height: 2px;
  transition:
    transform 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8),
    opacity 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
`;

export const StyledIcon1 = styled(Box)`
  ${getIconsCommonStyles()};
  transform: translateY(-8px);
  animation-delay: 100ms;

  &.a {
    transform: rotate(40deg);
  }
`;

export const StyledIcon2 = styled(Box)`
  ${getIconsCommonStyles()};

  &.c {
    opacity: 0;
  }
`;

export const StyledIcon3 = styled(Box)`
  ${getIconsCommonStyles()};
  transform: translateY(8px);
  animation-delay: 250ms;

  &.b {
    transform: rotate(-40deg);
  }
`;

export const StyledClear = styled(Box)`
  clear: both;
`;
