import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ComingSoon: React.FC = () => {
  const { t } = useTranslation();

  return <Typography variant="h1">{t('comingSoon')}</Typography>;
};

export default ComingSoon;
