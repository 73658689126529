import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { COMPANY_RESERVATIONS } from 'api/requests/company';

import { useLazyQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { OutputCompanyReservationDto, ReservationStatus } from 'types/reservation';

const useScheduleTab = () => {
  const [dateRange, setDateRange] = useState({ from: dayjs().startOf('day'), to: dayjs().endOf('month') });
  const [companyReservations, setCompanyReservations] = useState<OutputCompanyReservationDto[]>([]);

  const { pathname } = useLocation();

  const [getMasterReservations, { loading }] = useLazyQuery<
    { companyReservations: OutputCompanyReservationDto[] },
    {
      from: string;
      to: string;
      statuses?: ReservationStatus[];
    }
  >(COMPANY_RESERVATIONS);

  useEffect(() => {
    getMasterReservations({
      variables: {
        from: dateRange.from.format('YYYY-MM-DD'),
        to: dateRange.to.format('YYYY-MM-DD'),
        statuses: [ReservationStatus.APPROVED, ReservationStatus.WAITING_FOR_APPROVE],
      },
    }).then(res => {
      if (res.data?.companyReservations) {
        setCompanyReservations(res.data.companyReservations);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, pathname]);

  const handleDateRangeChange = (data: { from: Dayjs; to: Dayjs }) => {
    setDateRange(data);
    setCompanyReservations([]);
  };

  return {
    companyReservations,
    loading,
    handleDateRangeChange,
  };
};

export default useScheduleTab;
