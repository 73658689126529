import React from 'react';

export function createContext<R extends React.Reducer<React.ReducerState<R>, React.ReducerAction<R>>>(
  reducer: R,
  initialState: React.ReducerState<R>,
) {
  const Context = React.createContext({
    state: initialState,
    dispatch: (value?: any) => value,
  });

  function Provider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const context = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
  }

  return { Context, Provider };
}
