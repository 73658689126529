import React from 'react';
import { Navigate } from 'react-router-dom';
import { AccountTypeEnum } from 'constatns';
import { useAppState } from 'contexts/app';

import { PATHS } from '../constants';

type Props = {
  permittedTypes: AccountTypeEnum[];
  children: React.ReactNode;
};

export const AccountRestriction: React.FC<Props> = ({ permittedTypes, children }) => {
  const {
    state: { account },
  } = useAppState();

  if (!account || !permittedTypes.includes(account)) {
    return <Navigate to={PATHS.MAIN_PAGE} />;
  }

  return children;
};
