import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import TruncatedText from 'components/@shared/TruncatedText';
import generateRandomNumber from 'utils/generateRundomNumber';
import generateIncrementalArray from 'utils/generateIncrementalArray';

import {
  StyledAttribute,
  StyledAttributeSection,
  StyledCard,
  StyledCardActions,
  StyledCategoryContainer,
  StyledIconButton,
} from '../styled';

export type ServiceCardSkeletonProps = {
  withBorder?: boolean;
  seed?: string;
};

const ServiceCardSkeleton: React.FC<ServiceCardSkeletonProps> = ({ withBorder, seed }) => (
  <StyledCard withBorder={withBorder} disabled>
    <Stack gap="12px">
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px" width="100%">
        <StyledCategoryContainer>
          <Skeleton height={40} width={generateRandomNumber(100, 140, seed + 'a')} sx={{ borderRadius: '8px' }} />
        </StyledCategoryContainer>
        <Skeleton height={40} width={generateRandomNumber(100, 140, seed + 'b')} sx={{ borderRadius: '8px' }} />
      </Stack>
      <TruncatedText variant="h6" rowsLimit={3}>
        <Skeleton width={`${generateRandomNumber(40, 100, seed + 'c')}%`} />
      </TruncatedText>
      <StyledAttributeSection>
        {generateIncrementalArray(generateRandomNumber(1, 4, seed + 'd')).map(item => (
          <StyledAttribute key={item}>
            <Skeleton variant="circular" width="16px" />
            <TruncatedText variant="body2" color="primary.main">
              <Skeleton width={generateRandomNumber(60, 100, seed + 'e')} />
            </TruncatedText>
          </StyledAttribute>
        ))}
      </StyledAttributeSection>
      <Stack direction="row" gap="8px" alignItems="center" width="100%">
        <Skeleton variant="circular" width="40px" height="40px" />
        <Stack width="100%">
          <Typography variant="subtitle2">
            <Skeleton width={`${generateRandomNumber(40, 100, seed + 'f')}%`} />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={`${generateRandomNumber(40, 100, seed + 'g')}%`} />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
    <StyledCardActions>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <TruncatedText variant="h4" color="accent.main" component="h4">
          <Skeleton width={generateRandomNumber(60, 80, seed + 'h')} />
        </TruncatedText>
        <Stack direction="row" gap="8px">
          <StyledIconButton disableRipple>
            <Skeleton variant="rectangular" width={48} height={48} sx={{ borderRadius: '8px' }} />
          </StyledIconButton>
          <StyledIconButton disableRipple>
            <Skeleton variant="rectangular" width={48} height={48} sx={{ borderRadius: '8px' }} />
          </StyledIconButton>
        </Stack>
      </Stack>
    </StyledCardActions>
    ;
  </StyledCard>
);
export default ServiceCardSkeleton;
