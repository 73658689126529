import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/@shared/Container';
import { useTranslation } from 'react-i18next';
import Gap from 'components/@shared/Gap';
import { ReactComponent as PrevIcon } from 'assets/icons/prev.svg';
import { ReactComponent as NextIcon } from 'assets/icons/next.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ScrollableButtonList from 'components/@shared/ScrollableButtonList';

import { StyledSlider } from './styled';
import Slider from 'react-slick';

export type WithSubcategoriesProps = {
  examples: Array<{ id: string; subcategoryTitle: string; imgSrcList: string[] }>;
};

export type WithoutSubcategoriesProps = {
  examples: string[];
};

export type ExamplesSectionProps = WithSubcategoriesProps | WithoutSubcategoriesProps;

const getSliderLength = (count: number) => {
  return (
    {
      1: 'calc(25% - 4px)',
      2: 'calc(50% - 4px)',
      3: 'calc(75% - 4px)',
    }[count] || '100%'
  );
};

const isWithSubcategoriesProps = (props: ExamplesSectionProps): props is WithSubcategoriesProps =>
  !!(props as WithSubcategoriesProps).examples[0]?.subcategoryTitle;

function ExamplesSection(props: WithSubcategoriesProps): React.ReactNode;
function ExamplesSection(props: WithoutSubcategoriesProps): React.ReactNode;
function ExamplesSection(props: ExamplesSectionProps) {
  const { t } = useTranslation();

  const [sliderIndex, setSliderIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const ref = useRef<Slider>(null);

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleClickCategory = (key: number) => {
    setCurrentSlideIndex(0);
    setSliderIndex(key);
    ref.current?.slickGoTo(0);
  };

  useEffect(() => {
    if (isWithSubcategoriesProps(props)) {
      props.examples.forEach(({ imgSrcList }) => {
        imgSrcList.forEach(src => {
          const img = new Image();
          img.src = src;
        });
      });
    } else {
      props.examples.forEach(src => {
        const img = new Image();
        img.src = src;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slides = isWithSubcategoriesProps(props) ? props.examples[sliderIndex].imgSrcList : props.examples;

  return (
    !!props.examples.length && (
      <Container>
        <Gap size={upMedium ? 80 : 40} />
        <Typography variant={upMedium ? 'h2' : 'h4'}>{t('serviceDetailsPage.examplesTitle')}</Typography>
        <Gap size={upMedium ? 40 : 20} />

        {isWithSubcategoriesProps(props) && (
          <>
            <ScrollableButtonList
              selectedItemId={sliderIndex}
              list={props.examples.map((item, index) => ({ id: index, label: item.subcategoryTitle }))}
              onClick={handleClickCategory}
            />
            <Gap size={upMedium ? 40 : 20} />
          </>
        )}

        <Stack width={!upMedium ? '100%' : getSliderLength(slides.length)}>
          <StyledSlider
            ref={ref}
            arrows={upMedium}
            swipeToSlide
            dots
            speed={300}
            slidesToScroll={1}
            prevArrow={<PrevIcon />}
            nextArrow={<NextIcon />}
            slidesToShow={upMedium ? Math.min(4, slides.length) : 1}
            slidePosition={currentSlideIndex}
            slideCount={slides.length}
            afterChange={setCurrentSlideIndex}
          >
            {slides.map(src => (
              <img key={src} src={src} alt="src" />
            ))}
          </StyledSlider>
        </Stack>
        <Gap size={upMedium ? 68 : 28} />
      </Container>
    )
  );
}

export default ExamplesSection;
