import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContainer = styled(
  Box,
  preventForwardProps(['variant']),
)<{ variant?: 'white' | 'grey' | 'transparent' }>(
  ({ theme, variant }) => css`
    ${variant === 'white' && 'padding: 12px;'};
    ${variant === 'grey' && 'padding: 8px 12px;'};
    ${variant === 'white' && `background: ${theme.palette.primary.light};`};
    ${variant === 'grey' && `background: ${theme.palette.primary.light2};`};
    ${variant === 'transparent' && 'background: transparent;'};
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
  `,
);
