import { css, styled } from '@mui/material/styles';
import { Box, Accordion, Stack, Typography } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';
import Image from 'components/@shared/Image';
import IconButton from 'components/@shared/IconButton';

/** @deprecated */
export const StyledQualification = styled(
  Typography,
  preventForwardProps(['expanded']),
)<{ expanded?: boolean }>(
  ({ expanded }) => css`
    opacity: ${expanded ? 0 : 1};
    height: ${expanded ? '0' : '16px'};
    transition: all 0.3s;
  `,
);

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledImage = styled(Image)`
  width: 64px;

  & img {
    border-radius: 12px;
  }
`;

export const StyledSubcategoryList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    box-shadow: none;
    background: ${theme.palette.primary.light2};
    border-radius: 16px;
    padding: 8px;

    &:before {
      content: none;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 16px;
    }

    & .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px 0 0;
    }

    & .MuiButtonBase-root {
      min-height: 0;
    }

    & .MuiAccordionSummary-root {
      padding: 0;
    }

    & .MuiAccordionSummary-content {
      margin: 0;
    }

    & .MuiSvgIcon-root {
      color: ${theme.palette.primary.main};
    }

    ${theme.breakpoints.up('md')} {
      padding: 20px;
    }
  `,
);

export const StyledServiceListContainer = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 8px;

    ${theme.breakpoints.up('lg')} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 12px 8px;
    }
  `,
);

export const StyledServiceContainer = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
    gap: 12px;
    padding: 20px;
    border-radius: 12px;
    cursor: pointer;
  `,
);

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    height: 48px;
    width: 48px;
    background: ${theme.palette.primary.light2};
    color: ${theme.palette.primary.main};

    @media (hover: hover) {
      &:hover {
        background: ${theme.palette.primary.light3};
        color: ${theme.palette.primary.main};
      }
    }

    @media (hover: none) {
      &:hover {
        background: ${theme.palette.primary.light3};
        color: ${theme.palette.primary.main};
      }
    }

    :hover {
      background: ${theme.palette.primary.light3};
      color: ${theme.palette.primary.main};
    }

    &:active {
      background: ${theme.palette.primary.light3};
      color: ${theme.palette.primary.main};
    }
  `,
);
