import { css, styled } from '@mui/material/styles';
import { Card, Box, CardActions } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';
import { Link } from 'react-router-dom';

export const StyledCard = styled(
  Card,
  preventForwardProps(['disabled', 'withBorder']),
)<{ disabled?: boolean; withBorder?: boolean }>(
  ({ theme, disabled, withBorder }) => css`
    padding: 20px;
    flex: 1 1 305px;
    min-width: 297px;
    gap: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: ${theme.palette.primary.light};
    border: 1px solid ${withBorder ? theme.palette.primary.light3 : theme.palette.primary.light};
    border-radius: 16px;

    box-shadow: none;

    ${!disabled &&
    css`
      cursor: pointer;

      &:hover {
        border: 1px solid ${theme.palette.accent.light};
      }

      &:active {
        border: 1px solid ${theme.palette.accent.dark};
      }
    `}
  `,
);

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.palette.accent.main};
    overflow: hidden;
  `,
);

export const StyledAttributeSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const StyledAttributeRow = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const StyledCardActions = styled(CardActions)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  padding: 0;

  & :not(:first-of-type) {
    margin: 0;
  }
`;
