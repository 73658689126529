import React from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

import { StyledButton, StyledCircularProgress } from './styled';

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
  typographyProps?: TypographyProps;
  color?: 'primary' | 'error' | 'info';
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  color = 'primary',
  children,
  typographyProps,
  disabled,
  loading,
  ...props
}) => (
  <StyledButton color={color} disabled={disabled || loading} loading={loading} {...props}>
    <StyledCircularProgress size="24px" thickness={4} show={loading} />
    <Typography variant="subtitle2" {...typographyProps}>
      {children}
    </Typography>
  </StyledButton>
);

export default Button;
