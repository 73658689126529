import React, { useRef, useState } from 'react';
import { Stack } from '@mui/material';
import 'dayjs/locale/uk';
import dayjs, { Dayjs } from 'dayjs';

import i18n from 'localizations';
import { StyledContainer } from './styled';
import Header from './Header';
import { ReservationCalendarProps, ReservationCalendarVariant } from './types';
import ListView from './ListView';
import ReservationVariantButton from './ReservationVariantButton';
import ReservationVariantSelect from './ReservationVariantSelect';
import CalendarView from './CalendarView';

const ReservationCalendar: React.FC<ReservationCalendarProps> = ({
  reservationList,
  loading,
  variant = ReservationCalendarVariant.List,
  onDateRangeChange,
  onReservationClick,
  className,
}) => {
  const [date, setDate] = useState<Dayjs>(dayjs());
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (value: Dayjs) => {
    let from;
    let to;
    const today = dayjs();

    if (variant === ReservationCalendarVariant.List) {
      if (value.isBefore(today) || today.isSame(value, 'month')) {
        from = today.startOf('day');
      } else {
        from = value.startOf('month');
      }
      to = value.endOf('month');
    } else {
      if (value.isBefore(today) || today.locale(i18n.language).isSame(value, 'week')) {
        from = today.locale(i18n.language).startOf('week');
      } else {
        from = value.locale(i18n.language).startOf('week');
      }
      to = from.locale(i18n.language).endOf('week');
    }
    setDate(from);
    onDateRangeChange({ from, to });
  };

  return (
    <Stack ref={calendarContainerRef} className={className} width="100%">
      <Header date={date} onChange={handleDateChange} variant={variant} />
      <StyledContainer>
        {variant === ReservationCalendarVariant.List && (
          <ListView reservationList={reservationList} loading={loading} onReservationClick={onReservationClick} />
        )}
        {variant === ReservationCalendarVariant.Calendar && (
          <CalendarView reservationList={reservationList} loading={loading} onReservationClick={onReservationClick} />
        )}
      </StyledContainer>
    </Stack>
  );
};

export default ReservationCalendar;

export { ReservationVariantButton, ReservationVariantSelect };
