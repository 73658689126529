import React, { useEffect, useState } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import Gap from 'components/@shared/Gap';
import { OutputScheduleDayDto } from 'types';
import dayjs, { Dayjs } from 'dayjs';
import Button from 'components/@shared/Button';
import LoadingState from 'components/@common/LoadingState';

import useBookingServicePage from './useBookingServicePage';
import {
  StyledContainer,
  StyledCalendar,
  StyledImage,
  StyledTimeSlotsSection,
  StyledCircularProgress,
  StyledServiceCard,
} from './styled';
import { useBreadcrumbs } from './useBreadcrumbs';
import mapServiceModelToViewModel from './mapServiceModelToViewModel';
import SuccessModal from './SuccessModal';

enum BookingStep {
  FirstStep = 'FirstStep',
  SecondStep = 'SecondStep',
}

const BookingServicePage: React.FC = () => {
  const { t } = useTranslation();
  const {
    serviceDetails,
    onMonthChange,
    availableBookingDays,
    serviceLoading,
    loading,
    successModalOpen,
    onBackToService,
    onBook,
    onCloseSuccessModal,
  } = useBookingServicePage();

  const breadcrumbs = useBreadcrumbs(serviceDetails);

  const [activeDay, setActiveDay] = useState<Dayjs>(dayjs());
  const [availableTimeSlots, setAvailableTimeSlots] = useState<OutputScheduleDayDto[]>(
    availableBookingDays.find(({ date }) => activeDay.isSame(date, 'day'))?.availableTimeSlots || [],
  );

  const [activeTimeSlot, setActiveTimeSlot] = useState<OutputScheduleDayDto | null>(null);
  const [bookingStep, setBookingStep] = useState(BookingStep.FirstStep);

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    let data = availableBookingDays.find(({ date }) => activeDay.isSame(date, 'day'));
    let dayOfMonth = activeDay.date() - 1;
    const daysInMonth = activeDay.daysInMonth();
    const startOfMonth = activeDay.startOf('month');

    while (!data?.availableTimeSlots && dayOfMonth < daysInMonth) {
      const currentDate = startOfMonth.add(dayOfMonth, 'days');
      dayOfMonth += 1;

      if (currentDate.isBefore(dayjs())) {
        continue;
      }
      data = availableBookingDays.find(({ date }) => currentDate.isSame(date, 'day'));
    }

    if (data) {
      setActiveDay(dayjs(data.date));
      setAvailableTimeSlots(data.availableTimeSlots || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableBookingDays]);

  const handleMonthChange = (value: Dayjs) => {
    setActiveTimeSlot(null);
    onMonthChange(value);
    setActiveDay(value);
  };

  const handleDayChange = (value: Dayjs) => {
    setActiveTimeSlot(null);
    setActiveDay(value);
    setAvailableTimeSlots(availableBookingDays.find(({ date }) => value.isSame(date, 'day'))?.availableTimeSlots || []);
  };

  if (serviceLoading) {
    return <LoadingState />;
  }

  if (!serviceDetails) {
    return null;
  }

  const imgSrc = serviceDetails.company
    ? serviceDetails.company.profileImageUri
    : serviceDetails.master.profileImageUri;
  const name = serviceDetails.company ? serviceDetails.company.name : serviceDetails.master.contactName;

  const bookingStepData =
    bookingStep === BookingStep.FirstStep
      ? {
          title: t('bookServicePage.firstStepTitle'),
          stepper: '1 / 2',
          continueButtonLabel: t('continue'),
          onBack: onBackToService,
          onContinue: () => setBookingStep(BookingStep.SecondStep),
        }
      : {
          title: t('bookServicePage.secondStepTitle'),
          stepper: '2 / 2',
          continueButtonLabel: t('bookServicePage.submitButtonLabel'),
          onBack: () => setBookingStep(BookingStep.FirstStep),
          onContinue: () => !!activeTimeSlot && onBook(activeDay, activeTimeSlot.from),
        };

  return (
    <Container>
      <Breadcrumbs {...breadcrumbs} />
      <Stack direction="row" gap="8px" alignItems="center">
        {imgSrc && <StyledImage src={imgSrc} alt={name} />}
        <Typography variant="subtitle1">{name}</Typography>
      </Stack>
      <Gap size={12} />
      <Typography variant={upMedium ? 'h1' : 'h3'}>{t('bookServicePage.title')}</Typography>
      <Gap size={upMedium ? 24 : 20} />
      <StyledContainer>
        <Stack gap="20px" width="800px" maxWidth="100%">
          <Stack direction="row" gap="8px" alignItems="center" justifyContent="space-between">
            <Typography variant="h4">{bookingStepData.title}</Typography>
            <Typography variant="subtitle1" color="primary.dark2">
              {bookingStepData.stepper}
            </Typography>
          </Stack>
          {bookingStep === BookingStep.FirstStep && (
            <Stack direction={upMedium ? 'row' : 'column'} gap="20px">
              <StyledCalendar
                onMonthChange={handleMonthChange}
                value={activeDay}
                availableBookingDays={availableBookingDays}
                onChange={handleDayChange}
                loading={loading}
              />
              <StyledTimeSlotsSection
                availableTimeSlots={availableTimeSlots}
                activeTimeSlot={activeTimeSlot}
                onChange={setActiveTimeSlot}
              />
            </Stack>
          )}
          {bookingStep === BookingStep.SecondStep && activeTimeSlot && (
            <StyledServiceCard
              {...mapServiceModelToViewModel(serviceDetails, activeDay, activeTimeSlot)}
              withBorder
              withActions={false}
              disabled
            />
          )}
          <Stack direction="row" gap={upMedium ? '20px' : '8px'} width="100%">
            <Button
              variant="outlined"
              sx={{ width: upMedium ? 'calc(50% - 10px)' : '30%' }}
              onClick={bookingStepData.onBack}
            >
              {t('back')}
            </Button>
            <Button
              variant="contained"
              sx={{ width: upMedium ? 'calc(50% - 10px)' : '70%' }}
              disabled={!activeTimeSlot || (bookingStep === BookingStep.SecondStep && loading)}
              onClick={bookingStepData.onContinue}
            >
              {bookingStep === BookingStep.SecondStep && loading ? (
                <StyledCircularProgress size={16} />
              ) : (
                bookingStepData.continueButtonLabel
              )}
            </Button>
          </Stack>
        </Stack>
      </StyledContainer>
      <SuccessModal open={successModalOpen} onClose={onCloseSuccessModal} />
    </Container>
  );
};

export default BookingServicePage;
