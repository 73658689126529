import { css, styled } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';

export const HorizontalGap = styled(
  'div',
  preventForwardProps(['size']),
)<{ size: number }>(
  ({ size }) => css`
    width: ${size}px;
  `,
);

export const VerticalGap = styled(
  'div',
  preventForwardProps(['size']),
)<{ size: number }>(
  ({ size }) => css`
    padding-top: ${size}px;
  `,
);
