import { css, styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';

export const StyledPopperContainer = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
    border-radius: 12px;
    border: 1px solid ${theme.palette.accent.main};
    padding: 20px;
    gap: 20px;

    * {
      cursor: pointer;

      :hover {
        color: ${theme.palette.accent.light};
      }

      :active {
        color: ${theme.palette.accent.dark};
      }

      &[data-selected='true'] {
        color: ${theme.palette.accent.main};
      }
    }
  `,
);

export const StyledButton = styled(Button)(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 16px;
    border-radius: 12px;
    text-transform: none;
    flex: 1 1 50%;
    position: relative;

    background: ${theme.palette.primary.light2};
    color: ${theme.palette.primary.main};

    svg {
      flex-shrink: 0;
    }

    &:hover {
      color: ${theme.palette.accent.light};
      background: ${theme.palette.primary.light2};
    }

    &:active {
      color: ${theme.palette.accent.dark};
      background: ${theme.palette.primary.light2};
    }
  `;
});
