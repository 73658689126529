import { css, styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import ContactsSection from 'components/@common/ContactsSection';
import preventForwardProps from 'utils/preventForwardProps';
import IconButton from 'components/@shared/IconButton';
import Button from 'components/@shared/Button';

export const StyledMainContainer = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 20px 40px;
    grid-template-areas:
      'info'
      'master'
      'contacts';

    ${theme.breakpoints.up('md')} {
      grid-template-columns: 3fr 2fr;
      grid-template-rows: auto;
      grid-template-areas:
        'info master'
        'contacts master';
    }
  `,
);

export const StyledInfoSection = styled(Stack)`
  grid-area: info;

  gap: 20px;
`;

export const StyledMasterSection = styled(Box)`
  grid-area: master;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledContactsSection = styled(ContactsSection)`
  grid-area: contacts;
`;

export const StyledAttributeSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledAttributeRow = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

export const StyledIconButton = styled(
  IconButton,
  preventForwardProps(['favorite']),
)<{ favorite?: boolean }>(
  ({ theme, favorite }) => css`
    height: 48px;
    width: 48px;
    background: ${theme.palette.primary.light2};
    color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};

    @media (hover: hover) {
      &:hover {
        background: ${theme.palette.primary.light3};
        color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
      }
    }

    @media (hover: none) {
      &:hover {
        background: ${theme.palette.primary.light3};
        color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
      }
    }

    :hover {
      background: ${theme.palette.primary.light3};
      color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
    }

    &:active {
      background: ${theme.palette.primary.light3};
      color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
    }
  `,
);

export const StyledAvatar = styled(
  'div',
  preventForwardProps(['imgUrl']),
)<{ imgUrl?: string }>(
  ({ imgUrl }) => css`
    background: url('${imgUrl}') no-repeat center center;
    background-size: cover;
    border-radius: 100%;
    height: 24px;
    width: 24px;
  `,
);

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.accent.main};
    width: fit-content;
  `,
);
