import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import { PATHS } from 'navigation/constants';

import { useBreadcrumbs } from './useBreadcrumbs';
import NewOrdersTab from './NewOrdersTab';
import ScheduleTab from './ScheduleTab';
import { StyledTabs } from './styled';

const CompanyCalendarPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumbs();

  const COMPANY_CALENDAR_SCHEDULE = useMatch(PATHS.COMPANY_CALENDAR_SCHEDULE);
  const COMPANY_CALENDAR_NEW_ORDERS = useMatch(PATHS.COMPANY_CALENDAR_NEW_ORDERS);

  const activeTabIndex = COMPANY_CALENDAR_SCHEDULE ? 0 : 1;

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const tabList = [
    {
      label: t('calendarPage.scheduleTab'),
      content: <ScheduleTab />,
    },
    {
      label: t('calendarPage.newOrdersTab'),
      content: <NewOrdersTab />,
    },
  ];

  const handleTabChange = () => {
    if (COMPANY_CALENDAR_SCHEDULE) {
      navigate(PATHS.COMPANY_CALENDAR_NEW_ORDERS);
    }

    if (COMPANY_CALENDAR_NEW_ORDERS) {
      navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
    }
  };

  return (
    <Container>
      <Breadcrumbs {...breadcrumbs} />
      <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
        {t('calendarPage.title')}
      </Typography>
      <Gap size={upMedium ? 56 : 32} />
      <StyledTabs tabList={tabList} activeTabIndex={activeTabIndex} onTabChange={handleTabChange} />
    </Container>
  );
};

export default CompanyCalendarPage;
