import { css, styled } from '@mui/material/styles';

export const StyledEllipse = styled('div')(
  ({ theme }) => css`
    margin: 1px 8px;
    width: 6px;
    height: 6px;
    background: ${theme.palette.primary.dark3};
    border-radius: 50%;
    display: inline-block;
  `,
);
