import { gql } from '@apollo/client';

export const PARTNER_BY_SLUG = gql`
  query PartnerBySlug($slug: String!) {
    partnerBySlug(slug: $slug) {
      id
      type
    }
  }
`;
