import React from 'react';
import { useTranslation } from 'react-i18next';
import generateIncrementalArray from 'utils/generateIncrementalArray';
import ReservationCard, { ReservationCardSkeleton } from 'components/@common/ReservationCard';
import mapReservationModelToViewModel from 'utils/mapReservationModelToViewModel';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Button from 'components/@shared/Button';
import { StyledGrid } from './styled';
import useNewOrdersTab from './useNewOrdersTab';
import getRouteWithParams from 'utils/getRouteWithParams';
import { PATHS } from 'navigation/constants';
import { useNavigate } from 'react-router';
import { OutputMasterReservationDto } from 'types/reservation';

const NewOrdersTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { reservations, ordersPerFetchCount, reservationsLoading, pageInfo, showMoreReservations, handleConfirm } =
    useNewOrdersTab();

  const handleReservationClick = (data: OutputMasterReservationDto) =>
    navigate(getRouteWithParams(PATHS.MASTER_RESERVATION_DETAILS, { reservationId: data.id.toString() }), {
      state: {
        reservation: data,
      },
    });

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack mt={upMedium ? '32px' : '20px'} gap={upMedium ? '20px' : '12px'}>
      <StyledGrid>
        {reservationsLoading
          ? generateIncrementalArray(ordersPerFetchCount).map(item => <ReservationCardSkeleton key={item} />)
          : reservations?.map(data => (
              <ReservationCard
                key={data.id}
                {...mapReservationModelToViewModel(data)}
                onConfirm={() => handleConfirm(data.id)}
                onClick={() => handleReservationClick(data)}
              />
            ))}
      </StyledGrid>
      {pageInfo?.hasNextPage && (
        <Button variant="contained" onClick={showMoreReservations}>
          {t('showMore')}
        </Button>
      )}
    </Stack>
  );
};

export default NewOrdersTab;
