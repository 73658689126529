const formatPhoneNumber = (value: string): string => {
  const valueWithoutPlus = value.replace('+', '');

  return `+${valueWithoutPlus.slice(0, 2)} (${valueWithoutPlus.slice(2, 5)}) ${valueWithoutPlus.slice(
    5,
    8,
  )} ${valueWithoutPlus.slice(8, 10)} ${valueWithoutPlus.slice(10)}`;
};

export default formatPhoneNumber;
