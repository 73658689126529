import { css, styled } from '@mui/material/styles';
import { Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledBreadcrumbs = styled(Breadcrumbs)(
  ({ theme }) => css`
    margin-bottom: 20px;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 24px;
    }

    & .MuiBreadcrumbs-ol {
      display: block;
    }

    & .MuiBreadcrumbs-li,
    & .MuiBreadcrumbs-separator {
      display: inline;
    }
  `,
);

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    font-family: Montserrat, sans-serif;
    font-style: normal;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

    color: ${theme.palette.primary.main};
    text-decoration: none;
  `,
);
