import { useTranslation } from 'react-i18next';
import art from 'assets/icons/serviceCategories/art.svg';
import auto from 'assets/icons/serviceCategories/auto.svg';
import beauty from 'assets/icons/serviceCategories/beauty.svg';
import consultations from 'assets/icons/serviceCategories/consultations.svg';
import medicine from 'assets/icons/serviceCategories/medicine.svg';
import pets from 'assets/icons/serviceCategories/pets.svg';
import sport from 'assets/icons/serviceCategories/sport.svg';
import learning from 'assets/icons/serviceCategories/learning.svg';

import { CategoryEnum } from 'constatns';

const useCategories = () => {
  const { t } = useTranslation();

  return {
    [CategoryEnum.Art]: {
      id: CategoryEnum.Art,
      localizedName: t('category.art'),
      icon: art,
    },
    [CategoryEnum.Auto]: {
      id: CategoryEnum.Auto,
      localizedName: t('category.auto'),
      icon: auto,
    },
    [CategoryEnum.Beauty]: {
      id: CategoryEnum.Beauty,
      localizedName: t('category.beauty'),
      icon: beauty,
    },
    [CategoryEnum.Consultations]: {
      id: CategoryEnum.Consultations,
      localizedName: t('category.consultations'),
      icon: consultations,
    },
    [CategoryEnum.Medicine]: {
      id: CategoryEnum.Medicine,
      localizedName: t('category.medicine'),
      icon: medicine,
    },
    [CategoryEnum.Pets]: {
      id: CategoryEnum.Pets,
      localizedName: t('category.pets'),
      icon: pets,
    },
    [CategoryEnum.Sport]: {
      id: CategoryEnum.Sport,
      localizedName: t('category.sport'),
      icon: sport,
    },
    [CategoryEnum.Learning]: {
      id: CategoryEnum.Learning,
      localizedName: t('category.learning'),
      icon: learning,
    },
  };
};

export default useCategories;
