import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

const useColumnsCount = () => {
  const theme = useTheme();
  const twoColumns = useMediaQuery(theme.breakpoints.up(1115));
  const threeColumns = useMediaQuery(theme.breakpoints.up(1440));

  return useMemo(() => {
    if (threeColumns) {
      return 3;
    }
    if (twoColumns) {
      return 2;
    }
    return 2;
  }, [twoColumns, threeColumns]);
};

export default useColumnsCount;
