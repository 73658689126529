import { FormikConfig, FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import * as React from 'react';
import { AnySchema } from 'yup/lib/schema';
import Reference from 'yup/lib/Reference';
import Lazy from 'yup/lib/Lazy';
import { ObjectShape } from 'yup/lib/object';
import { getFormikHelpers } from 'utils/formikUtils';

type YupSchema<T extends object> = {
  [key in keyof T]?: AnySchema | Reference | Lazy<any, any>;
};

type Props<T extends FormikValues> = FormikConfig<T> & {
  customErrors?: FormikErrors<Record<keyof T, string>>;
  yupSchema?: YupSchema<T>;
};

const errorsToTouched = <Values extends FormikValues>(
  customErrors: FormikErrors<Values>,
  touched: FormikTouched<Values>,
) => {
  const customErrorsTouched = Object.keys(customErrors).reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
  return { ...touched, ...customErrorsTouched };
};

const useForm = <Values extends FormikValues>({
  validateOnChange,
  validateOnBlur,
  enableReinitialize,
  customErrors,
  validationSchema,
  onSubmit,
  yupSchema,
  ...props
}: Props<Values>) => {
  const errors = useMemo(
    () =>
      customErrors &&
      Object.entries(customErrors)
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        .filter(([_, value]) => typeof value === 'string' && value !== '')
        .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {}),
    [customErrors],
  );
  const [isValidateOnChange, setIsValidateOnChange] = useState(!!errors && Object.keys(errors).length !== 0);

  const schema = validationSchema ?? yupSchema ? Yup.object(yupSchema as ObjectShape) : undefined;

  const formik = useFormik({
    validateOnChange: validateOnChange ?? isValidateOnChange,
    validateOnBlur: validateOnBlur ?? isValidateOnChange,
    enableReinitialize: enableReinitialize ?? false,
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => onSubmit(schema ? (schema.cast(values) as Values) : values, formikHelpers),
    ...props,
  });

  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      formik.setTouched(errorsToTouched(errors, formik.touched), false);
      formik.setErrors(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>): void => {
    if (!isValidateOnChange) setIsValidateOnChange(true);
    formik.handleSubmit(e);
  };

  const resetValidation = () => setIsValidateOnChange(false);

  return {
    formik,
    handleSubmit,
    resetValidation,
    formikHelpers: getFormikHelpers(formik),
    setFieldValue: formik.setFieldValue,
  };
};

export default useForm;
