import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 12px;

    ${theme.breakpoints.up('md')} {
      padding: 80px 0;
      gap: 40px;
    }
  `,
);

export const StyledList = styled(Box)`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
`;

export const StyledButtonContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    ${theme.breakpoints.up('md')} {
      margin: 17px auto 0;
      max-width: 325px;
    }
  `,
);
