import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from 'components/@shared/Image';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 20px;
    border-radius: 16px;
    border: 1px solid ${theme.palette.primary.light3};

    cursor: pointer;

    &:hover .service-name {
      color: ${theme.palette.accent.light};
    }

    &:active .service-name {
      color: ${theme.palette.accent.dark};
    }

    ${theme.breakpoints.up('md')} {
      padding: 20px 20px 20px 24px;
    }
  `,
);

export const StyledImageContainer = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  width: auto;
`;

export const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: 40px;
    height: 40px;
    border: 2px solid ${theme.palette.primary.light};
    border-radius: 8px;
    overflow: hidden;
  `,
);

export const StyledAttributeSection = styled(Box)`
  display: flex;
  gap: 6px;
  width: 100%;
  flex-wrap: wrap;
`;

export const StyledAttribute = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 1px solid ${theme.palette.primary.light3};
    border-radius: 8px;

    svg {
      color: ${theme.palette.primary.dark2};
      height: 16px;
      width: 16px;
    }
  `,
);
