import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

    ${theme.breakpoints.up('md')} {
      flex: 0 1 385px;
    }
  `,
);

export const StyledEmailSection = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 12px;
    align-items: center;

    & svg {
      color: ${theme.palette.accent.main};
    }

    cursor: pointer;

    &:hover {
      & svg {
        color: ${theme.palette.accent.light};
      }
    }

    &:active {
      & svg {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);

export const StyledEmailTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
