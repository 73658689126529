import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { PARTNER_BY_SLUG } from 'api/requests/@shared';
import { AccountTypeEnum } from 'constatns';
import MasterDetailsPage from '../MasterDetailsPage';

const CompanyMasterPage: React.FC = () => {
  const { i18n } = useTranslation();

  const { slug, masterId } = useParams<'slug' | 'masterId'>();

  const [partnerBySlug] = useLazyQuery<
    { partnerBySlug: { id: number; type: AccountTypeEnum } },
    {
      slug: string;
    }
  >(PARTNER_BY_SLUG, {
    fetchPolicy: 'no-cache',
  });

  const [id, setId] = useState<null | number>(null);

  useEffect(() => {
    if (slug) {
      partnerBySlug({ variables: { slug } }).then(res => {
        if (res.data?.partnerBySlug) {
          setId(res.data.partnerBySlug.id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, i18n.language]);

  if (!masterId || id === null) return null;

  return <MasterDetailsPage masterId={+masterId} companyId={id} />;
};

export default CompanyMasterPage;
