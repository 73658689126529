import { useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import validateEmail from 'utils/validateEmail';
import { PhoneMetaData } from 'components/@shared/PhoneInput';

export const NAME_MAXIMUM_LENGTH = 40;
const MOBILE_PHONE_MAXIMUM_LENGTH = 19;
export const ABOUT_MAXIMUM_LENGTH = 1000;
export const MAXIMUM_SERVICE_LENGTH = 200;

const YupString = Yup.string().trim();

export const useYupRules = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      notRequiredStringRule: YupString.trim().notRequired(),
      requiredStringRule: YupString.trim().required(t('validation.fieldIsRequired')),
      contactNameRule: YupString.trim().required(t('validation.fieldIsRequired')),
      phoneNumberRule: YupString.required(t('validation.fieldIsRequired')).test(
        'phoneMaxLength',
        t('validation.mobileNumberIsInvalid'),
        (value, context) => {
          return context.parent.phoneMetaData?.format
            ? value?.replace('+', '').length === context.parent.phoneMetaData?.format.match(/\./g).length
            : (value?.length || 0) <= MOBILE_PHONE_MAXIMUM_LENGTH;
        },
      ),
      phoneMetaDataRule: Yup.mixed<PhoneMetaData | {}>(),
      cityRule: Yup.object().required(t('validation.fieldIsRequired')).nullable(),
      addressRule: Yup.object().required(t('validation.fieldIsRequired')).nullable(),
      emailRule: YupString.required(t('validation.fieldIsRequired')).test(
        'test',
        t('validation.emailNumberIsInvalid'),
        value => !!value && !!validateEmail(value),
      ),
      aboutRule: YupString.notRequired(),
      socialMediaLinkRule: YupString.notRequired(),
      scheduleRequiredRule: Yup.boolean().isFalse(t('validation.scheduleCountInvalid')),
      activitiesRequiredRule: Yup.boolean().isFalse(t('validation.activitiesCountInvalid')),
      serviceNameRule: YupString.required(t('validation.fieldIsRequired')),
      durationRule: Yup.object().nullable().required(t('validation.fieldIsRequired')),
      priceRule: Yup.number().required(t('validation.fieldIsRequired')),
    }),
    [t],
  );
};
