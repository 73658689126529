import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import AppBar from '../AppBar';

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledAppBar = styled(AppBar)(
  ({ theme }) => css`
    position: sticky;
    // TODO: check that nothing is broken
    //z-index: 2;
    z-index: 10000;
    top: 0;
    margin-bottom: 20px;

    ${theme.breakpoints.up('sm')} {
      margin-bottom: 24px;
    }
  `,
);

export const StyledMainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;
