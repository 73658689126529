import React from 'react';
import { TextFieldProps } from '@mui/material';

import { StyledTextField } from './styled';

export type InputProps = TextFieldProps & {
  name?: string;
  children?: React.ReactNode;
};

const Input: React.FC<InputProps> = ({
  name,
  inputProps,
  InputLabelProps,
  error,
  fullWidth = true,
  autoComplete = 'off',
  value,
  ...props
}) => (
  <StyledTextField
    hidden
    name={name}
    value={value}
    error={error}
    fullWidth={fullWidth}
    autoComplete={autoComplete}
    inputProps={{ autoCapitalize: 'none', ...inputProps }}
    InputLabelProps={{ shrink: true, ...InputLabelProps }}
    {...props}
  />
);

export default React.memo(Input);
