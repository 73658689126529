import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteProps, TextField, useTheme } from '@mui/material';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';

import { StyledAutocomplete } from './styled';

export type ListItem = {
  id: number;
  localizedName: string;
};

type Props = Pick<
  AutocompleteProps<ListItem, boolean, boolean, boolean>,
  'onInputChange' | 'onChange' | 'value' | 'options' | 'loading' | 'defaultValue'
> & {
  selectRef: React.Ref<HTMLElement>;
};

const CitySelect: React.FC<Props> = ({ defaultValue, onInputChange, onChange, value, options, loading, selectRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const textStyles = {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '18px',
    padding: '10 20px',
    background: 'none !important',

    [theme.breakpoints.up('sm')]: {
      fontWeight: 800,
      fontSize: '16px',
      lineHeight: '22px',
    },
  };

  return (
    <StyledAutocomplete
      options={options}
      defaultValue={defaultValue}
      ref={selectRef}
      noOptionsText={t('noOptionsText')}
      loading={loading}
      loadingText={t('loadingText')}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('searchSection.citySelectorPlaceholder')}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      popupIcon={<LocationIcon height="24px" width="24px" />}
      sx={{
        '& .MuiAutocomplete-popupIndicator': {
          transform: 'none',
        },
      }}
      componentsProps={{
        popupIndicator: {
          disableRipple: true,
        },
        clearIndicator: {
          disableRipple: true,
        },
        popper: {
          sx: {
            zIndex: 0,
            background: theme.palette.primary.light2,
            borderRadius: '12px',
            overflow: 'hidden',
            margin: '8px 0 !important',

            '& .MuiPaper-root': {
              background: 'transparent',
            },
            '& .MuiAutocomplete-option': {
              ...textStyles,

              '&:hover': {
                cursor: 'pointer',
                background: 'none !important',
                color: theme.palette.accent.light,
              },

              '&:active': {
                color: theme.palette.accent.dark,
                background: 'none !important',
              },
            },
            '& .MuiAutocomplete-noOptions': {
              ...textStyles,
              color: theme.palette.primary.dark2,
            },
            '& .MuiAutocomplete-loading': {
              ...textStyles,
              color: theme.palette.primary.dark2,
            },
          },
        },
      }}
      getOptionLabel={option => (option as ListItem).localizedName}
      isOptionEqualToValue={(o, v) => (o as ListItem).id === (v as ListItem).id}
      // @ts-ignore
      onChange={onChange}
      onInputChange={onInputChange}
    />
  );
};

export default React.memo(CitySelect);
