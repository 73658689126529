import { css, styled } from '@mui/material/styles';
import { Toolbar, Box, AppBar } from '@mui/material';
import { APP_BAR_HEIGHT } from 'constatns';

export const StyledAppBar = styled(AppBar)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light};
    box-shadow: 0 1px 1px 0 ${theme.palette.primary.light2};
    height: ${APP_BAR_HEIGHT}px;
    justify-content: center;
  `,
);

export const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    color: ${theme.palette.primary.dark};
    flex: 1 1 auto;
    gap: 16px;
  `,
);

export const StyledLeftSection = styled(Box)`
  display: flex;
  flex: 0 1 556px;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledRightSection = styled(Box)`
  display: flex;
  align-items: stretch;
  gap: 16px;
`;
