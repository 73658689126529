import React, { useEffect, useState } from 'react';
import { debounce } from '@mui/material';

const useResize = (ref?: React.RefObject<HTMLElement>) => {
  const [height, setHeight] = useState(ref ? ref.current?.offsetHeight || null : window.innerHeight);
  const [width, setWidth] = useState(ref ? ref.current?.offsetWidth || null : window.innerWidth);

  useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [ref?.current]);


  useEffect(() => {
    const debouncedResize = debounce(function handleResize() {
      setHeight(ref ? ref.current?.offsetHeight || null : window.innerHeight);
      setWidth(ref ? ref.current?.offsetWidth || null : window.innerWidth);
    }, 300);

    window.addEventListener('resize', debouncedResize);
    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { height, width };
};

export default useResize;
