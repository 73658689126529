import { css, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: start;
    max-width: 295px;
    padding: 24px 0;

    ${theme.breakpoints.up('sm')} {
      padding: 136px 0 52px;
      max-width: 816px;
      gap: 24px;
    }
  `,
);

export const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      max-width: 711px;
    }
  `,
);
