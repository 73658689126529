import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGroupedCategoryList } from 'utils/getGroupedCategoryList';
import { AccordionDetails, AccordionSummary, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from 'components/@shared/Button';
import InlineList from 'components/@shared/InlineList';

import EmptyState from './EmptyState';
import useServicesTab from './useServicesTab';
import { StyledAccordion, StyledServiceList } from './styled';
import ServiceCard from './ServiceCard';
import mapServiceModelToViewModel from './mapServiceModelToViewModel';
import { useLocation } from 'react-router-dom';

const ServicesTab: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const { categories, services, onAddService, onDeleteService, onEditService } = useServicesTab();

  const [expanded, setExpanded] = useState<string | false>(state?.currentCategoryID || false);

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : false);

  return !categories.length ? (
    <EmptyState />
  ) : (
    <Stack gap="20px">
      {getGroupedCategoryList(categories).map((c, cIndex) => (
        <Stack key={c.id} gap="8px">
          <Typography variant="h5" color="primary.main" component="h4">
            {c.localizedName}
          </Typography>
          <Stack gap="20px">
            {c.attributeGroupList.map((g, gIndex) => {
              const currentAttributeGroupServices = services.filter(item => item.category.localId === g.id);
              return (
                <StyledAccordion
                  key={g.id}
                  expanded={expanded ? expanded === g.id : cIndex === 0 && gIndex === 0}
                  disableGutters
                  onChange={handleChange(g.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id={c.id}
                    onClick={() => {}}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      mr="24px"
                      gap="8px"
                    >
                      <InlineList list={g.attributeList.map((item, index) => ({ item, key: index }))} variant="h5" />
                      {upMedium && (
                        <Button variant="contained" sx={{ width: '236px' }} onClick={() => onAddService(g.id)}>
                          {t('masterCabinetPage.addServiceButtonLabel')}
                        </Button>
                      )}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {currentAttributeGroupServices.length ? (
                      <StyledServiceList>
                        {currentAttributeGroupServices.map(item => (
                          <ServiceCard
                            key={item.id}
                            {...mapServiceModelToViewModel(item)}
                            onDelete={() => onDeleteService(item.id)}
                            onClick={() => onEditService(item)}
                          />
                        ))}
                      </StyledServiceList>
                    ) : (
                      <Typography variant="body2" color="primary.dark2">
                        {t('masterCabinetPage.servicesTabNoServicesTitle')}
                      </Typography>
                    )}
                    {!upMedium && (
                      <Button variant="contained" onClick={() => onAddService(g.id)}>
                        {t('masterCabinetPage.addServiceButtonLabel')}
                      </Button>
                    )}
                  </AccordionDetails>
                </StyledAccordion>
              );
            })}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default ServicesTab;
