import { OutputMasterServiceDto, OutputServiceDto } from 'types/service';
import { ServiceCardProps } from 'components/@common/ServiceCard';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import convertTimeSpanToDayjs from './convertTimeSpanToDayjs';
import mapAttributesGroupIdToIcon from './mapAttributesGroupIdToIcon';
import i18n from 'localizations';

const isOutputServiceDto = (service: OutputServiceDto | OutputMasterServiceDto): service is OutputServiceDto =>
  !!(service as OutputServiceDto).company;

const mapServiceModelToViewModel = (
  service: OutputServiceDto | OutputMasterServiceDto,
  distanceMetersOfGroup?: number,
  showMaster?: boolean,
): ServiceCardProps => {
  const attributeList = [];
  const distanceMeters = distanceMetersOfGroup || (service as OutputServiceDto).distanceMeters;

  attributeList.push({
    startAdornment: <TimeIcon />,
    value: convertTimeSpanToDayjs(service.duration).format(`H [${i18n.t('hoursShort')}] mm [${i18n.t('minutesShort')}]`),
  });

  const qualification = service.category.attributeGroups.find(item => item.isQualification)?.attribute.localizedName;

  service.category.attributeGroups.forEach(item => {
    const Icon = mapAttributesGroupIdToIcon(item.id);
    if (!item.isQualification) {
      attributeList.push({
        startAdornment: <Icon />,
        value: item.attribute.localizedName,
      });
    }
  });

  return {
    id: service.id,
    name: service.name,
    category: service.category.id,

    price: service.price,
    attributeList,
    distanceMeters,
    ...((isOutputServiceDto(service) || showMaster) && service.master.contactName && { masterDetails: service.master }),
    qualification,
  };
};

export default mapServiceModelToViewModel;
