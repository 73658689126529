import { css, styled } from '@mui/material/styles';
import { CircularProgress, Stack } from '@mui/material';

export const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.primary.light3};
    border-radius: 0 0 20px 20px;
    border-top: none;
    min-height: 100px;
  `,
);

export const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => css`
    color: ${theme.palette.accent.main};
  `,
);
