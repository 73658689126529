import { useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router';
import { PATHS } from 'navigation/constants';
import {
  ADD_MASTER_SERVICE_PHOTOS,
  ADD_OR_UPDATE_MASTER_SERVICE,
  REMOVE_MASTER_SERVICE_PHOTO,
} from 'api/requests/service';
import { InputMasterServiceDto, OutputMasterServiceDto } from 'types';
import convertDayjsToTimespan from 'utils/convertDayjsToTimespan';
import useMutation from 'hooks/useMutation';
import { useMasterState } from 'contexts/master';
import { setCurrentService } from 'contexts/master/actions';
import getRouteWithParams from 'utils/getRouteWithParams';
import { useAppState } from 'contexts/app';
import { setErrorBanner } from 'contexts/app/actions';
import { ApolloError } from '@apollo/client';

import { ServiceFormType } from './types';

export const useManageServicePage = () => {
  const navigate = useNavigate();
  const { id, serviceId } = useParams<'id' | 'serviceId'>();

  const {
    state: { currentCategory, currentService, master },
    dispatch: masterDispatch,
  } = useMasterState();

  const { dispatch } = useAppState();

  const [imgList, setImgList] = useState<File[]>([]);

  const [addOrUpdateMasterService, { loading: addOrUpdateMasterServiceLoading }] = useMutation<
    { addOrUpdateMasterService: OutputMasterServiceDto },
    {
      input: InputMasterServiceDto;
    }
  >(ADD_OR_UPDATE_MASTER_SERVICE);

  const [addMasterServicePhotos, { loading: addMasterServicePhotosLoading }] = useMutation<
    { addMasterServicePhotos: OutputMasterServiceDto },
    {
      masterId?: number;
      serviceId: number;
      photos: File[];
    }
  >(ADD_MASTER_SERVICE_PHOTOS);

  const [removeMasterServicePhoto, { loading: removeMasterServicePhotoLoading }] = useMutation<
    { addMasterServicePhotos: OutputMasterServiceDto },
    {
      masterId?: number;
      serviceId: number;
      photoId: number;
    }
  >(REMOVE_MASTER_SERVICE_PHOTO);

  const COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE = useMatch(
    PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE,
  );
  const COMPANY_CABINET_MASTER_SERVICES_TAB_EDIT_SERVICE = useMatch(
    PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_EDIT_SERVICE,
  );

  const navigateTo = () => {
    const to =
      (COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE || COMPANY_CABINET_MASTER_SERVICES_TAB_EDIT_SERVICE) && id
        ? getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB, { id })
        : PATHS.MASTER_CABINET_SERVICES_TAB;
    navigate(to, {
      state: {
        currentCategoryID: currentCategory?.localId || currentService?.category?.localId,
      },
    });
  };

  useEffect(() => {
    if ((serviceId === undefined && !currentCategory) || (serviceId !== undefined && !currentService)) {
      navigateTo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);

  const handleManageService = async ({ name, duration, price, description }: ServiceFormType) => {
    try {
      const masterId = master.id;

      let category;

      if (currentService) {
        const { localId, ...rest } = currentService.category;
        category = rest;
      } else if (currentCategory) {
        category = currentCategory;
      }

      if (masterId !== undefined && category && duration) {
        const response = await addOrUpdateMasterService({
          variables: {
            input: {
              ...(serviceId !== undefined && { serviceId: +serviceId }),
              masterId,
              name,
              duration: convertDayjsToTimespan(duration),
              price: +price,
              ...(!!description && { description }),
              category,
            },
          },
        });

        if (response.data?.addOrUpdateMasterService) {
          await addMasterServicePhotos({
            variables: {
              masterId,
              serviceId: response.data.addOrUpdateMasterService.id,
              photos: imgList,
            },
          });
          navigateTo();
        }
      }
    } catch (error) {
      dispatch(setErrorBanner(error as ApolloError));
    }
  };

  const handleBack = () => {
    navigate(
      COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE
        ? PATHS.COMPANY_CABINET_MASTERS_TAB
        : PATHS.MASTER_CABINET_SERVICES_TAB,
    );
  };

  const removePhoto = async (photoId: number) => {
    if (!currentService) return;

    let removedPhoto;

    try {
      const masterId = master.id;
      const serviceId = currentService?.id;

      if (masterId && serviceId) {
        removedPhoto = currentService.photos.find(i => i.id === photoId);
        await masterDispatch(
          setCurrentService({
            ...currentService,
            photos: currentService?.photos.filter(item => item.id !== photoId),
          }),
        );
        removeMasterServicePhoto({
          variables: {
            masterId,
            serviceId,
            photoId,
          },
        });
      }
    } catch (e) {
      if (removedPhoto) {
        setCurrentService({
          ...currentService,
          photos: [...currentService.photos, removedPhoto],
        });
      }
    }
  };

  return {
    currentService,
    imgList,
    setImgList,
    master,
    attributeGroups: currentCategory?.attributeGroups,
    handleManageService,
    loading: addOrUpdateMasterServiceLoading || addMasterServicePhotosLoading,
    handleBack,
    removePhoto,
  };
};
