import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import Gap from 'components/@shared/Gap';
import { FormHelperText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Switch from 'components/@shared/Switch';
import Input from 'components/@shared/Input';
import PhoneInput, { PhoneMetaData } from 'components/@shared/PhoneInput';
import SocialMedia, { SocialMediaType } from 'components/@common/SocialMedia';
import Autocomplete from 'components/@shared/Autocomplete';
import Button from 'components/@shared/Button';
import Select from 'components/@shared/Select';
import { CategoryItem } from 'types/service';
import { ABOUT_MAXIMUM_LENGTH, useYupRules } from 'hooks/useYupRules';
import useForm from 'hooks/useForm';

import { CityItem } from 'types/filter';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AutocompleteChangeDetails } from '@mui/base/useAutocomplete/useAutocomplete';
import CategoryLabel from 'components/@common/CategoryLabel';
import SaveChangesModal from 'components/@common/SaveChangesModal';
import ChipsSection from 'components/@common/ChipsSection';
import TimePicker from 'components/@shared/TimePicker';
import { getGroupedCategoryList } from 'utils/getGroupedCategoryList';
import { DayOfWeekEnum } from 'constatns';
import { getScheduleChipList } from 'utils/getScheduleChipList';
import convertDayjsToTimespan from 'utils/convertDayjsToTimespan';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
import { useMatch } from 'react-router';
import { PATHS } from 'navigation/constants';
import { Address } from 'types';
import AddressAutocomplete from 'components/@shared/AddressAutocomplete';
import Slug from 'components/@common/Slug';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';
import DeleteModal from 'components/@common/DeleteModal';
import InlineList from 'components/@shared/InlineList';
import useDayOfWeekList from 'hooks/useDayOfWeekList';

import useInfoTab from './useInfoTab';
import {
  StyledActivateContainer,
  StyledLeftContainer,
  StyledMainContainer,
  StyledRightContainer,
  StyledSelect,
  StyledInput,
  StyledActivateDescription,
} from './styled';
import { StyledImg, StyledImgContainer, StyledImgPlaceholder } from '../styled';

type CallbackFunction<T extends any[] = any[]> = (...args: T) => void;

export type MasterFormValues = {
  contactName: string;
  city: CityItem | null;
  address: Address | null;
  phoneNumber: string;
  phoneMetaData: PhoneMetaData | {};
  email: string;
  about?: string;
  instagramLink?: string;
  facebookLink?: string;
  telegramLink?: string;
  _scheduleRequired: boolean;
  _activitiesRequired: boolean;
};

enum DeleteModalType {
  Experience = 'Experience',
  Master = 'Master',
}

const InfoTab: React.FC = () => {
  const { t } = useTranslation();

  const {
    master,
    cityList,
    cityListLoading,
    categoryList,
    selectedCategory,
    requiredAttributes,
    selectedAttributeGroups,
    citySelectInputValue,
    switchActivateMasterLoading,
    manageMasterLoading,
    onCategoryChange,
    onAttributeChange,
    onAddExperienceClick,
    onDeleteExperienceClick,
    onDeleteCategoryClick,
    onCitySelectInputChange,
    onSwitchActivateMaster,
    onSaveChanges,
    onChangeScheduleDay,
    onDeleteSchedule,
    handleImgInputChange,
    setMasterPhotoLoading,
    handleDeleteMaster,
    handleSlugUpdate,
  } = useInfoTab();

  const [fromDateSchedule, setFromDateSchedule] = useState<Dayjs | null>(null);
  const [toDateSchedule, setToDateSchedule] = useState<Dayjs | null>(null);
  const [scheduleDay, setScheduleDay] = useState<DayOfWeekEnum | null>(null);
  const [draftFormValues, setDraftFormValues] = useState<MasterFormValues | null>();
  const [validateOnChange, setValidateOnChange] = useState(master.activated);
  const [openDeleteModal, setOpenDeleteModal] = useState<null | DeleteModalType>(null);
  const [experienceToDelete, setExperienceToDelete] = useState<
    | {
        id: string;
        chipSegmentList: string[];
      }
    | string
    | null
  >(null);

  const MASTER_CABINET_INFO_TAB = useMatch(PATHS.MASTER_CABINET_INFO_TAB);

  const ref = useRef<HTMLInputElement>(null);

  const imageStatus = useLoadImage(master.profileImageUri || '');

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const {
    contactNameRule,
    cityRule,
    addressRule,
    phoneNumberRule,
    phoneMetaDataRule,
    emailRule,
    aboutRule,
    socialMediaLinkRule,
    scheduleRequiredRule,
    activitiesRequiredRule,
  } = useYupRules();

  const initialValues = {
    contactName: master.contactName || '',
    city: master.town || null,
    address: master.address || null,
    phoneNumber: master.phone || '',
    phoneMetaData: {},
    email: master.email || '',
    about: master.about || '',
    instagramLink: master.instagramLink || '',
    facebookLink: master.facebookLink || '',
    telegramLink: master.telegramLink || '',
    _scheduleRequired:
      !master.schedule || !Object.entries(master.schedule).find(([key, value]) => !key.startsWith('__') && !!value),
    _activitiesRequired: !master.categories.length,
  };

  const {
    formik,
    setFieldValue,
    formikHelpers: { getFormikProps, withOnChangeStringParameter },
  } = useForm<MasterFormValues>({
    initialValues: {
      ...initialValues,
      ...draftFormValues,
      _scheduleRequired: initialValues._scheduleRequired,
      _activitiesRequired: initialValues._activitiesRequired,
    },
    yupSchema: {
      contactName: contactNameRule,
      city: MASTER_CABINET_INFO_TAB ? cityRule : cityRule.notRequired(),
      address: MASTER_CABINET_INFO_TAB ? addressRule : addressRule.notRequired(),
      phoneNumber: phoneNumberRule,
      phoneMetaData: phoneMetaDataRule,
      email: emailRule,
      about: aboutRule,
      instagramLink: socialMediaLinkRule,
      facebookLink: socialMediaLinkRule,
      telegramLink: socialMediaLinkRule,
      _scheduleRequired: scheduleRequiredRule,
      _activitiesRequired: activitiesRequiredRule,
    },
    enableReinitialize: true,
    validateOnChange,
    onSubmit: async () => {
      await onSwitchActivateMaster();
    },
  });

  useEffect(() => {
    if (!Object.keys(formik.errors).length && !master.activated) {
      setValidateOnChange(false);
    }
    if (master.activated) {
      setValidateOnChange(true);
    }
  }, [formik.errors, master.activated]);

  const handleCitySelectChange = useCallback(
    (event: any, value: any, reason: any, details?: AutocompleteChangeDetails<unknown>) => {
      const city = (details?.option as CityItem) || null;
      setFieldValue('city', city);
      onCitySelectInputChange((details?.option as CityItem)?.localizedName ?? '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dayOfWeekList = useDayOfWeekList();

  const handleChangeCitySelectInput = (event: any, value: string, reason: string) => {
    if (reason === 'reset') {
      onCitySelectInputChange(formik.values.city?.localizedName ?? '');
    }
    if (reason === 'input') {
      onCitySelectInputChange(value);
      if (!value) {
        setFieldValue('city', null);
      }
    }
  };

  const handleAddScheduleDay = () => {
    if (scheduleDay) {
      onChangeScheduleDay(scheduleDay, {
        from: convertDayjsToTimespan(fromDateSchedule || dayjs().startOf('day')),
        to: convertDayjsToTimespan(toDateSchedule || dayjs().startOf('day')),
      });
      setFromDateSchedule(null);
      setToDateSchedule(null);
      setScheduleDay(null);
    }
  };

  const handleChangeActiveAccount = async () => {
    handleDiscardChanges();
    if (!master.activated) {
      const result = await formik.validateForm(initialValues);
      formik.setErrors(result);
      if (Object.keys(result).length) {
        setValidateOnChange(true);
        setTimeout(() => {
          document.querySelector('.Mui-error')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
        return;
      }
    }
    await onSwitchActivateMaster();
  };

  const handleSaveChanges = async () => {
    if (master.activated) {
      const result = await formik.validateForm();
      formik.setErrors(result);
      if (Object.keys(result).length) {
        return;
      }
    }
    await onSaveChanges(formik.values);
    setDraftFormValues(null);
  };

  const handleDeleteExperience = async () => {
    if (experienceToDelete === null) return;

    if (typeof experienceToDelete === 'string') {
      await onDeleteCategoryClick(experienceToDelete);
    } else {
      await onDeleteExperienceClick(experienceToDelete.id);
    }

    setDraftFormValues(formik.values);
    setOpenDeleteModal(null);
    setExperienceToDelete(null);
  };

  const isAnyChanges = () => {
    return (
      initialValues.contactName !== formik.values.contactName ||
      (!!MASTER_CABINET_INFO_TAB &&
        (initialValues.city?.id !== formik.values.city?.id ||
          initialValues.address?.addressLine !== formik.values.address?.addressLine)) ||
      initialValues.phoneNumber !== formik.values.phoneNumber ||
      initialValues.email !== formik.values.email ||
      initialValues.about !== formik.values.about ||
      initialValues.instagramLink !== formik.values.instagramLink ||
      initialValues.facebookLink !== formik.values.facebookLink ||
      initialValues.telegramLink !== formik.values.telegramLink
    );
  };

  const handleDiscardChanges = () => {
    formik.resetForm();
    onCitySelectInputChange(initialValues.city?.localizedName ?? '');
  };

  const handleAddImgClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const withSavingDraft =
    <T extends CallbackFunction>(callback: T) =>
    (...props: Parameters<T>) => {
      setDraftFormValues(formik.values);
      callback(...props);
    };

  const requiredAttributesByCategory = selectedCategory?.id && requiredAttributes[selectedCategory.id];

  const button = !MASTER_CABINET_INFO_TAB && (
    <Button variant="contained" color="error" onClick={() => setOpenDeleteModal(DeleteModalType.Master)}>
      {t('masterCabinetPage.deleteMasterButton')}
    </Button>
  );

  const labels = useMemo(
    () =>
      MASTER_CABINET_INFO_TAB
        ? ({
            infoTitle: 'masterCabinetPage.infoTitle',
            avatarTitle: 'masterCabinetPage.avatarTitle',
            contactNameFieldLabel: 'masterCabinetPage.contactNameFieldPlaceholder',
            cityFieldLabel: 'masterCabinetPage.cityFieldLabel',
            emailPlaceholder: 'masterCabinetPage.emailPlaceholder',
            aboutLabel: 'masterCabinetPage.aboutLabel',
            aboutPlaceholder: 'masterCabinetPage.aboutPlaceholder',
            activitiesDescription: 'masterCabinetPage.activitiesDescription',
          } as const)
        : ({
            infoTitle: 'masterCabinetPage.companyMasterInfoTitle',
            avatarTitle: 'masterCabinetPage.companyMasterAvatarTitle',
            contactNameFieldLabel: 'masterCabinetPage.companyMasterContactNameFieldPlaceholder',
            cityFieldLabel: 'masterCabinetPage.companyMasterCityFieldLabel',
            emailPlaceholder: 'masterCabinetPage.companyMasterEmailPlaceholder',
            aboutLabel: 'masterCabinetPage.companyMasterAboutLabel',
            aboutPlaceholder: 'masterCabinetPage.companyMasterAboutPlaceholder',
            activitiesDescription: 'masterCabinetPage.companyMasterActivitiesDescription',
          } as const),
    [MASTER_CABINET_INFO_TAB],
  );

  const groupedCategoryList = useMemo(() => getGroupedCategoryList(master.categories), [master.categories]);

  const isAddAttributeGroupActive = useMemo(() => {
    const selectedAttributeGroupList = Object.values(selectedAttributeGroups);
    const selectedAttributeGroupsId = selectedAttributeGroupList
      .map(item => item?.id)
      .filter(item => !!item)
      .sort()
      .join('__');

    return (
      !!selectedCategory?.id &&
      !!requiredAttributes[selectedCategory.id]?.length &&
      requiredAttributes[selectedCategory.id]?.length === selectedAttributeGroupList.length &&
      !groupedCategoryList.find(c => !!c.attributeGroupList.find(ag => ag.id === selectedAttributeGroupsId))
    );
  }, [selectedCategory, requiredAttributes, selectedAttributeGroups, groupedCategoryList]);

  const scheduleList = dayOfWeekList.filter(i => !master.schedule?.[i.id]);

  return (
    <>
      <StyledActivateContainer>
        <Stack gap="4px">
          <Typography variant="subtitle1" color="primary.main">
            {master.activated
              ? t('masterCabinetPage.activeAccountTitle')
              : t('masterCabinetPage.notActiveAccountTitle')}
          </Typography>
          <StyledActivateDescription variant="body3" color="primary.dark2" active={!master.activated}>
            {t('masterCabinetPage.notActiveAccountDescription')}
          </StyledActivateDescription>
        </Stack>
        <Switch
          checked={master.activated || false}
          onChange={handleChangeActiveAccount}
          loading={switchActivateMasterLoading}
        />
      </StyledActivateContainer>
      <Gap size={24} />
      <StyledMainContainer>
        <StyledLeftContainer>
          <ContainerWithBorder gap="24px">
            <Typography variant="subtitle1" color="primary.main">
              {t(labels.infoTitle)}
            </Typography>
            <Stack direction="row" gap="16px">
              <StyledInput type="file" onChange={handleImgInputChange} ref={ref} accept="image/png, image/jpeg" />
              <StyledImgContainer>
                {master.profileImageUri && imageStatus === ImageStatus.Loaded ? (
                  <StyledImg src={master.profileImageUri} alt="avatar" />
                ) : (
                  <StyledImgPlaceholder />
                )}
              </StyledImgContainer>
              <Stack justifyContent="space-between" gap="16px" flex="1 1 66%">
                <Stack gap="4px">
                  <Typography variant="subtitle2" color="primary.main">
                    {t(labels.avatarTitle)}
                  </Typography>
                  <Typography variant="body3" color="primary.main">
                    {t('masterCabinetPage.avatarDescription')}
                  </Typography>
                </Stack>
                <Button
                  variant="outlined"
                  sx={{ paddingX: '10px !important' }}
                  onClick={handleAddImgClick}
                  loading={setMasterPhotoLoading}
                >
                  {t(
                    master.profileImageUri
                      ? 'masterCabinetPage.avatarChangeButtonLabel'
                      : 'masterCabinetPage.avatarAddButtonLabel',
                  )}
                </Button>
              </Stack>
            </Stack>
            <Input
              {...getFormikProps('contactName')}
              label={t('masterCabinetPage.contactNameFieldLabel')}
              placeholder={t(labels.contactNameFieldLabel)}
              error={!!formik.errors.contactName}
              helperText={formik.errors.contactName}
            />
            {MASTER_CABINET_INFO_TAB && (
              <>
                <Autocomplete
                  value={formik.values.city}
                  inputValue={citySelectInputValue}
                  defaultValue={master.town}
                  options={cityList}
                  loading={cityListLoading}
                  InputProps={{
                    label: t(labels.cityFieldLabel),
                    placeholder: t('masterCabinetPage.cityFieldPlaceholder'),
                    error: !!formik.errors.city,
                    helperText: formik.errors.city,
                  }}
                  loadingStateMessage={t('loadingText')}
                  emptyStateMessage={t('noOptionsText')}
                  onChange={handleCitySelectChange}
                  onInputChange={handleChangeCitySelectInput}
                />
                <AddressAutocomplete
                  value={formik.values.address}
                  onChange={value => setFieldValue('address', value)}
                  label={t('companyCabinetPage.addressFieldLabel')}
                  placeholder={t('companyCabinetPage.addressFieldPlaceholder')}
                  error={!!formik.errors.address}
                  helperText={formik.errors.address}
                />
              </>
            )}
            <PhoneInput
              {...getFormikProps('phoneNumber')}
              onChange={(value, data) => {
                formik.setFieldValue('phoneMetaData', data);
                formik.setFieldValue('phoneNumber', value);
              }}
              label={t('masterCabinetPage.phoneNumberLabel')}
              error={!!formik.errors.phoneNumber}
              helperText={formik.errors.phoneNumber}
            />
            <Input
              {...getFormikProps('email')}
              InputProps={{
                type: 'email',
              }}
              label={t('masterCabinetPage.emailLabel')}
              placeholder={t(labels.emailPlaceholder)}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
            <Input
              {...getFormikProps('about')}
              label={t(labels.aboutLabel)}
              placeholder={t(labels.aboutPlaceholder)}
              multiline
              rows={4}
              inputProps={{ maxLength: ABOUT_MAXIMUM_LENGTH }}
              error={!!formik.errors.about}
              helperText={formik.errors.about}
            />
            <Stack gap="8px">
              <SocialMedia
                {...withOnChangeStringParameter(getFormikProps('telegramLink'))}
                type={SocialMediaType.Telegram}
              />
              <SocialMedia
                {...withOnChangeStringParameter(getFormikProps('instagramLink'))}
                type={SocialMediaType.Instagram}
              />
              <SocialMedia
                {...withOnChangeStringParameter(getFormikProps('facebookLink'))}
                type={SocialMediaType.Facebook}
              />
            </Stack>
          </ContainerWithBorder>
          <ContainerWithBorder gap="16px" error={!!formik.errors._scheduleRequired}>
            <Stack gap="16px">
              <Typography variant="subtitle1" color="primary.main">
                {t('masterCabinetPage.scheduleTitle')}
              </Typography>
              <Typography variant="subtitle2" color="primary.main">
                {t('masterCabinetPage.scheduleSubtitle')}
              </Typography>
              <Select
                itemList={scheduleList}
                value={dayOfWeekList.find(i => i.id === scheduleDay) || null}
                label={t('masterCabinetPage.scheduleSelectLabel')}
                shrink={false}
                withIcons
                disabled={!scheduleList.length}
                onChange={item => setScheduleDay((item?.id as DayOfWeekEnum) || null)}
              />
              <Stack direction="row" gap="12px">
                <TimePicker
                  value={fromDateSchedule}
                  label={t('masterCabinetPage.fromLabel')}
                  maxTime={toDateSchedule}
                  format="HH:mm"
                  onChange={setFromDateSchedule}
                  disabled={!scheduleDay}
                  sx={{ flex: '1 1 50%' }}
                />
                <TimePicker
                  value={toDateSchedule}
                  label={t('masterCabinetPage.toLabel')}
                  minTime={fromDateSchedule}
                  format="HH:mm"
                  onChange={setToDateSchedule}
                  disabled={!scheduleDay}
                  sx={{ flex: '1 1 50%' }}
                />
              </Stack>
              <Button
                variant="contained"
                onClick={withSavingDraft(handleAddScheduleDay)}
                disabled={!toDateSchedule || fromDateSchedule === toDateSchedule}
                loading={manageMasterLoading}
              >
                {t('masterCabinetPage.scheduleAddButtonLabel')}
              </Button>
              {!!formik.errors._scheduleRequired && (
                <FormHelperText error>
                  <Typography variant="body3">{formik.errors._scheduleRequired}</Typography>
                </FormHelperText>
              )}
              {!!master.schedule &&
                !!Object.entries(master.schedule).find(([key, value]) => !key.startsWith('__') && !!value) && (
                  <ChipsSection
                    header={t('masterCabinetPage.scheduleChipListTitle')}
                    chipList={getScheduleChipList(master.schedule)}
                    onDeleteSection={withSavingDraft(onDeleteSchedule)}
                    onDeleteItem={withSavingDraft(({ id }) => onChangeScheduleDay(id as DayOfWeekEnum, undefined))}
                  />
                )}
            </Stack>
          </ContainerWithBorder>
          {upMedium && button}
        </StyledLeftContainer>
        <StyledRightContainer>
          {!!master.slug && !!MASTER_CABINET_INFO_TAB && <Slug value={master.slug} onChange={handleSlugUpdate} />}

          <ContainerWithBorder gap="16px" error={!!formik.errors._activitiesRequired}>
            <Stack gap="16px">
              <Typography variant="h6" color="primary.main" id="addActivity">
                {t('masterCabinetPage.activitiesTitle')}
              </Typography>
              <Typography variant="body1" color="primary.dark2">
                {t(labels.activitiesDescription)}
              </Typography>
              <Select
                itemList={categoryList}
                value={selectedCategory}
                placeholder={t('notSelected')}
                withIcons
                onChange={item => onCategoryChange(item as CategoryItem | null)}
              />
              {requiredAttributesByCategory && (
                <Stack direction="row" gap="16px" flexWrap="wrap">
                  {requiredAttributesByCategory.map(ra => {
                    return (
                      <StyledSelect
                        key={ra.id}
                        value={selectedAttributeGroups[ra.id] || null}
                        label={ra.localizedName}
                        itemList={ra.attributes}
                        placeholder={t('notSelected')}
                        onChange={item => onAttributeChange(item, ra.id)}
                      />
                    );
                  })}
                </Stack>
              )}
            </Stack>
            <Button
              variant="outlined"
              onClick={withSavingDraft(onAddExperienceClick)}
              loading={manageMasterLoading}
              disabled={!isAddAttributeGroupActive}
            >
              {t('masterCabinetPage.addExperienceButtonLabel')}
            </Button>
            <Stack gap="8px">
              {groupedCategoryList.map(({ id, attributeGroupList }) => (
                <ChipsSection
                  key={id}
                  header={<CategoryLabel key={id} category={id} variant="transparent" />}
                  chipList={attributeGroupList.map(i => ({ id: i.id, chipSegmentList: i.attributeList }))}
                  onDeleteSection={() => {
                    setExperienceToDelete(id);
                    setOpenDeleteModal(DeleteModalType.Experience);
                  }}
                  onDeleteItem={item => {
                    setExperienceToDelete(item);
                    setOpenDeleteModal(DeleteModalType.Experience);
                  }}
                />
              ))}
            </Stack>
            {!!formik.errors._activitiesRequired && (
              <FormHelperText error>
                <Typography variant="body3">{formik.errors._activitiesRequired}</Typography>
              </FormHelperText>
            )}
          </ContainerWithBorder>
          {!upMedium && button}
        </StyledRightContainer>
      </StyledMainContainer>
      <SaveChangesModal
        onSaveChanges={handleSaveChanges}
        onDiscardChanges={handleDiscardChanges}
        open={isAnyChanges()}
        loading={manageMasterLoading}
      />
      <DeleteModal
        title={t('masterCabinetPage.deleteExperienceTitle')}
        open={openDeleteModal === DeleteModalType.Experience}
        description={
          <Stack gap="12px" alignItems="start">
            <Typography variant="body2">{t('masterCabinetPage.deleteExperienceDescription')}</Typography>
            {typeof experienceToDelete !== 'string' && experienceToDelete !== null && (
              <InlineList
                list={experienceToDelete.chipSegmentList.map((item, index) => ({ item, key: index }))}
                alignSelf="start"
              />
            )}
          </Stack>
        }
        onClose={() => setOpenDeleteModal(null)}
        onSubmit={handleDeleteExperience}
      />
      <DeleteModal
        title={t('masterCabinetPage.confirmDeleteMasterTitle')}
        open={openDeleteModal === DeleteModalType.Master}
        onClose={() => setOpenDeleteModal(null)}
        onSubmit={handleDeleteMaster}
      />
    </>
  );
};

export default InfoTab;
