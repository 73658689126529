import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { Typography } from '@mui/material';
import { CategoryEnum } from 'constatns';
import Container from 'components/@shared/Container';
import IconButton from 'components/@shared/IconButton';

import CategoryLabel from '../CategoryLabel';
import useSearchSection from './useSearchSection';
import {
  StyledContainer,
  StyledSearchFieldContainer,
  StyledInput,
  StyledCategoriesContainer,
  StyledCategoryList,
} from './styled';
import CitySelect from './CitySelect';

type Props = {
  mainPage?: boolean;
  className?: string;
};

const SearchSection: React.FC<Props> = ({ mainPage, className }) => {
  const { t } = useTranslation();

  const {
    city,
    searchValue,
    citySearchQuery,
    buttonWidth,
    cityList,
    selectRef,
    handleSearchChange,
    handleCitySelectInputChange,
    handleCitySelectChange,
    cityListLoading,
    handleCategoryClick,
    handleSubmit,
  } = useSearchSection(mainPage);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <StyledContainer className={className}>
      <Container>
        <StyledSearchFieldContainer>
          <form onSubmit={handleSubmit}>
            <StyledInput
              name="search"
              value={searchValue}
              placeholder={t('searchSection.searchPlaceholder')}
              buttonWidth={!mainPage ? buttonWidth : 0}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
            {!mainPage && (
              <CitySelect
                options={cityList}
                defaultValue={city}
                value={citySearchQuery}
                selectRef={selectRef}
                loading={cityListLoading}
                onChange={handleCitySelectChange}
                onInputChange={handleCitySelectInputChange}
              />
            )}
            <input type="submit" hidden />
          </form>
        </StyledSearchFieldContainer>
        {mainPage && (
          <StyledCategoriesContainer>
            <Typography variant="body1" color="primary.light">
              {t('searchSection.categoryListTitle')}
            </Typography>
            <StyledCategoryList>
              {Object.values(CategoryEnum).map(item => (
                <IconButton key={item} onClick={() => handleCategoryClick(item)}>
                  <CategoryLabel category={item} variant="grey" />
                </IconButton>
              ))}
            </StyledCategoryList>
          </StyledCategoriesContainer>
        )}
      </Container>
    </StyledContainer>
  );
};
export default SearchSection;
