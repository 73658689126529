import React from 'react';
import { CircularProgress as MuiCircularProgress, CircularProgressProps } from '@mui/material';

const CircularProgress: React.FC<CircularProgressProps> = props => {
  return (
    <MuiCircularProgress
      sx={{
        color: theme => theme.palette.accent.main,
      }}
      size={32}
      thickness={4}
      {...props}
    />
  );
};

export default CircularProgress;
