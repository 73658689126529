import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import IconButton from 'components/@shared/IconButton';
import DeleteModal from 'components/@common/DeleteModal';
import TruncatedText from 'components/@shared/TruncatedText';
import Avatar from 'components/@common/Avatar';
import { OutputMasterDto, OutputServicePhotoDto } from 'types';

import { StyledContainer, StyledImageContainer, StyledImage, StyledAttribute, StyledAttributeSection } from './styled';

const IMAGES_MAX_COUNT = 5;
export type ServiceCardProps = {
  name: string;
  price: number;
  photos: OutputServicePhotoDto[];
  attributeList: Array<{ startAdornment?: React.ReactNode; value: string }>;
  masterDetails?: OutputMasterDto;
  qualification?: string;
  onDelete: () => Promise<void>;
  onClick: () => void;
};

const ServiceCard: React.FC<ServiceCardProps> = ({
  name,
  price,
  attributeList,
  masterDetails,
  qualification,
  photos,
  onDelete,
  onClick,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const { t } = useTranslation();

  const extraImagesCount = photos.length - IMAGES_MAX_COUNT;

  return (
    <StyledContainer onClick={onClick}>
      <Stack direction="row" gap="20px" justifyContent="space-between" alignItems="start" height="100%">
        <Stack gap="8px" justifyContent="space-between" height="100%">
          <Stack gap="8px">
            <Stack alignItems="center" flexDirection="row" justifyContent="start" gap="24px">
              <StyledImageContainer>
                {photos.slice(0, IMAGES_MAX_COUNT).map(item => (
                  <Stack key={item.id} width="24px">
                    <StyledImage src={item.uri} />
                  </Stack>
                ))}
              </StyledImageContainer>
              {extraImagesCount > 0 && (
                <Typography variant="subtitle2">
                  {t('masterCabinetPage.extraImagesLabel', { count: extraImagesCount })}
                </Typography>
              )}
            </Stack>
            <Typography variant="subtitle2" color="primary.main" className="service-name">
              {name}
            </Typography>
            <StyledAttributeSection>
              {attributeList?.map(item => (
                <StyledAttribute key={item.value}>
                  {item.startAdornment}
                  <TruncatedText variant="body2" color="primary.main">
                    {item.value}
                  </TruncatedText>
                </StyledAttribute>
              ))}
            </StyledAttributeSection>
            <Stack direction="row" gap="8px" alignItems="center" width="100%">
              <Avatar {...masterDetails} size="40px" />
              <Stack>
                <Typography variant="subtitle2">{masterDetails?.contactName}</Typography>
                <Typography variant="body2">{qualification}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography variant="subtitle1" color="accent.main">
            {t('price', { val: price })}
          </Typography>
        </Stack>
        <IconButton onClick={() => setOpenModal(true)}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <DeleteModal
        title={t('masterCabinetPage.confirmDeleteTitle')}
        description={<Typography variant="subtitle1">{name}</Typography>}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={onDelete}
      />
    </StyledContainer>
  );
};

export default ServiceCard;
