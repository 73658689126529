import React from 'react';
import { AutocompleteProps, TextFieldProps, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { StyledAutocomplete } from './styled';
import Input from '../Input';
import { AutocompleteSkeleton } from './AutocompleteSkeleton';

export type ListItem = {
  id: any;
  localizedName: string;
};

type Props<T extends ListItem> = Pick<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'onInputChange' | 'onChange' | 'value' | 'options' | 'loading' | 'defaultValue' | 'inputValue'
> & {
  loadingStateMessage?: string;
  emptyStateMessage?: string;
  InputProps?: TextFieldProps;
};

const Autocomplete = <T extends ListItem>({
  onInputChange,
  onChange,
  value,
  defaultValue,
  options,
  loading,
  loadingStateMessage,
  emptyStateMessage,
  inputValue,
  InputProps = {},
}: Props<T>) => {
  const theme = useTheme();

  const textStyles = {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '18px',
    padding: '10 20px',
    background: 'none !important',
  };

  return (
    <StyledAutocomplete
      fullWidth
      options={options}
      noOptionsText={emptyStateMessage}
      loading={loading}
      loadingText={loadingStateMessage}
      defaultValue={defaultValue}
      inputValue={inputValue}
      // @ts-ignore
      renderOption={(props, option: ListItem) => {
        return (
          <li {...props} key={option.id}>
            {option.localizedName}
          </li>
        );
      }}
      renderInput={params => <Input {...params} {...InputProps} value={value} />}
      popupIcon={<ExpandMoreIcon />}
      componentsProps={{
        popupIndicator: {
          disableRipple: true,
        },
        clearIndicator: {
          disableRipple: true,
        },
        popper: {
          sx: {
            zIndex: 1,
            margin: '8px 0 !important',
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.accent.main}`,
            borderRadius: '8px',
            overflow: 'hidden',

            '& .MuiPaper-root': {
              background: 'transparent',
            },
            '& .MuiAutocomplete-option': {
              ...textStyles,

              '&:hover': {
                cursor: 'pointer',
                background: 'none !important',
                color: theme.palette.accent.light,
              },

              '&:active': {
                color: theme.palette.accent.dark,
                background: 'none !important',
              },
            },
            '& .MuiAutocomplete-noOptions': {
              ...textStyles,
              color: theme.palette.primary.dark2,
            },
            '& .MuiAutocomplete-loading': {
              ...textStyles,
              color: theme.palette.primary.dark2,
            },
          },
        },
      }}
      getOptionLabel={option => (option as ListItem).localizedName}
      isOptionEqualToValue={(o, v) => (o as ListItem).id === (v as ListItem).id}
      // @ts-ignore
      onChange={onChange}
      onInputChange={onInputChange}
    />
  );
};

export default React.memo(Autocomplete);

export { AutocompleteSkeleton };
