import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import useResize from 'hooks/useResize';
import { PATHS } from 'navigation/constants';
import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AutocompleteChangeDetails } from '@mui/base/useAutocomplete/useAutocomplete';
import { CityItem } from 'types/filter';
import useFilterCityList from 'hooks/useFilterCityList';
import { useDebounce } from 'hooks/useDebounce/useDebounce';

const useSearchSection = (mainPage?: boolean) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const nameParam = searchParams.get('name');
  const townIdParam = searchParams.get('townId');
  const townNameParam = searchParams.get('townName');

  const { filterCityList, filterCityListLoading, searchCity } = useFilterCityList();

  const [searchValue, setSearchValue] = useState(nameParam || '');
  const [city, setCity] = useState<CityItem | undefined>(
    townIdParam && townNameParam
      ? {
          id: +townIdParam,
          localizedName: townNameParam,
        }
      : undefined,
  );
  const [citySearchQuery, setCitySearchQuery] = useState(townNameParam || '');

  const [buttonWidth, setButtonWidth] = useState(0);
  const selectRef = useRef<HTMLElement>(null);
  const { height, width } = useResize();

  useEffect(() => {
    if (selectRef.current) {
      setButtonWidth(selectRef.current.offsetWidth);
    }
  }, [height, width]);

  useEffect(() => {
    searchCity(citySearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citySearchQuery]);

  const handleCitySelectInputChange = useCallback((e: React.SyntheticEvent, value: string) => {
    setCitySearchQuery(value);
  }, []);

  const handleCitySelectChange = useCallback(
    (event: any, value: any, reason: any, details?: AutocompleteChangeDetails<CityItem>) => {
      setCity(details?.option);
    },
    [],
  );

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    if (!mainPage) {
      setSearchParams(params => {
        if (debouncedSearchValue) {
          params.set('name', debouncedSearchValue);
        } else {
          params.delete('name');
        }
        if (city) {
          params.set('townId', city.id.toString());
          params.set('townName', city.localizedName);
        } else {
          params.delete('townId');
          params.delete('townName');
        }
        return params;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, city]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    [],
  );

  const handleCategoryClick = (category: string) =>
    navigate({
      pathname: PATHS.SERVICES_PAGE,
      search: `?${createSearchParams({
        ...(city && { townId: city.id.toString(), townName: city.localizedName }),
        name: searchValue,
        categoryId: category,
      })}`,
    });

  const handleSubmit = (event?: React.FormEvent) => {
    event?.preventDefault();
    if (mainPage) {
      navigate({
        pathname: PATHS.SERVICES_PAGE,
        search: `?${createSearchParams({
          ...(city && { townId: city.id.toString(), townName: city.localizedName }),
          name: searchValue,
        })}`,
      });
    }
  };

  return {
    city,
    searchValue,
    citySearchQuery,
    buttonWidth,
    cityList: filterCityList,
    selectRef,
    handleSearchChange,
    handleCitySelectInputChange,
    handleCitySelectChange,
    cityListLoading: filterCityListLoading,
    handleCategoryClick,
    handleSubmit,
  };
};

export default useSearchSection;
