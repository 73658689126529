import { css, styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.palette.primary.main};
    gap: 16px;

    &:hover {
      color: ${theme.palette.accent.light};
    }

    &:active {
      color: ${theme.palette.accent.dark};
    }
  `,
);

export const StyledCheckbox = styled(Checkbox)(
  ({ theme }) => css`
    margin: 0;
    padding: 0;

    color: ${theme.palette.primary.main};

    &.Mui-checked {
      color: ${theme.palette.accent.main};
    }
  `,
);
