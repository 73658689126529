import { ApolloClient, InMemoryCache, gql, from } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { getCookie, setCookie } from 'utils/cookie';
import Apiip from 'apiip.net';
import { setContext } from '@apollo/client/link/context';
import { defaultLocale, mapCountryCodeToLanguage } from 'localizations';
import { Location } from 'types';

// @ts-ignore TODO: resolve types
const apiip = Apiip(process.env.REACT_APP_LOCATION_BY_IP_API_KEY);

const removeTypenameLink = removeTypenameFromVariables();

const locationMiddleware = setContext(async (operation, prevContext) => {

  const location = getCookie('location');

  if (!location) {
    try {
      const locationData = (await apiip.getLocation()) as Omit<Location, 'isBrowserLocation'>;
      setCookie(
        'location',
        JSON.stringify({
          city: locationData.city,
          cityGeoNameId: locationData.cityGeoNameId,
          countryCode: locationData.countryCode,
          countryName: locationData.countryName,
          countryNameNative: locationData.countryNameNative,
          latitude: locationData.latitude,
          longitude: locationData.longitude,
          regionCode: locationData.regionCode,
          regionName: locationData.regionName,
          isBrowserLocation: false,
        }).toString(),
      );
      const locale = localStorage.getItem('locale');
      if (!locale) {
        localStorage.setItem('locale', mapCountryCodeToLanguage(locationData.countryCode));
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setCookie(
        'location',
        JSON.stringify({
          ...JSON.parse(getCookie('location') || '{}'),
          latitude,
          longitude,
          isBrowserLocation: true,
        }).toString(),
      );
    });
  } else {
    console.log('Geolocation not supported');
  }

  const locale = localStorage.getItem('locale');

  return {
    ...prevContext,
    headers: { ...prevContext.headers, 'X-Lang': locale || defaultLocale },
  };
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}graphql`,
  credentials: 'include',
  headers: {
    'graphql-preflight': '',
  },
});

const link = from([locationMiddleware, removeTypenameLink, httpLink]);
const index = new ApolloClient({
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(gql`
      fragment TownFragment on OutputTownDto {
        id
        localizedName
      }

      fragment ScheduleDayFragment on OutputScheduleDayDto {
        from
        to
      }

      fragment ScheduleFragment on OutputScheduleDto {
        mon {
          ...ScheduleDayFragment
        }
        tue {
          ...ScheduleDayFragment
        }
        wed {
          ...ScheduleDayFragment
        }
        thu {
          ...ScheduleDayFragment
        }
        fri {
          ...ScheduleDayFragment
        }
        sat {
          ...ScheduleDayFragment
        }
        sun {
          ...ScheduleDayFragment
        }
      }

      fragment RequiredAttributeFragment on OutputRequiredAttributeDto {
        id
        localizedName
      }

      fragment RequiredAttributeGroupFragment on OutputRequiredAttributesDto {
        id
        localizedName
        isSubcategory
        isQualification
        attributes {
          ...RequiredAttributeFragment
        }
      }

      fragment AttributeFragment on OutputAttributeDto {
        id
        localizedName
      }

      fragment AttributeGroupFragment on OutputAttributeGroupDto {
        id
        localizedName
        isSubcategory
        isQualification
        attribute {
          ...AttributeFragment
        }
      }

      fragment CategoryFragment on OutputCategoryDto {
        id
        localizedName
        attributeGroups {
          ...AttributeGroupFragment
        }
      }

      fragment MasterFragment on OutputMasterDto {
        id
        slug
        activated
        about
        contactName
        instagramLink
        telegramLink
        facebookLink
        phone
        email
        profileImageUri
        town {
          ...TownFragment
        }
        address {
          ...OutputAddressDtoFragment
        }
        schedule {
          ...ScheduleFragment
        }
        categories {
          ...CategoryFragment
        }
      }

      fragment OutputServicePhotoDtoFragment on OutputServicePhotoDto {
        id
        uri
      }

      fragment MasterServiceFragment on OutputMasterServiceDto {
        id
        name
        duration
        price
        description
        category {
          ...CategoryFragment
        }
        master {
          ...MasterFragment
        }
        photos {
          ...OutputServicePhotoDtoFragment
        }
      }

      fragment OutputServiceDtoFragment on OutputServiceDto {
        id
        name
        duration
        price
        description
        distanceMeters
        category {
          ...CategoryFragment
        }
        master {
          ...MasterFragment
        }
        company {
          ...CompanyFragment
        }
      }

      fragment AccountFragment on AccountOutputDto {
        type
      }

      fragment CompanyFragment on OutputCompanyDto {
        id
        slug
        address {
          ...OutputAddressDtoFragment
        }
        activated
        about
        name
        contactName
        instagramLink
        telegramLink
        facebookLink
        phone
        email
        profileImageUri
        town {
          ...TownFragment
        }
        schedule {
          ...ScheduleFragment
        }
      }

      fragment MasterListItemFragment on OutputMasterDto {
        id
        activated
        contactName
        profileImageUri
        categories {
          ...CategoryFragment
        }
      }

      fragment PageInfoFragment on PageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      fragment ServicesEdgeFragment on ServicesEdge {
        cursor
        node {
          ...OutputServiceDtoFragment
        }
      }

      fragment ServicesConnectionFragment on ServicesConnection {
        pageInfo {
          ...PageInfoFragment
        }
        nodes {
          ...OutputServiceDtoFragment
        }
      }

      fragment OutputServiceGroupDtoFragment on OutputServiceGroupDto {
        company {
          ...CompanyFragment
        }
        master {
          ...MasterFragment
        }
        services {
          ...MasterServiceFragment
        }
        distanceMeters
        servicesCount
      }

      fragment GroupedServicesFragment on GroupedServicesEdge {
        cursor
        node {
          ...OutputServiceGroupDtoFragment
        }
      }

      fragment GroupedServicesConnectionFragment on GroupedServicesConnection {
        pageInfo {
          ...PageInfoFragment
        }
        nodes {
          ...OutputServiceGroupDtoFragment
        }
      }

      fragment OutputBookingDayDtoFragment on OutputBookingDayDto {
        date
        availableTimeSlots {
          ...ScheduleDayFragment
        }
      }

      fragment OutputClientDtoFragment on OutputClientDto {
        id
        contactName
        phone
        email
        activated
        profileImageUri
      }

      fragment OutputReservationDtoFragment on OutputReservationDto {
        id
        date
        timeSlot {
          ...ScheduleDayFragment
        }
        service {
          ...OutputServiceDtoFragment
        }
      }

      fragment OutputMasterReservationDtoFragment on OutputMasterReservationDto {
        id
        date
        status
        timeSlot {
          ...ScheduleDayFragment
        }
        service {
          id
          name
          price
        }
        client {
          id
          contactName
          phone
          profileImageUri
          email
        }
      }

      fragment OutputCompanyReservationDtoFragment on OutputCompanyReservationDto {
        id
        date
        status
        timeSlot {
          ...ScheduleDayFragment
        }
        service {
          id
          name
          price
        }
        client {
          id
          contactName
          phone
          profileImageUri
          email
        }
        master {
          id
          contactName
          profileImageUri
          email
        }
      }

      fragment SearchMasterReservationsConnectionFragment on SearchMasterReservationsConnection {
        pageInfo {
          ...PageInfoFragment
        }
        nodes {
          ...OutputMasterReservationDtoFragment
        }
      }

      fragment SearchCompanyReservationsConnectionFragment on SearchCompanyReservationsConnection {
        pageInfo {
          ...PageInfoFragment
        }
        nodes {
          ...OutputCompanyReservationDtoFragment
        }
      }

      fragment OutputAddressDtoFragment on OutputAddressDto {
        addressLine
        coordinates {
          latitude
          longitude
        }
      }
    `),
  }),
});

export default index;
