import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router';
import Button from 'components/@shared/Button';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { PATHS } from 'navigation/constants';
import getRouteWithParams from 'utils/getRouteWithParams';

import { StyledContainer } from './styled';

const EmptyState: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const MASTER_CABINET_SERVICES_TAB = useMatch(PATHS.MASTER_CABINET_SERVICES_TAB);
  const COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB = useMatch(PATHS.COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB);
  const COMPANY_CABINET_MASTER_SERVICES_TAB = useMatch(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB);

  const handleClick = () => {
    if (MASTER_CABINET_SERVICES_TAB) {
      return navigate(`${PATHS.MASTER_CABINET_INFO_TAB}#addActivity`);
    }

    if (COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB) {
      return navigate(`${PATHS.COMPANY_CABINET_CREATE_MASTER_INFO_TAB}#addActivity`);
    }

    if (COMPANY_CABINET_MASTER_SERVICES_TAB && id) {
      return navigate(getRouteWithParams(`${PATHS.COMPANY_CABINET_MASTER_INFO_TAB}#addActivity`, { id }));
    }
  };

  return (
    <StyledContainer>
      <Typography variant="body1" color="primary.main" textAlign={upMedium ? 'center' : 'start'}>
        {t(
          MASTER_CABINET_SERVICES_TAB
            ? 'masterCabinetPage.servicesTabEmptyStateMessage'
            : 'masterCabinetPage.companyMasterServicesTabEmptyStateMessage',
        )}
      </Typography>
      <Button variant="contained" sx={{ width: upMedium ? '334px' : '100%' }} onClick={handleClick}>
        {t('masterCabinetPage.servicesTabEmptyStateButton')}
      </Button>
    </StyledContainer>
  );
};

export default EmptyState;
