import { OutputCompanyDto, OutputMasterDto } from '../types';
import { getScheduleChipList } from './getScheduleList';
import { ContactsSectionProps } from '../components/@common/ContactsSection';

const mapServiceModelToContactsSectionViewModel = ({
  company,
  master,
}: {
  master?: OutputMasterDto;
  company?: OutputCompanyDto;
}): Omit<ContactsSectionProps, 'showPhoneLogic' | 'withTitle' | 'className'> => {
  const schedule = company?.schedule || master?.schedule;
  const address = company?.address?.addressLine || master?.address?.addressLine;

  return {
    phone: company ? company.phone : master?.phone,
    email: company ? company.email : master?.email,
    schedule: schedule ? getScheduleChipList(schedule) : undefined,
    address: address ? address : undefined,
    instagram: company ? company.instagramLink : master?.instagramLink,
    facebook: company ? company.facebookLink : master?.facebookLink,
    telegram: company ? company.telegramLink : master?.telegramLink,
  };
};

export default mapServiceModelToContactsSectionViewModel;
