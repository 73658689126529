import { StateType } from './types';

export enum ACTION {
  SET_ACCOUNT = 'SET_ACCOUNT',
  RESET_ACCOUNT = 'RESET_ACCOUNT',

  SET_BANNER = 'SET_BANNER',
  RESET_BANNER = 'RESET_BANNER',

  SET_FILTER_DEFAULT_CITY_LIST = 'SET_FILTER_DEFAULT_CITY_LIST',
  SET_FILTER_CITY_LIST = 'SET_FILTER_CITY_LIST',

  SET_AUTH_CREDENTIALS = 'SET_AUTH_CREDENTIALS',
  RESET_AUTH_CREDENTIALS = 'RESET_AUTH_CREDENTIALS',

  SET_NEW_RESERVATIONS_COUNT = 'SET_NEW_RESERVATIONS_COUNT',
}

export const initialState: StateType = {
  account: null,
  banner: null,
  filterCityList: [],
  defaultFilterCityList: [],
  credentials: {
    email: '',
    password: '',
  },
  newReservationsCount: null,
};
