import React from 'react';

import useMutation from 'hooks/useMutation';
import { useClientState } from 'contexts/client';
import { setClient } from 'contexts/client/actions';
import { CREATE_CLIENT, SET_CLIENT_PHOTO, UPDATE_CLIENT } from 'api/requests/client';
import { OutputClientDto, UpdateClientInputDtoInput } from 'types/client';

import { ClientFormValues } from './index';

const useInfoTab = () => {
  const {
    state: { client },
    dispatch,
  } = useClientState();

  const [setClientPhoto] = useMutation<{ setClientPhoto: OutputClientDto }, { clientId?: number; photo: File }>(
    SET_CLIENT_PHOTO,
  );

  const [createClient, { loading: createClientLoading }] = useMutation<
    { createClient: OutputClientDto },
    {
      input: UpdateClientInputDtoInput;
    }
  >(CREATE_CLIENT);
  const [updateClient, { loading: updateClientLoading }] = useMutation<{
    updateClient: OutputClientDto;
  }>(UPDATE_CLIENT);

  const onSaveChanges = async (clientFormValues: ClientFormValues) => {
    try {
      const id = client?.id;
      const variables = {
        input: {
          contactName: clientFormValues.contactName,
          phone: clientFormValues.phoneNumber || '',
        },
      };
      if (id !== undefined) {
        const response = await updateClient({ variables });

        if (response.data?.updateClient) {
          dispatch(setClient(response.data?.updateClient));
        }
      } else {
        const response = await createClient({ variables });

        if (response.data?.createClient) {
          dispatch(setClient(response.data?.createClient));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleImgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file === undefined) return;
    setClientPhoto({ variables: { photo: file } })
      .then(res => {
        if (res.data) {
          dispatch(setClient(res.data.setClientPhoto));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return {
    client,
    manageClientLoading: createClientLoading || updateClientLoading,
    onSaveChanges,
    handleImgInputChange,
  };
};

export default useInfoTab;
