import React from 'react';
import { Stack, Typography } from '@mui/material';
import 'dayjs/locale/uk';
import { Dayjs } from 'dayjs';
import Avatar from 'components/@common/Avatar';
import { sortReservationsCallback } from 'utils/sortReservationsCallback';
import i18n from 'localizations';
import { ReservationStatus } from 'types/reservation';
import convertTimeSpanToDayjs from 'utils/convertTimeSpanToDayjs';

import { StyledReservationItem } from './styled';
import { Reservation } from '../types';
import { StyledCircularProgress } from '../styled';

const getListViewData = (reservationList: Reservation[]) => {
  return Object.values(
    reservationList.sort(sortReservationsCallback).reduce<
      Record<
        string,
        {
          date: Dayjs;
          list: Reservation[];
        }
      >
    >((acc, item) => {
      const stringData = item.date.format();
      if (acc[stringData]) {
        return {
          ...acc,
          [stringData]: {
            ...acc[stringData],
            list: [...acc[stringData].list, item],
          },
        };
      }
      return {
        ...acc,
        [stringData]: {
          date: item.date,
          list: [item],
        },
      };
    }, {}),
  );
};

export type Props = {
  reservationList: Reservation[];
  loading?: boolean;
  onReservationClick: (reservationId: number) => void;
};

const ListView: React.FC<Props> = ({ reservationList, loading, onReservationClick }) => {
  return (
    <Stack padding="0 16px 20px" alignItems="stretch" gap="20px" mt="20px">
      {!loading ? (
        getListViewData(reservationList).map(({ date, list }) => {
          const dayString = date.locale(i18n.language).format(' dd').toLocaleUpperCase();
          const dayNumber = date.locale(i18n.language).format(' DD');

          return (
            <Stack key={dayString + dayNumber} direction="row" gap="8px">
              <Stack width="32px">
                <Typography variant="tag1">{dayString}</Typography>
                <Typography variant="tag1">{dayNumber}</Typography>
              </Stack>
              <Stack gap="4px" flex="1 1 100%">
                {list.map(listItem => (
                  <StyledReservationItem
                    key={listItem.id}
                    approved={listItem.status === ReservationStatus.APPROVED}
                    onClick={() => onReservationClick(listItem.id)}
                  >
                    <Avatar {...listItem} />
                    <Stack gap="4px">
                      <Typography variant="subtitle1">{listItem.contactName}</Typography>
                      <Typography variant="tag1">{`${convertTimeSpanToDayjs(listItem.timeSlot.from).format(
                        'HH:mm',
                      )} - ${convertTimeSpanToDayjs(listItem.timeSlot.to).format('HH:mm')}`}</Typography>
                    </Stack>
                  </StyledReservationItem>
                ))}
              </Stack>
            </Stack>
          );
        })
      ) : (
        <Stack height="200px" alignItems="center" justifyContent="center">
          {/* TODO add skeletons */}
          <StyledCircularProgress size={48} />
        </Stack>
      )}
    </Stack>
  );
};

export default ListView;
