import { css, styled } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';
import IconButton from 'components/@shared/IconButton';
import { alpha, Box, Card, CardActions } from '@mui/material';

export const StyledCard = styled(
  Card,
  preventForwardProps(['disabled', 'withBorder']),
)<{ disabled?: boolean; withBorder?: boolean }>(
  ({ theme, disabled, withBorder }) => css`
    padding: 20px;
    flex: 1 1 305px;
    min-width: 297px;
    gap: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: ${theme.palette.primary.light};
    border: 1px solid ${withBorder ? theme.palette.primary.light3 : theme.palette.primary.light};
    border-radius: 16px;

    box-shadow: none;

    ${!disabled &&
    css`
      cursor: pointer;

      &:hover .service-name {
        color: ${theme.palette.accent.light};
      }

      &:active .service-name {
        color: ${theme.palette.accent.dark};
      }
    `}
  `,
);

export const StyledAttributeSection = styled(Box)`
  display: flex;
  gap: 6px;
  width: 100%;
  flex-wrap: wrap;
`;

export const StyledAttribute = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border: 1px solid ${theme.palette.primary.light3};
    border-radius: 8px;

    svg {
      color: ${theme.palette.primary.dark2};
      height: 16px;
      width: 16px;
    }
  `,
);

export const StyledCardActions = styled(CardActions)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  padding: 0;

  & :not(:first-of-type) {
    margin: 0;
  }
`;

export const StyledCategoryContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    overflow: hidden;

    :after {
      content: '';
      position: absolute;
      height: 100%;
      width: 32px;
      background: linear-gradient(
        90deg,
        ${alpha(theme.palette.background.default, 0)} 0%,
        ${theme.palette.background.default} 100%
      );
      right: 0;
    }
  `,
);

export const StyledIconButton = styled(
  IconButton,
  preventForwardProps(['favorite']),
)<{ favorite?: boolean }>(
  ({ theme, favorite }) => css`
    height: 48px;
    width: 48px;
    background: ${theme.palette.primary.light2};
    color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};

    @media (hover: hover) {
      &:hover {
        background: ${theme.palette.primary.light3};
        color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
      }
    }

    @media (hover: none) {
      &:hover {
        background: ${theme.palette.primary.light3};
        color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
      }
    }

    :hover {
      background: ${theme.palette.primary.light3};
      color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
    }

    &:active {
      background: ${theme.palette.primary.light3};
      color: ${favorite ? theme.palette.accent.light : theme.palette.primary.main};
    }
  `,
);
