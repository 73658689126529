import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Modal from 'components/@shared/Modal';
import Button from 'components/@shared/Button';

type Props = {
  open: boolean;
  onClose: () => void;
};

const SuccessModal: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Modal open={open} onClose={onClose} maxWidth="407px">
      <Stack gap={upMd ? '24px' : '20px'}>
        <Typography
          variant={upMd ? 'h3' : 'h6'}
          color="primary.main"
          textAlign="center"
          alignSelf="center"
          width="calc(100% - 58px)"
        >
          {t('bookServicePage.successModalTitle')}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          {t('bookServicePage.successModalButtonLabel')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default SuccessModal;
