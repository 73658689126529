import { OutputCompanyReservationDto } from 'types/reservation';
import { Reservation } from 'components/@common/ReservationCalendar/types';
import dayjs from 'dayjs';
import convertTimeSpanToMinutes from './convertTimeSpanToMinutes';

export const getCompanyReservationList = (reservations: OutputCompanyReservationDto[]): Reservation[] =>
  reservations.map(item => ({
    id: item.id,
    date: dayjs(item.date),
    status: item.status,
    timeSlot: item.timeSlot,
    timeSlotNumeric: {
      from: convertTimeSpanToMinutes(item.timeSlot.from),
      to: convertTimeSpanToMinutes(item.timeSlot.to),
    },
    contactName: item.master.contactName,
    profileImageUri: item.master.profileImageUri,
    email: item.master.email,
  }));
