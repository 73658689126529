import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { Coordinates, OutputServiceDto } from 'types';
import { POPULAR } from 'api/requests/service';
import { useGeoLocation } from 'hooks/useGeoLocation';

const usePopular = () => {
  const { i18n } = useTranslation();

  const [getPopular, { data, loading }] = useLazyQuery<
    {
      popular: OutputServiceDto[];
    },
    {
      coordinates?: Coordinates;
    }
  >(POPULAR);

  const coordinates = useGeoLocation();

  useEffect(() => {
    getPopular({
      variables: {
        ...(coordinates && { coordinates }),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, i18n.language]);

  const theme = useTheme();
  const threeColumns = useMediaQuery(theme.breakpoints.between(1130, 1450));

  const count = useMemo(() => {
    if (threeColumns) {
      return 3;
    }

    return 4;
  }, [threeColumns]);

  return {
    popularList: data?.popular.slice(0, count),
    loading,
    count,
  };
};

export default usePopular;
