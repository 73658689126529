import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAccount } from 'hooks/useAccount';

import { StyledContainer, StyledAppBar, StyledMainContainer } from './styled';
import Footer from '../Footer';
import ScrollToAnchor from '../ScrollToAnchor';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
};

  const locale = localStorage.getItem('locale');

const CommonContainer: React.FC<Props> = ({ className }) => {
  const { loading } = useAccount();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, []);

  // TODO: implement loading state
  return loading ? null : (
    <StyledContainer>
      <StyledAppBar />
      <ScrollToAnchor />
      <StyledMainContainer className={className}>
        <Outlet />
      </StyledMainContainer>
      <Footer />
    </StyledContainer>
  );
};

export default CommonContainer;
