import React from 'react';

import { StyledContainer } from './styled';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Container: React.FC<Props> = ({ children, className }) => (
  <StyledContainer className={className} maxWidth="xl">
    {children}
  </StyledContainer>
);

export default Container;
