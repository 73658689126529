import { css, styled } from '@mui/material/styles';
import { Stack, Button, alpha } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledButtonsContainer = styled(
  Stack,
  preventForwardProps(['blurLeft', 'blurRight']),
)<{ blurLeft: boolean; blurRight: boolean }>(
  ({ theme, blurLeft, blurRight }) => css`
    width: 100%;
    position: relative;

    :before,
    :after {
      content: '';
      position: absolute;
      height: 42px;
      top: calc(50% - 21px);
      z-index: 1;
      transition: opacity 0.1s, width 0.3s;
    }

    :before {
      left: -1px;
      background: linear-gradient(
        90deg,
        ${theme.palette.background.default} 0%,
        ${alpha(theme.palette.background.default, 0)} 100%
      );
      opacity: ${blurLeft ? 1 : 0};
      width: ${blurLeft ? '20px' : 0};
    }

    :after {
      right: -1px;
      background: linear-gradient(
        90deg,
        ${alpha(theme.palette.background.default, 0)} 0%,
        ${theme.palette.background.default} 100%
      );
      opacity: ${blurRight ? 1 : 0};
      width: ${blurRight ? '20px' : 0};
    }
  `,
);

export const StyledButtonList = styled(Stack)`
  gap: 8px;
  flex-direction: row;
  flex: 0 0 auto;
  overflow-x: scroll;
  -ms-overflow-style: none; // IE
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none;
    height: 0; // Chrome, Safari
  }
`;

export const StyledButton = styled(
  Button,
  preventForwardProps(['selected']),
)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    white-space: nowrap;
    min-width: auto;
    padding: 12px 16px;
    background: ${selected ? theme.palette.accent.main : theme.palette.primary.light2};
    border-radius: 12px;
    box-shadow: unset;

    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;
    color: ${selected ? theme.palette.primary.light : theme.palette.primary.main};

    &:hover {
      color: ${selected ? theme.palette.primary.light : theme.palette.accent.light};
      background: ${selected ? theme.palette.accent.main : theme.palette.primary.light2};
    }

    &:active {
      color: ${selected ? theme.palette.primary.light : theme.palette.accent.dark};
      background: ${selected ? theme.palette.accent.main : theme.palette.primary.light2};
    }
  `,
);
