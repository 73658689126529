import { gql } from '@apollo/client';

export const GOOGLE_LOGIN = gql`
  mutation GoogleLogin($accessToken: String!) {
    googleLogin(accessToken: $accessToken) {
      ...AccountFragment
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($accountType: AccountType!) {
    createAccount(accountType: $accountType) {
      ...AccountFragment
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const GET_ACCOUNT = gql`
  query Account {
    account {
      ...AccountFragment
    }
  }
`;
