import { css, styled } from '@mui/material/styles';
import { Accordion, Box } from '@mui/material';

export const StyledItemList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    box-shadow: none;
    background: ${theme.palette.primary.light2};
    border-radius: 16px;
    padding: 20px;

    &:before {
      content: none;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 16px;
    }

    & .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 0 0;
    }

    & .MuiButtonBase-root {
      min-height: 0;
    }

    & .MuiAccordionSummary-root {
      padding: 0;
    }

    & .MuiAccordionSummary-content {
      margin: 0;
    }

    & .MuiSvgIcon-root {
      color: ${theme.palette.primary.main};
    }

    ${theme.breakpoints.up('md')} {
      padding: 40px;

      & .MuiAccordionDetails-root {
        gap: 12px;
        padding: 20px 0 0;
      }
    }

    & ul {
      list-style: none;
      padding-left: 20px;
    }

    ul li::before {
      content: '\\2022';
      color: ${theme.palette.accent.main};
      font-weight: bold;
      display: inline-block;
      width: 20px;
      margin-left: -20px;
      font-size: 20px;
      vertical-align: middle;
    }
  `,
);
