import React, { useState } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import WestIcon from '@mui/icons-material/West';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import TruncatedText from 'components/@shared/TruncatedText';
import { PATHS } from 'navigation/constants';
import getRouteWithParams from 'utils/getRouteWithParams';
import convertTimeSpanToDayjs from 'utils/convertTimeSpanToDayjs';
import dayjs from 'dayjs';
import i18n from 'localizations';
import { useTranslation } from 'react-i18next';
import Button from 'components/@shared/Button';
import Avatar from 'components/@common/Avatar';

import { handlePhoneClick } from 'utils/handlePhoneClick';
import { ReservationStatus } from 'types/reservation';
import { useBreadcrumbs } from './useBreadcrumbs';
import useMasterReservationDetailsPage from './useMasterReservationDetailsPage';
import { StyledClientContainer, StyledLink } from './styled';
import PhoneContact from '../@common/ContactsSection/PhoneContact';
import DeleteModal from '../@common/DeleteModal';

const MasterReservationDetailsPage: React.FC = () => {
  const { t } = useTranslation();

  const [cancelReservationModalOpen, setCancelReservationModalOpen] = useState(false);

  const { reservation, handleBack, approveReservation, cancelReservation } = useMasterReservationDetailsPage();
  const breadcrumbs = useBreadcrumbs();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));
  const upSmall = useMediaQuery(theme.breakpoints.up('sm'));

  if (!reservation) {
    return null;
  }

  const serviceUrl = getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: reservation.service.id.toString() });
  const priceText = reservation.service.price ? t('price', { val: reservation.service.price }) : t('priceFree');
  const { phone } = reservation.client;
  const isApproved = reservation.status === ReservationStatus.APPROVED;
  const isPending = reservation.status === ReservationStatus.WAITING_FOR_APPROVE;

  return (
    <Container>
      <Breadcrumbs {...breadcrumbs} />
      <Stack gap={upMedium ? '24px' : '16px'} alignItems="start" width="100%">
        <Button variant="text" color="info" startIcon={<WestIcon />} onClick={handleBack}>
          {t('reservationDetailsPage.backButtonLabel')}
        </Button>
        <Stack gap={upMedium ? '32px' : '16px'} width="100%">
          <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
            {t('reservationDetailsPage.title')}
          </Typography>
          <Stack gap="20px" width={upSmall ? '335px' : '100%'}>
            <Stack gap="6px">
              <Stack direction="row" gap="8px" alignItems="center">
                <Typography variant="body2" color="primary.dark2">
                  {t('name')}:
                </Typography>
                <StyledLink to={serviceUrl}>
                  <TruncatedText variant="body2">{reservation?.service.name}</TruncatedText>
                </StyledLink>
              </Stack>
              <Stack direction="row" gap="8px" alignItems="center">
                <Typography variant="body2" color="primary.dark2">
                  {t('date')}:
                </Typography>
                <TruncatedText variant="body2" color="primary.main">
                  {dayjs(reservation.date).locale(i18n.language).format('DD MMMM (dddd)')}
                </TruncatedText>
              </Stack>
              <Stack direction="row" gap="8px" alignItems="center">
                <Typography variant="body2" color="primary.dark2">
                  {t('time')}:
                </Typography>
                <TruncatedText variant="body2" color="primary.main">
                  {`${convertTimeSpanToDayjs(reservation.timeSlot.from).format('HH:mm')} - ${convertTimeSpanToDayjs(
                    reservation.timeSlot.to,
                  ).format('HH:mm')}`}
                </TruncatedText>
              </Stack>
              <Stack direction="row" gap="8px" alignItems="center">
                <Typography variant="body2" color="primary.dark2">
                  {t('priceLabel')}:
                </Typography>
                <TruncatedText variant="body2" color="primary.main">
                  {priceText}
                </TruncatedText>
              </Stack>
            </Stack>

            <StyledClientContainer>
              <Typography variant="h6" color="primary.main">
                {t('reservationDetailsPage.client')}
              </Typography>
              <Stack gap="12px">
                <Stack direction="row" alignItems="center" gap="8px">
                  <Avatar {...reservation.client} />
                  <Typography variant="h6" color="primary.main">
                    {reservation.client.contactName}
                  </Typography>
                </Stack>
                {!!phone && <PhoneContact value={phone} showLogic={false} onClick={() => handlePhoneClick(phone)} />}
              </Stack>
            </StyledClientContainer>
            {isPending && (
              <Button variant="outlined" color="primary" onClick={approveReservation}>
                {t('reservationDetailsPage.submitButton')}
              </Button>
            )}
            {(isApproved || isPending) && (
              <Button variant="outlined" color="error" onClick={() => setCancelReservationModalOpen(true)}>
                {t('reservationDetailsPage.cancelButton')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>

      <DeleteModal
        open={cancelReservationModalOpen}
        title={t('confirmCancelReservationTitle')}
        confirmLabel={t('confirmCancelReservationButtonLabel')}
        description={<Typography variant="subtitle1">{reservation?.service.name}</Typography>}
        onSubmit={async () => {
          if (cancelReservationModalOpen) {
            await cancelReservation();
          }
          setCancelReservationModalOpen(false);
        }}
        onClose={() => setCancelReservationModalOpen(false)}
      />
    </Container>
  );
};

export default MasterReservationDetailsPage;
