import { Coordinates } from 'types';
import { useEffect, useState } from 'react';

export const useGeoLocation = (): Coordinates | void => {
  const [coordinates, setCoordinates] = useState<Coordinates | void>();

  useEffect(() => {
    if (navigator.geolocation) {
      // eslint-disable-next-line no-undef
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const { latitude } = position.coords;
        const { longitude } = position.coords;
        setCoordinates({ latitude, longitude });
      });
    } else {
      console.log('Geolocation not supported');
    }
  }, []);

  return coordinates;
};
