import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${theme.breakpoints.up('md')} {
      gap: 20px;
    }
  `,
);

export const StyledServiceGroupContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: ${theme.palette.primary.light2};
    border-radius: 20px;
    padding: 12px;

    ${theme.breakpoints.up('md')} {
      padding: 20px;
    }
  `,
);

export const StyledServiceGroupList = styled(Box)(
  ({ theme }) => css`
    display: grid;
    gap: 12px;
    background: ${theme.palette.primary.light2};
    border-radius: 20px;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));

    ${theme.breakpoints.up('md')} {
      grid-template-columns: repeat(auto-fill, minmax(297px, 1fr));
    }
  `,
);

export const StyledAvatar = styled(
  'div',
  preventForwardProps(['imgUrl']),
)<{ imgUrl?: string }>(
  ({ imgUrl }) => css`
    background: url('${imgUrl}') no-repeat center center;
    background-size: cover;
    border-radius: 100%;
    height: 40px;
    width: 40px;
  `,
);
