import React, { useState } from 'react';
import SearchSection from 'components/@common/SearchSection';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import { BookServiceModal, useBookServiceModal } from 'components/@common/BookButton';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';

import { StyledButton, StyledMainContainer } from './styled';
import FiltersSection from './filters/FiltersSection';
import CardListSection from './CardListSection';
import FiltersModal from './filters/FiltersModal';
import useServicesPage from './useServicesPage';
import { useBreadcrumbs } from './useBreadcrumbs';

const ServicesPage: React.FC = () => {
  const { t } = useTranslation();

  const breadcrumbs = useBreadcrumbs();
  const {
    hasNextPage,
    serviceGroupsPerFetchCount,
    servicesPerFetchCount,
    serviceGroups,
    serviceGroupsLoading,
    showMoreServiceGroups,
    showMoreServices,
    handlePartnerClick,
    handleServiceClick,
  } = useServicesPage();
  const { onBook, ...bookServiceModalProps } = useBookServiceModal();

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleFiltersButtonClick = () => setFiltersModalOpen(true);

  const handleSortButtonClick = () => {};

  return (
    <>
      <Container>
        <Breadcrumbs {...breadcrumbs} />
        <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
          {t('servicesPage.title')}
        </Typography>
        <Gap size={upMedium ? 56 : 24} />
      </Container>
      <SearchSection />
      <Container>
        {upMedium ? (
          <Gap size={56} />
        ) : (
          <Stack direction="row" gap="20px" justifyContent="space-between" my="20px">
            <StyledButton onClick={handleFiltersButtonClick} disableRipple>
              <FilterIcon />
              <Typography variant="subtitle1">{t('filters')}</Typography>
            </StyledButton>
            <StyledButton onClick={handleSortButtonClick} disableRipple>
              <SortIcon />
              <Typography variant="subtitle1">{t('servicesPage.sortButtonLabel')}</Typography>
            </StyledButton>
          </Stack>
        )}
        <StyledMainContainer>
          {upMedium && <FiltersSection />}
          <CardListSection
            hasNextPage={hasNextPage}
            serviceGroupsPerFetchCount={serviceGroupsPerFetchCount}
            servicesPerFetchCount={servicesPerFetchCount}
            serviceGroups={serviceGroups}
            serviceGroupsLoading={serviceGroupsLoading}
            showMoreServiceGroups={showMoreServiceGroups}
            showMoreServices={showMoreServices}
            onPartnerClick={handlePartnerClick}
            onServiceClick={handleServiceClick}
            onBookService={onBook}
          />
        </StyledMainContainer>
      </Container>
      <FiltersModal open={filtersModalOpen} onClose={() => setFiltersModalOpen(false)} />
      <BookServiceModal {...bookServiceModalProps} />
    </>
  );
};

export default ServicesPage;
