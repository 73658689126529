import React from 'react';
import scheduleCalendarIcon from 'assets/icons/scheduleCalendar.svg';
import scheduleListIcon from 'assets/icons/scheduleList.svg';
import ExpandableSelect from 'components/@shared/ExpandableSelect';
import { ReservationCalendarVariant } from '../types';
import { useTranslation } from 'react-i18next';

export type Props = {
  variant: ReservationCalendarVariant;
  onChange: (variant: ReservationCalendarVariant) => void;
};

const ReservationVariantSelect: React.FC<Props> = ({ onChange, variant }) => {
  const { t } = useTranslation();

  const itemList = [
    {
      id: ReservationCalendarVariant.Calendar,
      localizedName: t('week'),
      icon: scheduleCalendarIcon,
    },
    {
      id: ReservationCalendarVariant.List,
      localizedName: t('schedule'),
      icon: scheduleListIcon,
    },
  ];
  const value = itemList.find(item => item.id === variant) || itemList[0];

  return (
    <ExpandableSelect
      value={value}
      itemList={itemList}
      withNoSelect={false}
      isFilled
      // TODO: resolve the typescript issue
      onChange={item => item && onChange((item as typeof value).id)}
    />
  );
};

export default ReservationVariantSelect;
