import { css, styled } from '@mui/material/styles';
import { Box, ListItemButton } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('lg')} {
      flex-direction: row;
      align-items: start;
      flex: 0 1 562px;
      justify-content: space-between;
    }
  `,
);

export const StyledLeftSection = styled(Box)`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledRightSection = styled(Box)`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledListItemButton = styled(ListItemButton)(
  ({ theme }) => css`
    background: none;
    color: ${theme.palette.primary.light};

    &:hover {
      background: none;
      color: ${theme.palette.accent.light};
    }

    &:active {
      background: none;
      color: ${theme.palette.accent.dark};
    }
  `,
);
