import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import Modal from 'components/@shared/Modal';
import Button from 'components/@shared/Button';
import { AccountTypeEnum } from 'constatns/auth';

import { CheckIconWrapper, StyledButton, StyledButtonsContainer } from './styled';

type Props = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (onSubmit: AccountTypeEnum) => void;
};

const SelectAccountTypeStep: React.FC<Props> = ({ open, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const [accountType, setAccountType] = useState<AccountTypeEnum | null>(null);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} hideCloseIcon gap="20px" width="400px" maxWidth="calc(100% - 40px)">
      <Stack gap="16px">
        <Typography variant="h5" color="primary.main">
          {t('authenticateModal.selectAccountTypeModalTitle')}
        </Typography>

        <Typography variant="body1">{t('authenticateModal.selectAccountTypeModalSubTitle')}</Typography>
      </Stack>

      <StyledButtonsContainer flexDirection="column">
        <StyledButton
          typographyProps={{ variant: 'subtitle1' }}
          active={accountType === AccountTypeEnum.Client}
          onClick={() => setAccountType(AccountTypeEnum.Client)}
        >
          <ShoppingCartIcon />
          {t('accountType.client')}
          <CheckIconWrapper active={accountType === AccountTypeEnum.Client} />
        </StyledButton>
        <StyledButton
          typographyProps={{ variant: 'subtitle1' }}
          active={accountType === AccountTypeEnum.Master}
          onClick={() => setAccountType(AccountTypeEnum.Master)}
        >
          <PersonIcon />
          {t('accountType.privateSpecialist')}
          <CheckIconWrapper active={accountType === AccountTypeEnum.Master} />
        </StyledButton>
        <StyledButton
          typographyProps={{ variant: 'subtitle1' }}
          active={accountType === AccountTypeEnum.Company}
          onClick={() => setAccountType(AccountTypeEnum.Company)}
        >
          <BusinessIcon />
          {t('accountType.company')}
          <CheckIconWrapper active={accountType === AccountTypeEnum.Company} />
        </StyledButton>
      </StyledButtonsContainer>

      <StyledButtonsContainer flexDirection={upSmall ? 'row' : 'column'}>
        <Button variant="outlined" typographyProps={{ variant: 'subtitle1' }} onClick={handleClose} disabled={loading}>
          {t('authenticateModal.cancel')}
        </Button>

        <Button
          size="small"
          disabled={!accountType}
          variant="contained"
          typographyProps={{ variant: 'subtitle1' }}
          onClick={() => accountType && onSubmit(accountType)}
          loading={loading}
        >
          {t('authenticateModal.createAccount')}
        </Button>
      </StyledButtonsContainer>
    </Modal>
  );
};

export default SelectAccountTypeStep;
