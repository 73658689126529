import { Box } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light2};
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  `,
);
