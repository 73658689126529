import React from 'react';
import { Typography } from '@mui/material';

import { StyledContactContainer, StyledContactTextContainer } from '../styled';

type Props = {
  icon: React.ReactNode;
  label: string;
  value?: React.ReactNode;
  onClick?: () => void;
  isLink?: boolean;
};

const Contact: React.FC<Props> = ({ icon, label, value, isLink, onClick }) => (
  <StyledContactContainer clickable={!!onClick} onClick={onClick}>
    {icon}
    <StyledContactTextContainer>
      <Typography variant="subtitle3" color="primary.dark2">
        {label}
      </Typography>
      <Typography variant="subtitle1" color={isLink ? 'accent.main' : 'primary.main'}>
        {value}
      </Typography>
    </StyledContactTextContainer>
  </StyledContactContainer>
);

export default Contact;
