import React, { useEffect, useRef } from 'react';
import { ClickAwayListener, Popper, Stack, Typography } from '@mui/material';
import { ReactComponent as ScheduleCalendarIcon } from 'assets/icons/scheduleCalendar.svg';
import { ReactComponent as ScheduleListIcon } from 'assets/icons/scheduleList.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useTranslation } from 'react-i18next';
import useResize from 'hooks/useResize';
import { StyledButton, StyledPopperContainer } from './styled';
import { ReservationCalendarVariant } from '../types';

export type Props = {
  variant: ReservationCalendarVariant;
  onChange: (variant: ReservationCalendarVariant) => void;
};

const ReservationVariantButton: React.FC<Props> = ({ onChange, variant }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttonWidth, setButtonWidth] = React.useState<null | number>(null);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { width } = useResize(buttonRef);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [width]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledButton ref={buttonRef} aria-describedby={id} onClick={handleClick}>
        {variant === ReservationCalendarVariant.Calendar ? <ScheduleCalendarIcon /> : <ScheduleListIcon />}
        <Typography variant="subtitle1">
          {variant === ReservationCalendarVariant.Calendar ? t('week') : t('schedule')}
        </Typography>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          sx={{ zIndex: 1, width: buttonWidth ? `${buttonWidth}px` : 'auto' }}
        >
          <StyledPopperContainer>
            <Stack
              data-selected={variant === ReservationCalendarVariant.Calendar}
              direction="row"
              gap="8px"
              alignItems="center"
            >
              <ScheduleCalendarIcon />
              <Typography variant="subtitle1" onClick={() => onChange(ReservationCalendarVariant.Calendar)}>
                {t('week')}
              </Typography>
            </Stack>
            <Stack
              data-selected={variant === ReservationCalendarVariant.List}
              direction="row"
              gap="8px"
              alignItems="center"
            >
              <ScheduleListIcon />
              <Typography variant="subtitle1" onClick={() => onChange(ReservationCalendarVariant.List)}>
                {t('schedule')}
              </Typography>
            </Stack>
          </StyledPopperContainer>
        </Popper>
      </StyledButton>
    </ClickAwayListener>
  );
};

export default ReservationVariantButton;
