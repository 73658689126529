import { css, styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

export const StyledMainContainer = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 20px 40px;
    grid-template-areas:
      'info'
      'services';

    ${theme.breakpoints.up('md')} {
      grid-template-columns: 2fr minmax(0, 3fr);
      grid-template-rows: auto;
      grid-template-areas: 'info services';
    }
  `,
);

export const StyledInfoSection = styled(Stack)`
  grid-area: info;

  gap: 20px;
`;

export const StyledServicesSection = styled(Box)`
  grid-area: services;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledAttributeRow = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;
