import { styled } from '@mui/material/styles';
import Select from 'components/@shared/Select';

export const StyledSelect = styled(Select)`
  width: auto;

  .MuiInputBase-root {
    height: 50px;
    width: 90px;
  }
`;
