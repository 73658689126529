import React from 'react';
import { createTheme, ThemeOptions, Theme, css } from '@mui/material/styles';
import MontserratRegular from 'assets/fonts/Montserrat/Montserrat-Regular.ttf';
import MontserratSemiBold from 'assets/fonts/Montserrat/Montserrat-SemiBold.ttf';
import MontserratBold from 'assets/fonts/Montserrat/Montserrat-Bold.ttf';
import MontserratExtraBold from 'assets/fonts/Montserrat/Montserrat-ExtraBold.ttf';
import MontserratBlack from 'assets/fonts/Montserrat/Montserrat-Black.ttf';

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }

  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    dark2?: string;
    dark3?: string;
    light2?: string;
    light3?: string;
  }

  interface SimplePaletteColorOptions {
    dark2?: string;
    dark3?: string;
    light2?: string;
    light3?: string;
  }

  interface TypographyVariants {
    bodyDefaultBook: React.CSSProperties;
    bodyDefaultMedium: React.CSSProperties;
    bodySmallBook: React.CSSProperties;
    bodySmallMedium: React.CSSProperties;
    subtitleBook: React.CSSProperties;
    subtitleMedium: React.CSSProperties;
    captionMedium: React.CSSProperties;
    overlineDefaultMedium: React.CSSProperties;
    overlineSmallMedium: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    buttonMedium: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    bodyDefaultMediumUnderline: React.CSSProperties;
    bodySmallMediumUnderline: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    body3?: React.CSSProperties;
    subtitle1?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    link1?: React.CSSProperties;
    link2?: React.CSSProperties;
    link3?: React.CSSProperties;
    tag1?: React.CSSProperties;
    tag2?: React.CSSProperties;
    tag3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    button: false;
    caption: false;
    overline: false;
    body1: true;
    body2: true;
    body3: true;
    subtitle1: true;
    subtitle2: true;
    subtitle3: true;
    link1: true;
    link2: true;
    link3: true;
    tag1: true;
    tag2: true;
    tag3: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const createCustomTheme = (options: ThemeOptions): Theme =>
  createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      // @ts-ignore
      MuiCssBaseline: {
        styleOverrides: css`
          @font-face {
            font-family: 'Montserrat';
            font-weight: normal;
            font-style: normal;
            src: url(${MontserratRegular});
          }
          @font-face {
            font-family: 'Montserrat';
            font-weight: 600;
            font-style: normal;
            src: url(${MontserratSemiBold});
          }
          @font-face {
            font-family: 'Montserrat';
            font-weight: 700;
            font-style: normal;
            src: url(${MontserratBold});
          }
          @font-face {
            font-family: 'Montserrat';
            font-weight: 800;
            font-style: normal;
            src: url(${MontserratExtraBold});
          }
          @font-face {
            font-family: 'Montserrat';
            font-weight: 900;
            font-style: normal;
            src: url(${MontserratBlack});
          }

          *:focus {
            outline: none;
          }

          @supports not selector(::-webkit-scrollbar) {
            * {
              scrollbar-width: thin;
              scrollbar-color: #4870ff transparent;
            }
          }

          @supports selector(::-webkit-scrollbar) {
            *::-webkit-scrollbar {
              width: 10px;
              height: 10px;
              background-color: transparent;
            }

            *::-webkit-scrollbar-track {
              background-color: transparent;
            }

            *::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background-color: #4870ff;
              background-clip: content-box;
              border: 2px solid transparent;
            }

            *::-webkit-scrollbar-thumb:focus {
              background-color: #4870ff;
            }

            *::-webkit-scrollbar-thumb:active {
              background-color: #4870ff;
            }

            *::-webkit-scrollbar-thumb:hover {
              background-color: #4870ff;
            }

            *::-webkit-scrollbar-corner {
              background-color: transparent;
            }
          }
        `,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    ...options,
  });

export default createCustomTheme;
