import { Autocomplete } from '@mui/material';
import { styled, css } from '@mui/material/styles';

const MIN_BUTTON_WIDTH = 156;
const MAX_BUTTON_WIDTH = 350;

export const StyledAutocomplete = styled(Autocomplete)(
  ({ theme }) => css`
    position: absolute;
    right: 8px;
    top: 8px;
    min-width: ${MIN_BUTTON_WIDTH}px;
    width: 30%;
    max-width: ${MAX_BUTTON_WIDTH}px;

    ${theme.breakpoints.up('sm')} {
      top: 6px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.primary.light2};
      border-radius: 8px;
    }

    & .MuiInputBase-root {
      border-radius: 8px;
      background: ${theme.palette.primary.light2};
    }

    & .MuiAutocomplete-input {
      color: ${theme.palette.primary.main};
      font-family: Montserrat, sans-serif;
      box-sizing: border-box;
      padding: 12px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;

      ${theme.breakpoints.up('sm')} {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
      }
    }

    & .Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.accent.main} !important;
      }
    }

    & .Mui-error {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.error.main} !important;
      }

      & .MuiAutocomplete-input {
        color: ${theme.palette.error.main};
      }
    }

    & .Mui-disabled {
      background: none !important;
      border: none !important;

      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.primary.dark2} !important;
      }

      & .MuiButtonBase-root {
        color: ${theme.palette.primary.dark2} !important;
      }
    }

    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.accent.light} !important;
      }
    }

    & .MuiAutocomplete-clearIndicator,
    & .MuiAutocomplete-popupIndicator {
      color: ${theme.palette.primary.main};
    }

    & .MuiAutocomplete-clearIndicator {
      &:hover {
        color: ${theme.palette.accent.light};
      }

      &:active {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);
