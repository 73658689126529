import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton';

import { StyledIconButton } from './styled';

type Props = Omit<IconButtonProps, 'color'> & {
  color?: 'primary' | 'error';
  active?: boolean;
};

const IconButton: React.FC<Props> = ({ color = 'primary', children, className, active, ...props }) => (
  <StyledIconButton color={color} className={className} active={active} {...props} disableRipple>
    {children}
  </StyledIconButton>
);

export default IconButton;
