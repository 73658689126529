import { useTranslation } from 'react-i18next';
import { PATHS } from 'navigation/constants';
import { OutputServiceGroupDto } from '../../types';

export const useBreadcrumbs = (data?: OutputServiceGroupDto) => {
  const { t } = useTranslation();

  if (!data) return;

  const getBreadcrumbsSegments = () => [
    {
      path: PATHS.MAIN_PAGE,
      label: t('breadcrumbs.home'),
    },
    { path: PATHS.SERVICES_PAGE, label: t('breadcrumbs.services') },
  ];

  return {
    breadcrumbsSegments: getBreadcrumbsSegments(),
    currentPageLabel: data.company?.name,
  };
};
