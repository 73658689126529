import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import MainPage from 'components/MainPage';
import ComingSoon from 'components/@common/ComingSoon';
import ServicesPage from 'components/ServicesPage';
import CommonContainer from 'components/@common/CommonContainer';
import ServiceDetailsPage from 'components/ServiceDetailsPage';
import TermsAndConditionsPage from 'components/TermsAndConditionsPage';
import MasterCabinetPage from 'components/MasterCabinetPage';
import ManageServicePage from 'components/ManageServicePage';
import CompanyCabinetPage from 'components/CompanyCabinetPage';
import BookingServicePage from 'components/BookingServicePage';
import ClientCabinetPage from 'components/ClientCabinetPage';
import MasterCalendarPage from 'components/MasterCalendarPage';
import CompanyCalendarPage from 'components/CompanyCalendarPage';
import MasterReservationDetailsPage from 'components/MasterReservationDetailsPage';
import CompanyReservationDetailsPage from 'components/CompanyReservationDetailsPage';
import PartnerPage from 'components/PartnerPage';
import CompanyMasterPage from 'components/CompanyMasterPage';
import AboutPage from 'components/AboutPage';
import PrivacyPolicyPage from 'components/PrivacyPolicyPage';
import ForCompaniesPage from 'components/ForCompaniesPage';
import { MasterProvider } from 'contexts/master';
import { CompanyProvider } from 'contexts/company';
import { ClientProvider } from 'contexts/client';
import { PATHS } from './constants';

import { AccountRestriction } from './restrictions/AccountRestriction';
import { AccountTypeEnum } from '../constatns';

const Navigation: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={PATHS.MAIN_PAGE} element={<CommonContainer />}>
        <Route
          element={
            <ClientProvider>
              <Outlet />
            </ClientProvider>
          }
        >
          <Route index element={<MainPage />} />
          <Route path={PATHS.SERVICES_PAGE} element={<ServicesPage />} />
          <Route path={PATHS.FAVORITES_PAGE} element={<ComingSoon />} />
          <Route path={PATHS.SERVICE_DETAILS_PAGE} element={<ServiceDetailsPage />} />
          <Route path={PATHS.PARTNER_DETAILS} element={<PartnerPage />} />
          <Route path={PATHS.COMPANY_MASTER_DETAILS_PAGE} element={<CompanyMasterPage />} />
          <Route path={PATHS.ABOUT_PAGE} element={<AboutPage />} />
          <Route path={PATHS.FOR_COMPANIES_PAGE} element={<ForCompaniesPage />} />
          <Route path={PATHS.TERMS_AND_CONDITIONS_PAGE} element={<TermsAndConditionsPage />} />
          <Route path={PATHS.PRIVACY_POLICY_PAGE} element={<PrivacyPolicyPage />} />
          <Route
            element={
              <AccountRestriction permittedTypes={[AccountTypeEnum.Client]}>
                <Outlet />
              </AccountRestriction>
            }
          >
            <Route path={PATHS.BOOKING_SERVICE} element={<BookingServicePage />} />
            <Route path={PATHS.CLIENT_CABINET_INFO_TAB} element={<ClientCabinetPage />} />
            <Route path={PATHS.CLIENT_CABINET_RESERVATIONS_TAB} element={<ClientCabinetPage />} />
          </Route>
        </Route>

        <Route
          element={
            <MasterProvider>
              <AccountRestriction permittedTypes={[AccountTypeEnum.Master]}>
                <Outlet />
              </AccountRestriction>
            </MasterProvider>
          }
        >
          <Route path={PATHS.MASTER_CABINET_INFO_TAB} element={<MasterCabinetPage />} />
          <Route path={PATHS.MASTER_CABINET_SERVICES_TAB} element={<MasterCabinetPage />} />
          <Route path={PATHS.MASTER_CABINET_SERVICES_TAB_ADD_SERVICE} element={<ManageServicePage />} />
          <Route path={PATHS.MASTER_CABINET_SERVICES_TAB_EDIT_SERVICE} element={<ManageServicePage />} />
          <Route path={PATHS.MASTER_CALENDAR_SCHEDULE} element={<MasterCalendarPage />} />
          <Route path={PATHS.MASTER_CALENDAR_NEW_ORDERS} element={<MasterCalendarPage />} />
          <Route path={PATHS.MASTER_RESERVATION_DETAILS} element={<MasterReservationDetailsPage />} />
        </Route>
        <Route
          element={
            <CompanyProvider>
              <MasterProvider>
                <AccountRestriction permittedTypes={[AccountTypeEnum.Company]}>
                  <Outlet />
                </AccountRestriction>
              </MasterProvider>
            </CompanyProvider>
          }
        >
          <Route path={PATHS.COMPANY_CABINET_INFO_TAB} element={<CompanyCabinetPage />} />
          <Route path={PATHS.COMPANY_CABINET_MASTERS_TAB} element={<CompanyCabinetPage />} />
          <Route path={PATHS.COMPANY_CABINET_CREATE_MASTER_INFO_TAB} element={<MasterCabinetPage />} />
          <Route path={PATHS.COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB} element={<MasterCabinetPage />} />
          <Route path={PATHS.COMPANY_CABINET_MASTER_INFO_TAB} element={<MasterCabinetPage />} />
          <Route path={PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB} element={<MasterCabinetPage />} />
          <Route path={PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE} element={<ManageServicePage />} />
          <Route path={PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB_EDIT_SERVICE} element={<ManageServicePage />} />
          <Route path={PATHS.COMPANY_CALENDAR_SCHEDULE} element={<CompanyCalendarPage />} />
          <Route path={PATHS.COMPANY_CALENDAR_NEW_ORDERS} element={<CompanyCalendarPage />} />
          <Route path={PATHS.COMPANY_RESERVATION_DETAILS} element={<CompanyReservationDetailsPage />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Navigation;
