import { Box, styled, css, Stack } from '@mui/material';
import { ReactComponent as ImgPlaceholderWhiteBgIcon } from 'assets/icons/imgPlaceholderWhiteBg.svg';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledCard = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 12px;
    padding: 12px;
    border-radius: 20px;
    background: ${theme.palette.primary.light2};
    align-items: start;
    cursor: pointer;
  `,
);

export const StyledImgContainer = styled(Box)`
  position: relative;
  width: 100%;
  min-width: 120px;
  flex: 0 0 34%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const StyledImg = styled('img')`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

export const StyledImgPlaceholder = styled(ImgPlaceholderWhiteBgIcon)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledStatusContainer = styled(
  Stack,
  preventForwardProps(['active']),
)<{ active?: boolean }>(
  ({ theme, active }) => css`
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 8px;
    width: 64px;
    background: ${active ? theme.palette.accent.main : theme.palette.primary.light3};
    color: ${active ? theme.palette.primary.light : theme.palette.primary.dark};
    padding: 2px;
    align-items: center;
    justify-content: center;
    z-index: 1;
  `,
);
