import { css, styled } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';
import Button from 'components/@shared/Button';

export const StyledButton = styled(
  Button,
  preventForwardProps(['active']),
)<{ active?: boolean }>(
  ({ theme, active }) => css`
    flex: 0 0 calc(50% - 4px);

    background: ${active ? theme.palette.accent.main : theme.palette.primary.light2};
    color: ${active ? theme.palette.primary.light : theme.palette.primary.main};
    border: 2px solid ${active ? theme.palette.accent.main : theme.palette.primary.light2};

    @media (hover: hover) {
      &:hover {
        background: ${active ? theme.palette.accent.light : theme.palette.primary.light3};
        border: 2px solid ${active ? theme.palette.accent.light : theme.palette.primary.light3};
        box-shadow: unset;
        cursor: pointer;
      }
    }

    @media (hover: none) {
      &:hover {
        background: ${active ? theme.palette.accent.main : theme.palette.primary.light2};
        border: 2px solid ${active ? theme.palette.accent.main : theme.palette.primary.light2};
      }
    }

    &:active {
      background: ${active ? theme.palette.accent.dark : theme.palette.primary.light3};
      border: 2px solid ${active ? theme.palette.accent.dark : theme.palette.primary.light3};
    }
  `,
);
