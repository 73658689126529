import React from 'react';
import useAuthModal from './useAuthModal';
import { AuthModalStep } from './constants';
import AuthStep from './AuthStep';
import SelectAccountTypeStep from './SelectAccountTypeStep';

type Props = {
  open: boolean;
  onClose: () => void;
};

const BookServiceModal: React.FC<Props> = ({ open, onClose }) => {
  const { loading, step, setLoading, handleSelectAccountTypeClick, handleLoginWithGoogle } = useAuthModal(
    open,
    onClose,
  );

  const handleClose = () => {
    setLoading(false);
    onClose();
  };

  return (
    <>
      <AuthStep
        open={step === AuthModalStep.Auth}
        loading={loading}
        onClose={handleClose}
        onLoginWithGoogle={handleLoginWithGoogle}
      />
      <SelectAccountTypeStep
        open={step === AuthModalStep.SelectAccountType}
        loading={loading}
        onClose={handleClose}
        onSubmit={handleSelectAccountTypeClick}
      />
    </>
  );
};

export default BookServiceModal;
