import { css, styled } from '@mui/material/styles';
import Input from '../Input';

export const StyledInput = styled(Input)`
  .MuiInputBase-root {
    padding: 0;
  }

  .MuiInputBase-input {
    padding: 21px 16px;
  }
`;
