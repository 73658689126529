import { css, styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const StyledMainContainer = styled(Box)`
  display: flex;
  align-items: start;
  gap: 20px;
`;

export const StyledButton = styled(Button)(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 16px;
    border-radius: 12px;
    text-transform: none;
    flex: 1 1 50%;

    background: ${theme.palette.primary.light2};
    color: ${theme.palette.primary.main};

    svg {
      flex-shrink: 0;
    }

    &:hover {
      color: ${theme.palette.accent.light};
    }

    &:active {
      color: ${theme.palette.accent.dark};
    }
  `;
});
