import React, { useEffect, useRef, useState } from 'react';
import i18next from 'localizations';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import Button from 'components/@shared/Button';
import TruncatedText from 'components/@shared/TruncatedText';
import Input from 'components/@shared/Input';
import extractNameFromUrl from 'utils/extractNameFromUrl';

import { StyledContainer, StyledInputContainer } from './styled';

export enum SocialMediaType {
  Telegram = 'Telegram',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
}

const typeToDataMapping = {
  [SocialMediaType.Telegram]: {
    Icon: TelegramIcon,
    title: i18next.t('socialMedia.telegram'),
  },
  [SocialMediaType.Instagram]: {
    Icon: InstagramIcon,
    title: i18next.t('socialMedia.instagram'),
  },
  [SocialMediaType.Facebook]: {
    Icon: FacebookIcon,
    title: i18next.t('socialMedia.facebook'),
  },
};

type Props = {
  type: SocialMediaType;
  value?: string;
  onChange?: (value: string) => void;
};

const SocialMedia: React.FC<Props> = ({ value, type, onChange }) => {
  const { t } = useTranslation();

  const [showInput, setShowInput] = useState(false);
  const [currentValue, setCurrentValue] = useState(value || '');

  const ref = useRef<HTMLDivElement>(null);

  const handleLinkClick = () => window.open(value, '_self');

  useEffect(() => {
    if (showInput) {
      // @ts-ignore
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target) && showInput) {
          setShowInput(false);
          setCurrentValue('');
        }
      };

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [ref, showInput]);

  useEffect(() => {
    setCurrentValue(value || '');
  }, [value]);

  const { Icon, title } = typeToDataMapping[type];

  const handleConfirmClick = () => {
    setShowInput(false);
    onChange?.(currentValue);
  };

  const handleDisconnectClick = () => {
    setCurrentValue('');
    onChange?.('');
  };

  const getButton = () => {
    if (showInput) {
      return (
        <Button variant="text" typographyProps={{ color: 'accent.main' }} onClick={handleConfirmClick}>
          {t('socialMedia.confirm')}
        </Button>
      );
    }

    if (!currentValue) {
      return (
        <Button variant="text" typographyProps={{ color: 'accent.main' }} onClick={() => setShowInput(true)}>
          {t('socialMedia.connect')}
        </Button>
      );
    }

    if (currentValue) {
      return (
        <Button variant="text" typographyProps={{ color: 'error.main' }} onClick={handleDisconnectClick}>
          {t('socialMedia.disconnect')}
        </Button>
      );
    }
  };

  const name = extractNameFromUrl(currentValue);

  return (
    <StyledContainer ref={ref}>
      <Stack direction="row" justifyContent="space-between" gap="8px" alignItems="center" flex="1 1 auto" minWidth={0}>
        <Stack gap="8px" alignItems="start" flex={`1 1 ${showInput ? '100%' : 'auto'}`}>
          <Typography variant="h6" color="primary.main">
            {title}
          </Typography>
          <StyledInputContainer expanded={showInput}>
            {showInput && (
              <Input
                value={currentValue}
                sx={{ margin: '4px 0 8px' }}
                label={t('socialMedia.linkInputLabel')}
                placeholder={t('socialMedia.linkInputPlaceholder')}
                onChange={e => setCurrentValue(e.target.value)}
              />
            )}
          </StyledInputContainer>
          {getButton()}
        </Stack>
        {!showInput && name && (
          <TruncatedText variant="subtitle1" color="accent.main" onClick={handleLinkClick} sx={{ cursor: 'pointer' }}>
            {`@${name}`}
          </TruncatedText>
        )}
      </Stack>
      <Icon />
    </StyledContainer>
  );
};

export default SocialMedia;
