import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { OutputServiceGroupDto } from 'types';
import { COMPANY_MASTER_PAGE } from 'api/requests/company';
import getRouteWithParams from 'utils/getRouteWithParams';
import { PATHS } from 'navigation/constants';

const useCompanyDetailsPage = (companyId: number) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { slug } = useParams<'slug'>();

  const [fetchData, { data, loading }] = useLazyQuery<
    { companyMasterPage: OutputServiceGroupDto },
    {
      companyId?: number;
    }
  >(COMPANY_MASTER_PAGE, {
    variables: { companyId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const handleServiceClick = (id: number) =>
    navigate(getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: id.toString() }));

  const handleMasterClick = (id: number) =>
    slug && navigate(getRouteWithParams(PATHS.COMPANY_MASTER_DETAILS_PAGE, { slug, masterId: id.toString() }));

  return {
    companyDetails: data?.companyMasterPage,
    loading,
    handleServiceClick,
    handleMasterClick,
  };
};

export default useCompanyDetailsPage;
