import { useEffect, useState } from 'react';
import { useAppState } from 'contexts/app';
import { useLazyQuery } from '@apollo/client';
import { GET_ACCOUNT } from 'api/requests/auth';
import { setAccount } from 'contexts/app/actions';
import { AccountTypeEnum } from 'constatns';

export const useAccount = () => {
  const {
    state: { account },
    dispatch,
  } = useAppState();

  const [loading, setLoading] = useState(true);

  const [getAccount] = useLazyQuery<{ account: { type: AccountTypeEnum } }>(GET_ACCOUNT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    (async () => {
      if (!account) {
        const response = await getAccount();

        dispatch(setAccount(response?.data?.account.type || null));
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading };
};
