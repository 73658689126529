import { useEffect, useState } from 'react';
import { APPROVE_MASTER_RESERVATION, SEARCH_MASTER_RESERVATIONS } from 'api/requests/master';
import { useLazyQuery } from '@apollo/client';
import useMutation from 'hooks/useMutation';
import { useNewReservationsCount } from 'hooks/useNewReservations';
import { OutputMasterReservationDto, ReservationStatus, SearchMasterReservationsConnection } from 'types/reservation';
import { PageInfo } from 'types';
import useColumnsCount from './useColumnsCount';

const useNewOrdersTab = () => {
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [reservations, setReservations] = useState<OutputMasterReservationDto[]>([]);
  const [reservationsLoading, setReservationsLoading] = useState(false);

  const [searchMasterReservations] = useLazyQuery<
    { searchMasterReservations: SearchMasterReservationsConnection },
    {
      statuses: ReservationStatus[];
      first?: number;
      after?: string;
      last?: number;
      before?: string;
    }
  >(SEARCH_MASTER_RESERVATIONS, { fetchPolicy: 'no-cache' });

  const [approveMasterReservation] = useMutation<
    void,
    {
      reservationId: number;
    }
  >(APPROVE_MASTER_RESERVATION, { fetchPolicy: 'no-cache' });

  const { fetchNewReservationsCount } = useNewReservationsCount(true);

  const ordersPerFetchCount = useColumnsCount() * 2;

  const fetchReservations = () => {
    setReservationsLoading(true);

    searchMasterReservations({
      variables: {
        statuses: [ReservationStatus.WAITING_FOR_APPROVE],
        first: ordersPerFetchCount,
      },
    })
      .then(({ data }) => {
        if (data?.searchMasterReservations) {
          setPageInfo(data.searchMasterReservations.pageInfo);
          setReservations(data.searchMasterReservations.nodes);
        }
      })
      .finally(() => {
        setReservationsLoading(false);
      });
  };

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersPerFetchCount]);

  const showMoreReservations = () => {
    searchMasterReservations({
      variables: {
        statuses: [ReservationStatus.WAITING_FOR_APPROVE],
        first: ordersPerFetchCount,
        after: pageInfo?.endCursor,
      },
    }).then(({ data }) => {
      if (data?.searchMasterReservations) {
        setPageInfo(data.searchMasterReservations.pageInfo);
        setReservations(prev => [...prev, ...data.searchMasterReservations.nodes]);
      }
    });
  };

  const handleConfirm = async (reservationId: number) => {
    await approveMasterReservation({ variables: { reservationId } });
    fetchReservations();
    fetchNewReservationsCount();
  };

  return {
    reservations,
    reservationsLoading,
    ordersPerFetchCount,
    pageInfo,
    showMoreReservations,
    handleConfirm,
  };
};

export default useNewOrdersTab;
