import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContactContainer = styled(
  Box,
  preventForwardProps(['clickable']),
)<{ clickable: boolean }>(
  ({ theme, clickable }) => css`
    display: flex;
    gap: 12px;
    align-items: start;

    & svg {
      color: ${theme.palette.accent.main};
      flex: 0 0 auto;
    }

    ${clickable &&
    css`
      cursor: pointer;

      &:hover {
        & svg {
          color: ${theme.palette.accent.light};
        }
      }

      &:active {
        & svg {
          color: ${theme.palette.accent.dark};
        }
      }
    `}
  `,
);

export const StyledContactTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
