import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const convertTimeSpanToMinutes = (timespanString: string): number => {
  const regex = /PT(\d+)(?:H(\d+)M)?/;
  const match = timespanString.match(regex);

  if (!match) {
    throw new Error('Invalid timespan format');
  }

  const hours = parseInt(match[1], 10);
  const minutes = match[2] ? parseInt(match[2], 10) : 0;

  return hours * 60 + minutes;
};

export default convertTimeSpanToMinutes;
