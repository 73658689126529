import { useEffect, useState } from 'react';
import { APPROVE_COMPANY_RESERVATION, SEARCH_COMPANY_RESERVATIONS } from 'api/requests/company';
import { useLazyQuery } from '@apollo/client';
import useMutation from 'hooks/useMutation';
import { useNewReservationsCount } from 'hooks/useNewReservations';
import { OutputCompanyReservationDto, ReservationStatus, SearchCompanyReservationsConnection } from 'types/reservation';
import { PageInfo } from 'types';
import useColumnsCount from './useColumnsCount';

const useNewOrdersTab = () => {
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [reservations, setReservations] = useState<OutputCompanyReservationDto[]>([]);
  const [reservationsLoading, setReservationsLoading] = useState(false);

  const [searchCompanyReservations] = useLazyQuery<
    { searchCompanyReservations: SearchCompanyReservationsConnection },
    {
      statuses: ReservationStatus[];
      first?: number;
      after?: string;
      last?: number;
      before?: string;
    }
  >(SEARCH_COMPANY_RESERVATIONS, { fetchPolicy: 'no-cache' });

  const [approveCompanyReservation] = useMutation<
    void,
    {
      reservationId: number;
    }
  >(APPROVE_COMPANY_RESERVATION, { fetchPolicy: 'no-cache' });

  const { fetchNewReservationsCount } = useNewReservationsCount(true);

  const ordersPerFetchCount = useColumnsCount() * 2;

  const fetchReservations = () => {
    setReservationsLoading(true);

    searchCompanyReservations({
      variables: {
        statuses: [ReservationStatus.WAITING_FOR_APPROVE],
        first: ordersPerFetchCount,
      },
    })
      .then(({ data }) => {
        if (data?.searchCompanyReservations) {
          setPageInfo(data.searchCompanyReservations.pageInfo);
          setReservations(data.searchCompanyReservations.nodes);
        }
      })
      .finally(() => {
        setReservationsLoading(false);
      });
  };

  useEffect(() => {
    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersPerFetchCount]);

  const showMoreReservations = () => {
    searchCompanyReservations({
      variables: {
        statuses: [ReservationStatus.WAITING_FOR_APPROVE],
        first: ordersPerFetchCount,
        after: pageInfo?.endCursor,
      },
    }).then(({ data }) => {
      if (data?.searchCompanyReservations) {
        setPageInfo(data.searchCompanyReservations.pageInfo);
        setReservations(prev => [...prev, ...data.searchCompanyReservations.nodes]);
      }
    });
  };

  const handleConfirm = async (reservationId: number) => {
    await approveCompanyReservation({ variables: { reservationId } });
    fetchReservations();
    fetchNewReservationsCount();
  };

  return {
    reservations,
    reservationsLoading,
    ordersPerFetchCount,
    pageInfo,
    showMoreReservations,
    handleConfirm,
  };
};

export default useNewOrdersTab;
