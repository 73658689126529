import { css, styled } from '@mui/material/styles';
import { Box, List, ListItemButton } from '@mui/material';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import LanguageSelector from '../../LanguageSelector';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light};
    border-radius: 12px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
);

export const StyledCopyrightSection = styled(Box)`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLogoIcon = styled(LogoIcon)`
  width: 80px;
`;

export const StyledList = styled(List)`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledListItemButton = styled(ListItemButton)(
  ({ theme }) => css`
    justify-content: center;
    background: none;
    color: ${theme.palette.primary.main};
    padding: 0;

    &:hover {
      background: none;
      color: ${theme.palette.accent.light};
    }

    &:active {
      background: none;
      color: ${theme.palette.accent.dark};
    }
  `,
);

export const StyledLanguageSelector = styled(LanguageSelector)`
  .MuiInputBase-root {
    height: 34px;
  }
`
