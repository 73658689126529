import { OutputScheduleDayDto, OutputServiceDto } from './service';
import { OutputClientDto } from './client';
import { OutputMasterDto } from './master';
import { PageInfo } from './common';

export type OutputReservationDto = {
  id: number;
  date: string;
  timeSlot: OutputScheduleDayDto;
  status: ReservationStatus;
  service: OutputServiceDto;
};

export enum ReservationStatus {
  WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
  APPROVED = 'APPROVED',
  CANCELED_BY_CLIENT = 'CANCELED_BY_CLIENT',
  CANCELED_BY_MASTER_OR_COMPANY = 'CANCELED_BY_MASTER_OR_COMPANY',
}

export type OutputCommonReservationDto = {
  id: number;
  date: string;
  timeSlot: OutputScheduleDayDto;
  status: ReservationStatus;
  service: Pick<OutputServiceDto, 'id' | 'name' | 'price'>;
  client: Pick<OutputClientDto, 'id' | 'contactName' | 'phone' | 'profileImageUri' | 'email'>;
};

export type OutputMasterReservationDto = OutputCommonReservationDto;

export type OutputCompanyReservationDto = OutputCommonReservationDto & {
  master: Pick<OutputMasterDto, 'id' | 'contactName' | 'profileImageUri' | 'email'>;
};

export type SearchMasterReservationsConnection = {
  pageInfo: PageInfo;
  nodes: OutputMasterReservationDto[];
};

export type SearchCompanyReservationsConnection = {
  pageInfo: PageInfo;
  nodes: OutputCompanyReservationDto[];
};
