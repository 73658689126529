import { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { ApolloError } from '@apollo/client';
import useMutation from 'hooks/useMutation';
import { CREATE_ACCOUNT, GOOGLE_LOGIN } from 'api/requests/auth';
import { AccountTypeEnum } from 'constatns/auth';
import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import { useAppState } from 'contexts/app';
import { setAccount, setErrorBanner } from 'contexts/app/actions';
import { AuthModalStep } from './constants';

const useAuthModal = (open: boolean, onClose: () => void) => {
  const navigate = useNavigate();
  const { dispatch } = useAppState();

  const [googleLogin] = useMutation<{ googleLogin: { type: AccountTypeEnum | null } }, { accessToken: string }>(
    GOOGLE_LOGIN,
    {
      context: {
        credentials: 'include',
      },
    },
  );
  const [createAccount, { loading: loadingCreateAccount }] = useMutation<
    { createAccount: { type: AccountTypeEnum } },
    { accountType: AccountTypeEnum }
  >(CREATE_ACCOUNT);

  const [step, setStep] = useState<AuthModalStep | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setStep(AuthModalStep.Auth);
    } else {
      setStep(null);
    }
  }, [open]);

  const handleNavigation = (accountType: AccountTypeEnum) => {
    if (accountType === AccountTypeEnum.Master) {
      navigate(PATHS.MASTER_CABINET_INFO_TAB);
    } else if (accountType === AccountTypeEnum.Company) {
      navigate(PATHS.COMPANY_CABINET_INFO_TAB);
    } else {
      navigate(PATHS.CLIENT_CABINET_INFO_TAB);
    }
  };

  const handleSelectAccountTypeClick = async (accountType: AccountTypeEnum) => {
    await createAccount({ variables: { accountType } });
    dispatch(setAccount(accountType));
    onClose();
    handleNavigation(accountType);
  };

  const googleLoginCallback = useGoogleLogin({
    scope: 'openid',
    flow: 'auth-code',
    redirect_uri: `${window.location.origin}/api/v1/partners/login/google/callback`,
    onSuccess: async tokenResponse => {
      try {
        const response = await googleLogin({
          variables: {
            accessToken: tokenResponse.code,
          },
        });

        if (!response.data) {
          return;
        }

        const type = response.data?.googleLogin.type;

        if (!type) {
          setStep(AuthModalStep.SelectAccountType);
          return;
        }
        dispatch(setAccount(type));
        onClose();
        handleNavigation(type);
      } catch (e) {
        console.log(e);
        dispatch(setErrorBanner(e as ApolloError));
      } finally {
        setLoading(false);
      }
    },
    onError: errorResponse => {
      const errorMessage = errorResponse.error_description;
      if (errorMessage) {
        dispatch(setErrorBanner(errorMessage));
      }
      setLoading(false);
    },
  });

  const handleLoginWithGoogle = () => {
    setLoading(true);
    googleLoginCallback();
  };

  return {
    loading: loading || loadingCreateAccount,
    step,
    setLoading,
    handleSelectAccountTypeClick,
    handleLoginWithGoogle,
  };
};

export default useAuthModal;
