import { Autocomplete } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const StyledAutocomplete = styled(Autocomplete)(
  ({ theme }) => css`
    & .MuiAutocomplete-inputRoot {
      padding: 12px 16px;
    }

    & .MuiAutocomplete-clearIndicator,
    & .MuiAutocomplete-popupIndicator {
      color: ${theme.palette.primary.main};
    }

    & .MuiAutocomplete-clearIndicator {
      &:hover {
        color: ${theme.palette.accent.light};
      }

      &:active {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);
