import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Tabs from 'components/@shared/Tabs';
import { PATHS } from 'navigation/constants';

import InfoTab from './InfoTab';
import MastersTab from './MastersTab';
import useCompanyCabinetPage from './useCompanyCabinetPage';

const CompanyCabinetPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useCompanyCabinetPage();

  const COMPANY_CABINET_INFO_TAB = useMatch(PATHS.COMPANY_CABINET_INFO_TAB);

  const activeTabIndex = COMPANY_CABINET_INFO_TAB ? 0 : 1;

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const tabList = [
    { label: t('companyCabinetPage.infoTab'), content: <InfoTab /> },
    { label: t('companyCabinetPage.mastersTab'), content: <MastersTab /> },
  ];

  const handleTabChange = (index: number) =>
    navigate(index === 0 ? PATHS.COMPANY_CABINET_INFO_TAB : PATHS.COMPANY_CABINET_MASTERS_TAB);

  return (
    <Container>
      <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
        {t('companyCabinetPage.title')}
      </Typography>
      <Gap size={upMedium ? 56 : 32} />
      <Tabs tabList={tabList} activeTabIndex={activeTabIndex} onTabChange={handleTabChange} />
    </Container>
  );
};

export default CompanyCabinetPage;
