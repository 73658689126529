import React from 'react';
import { Typography, IconButton, AlertProps, SnackbarProps } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { StyledAlert, StyledSnackbar } from './styled';

interface BannerProps {
  open: SnackbarProps['open'];
  onClose: () => void;
  message?: string;
  severity?: AlertProps['severity'];
}

const AUTO_HIDE_DURATION = 5000;

export const Banner: React.FC<BannerProps> = ({ open, onClose, message, severity = 'info' }) => {
  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason: string) => {
    if (reason === 'timeout') {
      onClose();
    }
  };

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={AUTO_HIDE_DURATION}
      key={new Date().getTime()}
      open={open}
      onClose={handleSnackbarClose}
    >
      <StyledAlert
        severity={severity}
        variant="filled"
        action={
          <IconButton onClick={onClose}>
            <CloseIcon height="24px" width="24px" />
          </IconButton>
        }
      >
        <Typography variant="body1">{message}</Typography>
      </StyledAlert>
    </StyledSnackbar>
  );
};
