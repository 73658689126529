import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
import { StyledButton, StyledContainer, StyledImg, StyledImgContainer, StyledImgPlaceholder } from './styled';

export type MasterCardProps = {
  profileImageUri?: string;
  name?: string;
  qualification?: string;
  schedule: string[];
  onClick: () => void;
};

const MasterCard: React.FC<MasterCardProps> = ({ profileImageUri, name, qualification, schedule, onClick }) => {
  const { t } = useTranslation();

  const imageStatus = useLoadImage(profileImageUri || '');

  return (
    <StyledContainer>
      <StyledImgContainer>
        {profileImageUri && imageStatus === ImageStatus.Loaded ? (
          <StyledImg src={profileImageUri} alt="avatar" />
        ) : (
          <StyledImgPlaceholder />
        )}
      </StyledImgContainer>
      <Stack justifyContent="space-between" gap="12px" flex="1 1 100%">
        <Stack gap="8px">
          {!!name && (
            <Typography variant="h5" color="primary.main">
              {name}
            </Typography>
          )}
          {!!qualification && (
            <Typography variant="subtitle1" color="primary.main">
              {qualification}
            </Typography>
          )}
          <Stack gap="4px">
            <Typography variant="subtitle3" color="primary.dark2">
              {t('scheduleTitle')}
            </Typography>
            {schedule.map(item => (
              <Typography key={item} variant="subtitle2" color="primary.main">
                {item}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <StyledButton variant="text" onClick={onClick}>
          {t('moreDetails')}
        </StyledButton>
      </Stack>
    </StyledContainer>
  );
};

export default MasterCard;
