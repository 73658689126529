import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as CloseSquareIcon } from 'assets/icons/closeSquare.svg';
import Gap from 'components/@shared/Gap';
import ExpandableSelector from 'components/@shared/ExpandableSelect';
import { ExpandableMultipleSelectSkeleton } from 'components/@shared/ExpandableMultipleSelect/ExpandableMultipleSelectSkeleton';
import Button from 'components/@shared/Button';
import ExpandableMultipleSelect from 'components/@shared/ExpandableMultipleSelect';
import { CategoryItem } from 'types';

import { StyledDrawer, StyledHeader, StyledFilterListContainer, StyledIconButton } from './styled';
import FilterPriceSection from '../FilterPriceSection';
import useFilters from '../useFilters';
import { Price } from '../types';

type Props = {
  open: boolean;
  onClose: () => void;
};

const FiltersModal: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState<Array<{ id: string; localizedName: string }>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [list, setList] = useState<Array<{ id: string; localizedName: string }>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);

  const { categoryList, selectedCategory, maxPrice, price, onChange, getMaxPrice } = useFilters();

  const [localStatePrice, setLocalStatePrice] = useState<Price>(price);
  const [localStateCategory, setLocalStateCategory] = useState<CategoryItem | null>(selectedCategory);

  useEffect(() => {
    setLocalStatePrice(price);
    setLocalStateCategory(selectedCategory);
  }, [selectedCategory?.id, price]);

  useEffect(() => {
    getMaxPrice(localStateCategory?.id || null)
  }, [localStateCategory]);

  const onApplyFilters = () => {
    onChange({ price: localStatePrice, category: localStateCategory });
    onClose();
  };

  const handleClose = () => {
    setLocalStatePrice(price);
    setLocalStateCategory(selectedCategory);
    onClose();
  };

  return (
    <StyledDrawer open={open} anchor="bottom" variant="temporary">
      <Stack overflow="hidden">
        <StyledHeader>
          <Typography variant="h4" color="primary.main">
            {t('servicesPage.filtersTitle')}
          </Typography>
          <StyledIconButton onClick={handleClose}>
            <CloseSquareIcon />
          </StyledIconButton>
        </StyledHeader>
        <Gap size={18} />
        <StyledFilterListContainer>
          <Typography variant="subtitle1" color="primary.main">
            {t('servicesPage.filtersSubtitle')}
          </Typography>
          <ExpandableSelector
            itemList={categoryList}
            value={localStateCategory}
            isFilled
            // TODO get rid of it
            // @ts-ignore
            onChange={setLocalStateCategory}
          />
          {loading && <ExpandableMultipleSelectSkeleton />}
          {!loading && !!list.length && (
            <ExpandableMultipleSelect
              title="Послуги"
              itemList={list}
              selectedItemList={selectedList}
              onChange={setSelectedList}
            />
          )}
          <FilterPriceSection
            min={0}
            max={maxPrice}
            value={localStatePrice}
            initialExpanded
            onChange={setLocalStatePrice}
          />
        </StyledFilterListContainer>
      </Stack>
      <Gap size={18} />
      <Button variant="contained" onClick={onApplyFilters}>
        {t('servicesPage.filtersButtonLabelLabel')}
      </Button>
    </StyledDrawer>
  );
};

export default FiltersModal;
