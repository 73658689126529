import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledReservationItem = styled(
  Stack,
  preventForwardProps(['approved']),
)<{ approved: boolean }>(
  ({ theme, approved }) => css`
    flex-direction: row;
    background: ${approved ? theme.palette.accent.main : theme.palette.background.default};
    border-radius: 8px;
    border: 1px solid ${theme.palette.accent.main};
    padding: 8px;
    gap: 8px;

    cursor: pointer;

    :hover {
      background: ${approved ? theme.palette.accent.light : theme.palette.primary.light2};
    }

    :active {
      background: ${approved ? theme.palette.accent.dark : theme.palette.primary.light3};
    }

    * {
      color: ${approved ? theme.palette.background.default : theme.palette.accent.main};
    }
  `,
);
export const StyledMonthButton = styled(Stack)(
  ({ theme }) => css`
    cursor: pointer;

    :hover {
      color: ${theme.palette.accent.light};

      svg {
        color: ${theme.palette.accent.light};
      }
    }

    :active {
      color: ${theme.palette.accent.dark};

      svg {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);
