import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Typography } from '@mui/material';
import { PATHS } from 'navigation/constants';

import { StyledContainer, StyledLeftSection, StyledRightSection, StyledListItemButton } from './styled';

type Props = {
  className?: string;
};

const NavSection: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledContainer className={className}>
      <StyledLeftSection>
        <List sx={{ padding: 0 }}>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.SERVICES_PAGE)} disableRipple>
              <Typography variant="h6">{t('nav.servicesLabel')}</Typography>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.ABOUT_PAGE)} disableRipple>
              <Typography variant="h6">{t('nav.aboutLabel')}</Typography>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.FOR_COMPANIES_PAGE)} disableRipple>
              <Typography variant="h6">{t('nav.forCompaniesLabel')}</Typography>
            </StyledListItemButton>
          </ListItem>
        </List>
      </StyledLeftSection>
      <StyledRightSection>
        <List sx={{ padding: 0 }}>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.TERMS_AND_CONDITIONS_PAGE)} disableRipple>
              <Typography variant="h6">{t('nav.termsAndConditionsLabel')}</Typography>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.PRIVACY_POLICY_PAGE)} disableRipple>
              <Typography variant="h6">{t('nav.privacyPolicy')}</Typography>
            </StyledListItemButton>
          </ListItem>
        </List>
      </StyledRightSection>
    </StyledContainer>
  );
};
export default NavSection;
