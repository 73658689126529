import { Accordion } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion)(({ theme, expanded }) => {
  return css`
    margin: 0;
    padding: 20px 0;
    box-shadow: none;

    background: ${theme.palette.primary.light2};
    border-radius: 12px !important;

    &.Mui-expanded {
      margin: 0;
    }

    &:before {
      content: none;
    }

    & .MuiAccordionSummary-root {
      margin: 0 20px;
      padding: 0;
      min-height: auto;
      height: 16px;
      cursor: pointer !important;
    }

    & .MuiAccordionSummary-expandIconWrapper {
      color: ${theme.palette.primary.main};
    }

    & .MuiAccordionSummary-content {
      &.Mui-expanded {
        margin: 0;
      }
    }

    & .MuiCollapse-root {
      opacity: ${expanded ? 1 : 0};
      transition: all 0.25ms;

      position: relative;

      &.MuiCollapse-entered:before {
        content: '';
        position: absolute;
        height: 20px;
        top: 0;
        right: 8px;
        left: 0;
        background: linear-gradient(0deg, transparent 0%, ${theme.palette.primary.light2} 100%);
        z-index: 1;
      }

      &.MuiCollapse-entered:after {
        content: '';
        position: absolute;
        height: 20px;
        bottom: 0;
        right: 8px;
        left: 0;
        background: linear-gradient(0deg, ${theme.palette.primary.light2} 0%, transparent 100%);
        z-index: 1;
      }
    }

    & .MuiAccordionDetails-root {
      padding: 0 20px 10px;
      max-height: 400px;
      overflow-y: auto;
    }

    & ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  `;
});
