import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';

import Modal from 'components/@shared/Modal';

import { StyledButton, StyledInlineButton } from './styled';

type Props = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onLoginWithGoogle: () => void;
};

const BookServiceModal: React.FC<Props> = ({ open, loading, onClose, onLoginWithGoogle }) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState('');

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Modal open={open} onClose={onClose} maxWidth="400px" width="calc(100% - 40px)">
      <Stack gap="20px">
        <Typography variant={upMd ? 'h3' : 'h6'} color="primary.main">
          {t('authenticateModal.title')}
        </Typography>

        <Stack height="232px" justifyContent="space-between">
          <Stack gap="16px">
            <Typography variant="body2">{t('authenticateModal.subtitle')}</Typography>

            <StyledButton
              startIcon={<GoogleIcon />}
              typographyProps={{ variant: 'subtitle1' }}
              onClick={onLoginWithGoogle}
              loading={loading}
            >
              {t('authenticateModal.button')}
            </StyledButton>
          </Stack>

          <Typography variant="body2" textAlign="center">
            {t('authenticateModal.termsAndConditionsDescription')}{' '}
            <StyledInlineButton typographyProps={{ variant: 'subtitle2', color: 'accent.main' }} variant="text">
              {t('authenticateModal.termsAndConditionsButtonLabel')}
            </StyledInlineButton>
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default BookServiceModal;
