import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import Gap from 'components/@shared/Gap';
import Button from 'components/@shared/Button';
import { CONTACT_US_EMAIL } from 'constatns';

import { StyledEmailSection, StyledEmailTextContainer, StyledContainer } from './styled';

type Props = {
  className?: string;
};

const EmailSection: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleEmailClick = () => window.open(`mailto:${CONTACT_US_EMAIL}`, '_self');

  return (
    <StyledContainer className={className}>
      <Typography variant="subtitle1" color="primary.light">
        {t('footer.subtitle')}
      </Typography>
      <Gap size={upMedium ? 24 : 16} />
      <StyledEmailSection onClick={handleEmailClick}>
        <MailIcon />
        <StyledEmailTextContainer>
          <Typography variant="subtitle3" color="primary.dark3">
            {t('footer.email')}
          </Typography>
          <Typography variant="subtitle1" color="primary.light">
            {CONTACT_US_EMAIL}
          </Typography>
        </StyledEmailTextContainer>
      </StyledEmailSection>
      <Gap size={upMedium ? 24 : 20} />
      {/* @ts-ignore */}
      <Button variant="contained" size="large" component="a" href={`mailto:${CONTACT_US_EMAIL}`}>
        {t('footer.button')}
      </Button>
    </StyledContainer>
  );
};
export default EmailSection;
