import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { APP_BASE_URL } from 'constatns';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

import {
  StyledButton,
  StyledCopyIconButton,
  StyledErrorMessage,
  StyledIconButton,
  StyledInput,
  StyledListContainer,
} from './styled';
import { RESERVED_SEGMENTS } from 'navigation/constants';

type Props = {
  value: string;
  list?: string[];
  onChange?: (slug: string) => Promise<void>;
};

const Slug: React.FC<Props> = ({ value, list = [], onChange }) => {
  const { t } = useTranslation();

  const [slug, setSlug] = useState(value);
  const [slugList, setSlugList] = useState(list);

  useEffect(() => {
    setSlug(value);
  }, [value]);

  useEffect(() => {
    setSlugList(list);
  }, [list]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSlug(event.target.value);
    setError(RESERVED_SEGMENTS.includes(event.target.value) ? t('slug.reservedErrorMessage') : null);
  };

  const handleSelectSuggestion = (suggestion: string): void => {
    setSlug(suggestion);
    setSlugList([]);
  };

  const handleSave = async (): Promise<void> => {
    try {
      setLoading(true);

      await onChange?.(slug);
      return;
    } catch (e) {
      setError(t('slug.errorMessage'));
    } finally {
      setLoading(false);
    }
  };

  const isChanged = value !== slug;

  const handleCopyToClipboard = () => {
    navigator.clipboard?.writeText?.(`${APP_BASE_URL}${value}`);
  };

  const startAdornment = (
    <Box sx={{ marginRight: '8px' }}>
      <Typography variant="subtitle2" color="accent.main">
        {APP_BASE_URL}
      </Typography>
    </Box>
  );

  const endAdornment = (
    <StyledCopyIconButton show={!isChanged} onClick={handleCopyToClipboard}>
      <CopyIcon />
    </StyledCopyIconButton>
  );

  return (
    <ContainerWithBorder gap="12px">
      <Typography variant="h6">{t('slug.title')}</Typography>
      <Stack direction="row" alignItems="center">
        <StyledInput
          isChanged={isChanged}
          value={slug}
          InputProps={{
            startAdornment,
            endAdornment,
          }}
          onChange={handleChange}
        />
        <StyledIconButton show={isChanged} disabled={!!error || loading} onClick={handleSave}>
          {!loading ? <CheckIcon /> : <CircularProgress size={24} />}
        </StyledIconButton>
      </Stack>
      <StyledErrorMessage show={!!error} variant="subtitle3" color="error.main">
        {error}
      </StyledErrorMessage>
      <StyledListContainer show={!!slugList.length}>
        {slugList.map(item => (
          <StyledButton key={item} onClick={() => handleSelectSuggestion(item)}>
            {item}
          </StyledButton>
        ))}
      </StyledListContainer>
    </ContainerWithBorder>
  );
};

export default Slug;
