import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';

import { StyledContainer, StyledTypography } from './styled';

const Header: React.FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      <StyledContainer>
        <Typography variant={upSmall ? 'h1' : 'h2'} component="h1">
          {t('mainPage.title')}
        </Typography>
        <StyledTypography variant="body1">{t('mainPage.subtitle')}</StyledTypography>
      </StyledContainer>
    </Container>
  );
};

export default Header;
