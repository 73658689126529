import React from 'react';
import { ReactComponent as CloseSquareIcon } from 'assets/icons/closeSquare.svg';
import { StackProps } from '@mui/material';

import { StyledModal, StyledContainer, StyledIconButton } from './styled';

type Props = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  hideCloseIcon?: boolean;
} & StackProps;

const Modal: React.FC<Props> = ({ open, children, onClose, hideCloseIcon = false, ...props }) => (
  <StyledModal open={open} onClose={onClose}>
    <StyledContainer width="100%" maxWidth="335px" {...props}>
      {!hideCloseIcon && (
        <StyledIconButton onClick={onClose}>
          <CloseSquareIcon />
        </StyledIconButton>
      )}
      {children}
    </StyledContainer>
  </StyledModal>
);

export default Modal;
