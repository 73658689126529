import { css, styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import { CONTAINER_PADDING_X_MOBILE } from 'styles/constants';
import IconButton from 'components/@shared/IconButton';

export const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 22px ${CONTAINER_PADDING_X_MOBILE}px;
  }
`;

export const StyledHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFilterListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  overflow-y: auto;
`;

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    svg {
      color: ${theme.palette.primary.dark3};
    }
  `,
);
