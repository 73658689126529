import React, { useState } from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReservationCalendarVariant } from 'components/@common/ReservationCalendar/types';
import { getCompanyReservationList } from 'utils/getCompanyReservationList';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { useTranslation } from 'react-i18next';
import { ReservationVariantButton } from 'components/@common/ReservationCalendar';
import ReservationVariantSelect from 'components/@common/ReservationCalendar/ReservationVariantSelect';
import getRouteWithParams from 'utils/getRouteWithParams';
import { PATHS } from 'navigation/constants';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';

import {
  StyledButton,
  StyledLeftSectionContainer,
  StyledReservationCalendar,
} from './styled';
import useScheduleTab from './useScheduleTab';

const ScheduleTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyReservations, loading, handleDateRangeChange } = useScheduleTab();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [variant, setVariant] = useState(ReservationCalendarVariant.List);

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleFiltersButtonClick = () => setFiltersModalOpen(true);
  const handleVariantChange = (value: ReservationCalendarVariant) => {
    setVariant(value);
    if (value === ReservationCalendarVariant.List) {
      handleDateRangeChange({ from: dayjs().startOf('month'), to: dayjs().endOf('month') });
    } else {
      handleDateRangeChange({ from: dayjs().startOf('week'), to: dayjs().endOf('week') });
    }
  };

  const handleReservationClick = (reservationId: number) =>
    navigate(getRouteWithParams(PATHS.COMPANY_RESERVATION_DETAILS, { reservationId: reservationId.toString() }), {
      state: {
        reservation: companyReservations.find(item => item.id === reservationId),
      },
    });

  return (
    <>
      {!upMedium && (
        <Stack direction="row" gap="8px" justifyContent="space-between" mt="20px">
          <StyledButton onClick={handleFiltersButtonClick} disableRipple>
            <FilterIcon />
            <Typography variant="subtitle1">{t('filters')}</Typography>
          </StyledButton>
          <ReservationVariantButton variant={variant} onChange={handleVariantChange} />
        </Stack>
      )}
      <Stack direction="row" gap="20px">
        {upMedium && (
          <Stack flex="0 0 305px">
            <StyledLeftSectionContainer>
              <ContainerWithBorder gap="20px">
                <Typography variant="h4">{t('calendarPage.scheduleVariantTitle')}</Typography>
                <ReservationVariantSelect variant={variant} onChange={handleVariantChange} />
              </ContainerWithBorder>
            </StyledLeftSectionContainer>
          </Stack>
        )}
        <StyledReservationCalendar
          reservationList={getCompanyReservationList(companyReservations)}
          onDateRangeChange={handleDateRangeChange}
          onReservationClick={handleReservationClick}
          variant={variant}
          loading={loading}
        />
      </Stack>
    </>
  );
};

export default ScheduleTab;
