import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Tabs from 'components/@shared/Tabs';
import { PATHS } from 'navigation/constants';

import InfoTab from './InfoTab';
import ReservationsTab from './ReservationsTab';
import useClientCabinetPage from './useClientCabinetPage';

const ClientCabinetPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CLIENT_CABINET_INFO_TAB = useMatch(PATHS.CLIENT_CABINET_INFO_TAB);
  const CLIENT_CABINET_BOOKING_SERVICES_TAB = useMatch(PATHS.CLIENT_CABINET_RESERVATIONS_TAB);

  useClientCabinetPage();

  const activeTabIndex = CLIENT_CABINET_INFO_TAB ? 0 : 1;

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const tabList = [
    {
      label: t('clientCabinetPage.infoTab'),
      content: <InfoTab />,
    },
    {
      label: t('clientCabinetPage.reservationsTab'),
      content: <ReservationsTab />,
    },
  ];

  const handleTabChange = () => {
    if (CLIENT_CABINET_INFO_TAB) {
      navigate(PATHS.CLIENT_CABINET_RESERVATIONS_TAB);
    }

    if (CLIENT_CABINET_BOOKING_SERVICES_TAB) {
      navigate(PATHS.CLIENT_CABINET_INFO_TAB);
    }
  };

  return (
    <Container>
      <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
        {t('clientCabinetPage.title')}
      </Typography>
      <Gap size={upMedium ? 56 : 32} />
      <Tabs tabList={tabList} activeTabIndex={activeTabIndex} onTabChange={handleTabChange} />
    </Container>
  );
};

export default ClientCabinetPage;
