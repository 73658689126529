import { Company } from 'types';

import { ACTION } from './constants';

export const setCompany = (payload: Company) => ({
  type: ACTION.SET_COMPANY,
  payload,
});

export const resetCompany = () => ({
  type: ACTION.RESET_COMPANY,
});

export const mergeCompany = (payload: Partial<Company>) => ({
  type: ACTION.MERGE_COMPANY,
  payload,
});
