import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandableSelector from 'components/@shared/ExpandableSelect';
import ExpandableMultipleSelect from 'components/@shared/ExpandableMultipleSelect';
import { ExpandableMultipleSelectSkeleton } from 'components/@shared/ExpandableMultipleSelect/ExpandableMultipleSelectSkeleton';
import { CategoryItem } from 'types';

import { StyledContainer, StyledFilterListContainer } from './styled';
import FilterPriceSection from '../FilterPriceSection';
import useFilters from '../useFilters';
import { Price } from '../types';

const FiltersSection: React.FC = () => {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState<Array<{ id: string; localizedName: string }>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [list, setList] = useState<Array<{ id: string; localizedName: string }>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);

  const { categoryList, selectedCategory, maxPrice, price, onChange } = useFilters();

  useEffect(() => {
    // TODO: add logic when BE is ready
  }, [selectedCategory]);

  const onPriceChange = (price: Price) => onChange({ price });
  const onCategoryChange = (category: CategoryItem | null) => onChange({ category });

  return (
    <StyledContainer>
      <Typography variant="h4" color="primary.main">
        {t('servicesPage.filtersTitle')}
      </Typography>
      <StyledFilterListContainer>
        <Typography variant="subtitle1" color="primary.main">
          {t('servicesPage.filtersSubtitle')}
        </Typography>
        {/* TODO get rid of it */}
        {/* @ts-ignore */}
        <ExpandableSelector itemList={categoryList} value={selectedCategory} isFilled onChange={onCategoryChange} />
        {loading && <ExpandableMultipleSelectSkeleton />}
        {!loading && !!list.length && (
          <ExpandableMultipleSelect
            title="Послуги"
            itemList={list}
            selectedItemList={selectedList}
            onChange={setSelectedList}
          />
        )}
        <FilterPriceSection min={0} max={maxPrice} value={price} initialExpanded onChange={onPriceChange} />
      </StyledFilterListContainer>
    </StyledContainer>
  );
};

export default FiltersSection;
