import { StateType } from './types';

export enum ACTION {
  SET_MASTER = 'SET_MASTER',
  RESET_MASTER = 'RESET_MASTER',
  MERGE_MASTER = 'MERGE_MASTER',

  SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY',
  RESET_CURRENT_CATEGORY = 'RESET_CURRENT_CATEGORY',
  SET_CURRENT_SERVICE = 'SET_CURRENT_SERVICE',
  RESET_CURRENT_SERVICE = 'RESET_CURRENT_SERVICE',

  RESET_REQUIRED_ATTRIBUTES = 'RESET_REQUIRED_ATTRIBUTES',
  MERGE_REQUIRED_ATTRIBUTES = 'MERGE_REQUIRED_ATTRIBUTES',
}

export const initialState: StateType = {
  master: {
    categories: [],
  },
  currentCategory: null,
  currentService: null,
  requiredAttributes: {},
};
