import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'localizations';
import 'dayjs/locale/uk';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePickerProps } from '@mui/x-date-pickers/DesktopTimePicker';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { useTheme } from '@mui/material';
import useResize from 'hooks/useResize';

import Button from '../Button';
import IconButton from '../IconButton';
import { StyledActionBarContainer, StyledDesktopTimePicker } from './styled';
import { useTranslation } from 'react-i18next';

const TimePicker: React.FC<DesktopTimePickerProps<Dayjs | null>> = ({ value, slots, slotProps, ...props }) => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const windowSizes = useResize();

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, windowSizes.height, windowSizes.width]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const ActionBar = () => (
    <StyledActionBarContainer>
      <Button
        variant="contained"
        sx={{ width: '100%', borderRadius: '12px 12px 0 0 !important' }}
        onClick={() => setOpen(false)}
      >
        OK
      </Button>
    </StyledActionBarContainer>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const OpenPickerButton = ({ disabled }: { disabled?: boolean }) => (
    <IconButton onClick={() => setOpen(true)} disabled={disabled}>
      <ClockIcon color="accent.main" />
    </IconButton>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <StyledDesktopTimePicker
        value={value || dayjs().set('hour', 0).set('minute', 0).set('second', 0)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        ref={ref}
        ampm={false}
        // @ts-ignore
        localeText={{ fieldHoursPlaceholder: () => '00', fieldMinutesPlaceholder: () => '00' }}
        format={`HH [${t('hoursShort')}] mm [${t('minutesShort')}]`}
        // @ts-ignore
        slots={{
          actionBar: ActionBar,
          openPickerButton: OpenPickerButton,
          ...slots,
        }}
        slotProps={{
          popper: {
            sx: {
              '&.MuiPickersPopper-root': {
                marginY: '8px !important',
                ...(width && { width: `${width}px` }),
              },

              '& .MuiPaper-root': {
                border: `1px solid ${theme.palette.accent.main}`,
                overflow: 'hidden',
                borderRadius: '12px',
                boxShadow: 'none',
              },
              '& .MuiMultiSectionDigitalClock-root': {
                borderBottom: 'none',
              },
              '& .MuiList-root': {
                width: '50%',
                scrollbarGutter: 'stable',
              },
              '& .MuiMenuItem-root': {
                color: theme.palette.primary.main,
                margin: '0 auto',
                ...theme.typography.subtitle1,
                '&:hover': {
                  background: 'none',
                  color: theme.palette.accent.light,
                },
              },
              '& .MuiMenuItem-root:hover': {
                background: 'none',
                color: theme.palette.accent.light,
              },
              '& .MuiMenuItem-root:active': {
                background: 'none',
                color: theme.palette.accent.dark,
              },
              '& .MuiMenuItem-root:focus-visible': {
                background: 'none',
                color: theme.palette.accent.dark,
              },
              '& .MuiMenuItem-root.Mui-selected': {
                background: 'none',
                color: theme.palette.accent.main,
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                background: 'none',
                color: theme.palette.accent.light,
              },
              '& .MuiMenuItem-root.Mui-disabled': {
                background: 'none !important',
                border: 'none !important',
                color: `${theme.palette.primary.dark2} !important`,
              },
              '& .MuiDialogActions-root': {
                padding: '0',
              },
            },
          },
          // @ts-ignore
          openPickerButton: {
            disableRipple: true,
          },
          ...slotProps,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
