import React, { useEffect, useState } from 'react';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { StyledAccordion } from './styled';
import TruncatedText from '../TruncatedText';
import Gap from '../Gap';
import Checkbox from '../Checkbox';

export type ListItem = {
  id: string;
  localizedName: string;
};

type Props = {
  itemList: ListItem[];
  selectedItemList: ListItem[];
  title?: string;
  className?: string;
  onChange?: (selectedItemList: ListItem[]) => void;
};

const ExpandableMultipleSelect: React.FC<Props> = ({ itemList, selectedItemList, className, title, onChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean | undefined>>(
    selectedItemList.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: true,
      }),
      {},
    ),
  );

  const handleChange = (item: ListItem) => {
    setSelectedItems(prev => ({
      ...prev,
      [item.id]: !prev[item?.id],
    }));
  };

  useEffect(() => {
    if (onChange) {
      onChange(itemList.filter(item => selectedItems[item.id]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <StyledAccordion className={className} expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TruncatedText variant="subtitle2" color="primary.main">
          {title}
        </TruncatedText>
      </AccordionSummary>
      <AccordionDetails>
        <Gap size={16} />
        <ul>
          {itemList.map(item => (
            <li key={item.id}>
              <Checkbox
                label={item.localizedName}
                checked={!!selectedItems[item.id]}
                onClick={() => handleChange(item)}
              />
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default React.memo(ExpandableMultipleSelect);
