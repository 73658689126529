import React from 'react';
import { StyledSwitch, StyledCircularProgress, StyledCircleIcon } from './styled';

type Props = {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  loading?: boolean;
  disabled?: boolean;
};

const Switch: React.FC<Props> = ({ loading, checked, disabled, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e, e.target.checked);

  return (
    <StyledSwitch>
      <input type="checkbox" checked={checked} onChange={handleChange} disabled={disabled} />
      <div className="slider">
        <div className="iconContainer">
          <StyledCircularProgress size={80} loading={loading} />
          <StyledCircleIcon loading={loading} />
        </div>
      </div>
    </StyledSwitch>
  );
};
export default Switch;
