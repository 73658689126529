import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';
import { PATHS } from 'navigation/constants';
import Button from 'components/@shared/Button';
import ServiceCard, { ServiceCardSkeleton } from 'components/@common/ServiceCard';
import { BookServiceModal, useBookServiceModal } from 'components/@common/BookButton';
import generateIncrementalArray from 'utils/generateIncrementalArray';
import getRouteWithParams from 'utils/getRouteWithParams';
import mapServiceModelToViewModel from 'utils/mapServiceModelToViewModel';

import { StyledButtonContainer, StyledContainer, StyledList } from './styled';
import usePopular from './usePopular';

const Popular: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { popularList, loading, count } = usePopular();
  const { onBook, ...bookServiceModalProps } = useBookServiceModal();

  return (
    <Container>
      <StyledContainer>
        <Typography variant="h2" color="primary.main">
          {t('mainPage.popularTitle')}
        </Typography>
        <StyledList>
          {loading
            ? generateIncrementalArray(count).map(item => <ServiceCardSkeleton key={item} withBorder />)
            : popularList?.map(data => (
                <ServiceCard
                  key={data.id}
                  {...mapServiceModelToViewModel(data, undefined, true)}
                  withBorder
                  onClick={serviceId =>
                    navigate(getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: serviceId.toString() }))
                  }
                  onBookService={onBook}
                />
              ))}
        </StyledList>
        <div>
          <StyledButtonContainer>
            <Button variant="contained" size="large" onClick={() => navigate(PATHS.SERVICES_PAGE)}>
              {t('mainPage.allServicesButtonLabel')}
            </Button>
          </StyledButtonContainer>
        </div>
      </StyledContainer>
      <BookServiceModal {...bookServiceModalProps} />
    </Container>
  );
};

export default Popular;
