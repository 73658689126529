import { useTranslation } from 'react-i18next';
import { PATHS } from 'navigation/constants';

export const useBreadcrumbs = () => {
  const { t } = useTranslation();

  const getBreadcrumbsSegments = () => [{ path: PATHS.MAIN_PAGE, label: t('breadcrumbs.home') }];

  return {
    breadcrumbsSegments: getBreadcrumbsSegments(),
    currentPageLabel: t('breadcrumbs.for-companies'),
  };
};
