import React from 'react';
import { TypographyProps } from '@mui/material/Typography';

import { StyledTypography } from './styled';

type Props = TypographyProps & {
  rowsLimit?: number;
};

const TruncatedText: React.FC<Props> = ({ children, rowsLimit = 1, ...props }) => (
  <StyledTypography rowsLimit={rowsLimit} {...props}>
    {children}
  </StyledTypography>
);

export default TruncatedText;
