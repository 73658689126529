import { css, styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(
  ({ theme }) => css`
    & .MuiTabs-flexContainer {
      justify-content: space-between;
      gap: 4px;
      margin-bottom: 20px;
    }

    ${theme.breakpoints.up('md')} {
      margin-bottom: 40px;
    }

    & .MuiTab-root {
      background: ${theme.palette.primary.light2};
      max-width: none;
      min-height: auto;
      border-radius: 8px;
      padding: 12px 16px;
      font-family: ${theme.typography.subtitle2.fontFamily};
      font-size: ${theme.typography.subtitle2.fontSize};
      font-style: ${theme.typography.subtitle2.fontStyle};
      font-weight: ${theme.typography.subtitle2.fontWeight};
      line-height: ${theme.typography.subtitle2.lineHeight};
      text-transform: none;
      color: ${theme.palette.primary.main};

      transition: all 0.3s;

      &.Mui-selected {
        background: ${theme.palette.accent.main};
        color: ${theme.palette.primary.light};

        &:hover {
          color: ${theme.palette.primary.light};
        }

        &:active {
          color: ${theme.palette.primary.light};
        }
      }

      &:hover {
        color: ${theme.palette.accent.light};
      }

      &:active {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);
