import { css, styled } from '@mui/material/styles';
import { SwipeableDrawer } from '@mui/material';

export const StyledDrawer = styled(SwipeableDrawer)(
  ({ theme }) => css`
    & .MuiPaper-root {
      padding: 20px 0;
      border-top: 1px solid ${theme.palette.primary.light2};
    }
  `,
);
