import { css, styled } from '@mui/material/styles';
import { Button, Stack, Typography } from '@mui/material';
import Input from 'components/@shared/Input';
import IconButton from 'components/@shared/IconButton';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledInput = styled(
  Input,
  preventForwardProps(['isChanged']),
)<{ isChanged: boolean }>(
  ({ isChanged }) => css`
    flex: ${isChanged ? 'calc(100% - 56px)' : '100%'} 0 0;

    input {
      height: 24px;
    }

    transition: all 0.3s;
  `,
);

export const StyledIconButton = styled(
  IconButton,
  preventForwardProps(['show']),
)<{ show: boolean }>(
  ({ theme, show }) => css`
    width: ${show ? '48px' : '0px'};
    height: ${show ? '48px' : '0px'};
    margin-left: ${show ? '8px' : '0px'};
    background: ${theme.palette.accent.main};
    opacity: ${show ? 1 : 0};

    &:hover {
      background-color: ${theme.palette.accent.light};
    }

    &:active {
      background-color: ${theme.palette.accent.dark};
    }

    &.Mui-disabled {
      background-color: ${theme.palette.primary.dark3};
    }

    svg {
      width: ${show ? '24px' : '0px'};
      height: ${show ? '24px' : '0px'};
      color: ${theme.palette.primary.light};

      transition: all 0.3s;
    }

    transition: all 0.3s;
  `,
);

export const StyledCopyIconButton = styled(
  IconButton,
  preventForwardProps(['show']),
)<{ show: boolean }>(
  ({ show }) => css`
    width: ${show ? '16px' : '0px'};
    height: ${show ? '16px' : '0px'};
    opacity: ${show ? 1 : 0};

    svg {
      width: ${show ? '16px' : '0px'};
      height: ${show ? '16px' : '0px'};

      transition: width 0.3s;
    }

    transition: opacity 0.3s;
  `,
);

export const StyledErrorMessage = styled(
  Typography,
  preventForwardProps(['show']),
)<{ show: boolean }>(
  ({ show }) => css`
    max-height: ${show ? '60px' : '0px'};
    opacity: ${show ? 1 : 0};

    height: auto;
    overflow: hidden;

    transition: all 0.3s;
  `,
);

export const StyledListContainer = styled(
  Stack,
  preventForwardProps(['show']),
)<{ show: boolean }>(
  ({ show }) => css`
    height: ${show ? '40px' : '0px'};

    transition: all 0.3s;

    gap: 8px;
    flex-direction: row;
    flex: 0 0 auto;
    overflow-x: scroll;
    -ms-overflow-style: none; // IE
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
      display: none;
      height: 0; // Chrome, Safari
    }
  `,
);

export const StyledButton = styled(
  Button,
  preventForwardProps(['selected']),
)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    white-space: nowrap;
    min-width: auto;
    padding: 12px 16px;
    background: ${selected ? theme.palette.accent.main : theme.palette.primary.light2};
    border-radius: 12px;
    box-shadow: unset;

    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;
    color: ${selected ? theme.palette.primary.light : theme.palette.primary.main};

    &:hover {
      color: ${selected ? theme.palette.primary.light : theme.palette.accent.light};
      background: ${selected ? theme.palette.accent.main : theme.palette.primary.light2};
    }

    &:active {
      color: ${selected ? theme.palette.primary.light : theme.palette.accent.dark};
      background: ${selected ? theme.palette.accent.main : theme.palette.primary.light2};
    }
  `,
);
