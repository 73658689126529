import { OutputServiceGroupDto } from 'types';

const getServiceGroupData = (group: OutputServiceGroupDto) => {
  const slug = group.company?.slug || group.master?.slug;
  const imgUrl = group.company?.profileImageUri || group.master?.profileImageUri;
  const name = group.company?.name || group.master?.contactName;
  const count = group.servicesCount;

  return {
    slug,
    imgUrl,
    name,
    count,
  };
};

export default getServiceGroupData;
