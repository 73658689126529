import { getApiErrorMessage } from 'utils/getApiErrorMessage';
import { BannerData, BannerType, CityItem, Credentials } from 'types';
import { ApolloError } from '@apollo/client';
import { AccountTypeEnum } from 'constatns';
import { ACTION } from './constants';

export const setAccount = (payload: AccountTypeEnum | null) => ({
  type: ACTION.SET_ACCOUNT,
  payload,
});

export const resetAccount = () => ({
  type: ACTION.SET_ACCOUNT,
});

export const setBanner = (payload: BannerData) => ({
  type: ACTION.SET_BANNER,
  payload,
});

export function setErrorBanner(error: ApolloError): {payload: BannerData, type: ACTION};
export function setErrorBanner(message: string): {payload: BannerData, type: ACTION};
export function setErrorBanner(error: ApolloError | string): {payload: BannerData, type: ACTION} {
  return setBanner({
    message: typeof error === 'string' ? error : getApiErrorMessage(error),
    type: BannerType.error,
  });
}

export const setSuccessBanner = (message: string) => setBanner({ message, type: BannerType.success });

export const resetBanner = () => ({
  type: ACTION.RESET_BANNER,
});

export const setFilterCityList = (payload: CityItem[]) => ({
  type: ACTION.SET_FILTER_CITY_LIST,
  payload,
});

export const setDefaultFilterCityList = (payload: CityItem[]) => ({
  type: ACTION.SET_FILTER_CITY_LIST,
  payload,
});

export const setAuthCredentials = (payload: Credentials) => ({
  type: ACTION.SET_AUTH_CREDENTIALS,
  payload,
});

export const resetAuthCredentials = () => ({
  type: ACTION.RESET_AUTH_CREDENTIALS,
});

export const setNewReservationsCount = (payload: number | null) => ({
  type: ACTION.SET_NEW_RESERVATIONS_COUNT,
  payload,
});
