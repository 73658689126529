import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Input from 'components/@shared/Input';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.accent.main};
    border-radius: 16px;
    width: 100%;
    padding: 20px 0;

    ${theme.breakpoints.up('sm')} {
      padding: 56px 0;
    }
  `,
);

export const StyledSearchFieldContainer = styled(Box)`
  position: relative;
`;

export const StyledInput = styled(
  Input,
  preventForwardProps(['buttonWidth']),
)<{ buttonWidth: number }>(
  ({ theme, buttonWidth }) => css`
    & .MuiInputBase-root {
      padding: 0 0 0 14px;
    }

    & .MuiInputBase-input {
      height: 56px;
      padding-right: ${buttonWidth + 16}px;
      padding-left: 12px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;

      ${theme.breakpoints.up('sm')} {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
      }
    }
  `,
);

export const StyledCategoriesContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-top: 22px;

    ${theme.breakpoints.up('md')} {
      margin-top: 32px;
      gap: 18px;
    }
  `,
);

export const StyledCategoryList = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
