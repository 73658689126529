import { OutputServiceDto } from 'types/service';
import convertTimeSpanToDayjs from 'utils/convertTimeSpanToDayjs';
import { ServiceCardProps } from './ServiceCard';
import mapAttributesGroupIdToIcon from 'utils/mapAttributesGroupIdToIcon';
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg';
import i18n from 'localizations';

const mapServiceModelToViewModel = (service: OutputServiceDto): Omit<ServiceCardProps, 'onDelete' | 'onClick'> => {
  const attributeList = [];

  const qualification = service.category.attributeGroups.find(item => item.isQualification)?.attribute.localizedName;

  attributeList.push({
    startAdornment: <TimeIcon />,
    value: convertTimeSpanToDayjs(service.duration).utc().format(
      `H [${i18n.t('hoursShort')}] mm [${i18n.t('minutesShort')}]`,
    ),
  });

  service.category.attributeGroups.forEach(item => {
    const Icon = mapAttributesGroupIdToIcon(item.id);
    if (!item.isQualification) {
      attributeList.push({
        startAdornment: <Icon />,
        value: item.attribute.localizedName,
      });
    }
  });

  return {
    name: service.name,
    price: service.price,
    masterDetails: service.master,
    qualification,
    attributeList,
    photos: service.photos || [],
  };
};

export default mapServiceModelToViewModel;
