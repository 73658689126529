export const generateUniqColors = (str: string): [string, string] => {
  // Simple hashing algorithm to generate consistent colors for the same string
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Function to convert an integer to a hexadecimal color code
  const intToRGB = (i: number) => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return `#${'00000'.substring(0, 6 - c.length)}${c}`;
  };

  // Generate a base color based on the hash
  const baseColor = intToRGB(hash);

  // Adjust the hue of the base color to get a contrasting color
  const hue = parseInt(baseColor.substr(1, 2), 16);
  const contrastingHue = (hue + 180) % 360; // Adjust the hue by 180 degrees
  const contrastingColor = `${intToRGB(contrastingHue << 16)}`;

  return [baseColor, contrastingColor];
};
