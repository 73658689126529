import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactComponent as ImgPlaceholderIconWhiteBg } from 'assets/icons/imgPlaceholderWhiteBg.svg';
import Button from 'components/@shared/Button';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 12px;
    align-items: start;
    width: 100%;

    background: ${theme.palette.primary.light2};
    border-radius: 20px;

    padding: 12px;

    ${theme.breakpoints.up('md')} {
      padding: 24px;
    }
  `,
);

export const StyledImgContainer = styled(Box)`
  position: relative;
  width: 100%;
  min-width: 120px;
  flex: 0 0 34%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const StyledImg = styled('img')`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
`;

export const StyledImgPlaceholder = styled(ImgPlaceholderIconWhiteBg)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.accent.main};
    width: fit-content;
  `,
);
