export enum CategoryEnum {
  Art = 'ART',
  Auto = 'AUTO',
  Beauty = 'BEAUTY',
  Consultations = 'CONSULTATIONS',
  Medicine = 'MEDICINE',
  Pets = 'PETS',
  Sport = 'SPORT',
  Learning = 'LEARNING',
}

export enum AttributesGroupId {
  BeautyGender = 'BEAUTY_GENDER',
  BeautySubcategory = 'BEAUTY_SUBCATEGORY',
  BeautyExperienceLevel = 'BEAUTY_EXPERIENCE_LEVEL',
  MedicineSubcategory = 'MEDICINE_SUBCATEGORY',
  MedicineReceptionType = 'MEDICINE_RECEPTION_TYPE',
  MedicineExperienceLevel = 'MEDICINE_EXPERIENCE_LEVEL',
  ConsultationSubcategory = 'CONSULTATIONS_SUBCATEGORY',
  ConsultationExperienceLevel = 'CONSULTATIONS_EXPERIENCE_LEVEL',
  EducationSubcategory = 'EDUCATION_SUBCATEGORY',
  EducationExperienceLevel = 'EDUCATION_EXPERIENCE_LEVEL',
}
