import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactComponent as ImgPlaceholderIcon } from 'assets/icons/imgPlaceholder.svg';

export const StyledImgContainer = styled(Box)`
  position: relative;
  width: 100%;
  min-width: 120px;
  flex: 0 0 34%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const StyledImg = styled('img')`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
`;

export const StyledImgPlaceholder = styled(ImgPlaceholderIcon)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
