import { OutputCompanyReservationDto, OutputMasterReservationDto } from 'types/reservation';
import i18n from 'localizations';
import { CardAttribute } from 'components/@shared/Card';
import Avatar from 'components/@common/Avatar';

import { ReservationCardProps } from 'components/@common/ReservationCard';
import dayjs from 'dayjs';
import convertTimeSpanToDayjs from './convertTimeSpanToDayjs';
import getRouteWithParams from './getRouteWithParams';
import { PATHS } from '../navigation/constants';

const mapReservationModelToViewModel = (
  reservation: OutputMasterReservationDto | OutputCompanyReservationDto,
): Omit<ReservationCardProps, 'onConfirm' | 'onClick'> => {
  const attributeList: CardAttribute[] = [];

  const masterData = (reservation as OutputCompanyReservationDto).master as
    | OutputCompanyReservationDto['master']
    | undefined;

  if (masterData?.contactName) {
    attributeList.push({
      key: i18n.t('master'),
      value: masterData.contactName,
      startAdornment: <Avatar {...masterData} size="24px" />,
    });
  }

  attributeList.push({
    key: i18n.t('service'),
    value: reservation.service.name,
    url: getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: reservation.service.id.toString() }),
  });

  attributeList.push({
    key: i18n.t('date'),
    value: dayjs(reservation.date).locale(i18n.language).format('DD MMMM (dddd)'),
  });

  attributeList.push({
    key: i18n.t('time'),
    value: `${convertTimeSpanToDayjs(reservation.timeSlot.from).format('HH:mm')} - ${convertTimeSpanToDayjs(
      reservation.timeSlot.to,
    ).format('HH:mm')}`,
  });

  return {
    id: reservation.id,
    clientName: reservation.client.contactName || '',
    clientAvatar: <Avatar {...reservation.client} />,
    price: reservation.service.price,
    phone: reservation.client.phone || '',
    attributeList,
  };
};

export default mapReservationModelToViewModel;
