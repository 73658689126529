import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import TruncatedText from 'components/@shared/TruncatedText';
import Card from 'components/@shared/Card';
import Button from 'components/@shared/Button';

import ReservationCardSkeleton from './Skeleton';
import PhoneContact from '../ContactsSection/PhoneContact';

export type ReservationCardProps = {
  id: number;
  clientName: string;
  clientAvatar: React.ReactNode;
  attributeList: Array<{ key: string; value: string }>;
  price?: number;
  phone: string;
  className?: string;
  onConfirm: () => void;
  onClick: () => void;
};

const ReservationCard: React.FC<ReservationCardProps> = ({
  id,
  clientName,
  clientAvatar,
  attributeList,
  price,
  phone,
  className,
  onConfirm,
  onClick,
}) => {
  const { t } = useTranslation();

  const priceText = price ? t('price', { val: price }) : t('priceFree');

  const handlePhoneClick = () => {
    window.open(`tel:${phone}`, '_self');
  };

  return (
    <Card
      onClick={() => {
        onClick();
      }}
      id={id}
      header={
        <Stack direction="row" gap="8px" alignItems="center">
          {clientAvatar}
          <TruncatedText variant="h6" rowsLimit={3}>
            {clientName}
          </TruncatedText>
        </Stack>
      }
      withBorder
      attributeList={attributeList}
      className={className}
      cardActions={
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <TruncatedText variant={priceText.length < 10 ? 'h4' : 'h5'} color="accent.main" component="h4">
              {priceText}
            </TruncatedText>
          </Stack>
          <PhoneContact
            value={phone}
            showLogic={false}
            onClick={e => {
              e.stopPropagation();
              handlePhoneClick();
            }}
          />
          <Button
            variant="contained"
            onClick={e => {
              e.stopPropagation();
              onConfirm();
            }}
          >
            {t('confirm')}
          </Button>
        </>
      }
    />
  );
};

export default ReservationCard;

export { ReservationCardSkeleton };
