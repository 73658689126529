import { CategoryEnum } from '../constatns';

export const prepareCategoriesData = (
  list: Array<{ id: string; attributeGroups: Array<{ id: string; attribute: { id: string } }> }>,
) =>
  list.map(({ id, attributeGroups }) => ({
    id: id as CategoryEnum,
    attributeGroups: attributeGroups.map(({ id: attributeGroupId, attribute }) => ({
      id: attributeGroupId,
      attribute: { id: attribute.id },
    })),
  }));
