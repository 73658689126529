import React from 'react';
import { AppProvider } from 'contexts/app';
import { Banner } from 'components/@common/Banner';
import ThemeProvider from 'styles/themes/context/ThemeProvider';
import { THEME_NAMES } from 'styles/themes';
import './localizations';
import './App.css';
import Navigation from './navigation';

const initialLoader = document.getElementById('initialLoader');

if (initialLoader) {
  initialLoader.classList.add('hidden');
}

setTimeout(() => {
  initialLoader?.remove();
}, 1000);

function App() {
  return (
    <AppProvider>
      <ThemeProvider defaultTheme={THEME_NAMES.main}>
        <Banner />
        <Navigation />
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;
