import { OutputMasterServiceDto } from 'types';
import { AllServicesSectionProps, ServicesBySubcategory, Subcategories } from './AllServicesSection';

const mapServiceListModelToAllServicesSectionViewModel = (
  list: OutputMasterServiceDto[],
): Omit<AllServicesSectionProps, 'onOpenService' | 'onBookService' | 'onMasterClick'> => {
  const servicesBySubcategory = list.reduce<ServicesBySubcategory>((acc, item) => {
    const subcategory = item.category.attributeGroups.find(a => a.isSubcategory);
    const qualification = item.category.attributeGroups.find(a => a.isQualification);
    const { contactName } = item.master;

    if (!subcategory || !qualification || !contactName) {
      return acc;
    }

    const service = {
      id: item.id,
      name: item.name,
      duration: item.duration,
      price: item.price,
    };

    if (acc[subcategory.attribute.id]) {
      const masterIndex = acc[subcategory.attribute.id].findIndex(m => item.master.id === m.id);

      if (masterIndex === -1) {
        return {
          ...acc,
          [subcategory.attribute.id]: [
            ...acc[subcategory.attribute.id],
            {
              id: item.master.id,
              contactName,
              profileImageUri: item.master.profileImageUri,
              qualification: qualification.attribute.localizedName,
              servicesByQualificationList: [
                {
                  id: qualification.attribute.id,
                  qualification: qualification.attribute.localizedName,
                  serviceList: [service],
                },
              ],
            },
          ],
        };
      }
      return {
        ...acc,
        [subcategory.attribute.id]: acc[subcategory.attribute.id].map(m => {
          if (item.master.id === m.id) {
            const qualificationIndex = m.servicesByQualificationList.findIndex(
              q => q.id === qualification.attribute.id,
            );

            if (qualificationIndex === -1) {
              return {
                ...m,
                servicesByQualificationList: [
                  ...m.servicesByQualificationList,
                  {
                    id: qualification.attribute.id,
                    qualification: qualification.attribute.localizedName,
                    serviceList: [service],
                  },
                ],
              };
            }
            return {
              ...m,
              servicesByQualificationList: m.servicesByQualificationList.map(q => {
                if (q.id === qualification.attribute.id) {
                  return {
                    ...q,
                    serviceList: [...q.serviceList, service],
                  };
                }
                return q;
              }),
            };
          }
          return m;
        }),
      };
    }
    return {
      ...acc,
      [subcategory.attribute.id]: [
        {
          id: item.master.id,
          contactName,
          profileImageUri: item.master.profileImageUri,
          qualification: qualification.attribute.localizedName,
          servicesByQualificationList: [
            {
              id: qualification.attribute.id,
              qualification: qualification.attribute.localizedName,
              serviceList: [service],
            },
          ],
        },
      ],
    };
  }, {});

  const subcategories = list.reduce<Subcategories>((acc, item) => {
    const subcategory = item.category.attributeGroups.find(a => a.isSubcategory);

    if (!subcategory) {
      return acc;
    }

    if (acc[subcategory.attribute.id]) {
      return acc;
    }
    return {
      ...acc,
      [subcategory.attribute.id]: subcategory.attribute.localizedName,
    };
  }, {});

  return { servicesBySubcategory, subcategories };
};

export default mapServiceListModelToAllServicesSectionViewModel;
