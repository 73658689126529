import getCategoryWithLocalId from 'utils/getCategoryWithLocalId';

import { ACTION, initialState } from './constants';
import { ActionType, StateType } from './types';

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ACTION.SET_MASTER:
      return {
        ...state,
        master: {
          ...action.payload,
          categories: action.payload.categories.map(i => getCategoryWithLocalId(i)),
          ...(action.payload.profileImageUri && {
            profileImageUri: `${action.payload.profileImageUri}?${Date.now()}`,
          }),
        },
      };

    case ACTION.RESET_MASTER:
      return {
        ...state,
        master: initialState.master,
      };

    case ACTION.MERGE_MASTER:
      // eslint-disable-next-line no-case-declarations
      const { categories, profileImageUri, ...master } = action.payload;

      return {
        ...state,
        master: {
          ...state.master,
          ...master,
          ...(profileImageUri && {
            profileImageUri: `${action.payload.profileImageUri}?${Date.now()}`,
          }),
          ...(categories && { categories: categories.map(i => getCategoryWithLocalId(i)) }),
        },
      };

    case ACTION.SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };

    case ACTION.RESET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: null,
      };

    case ACTION.SET_CURRENT_SERVICE:
      return {
        ...state,
        currentService: action.payload,
      };

    case ACTION.RESET_CURRENT_SERVICE:
      return {
        ...state,
        currentService: null,
      };

    case ACTION.MERGE_REQUIRED_ATTRIBUTES:
      return {
        ...state,
        requiredAttributes: {
          ...state.requiredAttributes,
          ...action.payload,
        },
      };

    case ACTION.RESET_REQUIRED_ATTRIBUTES:
      return {
        ...state,
        requiredAttributes: initialState.requiredAttributes,
      };

    default:
      return state;
  }
};
