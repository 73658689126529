import { Schedule } from 'types/common';
import { DayOfWeekEnum } from 'constatns';
import convertTimeSpanToDayjs from './convertTimeSpanToDayjs';
import i18n from 'localizations';

export const getScheduleChipList = (schedule: Schedule): string[] => {
  const list = [];
  if (schedule[DayOfWeekEnum.Mon]) {
    list.push(
      `${i18n.t('dayOfWeek.mon')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Mon]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Mon]?.to,
      ).format('HH:mm')}`,
    );
  }

  if (schedule[DayOfWeekEnum.Tue]) {
    list.push(
      `${i18n.t('dayOfWeek.tue')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Tue]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Tue]?.to,
      ).format('HH:mm')}`,
    );
  }

  if (schedule[DayOfWeekEnum.Wed]) {
    list.push(
      `${i18n.t('dayOfWeek.wed')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Wed]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Wed]?.to,
      ).format('HH:mm')}`,
    );
  }

  if (schedule[DayOfWeekEnum.Thu]) {
    list.push(
      `${i18n.t('dayOfWeek.thu')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Thu]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Thu]?.to,
      ).format('HH:mm')}`,
    );
  }

  if (schedule[DayOfWeekEnum.Fri]) {
    list.push(
      `${i18n.t('dayOfWeek.fri')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Fri]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Fri]?.to,
      ).format('HH:mm')}`,
    );
  }

  if (schedule[DayOfWeekEnum.Sat]) {
    list.push(
      `${i18n.t('dayOfWeek.sat')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Sat]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Sat]?.to,
      ).format('HH:mm')}`,
    );
  }

  if (schedule[DayOfWeekEnum.Sun]) {
    list.push(
      `${i18n.t('dayOfWeek.sun')} - ${i18n.t('masterCabinetPage.from')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Sun]?.from,
      ).format('HH:mm')} ${i18n.t('masterCabinetPage.to')} ${convertTimeSpanToDayjs(
        schedule[DayOfWeekEnum.Sun]?.to,
      ).format('HH:mm')}`,
    );
  }

  return list;
};
