import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { PARTNER_BY_SLUG } from 'api/requests/@shared';
import { AccountTypeEnum } from 'constatns';
import CompanyDetailsPage from '../CompanyDetailsPage';
import MasterDetailsPage from '../MasterDetailsPage';

const PartnerPage: React.FC = () => {
  const { slug } = useParams<'slug'>();

  const [partnerBySlug] = useLazyQuery<
    { partnerBySlug: { id: number; type: AccountTypeEnum } },
    {
      slug: string;
    }
  >(PARTNER_BY_SLUG, {
    fetchPolicy: 'no-cache',
  });

  const [id, setId] = useState<null | number>(null);
  const [accountType, setAccountType] = useState<null | AccountTypeEnum>(null);

  useEffect(() => {
    if (slug) {
      partnerBySlug({ variables: { slug } }).then(res => {
        if (res.data?.partnerBySlug) {
          setId(res.data.partnerBySlug.id);
          setAccountType(res.data.partnerBySlug.type);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (!accountType || id === null) return null;

  if (accountType === AccountTypeEnum.Company) return <CompanyDetailsPage id={id} />;
  if (accountType === AccountTypeEnum.Master) return <MasterDetailsPage masterId={id} />;
};

export default PartnerPage;
