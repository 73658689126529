import React from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import i18n from 'localizations';
import { StyledContainer } from './styled';

type Props = {
  distance: number;
  variant?: 'white' | 'grey' | 'transparent';
};

const formatDistance = (distanceInMeters: number): string => {
  let distance = distanceInMeters;
  let unit = 'meter';

  const thresholds = [1000];

  // eslint-disable-next-line no-restricted-syntax
  for (const threshold of thresholds) {
    if (distance >= threshold) {
      unit = 'kilometer';
      distance /= threshold;
      break;
    }
  }

  return `${Intl.NumberFormat(i18n.language, {
    style: 'unit',
    unit,
    maximumFractionDigits: 1,
  }).format(distance)}`;
};

const DistanceLabel: React.FC<Props> = ({ distance, variant = 'grey' }) => (
  <StyledContainer variant={variant}>
    <LocationIcon width="24px" height="24px" />
    <Typography variant="subtitle1" noWrap>{formatDistance(distance)}</Typography>
  </StyledContainer>
);

export default DistanceLabel;
