import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Input from 'components/@shared/Input';

export const StyledList = styled(Box)`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
`;

export const StyledInput = styled(Input)`
  & .MuiInputBase-input {
    padding-left: 8px;
  }
`;
