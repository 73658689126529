import { Accordion, Box, Slider } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledAccordion = styled(Accordion)(({ theme, expanded }) => {
  return css`
    margin: 0;
    padding: 20px 0;
    box-shadow: none;

    background: ${theme.palette.primary.light2};
    border-radius: 12px !important;

    &.Mui-expanded {
      margin: 0;
    }

    &:before {
      content: none;
    }

    & .MuiAccordionSummary-root {
      margin: 0 20px;
      padding: 0;
      min-height: auto;
      height: 16px;
      cursor: pointer !important;
    }

    & .MuiAccordionSummary-expandIconWrapper {
      color: ${theme.palette.primary.main};
    }

    & .MuiAccordionSummary-content {
      &.Mui-expanded {
        margin: 0;
      }
    }

    & .MuiCollapse-root {
      opacity: ${expanded ? 1 : 0};
      transition: all 0.25ms;
    }

    & .MuiAccordionDetails-root {
      padding: 0 20px 10px;
      max-height: 400px;
      overflow-y: auto;
    }

    & ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  `;
});

export const StyledSlider = styled(Slider)(({ theme }) => {
  return css`
    & .MuiSlider-rail {
      color: ${theme.palette.primary.dark3};
    }

    & .MuiSlider-track {
      color: ${theme.palette.accent.main};
    }

    & .MuiSlider-thumb {
      color: ${theme.palette.accent.main};

      &:hover,
      &.Mui-focusVisible {
        box-shadow: none !important;
        color: ${theme.palette.accent.light};
      }

      &.Mui-active {
        box-shadow: none !important;
        color: ${theme.palette.accent.dark};
      }

      &:before,
      &:after {
        content: none;
      }
    }

    &.Mui-disabled {
      background: transparent !important;
      border: none !important;

      & .MuiSlider-rail,
      & .MuiSlider-track,
      & .MuiSlider-thumb {
        color: ${theme.palette.primary.dark2};
        background: ${theme.palette.primary.dark2} !important;
      }
    }
  `;
});

export const StyledFromToContainer = styled(
  Box,
  preventForwardProps(['show']),
)<{ show: boolean }>(
  ({ show }) => css`
    opacity: ${show ? 1 : 0};
    height: ${show ? '28px' : 0};
    transition: all 0.3s;
  `,
);
