import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledGrid = styled(Box)(
  ({ theme }) => css`
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));

    ${theme.breakpoints.up('md')} {
      gap: 20px;
    }
  `,
);
