import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';
import { ReservationCalendarVariant } from '../types';

export const StyledWhiteContainer = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
  `,
);

export const StyledContainer = styled(
  Stack,
  preventForwardProps(['variant']),
)<{ variant: ReservationCalendarVariant }>(
  ({ theme, variant }) => css`
    border: 1px solid ${theme.palette.primary.light3};
    border-bottom: none;

    border-radius: 20px 20px 0 0;
    background: ${variant === ReservationCalendarVariant.List
      ? theme.palette.primary.light2
      : theme.palette.background.default};

    padding: 16px;
    padding-bottom: ${variant === ReservationCalendarVariant.List ? '16px' : '0px'};

    position: sticky;
    top: 100px;

    [data-week-days] {
      display: ${variant === ReservationCalendarVariant.List ? 'none' : 'flex'};
      border-radius: 8px 8px 0 0;
      margin-top: 16px;
      background: ${theme.palette.primary.light2};
    }
  `,
);

export const StyledPopperContainer = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.background.default};
    border-radius: 12px;
    border: 1px solid ${theme.palette.accent.main};
    padding: 20px;
    gap: 20px;

    * {
      cursor: pointer;

      :hover {
        color: ${theme.palette.accent.light};
      }

      :active {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);

export const StyledMonthButton = styled(Stack)(
  ({ theme }) => css`
    cursor: pointer;

    :hover {
      color: ${theme.palette.accent.light};

      svg {
        color: ${theme.palette.accent.light};
      }
    }

    :active {
      color: ${theme.palette.accent.dark};

      svg {
        color: ${theme.palette.accent.dark};
      }
    }
  `,
);

export const StyledWeekCell = styled(Stack)(
  ({ theme }) => css`
    width: 100%;
    height: 20px;
    border-right: 1px solid ${theme.palette.primary.light3};
    align-items: center;
  `,
);
