import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import { useLocation } from 'react-router-dom';
import { OutputMasterReservationDto } from 'types/reservation';
import { useEffect } from 'react';
import useMutation from 'hooks/useMutation';
import { APPROVE_MASTER_RESERVATION, CANCEL_MASTER_RESERVATION } from 'api/requests/master';
import { useNewReservationsCount } from 'hooks/useNewReservations';

const useMasterReservationDetailsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state?: { reservation?: OutputMasterReservationDto } };
  const { fetchNewReservationsCount } = useNewReservationsCount(true);

  const [approveMasterReservation] = useMutation<void, { reservationId?: number }>(APPROVE_MASTER_RESERVATION, {
    fetchPolicy: 'no-cache',
  });

  const [cancelMasterReservation] = useMutation<void, { reservationId?: number }>(CANCEL_MASTER_RESERVATION, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!state?.reservation) {
      navigate(PATHS.MASTER_CALENDAR_SCHEDULE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleBack = () => navigate(PATHS.MASTER_CALENDAR_SCHEDULE);

  const approveReservation = async () => {
    if (!state?.reservation) return;
    await approveMasterReservation({ variables: { reservationId: state.reservation.id } });
    fetchNewReservationsCount();
    navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
  };

  const cancelReservation = async () => {
    if (!state?.reservation) return;
    await cancelMasterReservation({ variables: { reservationId: state.reservation.id } });
    fetchNewReservationsCount();
    navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
  };

  return {
    reservation: state?.reservation,
    handleBack,
    approveReservation,
    cancelReservation,
  };
};

export default useMasterReservationDetailsPage;
