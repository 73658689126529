import React from 'react';
import { css, styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';
import { Typography } from '@mui/material';

import preventForwardProps from 'utils/preventForwardProps';

export const StyledTypography = styled<React.FC<TypographyProps & { rowsLimit: number }>>(
  Typography,
  preventForwardProps(['rowsLimit']),
)(({ rowsLimit }) => {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;

    ${rowsLimit > 1
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: ${rowsLimit};
          -webkit-box-orient: vertical;
        `
      : css`
          white-space: nowrap;
        `};
  `;
});
