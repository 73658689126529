import { TextField } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(
  ({ theme }) => css`
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.primary.light3};
      border-radius: 8px;
    }

    & .MuiInputBase-root {
      background: ${theme.palette.primary.light};
      border-radius: 8px;
      padding: 12px 16px;
    }

    & .MuiInputBase-input {
      color: ${theme.palette.primary.main};
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      box-sizing: border-box;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.accent.main} !important;
      }
    }

    & .Mui-error {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.error.main} !important;
      }

      & .MuiInputBase-input {
        color: ${theme.palette.error.main};
      }
    }

    & .Mui-disabled {
      background: none !important;
      border: none !important;

      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.primary.dark2} !important;
      }
    }

    & .MuiInputLabel-root {
      color: ${theme.palette.primary.dark3};
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;

      &.Mui-disabled {
        color: ${theme.palette.primary.dark2} !important;
      }

      &.Mui-error {
        color: ${theme.palette.primary.dark3};
      }
    }

    & .MuiFormHelperText-root {
      color: ${theme.palette.primary.dark2};
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      margin: 3px 3px 3px 16px;
      white-space: pre-line;

      &.Mui-error {
        color: ${theme.palette.error.main};
      }

      &.Mui-disabled {
        border: none !important;
      }
    }

    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.accent.light} !important;
      }
      & .Mui-disabled .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.primary.dark2} !important;
      }
    }
  `,
);
