import { css, styled } from '@mui/material/styles';
import { Badge } from '@mui/material';

export const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    .MuiBadge-badge {
      border-radius: 6px;
      color: ${theme.palette.primary.light};
      background: ${theme.palette.accent.light};
      top: 6px;
      right: 6px;
    }
  `,
);
