import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import { GET_CLIENT } from 'api/requests/client';
import { OutputClientDto } from 'types/client';
import { useClientState } from 'contexts/client';
import { setClient } from 'contexts/client/actions';

const useClientCabinetPage = () => {
  const navigate = useNavigate();

  const { state, dispatch } = useClientState();
  const [getClient] = useLazyQuery<{ client: OutputClientDto }>(GET_CLIENT, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!state.client) {
      getClient()
        .then(res => {
          if (res.data?.client) {
            dispatch(setClient(res.data.client));
          }
        })
        .catch(error => {
          if (error?.graphQLErrors[0]?.extensions?.code === 'AUTH_NOT_AUTHORIZED') {
            navigate(PATHS.MAIN_PAGE);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useClientCabinetPage;
