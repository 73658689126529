import { css, styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledSwitch = styled('label')(
  ({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 182px;
    height: 96px;
    flex-shrink: 0;
    margin: -68px;
    transform: scale(0.25);

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${theme.palette.primary.dark3};
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border-radius: 48px;
    }

    .slider > .iconContainer {
      position: absolute;
      height: 80px;
      width: 80px;
      left: 8px;
      bottom: 8px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }

    input:checked + .slider {
      background-color: ${theme.palette.accent.main};
    }

    input:checked:hover + .slider {
      background-color: ${theme.palette.accent.light};
    }

    input:active + .slider {
      background-color: ${theme.palette.accent.dark};
    }

    input:disabled + .slider {
      opacity: 0.5;
    }

    input:checked + .slider > .iconContainer {
      -webkit-transform: translateX(88px);
      -ms-transform: translateX(88px);
      transform: translateX(88px);
    }
  `,
);

export const StyledCircularProgress = styled(
  CircularProgress,
  preventForwardProps(['loading']),
)<{ loading?: boolean }>(
  ({ theme, loading }) => css`
    position: absolute;
    color: ${theme.palette.background.default};
    opacity: ${loading ? 1 : 0};
    transition: opacity 0.3s ease-in-out;
  `,
);

export const StyledCircleIcon = styled(
  'div',
  preventForwardProps(['loading']),
)<{ loading?: boolean }>(
  ({ theme, loading }) => css`
    position: absolute;
    color: ${theme.palette.background.default};
    background: ${theme.palette.background.default};
    border-radius: 50%;
    height: 80px;
    width: 80px;
    opacity: ${loading ? 0 : 1};
    transition: opacity 0.3s ease-in-out;
  `,
);
