import React from 'react';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkbox.svg';
import { ReactComponent as CheckboxActiveIcon } from 'assets/icons/checkboxActive.svg';

import { Typography } from '@mui/material';
import { StyledCheckbox, StyledFormControlLabel } from './styled';

type Props = CheckboxProps & {
  label?: string;
};

const Checkbox: React.FC<Props> = ({ label, className, ...props }) => (
  <StyledFormControlLabel
    className={className}
    sx={{ width: '100%' }}
    control={<StyledCheckbox {...props} disableRipple icon={<CheckboxIcon />} checkedIcon={<CheckboxActiveIcon />} />}
    label={<Typography variant="body1">{label}</Typography>}
  />
);
export default Checkbox;
