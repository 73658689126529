import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import { Address } from 'types';
import { InputProps } from '../Input';
import { StyledInput } from './styled';
import './styles.css';
import getLocationData from 'utils/getLocationData';
import { getCountryCode } from 'utils/geyCountryCode';

type AddressAutocompleteProps = Omit<InputProps, 'value' | 'onChange'> & {
  value: Address | null;
  onChange: (value: Address | null) => void;
};

const location = getLocationData();

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onChange, value, ...props }) => {
  const places = useMapsLibrary('places');

  const [inputValue, setInputValue] = useState<string>(value?.addressLine ?? '');
  const [open, setOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value?.addressLine ?? '');
  }, [value]);

  useEffect(() => {
    if (places && inputRef.current) {
      const autocomplete = new places.Autocomplete(inputRef.current, {
        componentRestrictions: { country: getCountryCode() },
        fields: ['formatted_address', 'geometry.location'],
      });
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address && place.geometry?.location) {
          onChange({
            addressLine: place.formatted_address,
            coordinates: {
              latitude: place.geometry.location?.lat(),
              longitude: place.geometry.location?.lng(),
            },
          });
        }
      });
    }

    return () => {
      document.querySelectorAll('.pac-container').forEach(item => item.remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  useEffect(() => {
    if (!open) {
      setInputValue(value?.addressLine ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);

  // this delay is needed because otherwise for some reason the place_changed event does not have time to work
  const handleBlur = () =>
    setTimeout(() => {
      setOpen(false);
    }, 300);
  const handleFocus = () => setOpen(true);

  return (
    <StyledInput
      inputRef={inputRef}
      value={inputValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default AddressAutocomplete;
