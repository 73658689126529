import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from 'components/@shared/Button';
import { PATHS } from 'navigation/constants';
import { AccountTypeEnum } from 'constatns';

import { StyledDrawer, StyledItemsContainer } from './styled';
import LanguageSelector from '../../LanguageSelector';
import { Stack } from '@mui/material';
import useResize from 'hooks/useResize';

type Props = {
  open: boolean;
  account: AccountTypeEnum | null;
  onLogOut: () => void;
  onClose: () => void;
};

export const navMenuItems = [
  { labelKey: 'servicesLabel', path: PATHS.SERVICES_PAGE },
  { labelKey: 'aboutLabel', path: PATHS.ABOUT_PAGE },
  { labelKey: 'forCompaniesLabel', path: PATHS.FOR_COMPANIES_PAGE },
] as const;

const NavMenu: React.FC<Props> = ({ open, account, onLogOut, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { height } = useResize();

  return (
    <StyledDrawer open={open} anchor="bottom" variant="temporary">
      {height && height > 500 && (
        <Stack position="absolute" left="calc(50% - 45px)">
          <LanguageSelector />
        </Stack>
      )}
      <StyledItemsContainer>
        {(!account || account === AccountTypeEnum.Client) &&
          navMenuItems.map(item => {
            const handleClick = () => {
              onClose();
              navigate(item.path);
            };
            return (
              <Button key={item.labelKey} typographyProps={{ variant: 'h5' }} onClick={handleClick}>
                {t(`nav.${item.labelKey}`)}
              </Button>
            );
          })}
      </StyledItemsContainer>
      {!account ? (
        <Button variant="contained" size="large">
          {t('appBar.actionBtnLabel')}
        </Button>
      ) : (
        <Button variant="outlined" size="large" onClick={onLogOut}>
          {t('appBar.logOutBtnLabel')}
        </Button>
      )}
    </StyledDrawer>
  );
};

export default NavMenu;
