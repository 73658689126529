import React, { useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import useMutation from 'hooks/useMutation';
import { LOGOUT } from 'api/requests/auth';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { PATHS, SEGMENTS } from 'navigation/constants';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as FavoritesIcon } from 'assets/icons/favorites.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CabinetIcon } from 'assets/icons/cabinet.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import Button from 'components/@shared/Button';
import Container from 'components/@shared/Container';
import IconButton from 'components/@shared/IconButton';
import Badge from 'components/@shared/Badge';
import { AccountTypeEnum } from 'constatns';
import { useAppState } from 'contexts/app';
import { useFavorites } from 'hooks/useFavorites';
import { useNewReservationsCount } from 'hooks/useNewReservations';

import HamburgerMenu from './HamburgerMenu';
import NavMenu, { navMenuItems } from './NavMenu';
import { StyledAppBar, StyledLeftSection, StyledRightSection, StyledToolbar } from './styled';
import AuthModal from '../AuthModal';
import LanguageSelector from '../LanguageSelector';

const accountPagesMapping = {
  [AccountTypeEnum.Client]: PATHS.CLIENT_CABINET_INFO_TAB,
  [AccountTypeEnum.Master]: PATHS.MASTER_CABINET_INFO_TAB,
  [AccountTypeEnum.Company]: PATHS.COMPANY_CABINET_INFO_TAB,
};

const AppBar: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { account },
  } = useAppState();

  const [logout] = useMutation<{ logout: boolean }>(LOGOUT);

  const [openMenu, setOpenMenu] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const { count: favoritesCount } = useFavorites();
  const { count: newReservationsCount } = useNewReservationsCount(true);

  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));

  const MASTER_CALENDAR = useMatch(`${SEGMENTS.MASTER_CALENDAR}/*`);
  const COMPANY_CALENDAR = useMatch(`${SEGMENTS.COMPANY_CALENDAR}/*`);
  const MASTER_RESERVATION_DETAILS = useMatch(PATHS.MASTER_RESERVATION_DETAILS);
  const COMPANY_RESERVATION_DETAILS = useMatch(PATHS.COMPANY_RESERVATION_DETAILS);
  const isCalendarPage =
    !!MASTER_CALENDAR || !!COMPANY_CALENDAR || !!MASTER_RESERVATION_DETAILS || !!COMPANY_RESERVATION_DETAILS;

  const FAVORITES_PAGE = useMatch(PATHS.FAVORITES_PAGE);
  const isFavoritePage = !!FAVORITES_PAGE;

  const MASTER_CABINET = useMatch(`${SEGMENTS.MASTER_CABINET}/*`);
  const COMPANY_CABINET = useMatch(`${SEGMENTS.COMPANY_CABINET}/*`);
  const CLIENT_CABINET = useMatch(`${SEGMENTS.CLIENT_CABINET}/*`);
  const isCabinetPage = !!MASTER_CABINET || !!COMPANY_CABINET || !!CLIENT_CABINET;

  const handleToggleMenu = () => setOpenMenu(prevState => !prevState);

  const handleCalendarClick = () => {
    if (account === AccountTypeEnum.Master) {
      navigate(PATHS.MASTER_CALENDAR_SCHEDULE);
    }
    if (account === AccountTypeEnum.Company) {
      navigate(PATHS.COMPANY_CALENDAR_SCHEDULE);
    }
  };

  const handleAccountClick = () => {
    if (account) {
      navigate(accountPagesMapping[account]);
    } else {
      setOpenAuthModal(true);
    }
  };

  const handleLogout = async () => {
    await logout();

    navigate(0);
  };

  const isServiceProvider = account === AccountTypeEnum.Company || account === AccountTypeEnum.Master;

  return (
    <>
      <StyledAppBar position="static" className={className}>
        <Container>
          <StyledToolbar>
            <StyledLeftSection>
              <IconButton onClick={() => navigate(PATHS.MAIN_PAGE)}>
                <LogoIcon width={upLg ? 142 : 107} />
              </IconButton>
              {upLg &&
                (!account || account === AccountTypeEnum.Client) &&
                navMenuItems.map(item => (
                  <Button key={item.labelKey} onClick={() => navigate(item.path)}>
                    <Typography variant="body1">{t(`nav.${item.labelKey}`)}</Typography>
                  </Button>
                ))}
            </StyledLeftSection>
            <StyledRightSection>
              {upLg && <LanguageSelector />}
              {upLg && !account && <Button variant="contained">{t('appBar.actionBtnLabel')}</Button>}
              {!openMenu && (
                <>
                  {!isServiceProvider && (
                    <IconButton onClick={() => navigate(PATHS.FAVORITES_PAGE)} active={isFavoritePage}>
                      <Badge badgeContent={favoritesCount}>
                        <FavoritesIcon />
                      </Badge>
                    </IconButton>
                  )}
                  {isServiceProvider && (
                    <IconButton onClick={handleCalendarClick} active={isCalendarPage}>
                      <Badge badgeContent={newReservationsCount}>
                        <CalendarIcon />
                      </Badge>
                    </IconButton>
                  )}
                  <IconButton onClick={handleAccountClick} active={isCabinetPage || openAuthModal}>
                    <CabinetIcon />
                  </IconButton>
                  {((account === AccountTypeEnum.Client && upLg) ||
                    (!!account && account !== AccountTypeEnum.Client)) && (
                    <IconButton onClick={handleLogout}>
                      <LogoutIcon />
                    </IconButton>
                  )}
                </>
              )}
              {!upLg && (!account || account === AccountTypeEnum.Client) && (
                <IconButton>
                  <HamburgerMenu open={openMenu} onToggle={handleToggleMenu} />
                </IconButton>
              )}
            </StyledRightSection>
          </StyledToolbar>
        </Container>
      </StyledAppBar>
      <NavMenu
        open={openMenu}
        account={account}
        onClose={() => setOpenMenu(prevState => !prevState)}
        onLogOut={handleLogout}
      />
      <AuthModal open={openAuthModal} onClose={() => setOpenAuthModal(false)} />
    </>
  );
};

export default AppBar;
