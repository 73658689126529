import { FieldInputProps, FormikProps, FormikValues } from 'formik';
import React from 'react';

type KeyOf<T extends object> = Extract<keyof T, string>;

export const getFormikHelpers = <Values extends FormikValues>(formik: FormikProps<Values>) => {
  const { getFieldProps, touched, errors } = formik;

  const getBasicFormikProps = <Value = Values[keyof Values]>(
    name: KeyOf<Values>,
  ): Omit<FieldInputProps<Value>, 'onChange'> & { onChange: (e: React.ChangeEvent<any>) => void } => ({
    ...getFieldProps(name),
  });

  interface HelperTextConfig {
    helperText: string;
  }

  const getFormikProps = <Value = any>(
    name: KeyOf<Values>,
    config?: Partial<HelperTextConfig>,
  ): Omit<FieldInputProps<Value>, 'onChange'> & {
    onChange: (e: React.ChangeEvent<any>) => void;
    error: boolean;
    helperText: string;
  } => {
    const { helperText } = <HelperTextConfig>{
      helperText: '',
      ...config,
    };
    return {
      ...getFieldProps(name),
      error: !!(touched[name] && errors[name]),
      helperText: (touched[name] && errors[name] ? errors[name] : helperText) as string,
    };
  };

  type GeneralFormikPopsType<Value = Values[keyof Values]> =
    | FieldInputProps<Value>
    | (FieldInputProps<Value> & {
        error: boolean;
        helperText: string;
      });

  const withOnChangeStringParameter = <Value = Values[keyof Values]>(
    formikProps: GeneralFormikPopsType<Value>,
  ): Omit<GeneralFormikPopsType<Value>, 'onChange'> & {
    onChange: (data: Values[typeof formikProps.name]) => void;
  } => {
    return {
      ...formikProps,
      onChange: data => formik.setFieldValue(formikProps.name, data),
    };
  };
  const withoutChange = <Value = Values[keyof Values]>(
    formikProps: GeneralFormikPopsType<Value>,
  ): Omit<GeneralFormikPopsType<Value>, 'onChange'> => {
    // eslint-disable-next-line   @typescript-eslint/no-unused-vars
    const { onChange, ...props } = formikProps;
    return props;
  };

  const withoutBlur = <Value = Values[keyof Values]>(
    formikProps: GeneralFormikPopsType<Value>,
  ): Omit<GeneralFormikPopsType<Value>, 'onBlur'> => {
    // eslint-disable-next-line   @typescript-eslint/no-unused-vars
    const { onBlur, ...props } = formikProps;
    return props;
  };

  const withoutHandlers = <Value = Values[keyof Values]>(
    formikProps: GeneralFormikPopsType<Value>,
  ): Omit<GeneralFormikPopsType<Value>, 'onChange' | 'onBlur'> => {
    // eslint-disable-next-line   @typescript-eslint/no-unused-vars
    const { onChange, onBlur, ...props } = formikProps;
    return props;
  };

  const isFormDisabled = !formik.isValid;

  return {
    getBasicFormikProps,
    getFormikProps,
    withOnChangeStringParameter,
    withoutBlur,
    withoutChange,
    withoutHandlers,
    isFormDisabled,
  };
};

export type FormCustomErrors<T extends object> = Partial<Record<keyof T, string>>;
