import { useTranslation } from 'react-i18next';
import { PATHS } from 'navigation/constants';
import { OutputServiceDto } from 'types';
import getRouteWithParams from 'utils/getRouteWithParams';

export const useBreadcrumbs = (data?: OutputServiceDto) => {
  const { t } = useTranslation();

  if (!data) return;

  const getBreadcrumbsSegments = () => [
    {
      path: PATHS.MAIN_PAGE,
      label: t('breadcrumbs.home'),
    },
    { path: PATHS.SERVICES_PAGE, label: t('breadcrumbs.services') },
    ...(data.company?.id
      ? [
          {
            path: getRouteWithParams(PATHS.PARTNER_DETAILS, { slug: data.company.slug }),
            label: data.company.name,
          },
        ]
      : []),
    {
      path: data.company
        ? getRouteWithParams(PATHS.COMPANY_MASTER_DETAILS_PAGE, {
            slug: data.company.slug,
            masterId: data.master.id.toString(),
          })
        : getRouteWithParams(PATHS.PARTNER_DETAILS, { slug: data.master.slug }),
      label: data.master.contactName,
    },
  ];

  return {
    breadcrumbsSegments: getBreadcrumbsSegments(),
    currentPageLabel: t('breadcrumbs.bookService'),
  };
};
