import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { OutputBookingDayDto, OutputServiceDto } from 'types';
import stringToNumber from 'utils/stringToNumber';
import { AVAILABLE_BOOKING_DAYS, CREATE_RESERVATION, SERVICE } from 'api/requests/service';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import getRouteWithParams from 'utils/getRouteWithParams';
import { PATHS } from 'navigation/constants';
import useMutation from 'hooks/useMutation';

const useBookingServicePage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { serviceId } = useParams<'serviceId'>();

  const [month, setMonth] = useState(dayjs().utc());
  const [availableBookingDays, setAvailableBookingDays] = useState<OutputBookingDayDto[]>([]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [fetchService, { data: serviceData, loading: serviceLoading }] = useLazyQuery<
    { service: OutputServiceDto },
    {
      serviceId?: number;
    }
  >(SERVICE, {
    variables: { serviceId: stringToNumber(serviceId) },
    fetchPolicy: 'no-cache',
  });

  const [getAvailableBookingDays, { loading: getAvailableBookingDaysLoading }] = useLazyQuery<
    { availableBookingDays: OutputBookingDayDto[] },
    {
      serviceId?: number;
      from: string;
      to: string;
    }
  >(AVAILABLE_BOOKING_DAYS, {
    fetchPolicy: 'no-cache',
  });

  const [createReservation, { loading: createReservationLoading }] = useMutation<
    { createReservation: { id: number } },
    {
      serviceId: number;
      date: string;
      startTime: string;
    }
  >(CREATE_RESERVATION, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    fetchService();
  }, [i18n.language]);

  useEffect(() => {
    if (serviceId) {
      getAvailableBookingDays({
        variables: {
          serviceId: +serviceId,
          from: month.startOf('month').format('YYYY-MM-DD'),
          to: month.endOf('month').add(1, 'day').format('YYYY-MM-DD'),
        },
      }).then(({ data }) => {
        if (data) {
          setAvailableBookingDays(data.availableBookingDays);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, month]);

  const onMonthChange = (value: Dayjs) => setMonth(value);

  const onBackToService = () =>
    serviceId !== undefined &&
    navigate(getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: serviceId.toString() }));

  const onBook = async (date: Dayjs, startTime: string) => {
    if (serviceData?.service.id === undefined) return;
    await createReservation({
      variables: {
        serviceId: serviceData?.service.id,
        date: date.format('YYYY-MM-DD'),
        startTime,
      },
    });
    setSuccessModalOpen(true);
  };

  const onCloseSuccessModal = () => navigate(PATHS.MAIN_PAGE);

  return {
    serviceDetails: serviceData?.service,
    availableBookingDays,
    onMonthChange,
    serviceLoading,
    loading: getAvailableBookingDaysLoading || createReservationLoading,
    successModalOpen,
    onBackToService,
    onBook,
    onCloseSuccessModal,
  };
};

export default useBookingServicePage;
