import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import Modal from 'components/@shared/Modal';
import Button from 'components/@shared/Button';

type Props = {
  open: boolean;
  title: string;
  description?: React.ReactNode;
  confirmLabel?: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
};

const DeleteModal: React.FC<Props> = ({ open, title, description, confirmLabel, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="335px" width="calc(100% - 40px)">
      <Stack gap="20px">
        <Stack gap="12px" maxWidth="265px">
          <Typography variant="h5" color="primary.main">
            {title}
          </Typography>
          {description}
        </Stack>
        <Stack direction="row" gap="8px">
          <Button fullWidth variant="outlined" disabled={loading} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button fullWidth variant="contained" color="error" loading={loading} onClick={handleSubmit}>
            {confirmLabel || t('confirmDelete')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
