import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { OutputServiceDto } from 'types';
import stringToNumber from 'utils/stringToNumber';
import { SERVICE } from 'api/requests/service';
import { PATHS } from 'navigation/constants';
import getRouteWithParams from 'utils/getRouteWithParams';

const useServiceDetailsPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { serviceId } = useParams<'serviceId'>();

  const [fetchService, { data, loading }] = useLazyQuery<{ service: OutputServiceDto }, { serviceId?: number }>(SERVICE, {
    variables: { serviceId: stringToNumber(serviceId) },
    fetchPolicy: 'no-cache',
  });

  const handleCompanyClick = () => {
    if (data?.service?.company) {
      navigate(
        getRouteWithParams(PATHS.PARTNER_DETAILS, {
          slug: data.service.company.slug,
        }),
      );
    }
  };

  useEffect(() => {
    fetchService();
  }, [i18n.language]);

  const handleMasterClick = () => {
    if (data) {
      navigate(
        data.service?.company
          ? getRouteWithParams(PATHS.COMPANY_MASTER_DETAILS_PAGE, {
              slug: data.service.company.slug,
              masterId: data.service.master.id.toString(),
            })
          : getRouteWithParams(PATHS.PARTNER_DETAILS, {
              slug: data.service.master.slug,
            }),
      );
    }
  };

  return {
    serviceDetails: data?.service,
    loading,
    handleCompanyClick,
    handleMasterClick,
  };
};

export default useServiceDetailsPage;
