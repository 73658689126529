import { Dayjs } from 'dayjs';
import { ReservationStatus } from 'types/reservation';
import { OutputScheduleDayDto } from 'types';

export enum ReservationCalendarVariant {
  List = 'List',
  Calendar = 'Calendar',
}

export type Reservation = {
  id: number;
  date: Dayjs;
  timeSlot: OutputScheduleDayDto;
  timeSlotNumeric: {
    from: number;
    to: number;
  };
  contactName?: string;
  profileImageUri?: string;
  email?: string;
  status: ReservationStatus;
};

export type ReservationCalendarProps = {
  reservationList: Reservation[];
  loading?: boolean;
  variant?: ReservationCalendarVariant;
  onDateRangeChange: (dateRange: { from: Dayjs; to: Dayjs }) => void;
  onReservationClick: (reservationId: number) => void;
  className?: string;
};
