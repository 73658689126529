import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import 'dayjs/locale/uk';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { sortReservationsCallback } from 'utils/sortReservationsCallback';
import generateIncrementalArray from 'utils/generateIncrementalArray';

import { ReservationStatus } from 'types/reservation';
import { StyledHour, StyledContainer, StyledReservationItem, StyledRowDivider } from './styled';
import { Reservation } from '../types';
import { COLUMNS_COUNTS, ROWS_COUNTS } from '../constants';

dayjs.extend(isoWeek);

const generateHoursList = () => {
  const hoursList = [];
  for (let hour = 1; hour <= 23; hour++) {
    hoursList.push(`${hour.toString().padStart(2, '0')}:00`);
  }
  return hoursList;
};

const groupSlots = (reservationList: Reservation[]) => {
  return reservationList.reduce<Array<Reservation[]>>((acc, item) => {
    let slotIndex = 0;
    let flag = true;

    do {
      const slot = acc[slotIndex];

      if (!slot) {
        // eslint-disable-next-line no-param-reassign
        acc[slotIndex] = [item];
        flag = false;
      } else if (slot[slot.length - 1].timeSlotNumeric.to <= item.timeSlotNumeric.from) {
        acc[slotIndex].push(item);
        flag = false;
      } else {
        slotIndex++;
      }
    } while (flag);
    return acc;
  }, []);
};

const getCalendarViewData = (reservationList: Reservation[]) => {
  const reservationsGroupedByDate = Object.values(
    reservationList.sort(sortReservationsCallback).reduce<
      Record<
        string,
        {
          date: Dayjs;
          list: Reservation[];
        }
      >
    >((acc, item) => {
      const stringData = item.date.format();
      if (acc[stringData]) {
        return {
          ...acc,
          [stringData]: {
            ...acc[stringData],
            list: [...acc[stringData].list, item],
          },
        };
      }
      return {
        ...acc,
        [stringData]: {
          date: item.date,
          list: [item],
        },
      };
    }, {}),
  );

  return reservationsGroupedByDate.map(({ date, list }) => ({ date, slots: groupSlots(list) }));
};

export type Props = {
  reservationList: Reservation[];
  loading?: boolean;
  onReservationClick: (reservationId: number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CalendarView: React.FC<Props> = ({ reservationList, loading, onReservationClick }) => {
  const [firstSlot, setFirstSlot] = useState<Reservation | null>(null);
  const [scrollIntoViewId, setScrollIntoViewId] = useState<string | null>(null);

  useEffect(() => {
    setFirstSlot(null);
  }, [reservationList]);

  useEffect(() => {
    if (!loading) {
      if (firstSlot) {
        setScrollIntoViewId(`slot-${firstSlot.id}`);
      } else {
        setScrollIntoViewId(`hour-6`);
      }
    }
  }, [loading, firstSlot]);

  useEffect(() => {
    if (scrollIntoViewId) {
      const element = document.getElementById(scrollIntoViewId);
      window.scrollTo({ top: (element?.offsetTop || 0) + 200, behavior: 'smooth' });
    }
  }, [scrollIntoViewId]);

  const hoursList = generateHoursList();

  const data = getCalendarViewData(reservationList);

  return (
    <Stack width="100%" padding="0 16px 16px">
      <Stack width="100%" direction="row">
        <StyledContainer>
          {generateIncrementalArray(ROWS_COUNTS - 1).map(item => (
            <StyledRowDivider key={item} index={item} />
          ))}
          {generateIncrementalArray(ROWS_COUNTS).map(item => (
            <StyledHour id={`hour-${item}`} key={item} index={item}>
              {hoursList[item - 1]}
            </StyledHour>
          ))}
        </StyledContainer>
        {generateIncrementalArray(COLUMNS_COUNTS).map(c => (
          <StyledContainer key={c}>
            {generateIncrementalArray(ROWS_COUNTS - 1).map(r => (
              <StyledRowDivider key={r} index={r} />
            ))}
            {!loading &&
              data
                .find(({ date }) => date.isoWeekday() === c)
                ?.slots.map((slot, index, slots) => {
                  return slot.map(s => {
                    if (firstSlot === null || firstSlot.timeSlotNumeric.from > s.timeSlotNumeric.from) {
                      setFirstSlot(s);
                    }

                    const start = s.timeSlotNumeric.from / 60;
                    const height = (s.timeSlotNumeric.to - s.timeSlotNumeric.from) / 60;
                    return (
                      <StyledReservationItem
                        key={`slot-${s.id}`}
                        id={`slot-${s.id}`}
                        coordinates={{ start, height, slotsCount: slots.length, slotPosition: index }}
                        approved={s.status === ReservationStatus.APPROVED}
                      >
                        <span>{s.contactName}</span>
                      </StyledReservationItem>
                    );
                  });
                })}
          </StyledContainer>
        ))}
      </Stack>
    </Stack>
  );
};

export default CalendarView;
