import { useEffect, useState } from 'react';
import { MASTER_RESERVATIONS } from 'api/requests/master';

import { useLazyQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { OutputMasterReservationDto, ReservationStatus } from 'types/reservation';
import { useLocation } from 'react-router-dom';

const useScheduleTab = () => {
  const [dateRange, setDateRange] = useState({ from: dayjs().startOf('day'), to: dayjs().endOf('month') });
  const [masterReservations, setMasterReservations] = useState<OutputMasterReservationDto[]>([]);

  const { pathname } = useLocation();

  const [getMasterReservations, { loading }] = useLazyQuery<
    { masterReservations: OutputMasterReservationDto[] },
    {
      from: string;
      to: string;
      statuses?: ReservationStatus[];
    }
  >(MASTER_RESERVATIONS);

  useEffect(() => {
    getMasterReservations({
      variables: {
        from: dateRange.from.format('YYYY-MM-DD'),
        to: dateRange.to.format('YYYY-MM-DD'),
        statuses: [ReservationStatus.APPROVED, ReservationStatus.WAITING_FOR_APPROVE],
      },
    }).then(res => {
      if (res.data?.masterReservations) {
        setMasterReservations(res.data.masterReservations);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, pathname]);

  const handleDateRangeChange = (data: { from: Dayjs; to: Dayjs }) => {
    setDateRange(data);
    setMasterReservations([]);
  };

  return {
    masterReservations,
    loading,
    handleDateRangeChange,
  };
};

export default useScheduleTab;
