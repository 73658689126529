import { FormControl, MenuItem } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledFormControl = styled(
  FormControl,
  preventForwardProps(['withIcons']),
)<{ withIcons: boolean }>(
  ({ theme, withIcons }) => css`
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.primary.dark3};
      border-radius: 12px;
    }

    & .MuiInputBase-root {
      background: ${theme.palette.primary.light};
      border-radius: 12px;
      height: ${withIcons ? 52 : 42}px;
    }

    & .MuiSelect-select {
      color: ${theme.palette.primary.main};
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      box-sizing: border-box;
      padding: 12px;
    }

    & .MuiSelect-icon {
      color: ${theme.palette.primary.main};
    }

    & .Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.accent.main} !important;
      }
    }

    & .Mui-error {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.error.main} !important;
      }

      & .MuiSelect-select {
        color: ${theme.palette.error.main};
      }
    }

    & .Mui-disabled {
      background: none !important;
      border: none !important;

      & .MuiOutlinedInput-notchedOutline {
          border: 1px solid ${theme.palette.primary.dark2} !important;
      }
      &:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.primary.dark2} !important;
      }

      & .MuiSelect-icon {
        color: ${theme.palette.primary.dark2} !important;
      }
    }

    & .MuiInputLabel-root {
      color: ${theme.palette.primary.dark3};
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;

      &.Mui-disabled {
        color: ${theme.palette.primary.dark2} !important;
      }

      &.Mui-error {
        color: ${theme.palette.primary.dark3};
      }
    }

    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.accent.light} !important;
      }
    }
  `,
);

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 12px;
  align-items: center;
`;
