import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContainer = styled(
  Stack,
  preventForwardProps(['white']),
)<{ white?: boolean }>(
  ({ theme, white }) => css`
    padding: 16px;
    background: ${white ? theme.palette.background.default : theme.palette.primary.light2};
    border-radius: 16px;
    gap: 12px;
  `,
);
