import { css, styled } from '@mui/material/styles';
import { Container } from '@mui/material';

import { CONTAINER_PADDING_X_DESKTOP, CONTAINER_PADDING_X_MOBILE } from 'styles/constants';

export const StyledContainer = styled(Container)(
  ({ theme }) => css`
    padding: 0 ${CONTAINER_PADDING_X_MOBILE}px !important;

    ${theme.breakpoints.up('sm')} {
      padding: 0 ${CONTAINER_PADDING_X_DESKTOP}px !important;
    }
  `,
);
