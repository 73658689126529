import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { CategoryEnum } from 'constatns';
import TruncatedText from 'components/@shared/TruncatedText';
import Avatar from 'components/@common/Avatar';

import {
  StyledAttribute,
  StyledAttributeSection,
  StyledCard,
  StyledCardActions,
  StyledCategoryContainer,
} from './styled';
import ServiceCardSkeleton from './Skeleton';
import { OutputMasterDto } from 'types';
import CategoryLabel from 'components/@common/CategoryLabel';
import DistanceLabel from 'components/@common/DistanceLabel';
import Button from 'components/@shared/Button';

export type ReservationCardProps = {
  name: string;
  category?: CategoryEnum;
  distanceMeters?: number;
  attributeList: Array<{ startAdornment?: React.ReactNode; value: string }>;
  price?: number;
  masterDetails?: OutputMasterDto;
  qualification?: string;
  className?: string;
  archived?: boolean;
  loading?: boolean;
  onClick: () => void;
};

const ReservationCard: React.FC<ReservationCardProps> = ({
  category,
  distanceMeters,
  name,
  masterDetails,
  qualification,
  attributeList,
  price,
  className,
  archived,
  loading,
  onClick,
}) => {
  const { t } = useTranslation();

  const priceText = price ? t('price', { val: price }) : t('priceFree');

  return (
    <StyledCard className={className} archived={archived}>
      <Stack gap="12px">
        {!!category && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px" width="100%">
            <StyledCategoryContainer>
              <CategoryLabel key={category} category={category} variant="grey" />
            </StyledCategoryContainer>
            {distanceMeters && <DistanceLabel distance={distanceMeters} />}
          </Stack>
        )}
        <TruncatedText variant="h6" rowsLimit={3} className="service-name">
          {name}
        </TruncatedText>
        <StyledAttributeSection>
          {attributeList?.map(item => (
            <StyledAttribute key={item.value}>
              {item.startAdornment}
              <TruncatedText variant="body2" color="primary.main">
                {item.value}
              </TruncatedText>
            </StyledAttribute>
          ))}
        </StyledAttributeSection>
        <Stack direction="row" gap="8px" alignItems="center" width="100%">
          <Avatar {...masterDetails} size="40px" />
          <Stack>
            <Typography variant="subtitle2">{masterDetails?.contactName}</Typography>
            <Typography variant="body2">{qualification}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <StyledCardActions>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <TruncatedText variant={priceText.length < 10 ? 'h4' : 'h5'} color="accent.main" component="h4">
            {priceText}
          </TruncatedText>
        </Stack>
        <Button loading={loading} className="action-button" onClick={onClick}>
          {t(archived ? 'clientCabinetPage.bookAgainButtonLabel' : 'clientCabinetPage.cancelReservationButtonLabel')}
        </Button>
      </StyledCardActions>
    </StyledCard>
  );
};

export default ReservationCard;

export { ServiceCardSkeleton };
