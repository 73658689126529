import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledBgContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.dark};
    border-radius: 16px;
    padding: 16px;
    margin: 40px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${theme.breakpoints.up('md')} {
      margin: 80px 0 40px;
      padding: 40px;
    }
  `,
);

export const StyledTopSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
