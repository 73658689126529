import { DayOfWeekEnum } from 'constatns';
import { useTranslation } from 'react-i18next';

const useDayOfWeekList = () => {
  const { t } = useTranslation();

  const dayOfWeekList = [
    {
      id: DayOfWeekEnum.Mon,
      localizedName: t('dayOfWeek.mon'),
    },
    {
      id: DayOfWeekEnum.Tue,
      localizedName: t('dayOfWeek.tue'),
    },
    {
      id: DayOfWeekEnum.Wed,
      localizedName: t('dayOfWeek.wed'),
    },
    {
      id: DayOfWeekEnum.Thu,
      localizedName: t('dayOfWeek.thu'),
    },
    {
      id: DayOfWeekEnum.Fri,
      localizedName: t('dayOfWeek.fri'),
    },
    {
      id: DayOfWeekEnum.Sat,
      localizedName: t('dayOfWeek.sat'),
    },
    {
      id: DayOfWeekEnum.Sun,
      localizedName: t('dayOfWeek.sun'),
    },
  ];

  return dayOfWeekList;
};

export default useDayOfWeekList;
