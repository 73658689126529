import { gql } from '@apollo/client';

export const CREATE_MASTER = gql`
  mutation CreateMaster($input: CreateMasterDtoInput!) {
    createMaster(input: $input) {
      ...MasterFragment
    }
  }
`;

export const UPDATE_MASTER = gql`
  mutation UpdateMaster($input: UpdateMasterDtoInput!) {
    updateMaster(input: $input) {
      ...MasterFragment
    }
  }
`;

export const REMOVE_COMPANY_MASTER = gql`
  mutation RemoveCompanyMaster($masterId: Long!) {
    removeCompanyMaster(masterId: $masterId) {
    ...MasterFragment
  }
  }
`;

export const SET_MASTER_SLUG = gql`
  mutation SetMasterSlug($slug: String!) {
    setMasterSlug(slug: $slug) {
      ...MasterFragment
    }
  }
`;

export const ACTIVATE_MASTER = gql`
  mutation ActivateMaster($masterId: Long!) {
    activateMaster(masterId: $masterId) {
      ...MasterFragment
    }
  }
`;

export const DEACTIVATE_MASTER = gql`
  mutation DeactivateMaster($masterId: Long!) {
    deactivateMaster(masterId: $masterId) {
      ...MasterFragment
    }
  }
`;

export const GET_MASTER = gql`
  query Master($masterId: Long) {
    master(masterId: $masterId) {
      ...MasterFragment
    }
  }
`;

export const SET_MASTER_PHOTO = gql`
  mutation SetMasterPhoto($masterId: Long, $photo: Upload!) {
    setMasterPhoto(masterId: $masterId, photo: $photo) {
      ...MasterFragment
    }
  }
`;

export const MASTER_RESERVATIONS = gql`
  query MasterReservations($from: Date!, $to: Date!, $statuses: [ReservationStatus!]) {
    masterReservations(from: $from, to: $to, statuses: $statuses) {
      ...OutputMasterReservationDtoFragment
    }
  }
`;

export const SEARCH_MASTER_RESERVATIONS = gql`
  query SearchMasterReservations(
    $statuses: [ReservationStatus!]
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    searchMasterReservations(statuses: $statuses, first: $first, after: $after, last: $last, before: $before) {
      ...SearchMasterReservationsConnectionFragment
    }
  }
`;

export const APPROVE_MASTER_RESERVATION = gql`
  mutation ApproveMasterReservation($reservationId: Long!) {
    approveMasterReservation(reservationId: $reservationId) {
      ...OutputMasterReservationDtoFragment
    }
  }
`;

export const CANCEL_MASTER_RESERVATION = gql`
  mutation CancelMasterReservation($reservationId: Long!) {
    cancelMasterReservation(reservationId: $reservationId) {
      ...OutputMasterReservationDtoFragment
    }
  }
`;
