import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { GET_MASTER } from 'api/requests/master';
import { Master } from 'types';
import { useNavigate, useParams } from 'react-router';
import { PATHS } from 'navigation/constants';
import { useMasterState } from 'contexts/master';
import { setMaster } from 'contexts/master/actions';
import stringToNumber from 'utils/stringToNumber';

const useMasterCabinetPage = (skipGettingMaster: boolean) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { id } = useParams<'id'>();

  const { dispatch } = useMasterState();
  const [fetchMaster] = useLazyQuery<{ master: Master }, { masterId?: number }>(GET_MASTER, {
    variables: { masterId: stringToNumber(id) },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!skipGettingMaster) {
      fetchMaster()
        .then(response => {
          if (response.data?.master) {
            dispatch(setMaster(response.data.master));
          }
        })
        .catch(error => {
          if (error?.graphQLErrors[0]?.extensions?.code === 'AUTH_NOT_AUTHORIZED') {
            navigate(PATHS.MAIN_PAGE);
          }
        });
    }
  }, [skipGettingMaster, i18n.language, navigate]);

  const handleBackToCompanyPage = () => navigate(PATHS.COMPANY_CABINET_MASTERS_TAB);

  return { handleBackToCompanyPage };
};

export default useMasterCabinetPage;
