import { useState } from 'react';

const useFavorite = (id?: number) => {
  const existingFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
  const [favorite, setFavorite] = useState(!!existingFavorites.includes(id));

  const toggleFavorite = () => {
    if (favorite) {
      existingFavorites.splice(existingFavorites.indexOf(id), 1);
      setFavorite(false);
    } else {
      existingFavorites.push(id);
      setFavorite(true);
    }

    localStorage.setItem('favorites', JSON.stringify(existingFavorites));
  };

  return { favorite, toggleFavorite };
};

export default useFavorite;
