import { Theme } from '@mui/material/styles';
import mainTheme from './main';
import createCustomTheme from './createCustomTheme';

export enum THEME_NAMES {
  main = 'main',
}

export type THEMES = {
  [key in keyof typeof THEME_NAMES | string]: Theme;
};

const themes: THEMES = {
  [THEME_NAMES.main]: mainTheme,
};

export { createCustomTheme };

export default themes;
