import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useAppState } from 'contexts/app';
import { setErrorBanner } from 'contexts/app/actions';

const useErrorHandler = (error: ApolloError | undefined) => {
  const { dispatch } = useAppState();

  useEffect(() => {
    if (error) {
      dispatch(setErrorBanner(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useErrorHandler;
