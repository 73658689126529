import React, { useEffect, useRef, useState } from 'react';
import { AccordionDetails, AccordionSummary, SelectProps, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import { useTranslation } from 'react-i18next';
import { StiledLabel, StiledLi, StyledAccordion } from './styled';
import TruncatedText from '../TruncatedText';

export type ListItem = {
  id: string;
  localizedName: string;
  icon?: string;
};

type Props<T> = Omit<SelectProps, 'onChange'> & {
  itemList: T[];
  value: T | null;
  onChange?: (item: T | null) => void;
  isFilled?: boolean;
  withNoSelect?: boolean;
};

const ExpandableSelect = <T extends ListItem>({
  value,
  itemList,
  className,
  label,
  error,
  disabled,
  placeholder,
  isFilled,
  onChange,
  withNoSelect = true,
}: Props<T>) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [currentValue, setCurrentValue] = useState<ListItem | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // @ts-ignore
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleChange = (item: T | null) => {
    setExpanded(false);
    setCurrentValue(item);
    if (onChange) {
      onChange(item);
    }
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <StyledAccordion
      error={error}
      className={className}
      disabled={disabled}
      expanded={expanded}
      isFilled={isFilled}
      onChange={() => !disabled && setExpanded(prevState => !prevState)}
      ref={ref}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StiledLabel disabled={disabled}>{label}</StiledLabel>
        {currentValue?.icon && (
          <img width="24px" height="24px" src={currentValue.icon} alt={currentValue.localizedName} />
        )}
        <TruncatedText variant="subtitle2" color={disabled ? 'primary.dark2' : 'primary.main'}>
          {currentValue?.localizedName || placeholder || t('category.notSelected')}
        </TruncatedText>
      </AccordionSummary>
      <AccordionDetails>
        <ul>
          {withNoSelect && (
            <StiledLi
              key="non-selected"
              tabIndex={0}
              selected={currentValue === null}
              onClick={() => handleChange(null)}
            >
              <Typography variant="subtitle1" flexGrow={1}>
                {t('category.notSelected')}
              </Typography>
              {currentValue === null && <CheckIcon />}
            </StiledLi>
          )}
          {itemList.map((item, index) => (
            <StiledLi
              key={item.id}
              tabIndex={index}
              selected={item.id === currentValue?.id}
              onClick={() => handleChange(item)}
            >
              {item.icon && <img width="24px" height="24px" src={item.icon} alt={item.localizedName} />}
              <Typography variant="subtitle1" flexGrow={1}>
                {item.localizedName}
              </Typography>
              {item.id === currentValue?.id && <CheckIcon />}
            </StiledLi>
          ))}
        </ul>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default React.memo(ExpandableSelect);
