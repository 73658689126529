import React from 'react';
import { useAppState } from 'contexts/app';
import { Banner as BannerComponent } from 'components/@shared/Banner';
import { resetBanner } from 'contexts/app/actions';

export const Banner: React.FC = () => {
  const {
    state: { banner },
    dispatch,
  } = useAppState();

  const handleClose = () => {
    dispatch(resetBanner());
  };

  return (
    <BannerComponent open={Boolean(banner)} severity={banner?.type} message={banner?.message} onClose={handleClose} />
  );
};
