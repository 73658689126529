import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import Button from 'components/@shared/Button';
import { useAppState } from 'contexts/app';
import { PATHS } from 'navigation/constants';
import AuthModal from 'components/@common/AuthModal';
import { AccountTypeEnum } from 'constatns';

import {
  StyledActionsContainer,
  StyledContainer,
  StyledGreyContainer,
  StyledLogoContainer,
  StyledLogoIcon,
} from './styled';
import { useBreadcrumbs } from './useBreadcrumbs';

const accountPagesMapping = {
  [AccountTypeEnum.Client]: PATHS.CLIENT_CABINET_INFO_TAB,
  [AccountTypeEnum.Master]: PATHS.MASTER_CABINET_INFO_TAB,
  [AccountTypeEnum.Company]: PATHS.COMPANY_CABINET_INFO_TAB,
};

const AboutPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { account },
  } = useAppState();

  const [openAuthModal, setOpenAuthModal] = useState(false);

  const breadcrumbs = useBreadcrumbs();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));
  const upLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const handleOfferButtonClick = () => {
    if (account) {
      navigate(accountPagesMapping[account]);
    } else {
      setOpenAuthModal(true);
    }
  };

  return (
    <>
      <Container>
        <Breadcrumbs {...breadcrumbs} />
        <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
          {t('aboutPage.title')}
        </Typography>
        <Gap size={upMedium ? 40 : 20} />
        <StyledContainer>
          <StyledLogoContainer>
            <StyledLogoIcon />
          </StyledLogoContainer>
          <Stack gap="20px">
            <Typography variant="body1" color="primary">
              {t('aboutPage.description')}
            </Typography>
            <Typography variant="subtitle1" color="primary">
              {t('aboutPage.goal')}
            </Typography>
          </Stack>
        </StyledContainer>
        <Gap size={upMedium ? 92 : 40} />
      </Container>
      <StyledGreyContainer>
        <Container>
          <StyledActionsContainer>
            <Typography variant={upMedium ? 'h3' : 'h4'}>{t('aboutPage.actionSectionTitle')}</Typography>
            <Stack gap="12px" direction={upLarge ? 'row' : 'column'}>
              <Button variant="contained" fullWidth onClick={() => navigate(PATHS.SERVICES_PAGE)}>
                {t('aboutPage.searchButtonLabel')}
              </Button>
              <Button variant="outlined" fullWidth onClick={() => handleOfferButtonClick()}>
                {t('aboutPage.offerButtonLabel')}
              </Button>
            </Stack>
          </StyledActionsContainer>
        </Container>
      </StyledGreyContainer>
      <AuthModal open={openAuthModal} onClose={() => setOpenAuthModal(false)} />
    </>
  );
};

export default AboutPage;
