import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  OutputServiceGroupDto,
  GroupedServicesConnection,
  InputServiceFilterDtoInput,
  ServicesConnection,
} from 'types/service';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { GROUPED_SERVICES, SERVICES } from 'api/requests/service';
import { Coordinates, PageInfo } from 'types';
import { setErrorBanner } from 'contexts/app/actions';
import { useAppState } from 'contexts/app';

import { CategoryEnum } from 'constatns';
import { useNavigate } from 'react-router';
import getRouteWithParams from 'utils/getRouteWithParams';
import { PATHS } from 'navigation/constants';
import { useGeoLocation } from 'hooks/useGeoLocation';
import useColumnsCount from './useColumnsCount';

const SERVICE_GROUPS_PER_FETCH_COUNT = 5;

const useServicesPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { dispatch } = useAppState();

  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [serviceGroups, setServiceGroups] = useState<OutputServiceGroupDto[]>([]);
  const [serviceGroupsLoading, setServiceGroupsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const [searchGroupedServices] = useLazyQuery<
    { groupedServices: GroupedServicesConnection },
    {
      filter: InputServiceFilterDtoInput;
      coordinates?: Coordinates;
      first?: number;
      after?: string;
      last?: number;
      before?: string;
      servicesCountPerGroup?: number;
    }
  >(GROUPED_SERVICES);
  const [searchServices] = useLazyQuery<
    { services: ServicesConnection },
    {
      companyId?: number;
      masterId?: number;
      filter: InputServiceFilterDtoInput;
      first?: number;
      after?: string;
      last?: number;
      before?: string;
    }
  >(SERVICES);

  const servicesPerFetchCount = useColumnsCount();

  const coordinates = useGeoLocation();

  const categoryId = searchParams.get('categoryId') as CategoryEnum | null;
  const townId = searchParams.get('townId');
  const name = searchParams.get('name');
  const priceFrom = searchParams.get('priceFrom');
  const priceTo = searchParams.get('priceTo');

  useEffect(() => {
    setServiceGroupsLoading(true);
    setServiceGroups([]);

    searchGroupedServices({
      variables: {
        filter: {
          ...(categoryId && { categoryId }),
          ...(townId && { townId: +townId }),
          ...(name && { name }),
          ...(priceFrom && { priceFrom: +priceFrom }),
          ...(priceTo && { priceTo: +priceTo }),
        },
        first: SERVICE_GROUPS_PER_FETCH_COUNT,
        servicesCountPerGroup: servicesPerFetchCount,
        ...(coordinates && { coordinates }),
      },
    })
      .then(({ data }) => {
        if (data?.groupedServices) {
          setPageInfo(data.groupedServices.pageInfo);
          setServiceGroups(data.groupedServices.nodes);
        }
      })
      .finally(() => {
        setServiceGroupsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, townId, name, priceFrom, priceTo, coordinates, servicesPerFetchCount, i18n.language]);

  const showMoreServiceGroups = () => {
    setServiceGroupsLoading(true);

    searchGroupedServices({
      variables: {
        filter: {
          ...(categoryId && { categoryId }),
          ...(townId && { townId: +townId }),
          ...(name && { name }),
          ...(priceFrom && { priceFrom: +priceFrom }),
          ...(priceTo && { priceTo: +priceTo }),
        },
        first: SERVICE_GROUPS_PER_FETCH_COUNT,
        after: pageInfo?.endCursor,
        servicesCountPerGroup: servicesPerFetchCount,
      },
    })
      .then(({ data }) => {
        if (data) {
          setPageInfo(data.groupedServices.pageInfo);
          setServiceGroups(prev => [...prev, ...data.groupedServices.nodes]);
        }
      })
      .finally(() => {
        setServiceGroupsLoading(false);
      });
  };

  const showMoreServices = async ({ masterId, companyId }: { masterId?: number; companyId?: number }) => {
    try {
      const after = serviceGroups.find(item => {
        if (companyId !== undefined) {
          return item.company?.id === companyId;
        } else {
          return item.master?.id === masterId;
        }
      })?.services.length;
      const response = await searchServices({
        variables: {
          masterId,
          companyId,
          filter: {
            ...(categoryId && { categoryId }),
            ...(townId && { townId: +townId }),
            ...(name && { name }),
            ...(priceFrom && { priceFrom: +priceFrom }),
            ...(priceTo && { priceTo: +priceTo }),
          },
          first: servicesPerFetchCount,
          after: after?.toString(),
        },
      });

      const services = response.data?.services.nodes;
      if (!services?.length) return;
      setServiceGroups(prev =>
        prev.map(item => {
          if ((item.company && item.company.id === companyId) || (item.master && item.master.id === masterId)) {
            return { ...item, services: [...item.services, ...services] };
          } else return item;
        }),
      );
    } catch (error) {
      dispatch(setErrorBanner(error as ApolloError));
    }
  };

  const handlePartnerClick = (slug?: string) => !!slug && navigate(getRouteWithParams(PATHS.PARTNER_DETAILS, { slug }));

  const handleServiceClick = (id: number) =>
    navigate(getRouteWithParams(PATHS.SERVICE_DETAILS_PAGE, { serviceId: id.toString() }));

  return {
    hasNextPage: pageInfo?.hasNextPage,
    serviceGroupsPerFetchCount: SERVICE_GROUPS_PER_FETCH_COUNT,
    servicesPerFetchCount,
    serviceGroups,
    serviceGroupsLoading,
    showMoreServiceGroups,
    showMoreServices,
    handlePartnerClick,
    handleServiceClick,
  };
};

export default useServicesPage;
