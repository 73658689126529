import React from 'react';
import { Typography, BreadcrumbsProps as MUIBreadcrumbsProps } from '@mui/material';

import { StyledBreadcrumbs, StyledLink } from './styled';

type BreadcrumbSegment = {
  path: string;
  label?: string;
};

export type BreadcrumbsProps = {
  breadcrumbsSegments?: BreadcrumbSegment[];
  currentPageLabel?: string | null;
} & MUIBreadcrumbsProps;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbsSegments, currentPageLabel, ...props }) => (
  <StyledBreadcrumbs separator="-" {...props}>
    {breadcrumbsSegments?.map(({ path, label }) => {
      return (
        <StyledLink key={path} color="inherit" to={path}>
          {label}
        </StyledLink>
      );
    })}
    <Typography key={currentPageLabel} color="primary.dark2" variant="body3" sx={{ display: 'inline' }}>
      {currentPageLabel}
    </Typography>
  </StyledBreadcrumbs>
);
export default Breadcrumbs;
