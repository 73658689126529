import createCustomTheme from './createCustomTheme';
import { colors } from '../constants';

const commonFontStyles = {
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'normal',
  letterSpacing: 0,
};

const blackFontStyles = {
  ...commonFontStyles,
  fontWeight: '900',
};

const extraBoldFontStyles = {
  ...commonFontStyles,
  fontWeight: '800',
};

const boldFontStyles = {
  ...commonFontStyles,
  fontWeight: '700',
};

const semiBoldFontStyles = {
  ...commonFontStyles,
  fontWeight: '600',
};

const regularFontStyles = {
  ...commonFontStyles,
  fontWeight: '400',
};

const mainTheme = createCustomTheme({
  palette: {
    primary: {
      main: colors.black85,
      dark: colors.black85,
      dark2: colors.black40,
      dark3: colors.black20,
      light: colors.white,
      light2: colors.black02,
      light3: colors.black08,
    },
    secondary: {
      main: colors.yellow,
      dark: colors.orange,
    },
    accent: {
      main: colors.blueAccent,
      dark: colors.clickAccent,
      light: colors.hoverAccent,
    },
    error: {
      main: colors.red,
    },
  },
  typography: {
    h1: {
      ...blackFontStyles,
      fontSize: '48px',
      lineHeight: '56px',
    },
    h2: {
      ...blackFontStyles,
      fontSize: '32px',
      lineHeight: '36px',
    },
    h3: {
      ...blackFontStyles,
      fontSize: '24px',
      lineHeight: '28px',
    },
    h4: {
      ...blackFontStyles,
      fontSize: '20px',
      lineHeight: '24px',
    },
    h5: {
      ...extraBoldFontStyles,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h6: {
      ...extraBoldFontStyles,
      fontSize: '16px',
      lineHeight: '22px',
    },
    subtitle1: {
      ...boldFontStyles,
      fontSize: '14px',
      lineHeight: '18px',
    },
    subtitle2: {
      ...boldFontStyles,
      fontSize: '12px',
      lineHeight: '16px',
    },
    subtitle3: {
      ...boldFontStyles,
      fontSize: '11px',
      lineHeight: '16px',
    },
    body1: {
      ...regularFontStyles,
      fontSize: '14px',
      lineHeight: '20px',
    },
    body2: {
      ...regularFontStyles,
      fontSize: '12px',
      lineHeight: '18px',
    },
    body3: {
      ...regularFontStyles,
      fontSize: '11px',
      lineHeight: '16px',
    },
    link1: {
      ...regularFontStyles,
      fontSize: '14px',
      lineHeight: '18px',
      textDecorationLine: 'underline',
    },
    link2: {
      ...regularFontStyles,
      fontSize: '12px',
      lineHeight: '16px',
      textDecorationLine: 'underline',
    },
    link3: {
      ...regularFontStyles,
      fontSize: '11px',
      lineHeight: '16px',
      textDecorationLine: 'underline',
    },
    tag1: {
      ...semiBoldFontStyles,
      fontSize: '9px',
      lineHeight: '12px',
      textTransform: 'uppercase',
    },
    tag2: {
      ...semiBoldFontStyles,
      fontSize: '11px',
      lineHeight: '14px',
      textTransform: 'uppercase',
    },
    tag3: {
      ...semiBoldFontStyles,
      fontSize: '12px',
      lineHeight: '14px',
      textTransform: 'uppercase',
    },
  },
});

export default mainTheme;
