export * from './auth';

export const CONTACT_US_EMAIL = 'keepser@gmail.com';
export const APP_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export enum DayOfWeekEnum {
  Mon = 'mon',
  Tue = 'tue',
  Wed = 'wed',
  Thu = 'thu',
  Fri = 'fri',
  Sat = 'sat',
  Sun = 'sun',
}

export { CategoryEnum } from './actiivity';

export const APP_BAR_HEIGHT = 64;
