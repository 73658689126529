import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledMainContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 20px 40px;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `,
);

export const StyledLeftContainer = styled(Box)`
  flex: 0 0 440px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledInput = styled('input')`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;
