import { Category, OutputServicePhotoDto } from 'types';
import {
  WithSubcategoriesProps,
  WithoutSubcategoriesProps,
  ExamplesSectionProps,
} from 'components/@common/ExamplesSection';

import { isArray } from '@apollo/client/utilities';

type Service = {
  category: Category;
  photos?: OutputServicePhotoDto[];
};

function mapServicesToExamplesViewModel(data: Service): WithoutSubcategoriesProps;
function mapServicesToExamplesViewModel(data: Service[]): WithSubcategoriesProps;
function mapServicesToExamplesViewModel(data: Service | Service[]): ExamplesSectionProps {
  if (isArray(data)) {
    return {
      examples: data
        .reduce<
          Array<{
            id: string;
            subcategoryTitle: string;
            imgSrcList: string[];
          }>
        >((acc, e) => {
          const subcategory = e.category.attributeGroups.find(ag => ag.isSubcategory);

          if (!subcategory) return acc;

          const subcategoryIndex = acc.findIndex(s => s.id === subcategory?.attribute.id);

          if (subcategoryIndex !== -1) {
            return acc.map((s, index) =>
              index === subcategoryIndex
                ? {
                    ...s,
                    imgSrcList: [...s.imgSrcList, ...(e.photos || []).map(item => item.uri)],
                  }
                : s,
            );
          }
          return [
            ...acc,
            {
              id: subcategory.attribute.id,
              subcategoryTitle: subcategory.attribute.localizedName,
              imgSrcList: (e.photos || []).map(item => item.uri),
            },
          ];
        }, [])
        .filter(({ imgSrcList }) => !!imgSrcList.length),
    };
  }
  return {
    examples: (data.photos || []).map(item => item.uri),
  };
}

export default mapServicesToExamplesViewModel;
