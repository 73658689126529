import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import CircularProgress from 'components/@shared/CircularProgress';

const LoadingState = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="cener" justifyContent="center" flex="1 1 100%">
      <Stack direction="row" gap="8px" alignItems="center">
        <CircularProgress />
        <Typography variant="body1">{t('loadingStateMessage')}</Typography>
      </Stack>
    </Stack>
  );
};

export default LoadingState;
