import React from 'react';
import { HorizontalGap, VerticalGap } from './styled';

type Props = {
  size: number;
  isHorizontal?: boolean;
};

const Gap: React.FC<Props> = ({ isHorizontal = false, size }) => {
  return isHorizontal ? <HorizontalGap size={size} /> : <VerticalGap size={size} />;
};

export default Gap;
