import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PATHS } from 'navigation/constants';

import { useNavigate } from 'react-router';
import {
  StyledContainer,
  StyledCopyrightSection,
  StyledLanguageSelector,
  StyledList,
  StyledListItemButton,
  StyledLogoIcon,
} from './styled';

type Props = {
  className?: string;
};

const BottomSection: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledContainer className={className}>
      {!upMedium && (
        <StyledList>
          <ListItem disablePadding>
            <Stack justifyContent="center" alignItems="center" width="100%">
              <StyledLanguageSelector />
            </Stack>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.TERMS_AND_CONDITIONS_PAGE)} disableRipple>
              <Typography variant="body2">{t('nav.termsAndConditionsLabel')}</Typography>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton onClick={() => navigate(PATHS.PRIVACY_POLICY_PAGE)} disableRipple>
              <Typography variant="body2">{t('nav.privacyPolicy')}</Typography>
            </StyledListItemButton>
          </ListItem>
        </StyledList>
      )}
      <StyledCopyrightSection>
        <StyledLogoIcon />
        <Typography variant="subtitle3" color="primary.main">
          {t('footer.copyright')}
        </Typography>
      </StyledCopyrightSection>
    </StyledContainer>
  );
};
export default BottomSection;
