import React from 'react';
import { Skeleton } from '@mui/material';
import { StyledContainer } from './styled';

export const ExpandableMultipleSelectSkeleton: React.FC = () => (
  <StyledContainer>
    <Skeleton height={16} width={100} />
    <Skeleton height={24} width={24} variant="circular" />
  </StyledContainer>
);
