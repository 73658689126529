export const SEGMENTS = {
  MASTER_CALENDAR: '/master/calendar',
  COMPANY_CALENDAR: '/company/calendar',

  MASTER_CABINET: '/master/cabinet',
  COMPANY_CABINET: '/company/cabinet',
  CLIENT_CABINET: '/client/cabinet',
};

export const PATHS = {
  MAIN_PAGE: '/',
  SERVICES_PAGE: '/services',
  SERVICE_DETAILS_PAGE: '/services/:serviceId',
  FAVORITES_PAGE: '/favorites',
  ABOUT_PAGE: '/about',
  FOR_COMPANIES_PAGE: '/for-companies',
  TERMS_AND_CONDITIONS_PAGE: '/terms-and-conditions',
  PRIVACY_POLICY_PAGE: '/privacy-policy',

  MASTER_CABINET_INFO_TAB: '/master/cabinet/info',
  MASTER_CABINET_SERVICES_TAB: '/master/cabinet/services',
  MASTER_CABINET_SERVICES_TAB_ADD_SERVICE: '/master/cabinet/services/add-service',
  MASTER_CABINET_SERVICES_TAB_EDIT_SERVICE: '/master/cabinet/services/edit-service/:serviceId',
  MASTER_CALENDAR_SCHEDULE: '/master/calendar/schedule',
  MASTER_CALENDAR_NEW_ORDERS: '/master/calendar/new-orders',
  MASTER_RESERVATION_DETAILS: '/master/reservationDetails/:reservationId',

  COMPANY_CABINET_INFO_TAB: '/company/cabinet/info',
  COMPANY_CABINET_MASTERS_TAB: '/company/cabinet/masters',
  COMPANY_CABINET_CREATE_MASTER_INFO_TAB: '/company/cabinet/master/create/info',
  COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB: '/company/cabinet/master/create/services',
  COMPANY_CABINET_MASTER_INFO_TAB: '/company/cabinet/master/:id/info',
  COMPANY_CABINET_MASTER_SERVICES_TAB: '/company/cabinet/master/:id/services',
  COMPANY_CABINET_MASTER_SERVICES_TAB_ADD_SERVICE: '/company/cabinet/master/:id/services/add-service',
  COMPANY_CABINET_MASTER_SERVICES_TAB_EDIT_SERVICE: '/company/cabinet/master/:id/services/edit-service/:serviceId',
  COMPANY_CALENDAR_SCHEDULE: '/company/calendar/schedule',
  COMPANY_CALENDAR_NEW_ORDERS: '/company/calendar/new-orders',
  COMPANY_RESERVATION_DETAILS: '/company/reservationDetails/:reservationId',

  CLIENT_CABINET_INFO_TAB: '/client/cabinet/info',
  CLIENT_CABINET_RESERVATIONS_TAB: '/client/cabinet/reservations',

  BOOKING_SERVICE: '/booking-service/:serviceId',

  PARTNER_DETAILS: '/:slug',
  COMPANY_MASTER_DETAILS_PAGE: '/:slug/master/:masterId',
};

export const RESERVED_SEGMENTS = [
  'authenticate',
  'services',
  'favorites',
  'about',
  'for-companies',
  'terms-and-conditions',
  'privacy-policy',
  'master',
  'company',
  'client',
  'booking-service',
  'home',
  'account',
  'cabinet',
];
