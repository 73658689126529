import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import TruncatedText from 'components/@shared/TruncatedText';
import generateRandomNumber from 'utils/generateRundomNumber';
import generateIncrementalArray from 'utils/generateIncrementalArray';
import Card from 'components/@shared/Card';

const ReservationCardSkeleton: React.FC = () => (
  <Card
    disabled
    withBorder
    id={0}
    header={
      <Stack direction="row" gap="8px" alignItems="center" width="100%">
        <Skeleton variant="circular" width={32} height={32} />
        <Typography variant="body2" color="primary.dark2">
          <Skeleton width={generateRandomNumber(60, 100)} />
        </Typography>
      </Stack>
    }
    attributeListNode={generateIncrementalArray(generateRandomNumber(1, 4)).map(item => (
      <Stack key={item} direction="row" gap="8px" alignItems="center" justifyContent="start" width="100%">
        <Typography variant="body2" color="primary.dark2">
          <Skeleton width={generateRandomNumber(60, 100)} />
        </Typography>
        <TruncatedText variant="body2" color="primary.main">
          <Skeleton width={generateRandomNumber(60, 100)} />
        </TruncatedText>
      </Stack>
    ))}
    cardActions={
      <>
        <TruncatedText variant="h4" color="accent.main" component="h4">
          <Skeleton width={generateRandomNumber(60, 80)} />
        </TruncatedText>
        <Skeleton width="100%" height={38} />
        <Skeleton width="100%" height={42} />
      </>
    }
  />
);

export default ReservationCardSkeleton;
