import React from 'react';
import { Stack } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import Button from 'components/@shared/Button';
import IconButton from 'components/@shared/IconButton';
import InlineList from 'components/@shared/InlineList';

import { useTranslation } from 'react-i18next';
import { StyledContainer } from './styled';

type Item = {
  id: string;
  chipSegmentList: string[];
};

type Props = {
  header: React.ReactNode;
  chipList: Item[];
  white?: boolean;
  className?: string;
  onDeleteSection?: () => void;
  onDeleteItem?: (id: Item) => void;
};

const ChipsSection: React.FC<Props> = ({ header, chipList, white, className, onDeleteSection, onDeleteItem }) => {
  const { t } = useTranslation();

  return (
    <StyledContainer white={white} className={className}>
      <Stack direction="row" justifyContent="space-between">
        {header}
        {onDeleteSection ? (
          <Button color="error" onClick={onDeleteSection}>
            {t('delete')}
          </Button>
        ) : null}
      </Stack>
      <Stack gap="8px">
        {chipList.map(({ id, chipSegmentList }) => (
          <Stack key={id} direction="row" justifyContent="space-between" alignItems="start" gap="8px">
            <Stack display="inline" alignSelf="center">
              <InlineList list={chipSegmentList.map((item, index) => ({ item, key: index }))} />
            </Stack>
            {onDeleteItem ? (
              <IconButton color="error" onClick={() => onDeleteItem({ id, chipSegmentList })}>
                <DeleteIcon />
              </IconButton>
            ) : null}
          </Stack>
        ))}
      </Stack>
    </StyledContainer>
  );
};

export default ChipsSection;
