import React from 'react';
import { Stack, Typography } from '@mui/material';
import { StaticDatePickerProps } from '@mui/x-date-pickers/StaticDatePicker/StaticDatePicker.types';

import { Dayjs } from 'dayjs';
import { OutputScheduleDayDto } from 'types';
import convertTimeSpanToDayjs from 'utils/convertTimeSpanToDayjs';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './styled';

export type TimeSlotsSectionProps = Pick<StaticDatePickerProps<Dayjs>, 'value' | 'onMonthChange'> & {
  activeTimeSlot: null | OutputScheduleDayDto;
  availableTimeSlots: OutputScheduleDayDto[];
  onChange: (timeSlot: OutputScheduleDayDto) => void;
  loading?: boolean;
  className?: string;
};

const TimeSlotsSection: React.FC<TimeSlotsSectionProps> = ({
  availableTimeSlots,
  activeTimeSlot,
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loading,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <Stack gap="12px" className={className}>
      <Typography variant="subtitle1">{t('bookServicePage.timestampsTitle')}</Typography>
      <Stack gap="8px" direction="row" flexWrap="wrap">
        {availableTimeSlots.map(({ from, to }) => (
          <StyledButton
            variant="contained"
            key={from + to}
            active={!!activeTimeSlot && from === activeTimeSlot.from && to === activeTimeSlot.to}
            onClick={() => onChange({ from, to })}
          >
            {`${convertTimeSpanToDayjs(from).format('HH:mm')} - ${convertTimeSpanToDayjs(to).format('HH:mm')}`}
          </StyledButton>
        ))}
      </Stack>
    </Stack>
  );
};

export default TimeSlotsSection;
