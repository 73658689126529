import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import generateIncrementalArray from 'utils/generateIncrementalArray';
import { ServiceCardSkeleton } from 'components/@common/ServiceCard';

import { StyledServiceGroupContainer, StyledServiceGroupList } from './styled';

type Props = {
  servicesPerFetchCount: number;
  seed?: string;
};

const ServiceGroupSkeleton: React.FC<Props> = ({ servicesPerFetchCount, seed }) => (
  <StyledServiceGroupContainer>
    <Stack flexDirection="row" gap="8px" justifyContent="space-between" alignItems="center">
      <Stack flexDirection="row" gap="8px" alignItems="center">
        <Skeleton variant="circular" width={40} height={40} />
        <Typography variant="h6" color="primary.main">
          <Skeleton width={100} />
        </Typography>
      </Stack>
      <Typography variant="body2" color="primary.main">
        <Skeleton width={70} />
      </Typography>
    </Stack>
    <StyledServiceGroupList>
      {generateIncrementalArray(servicesPerFetchCount).map(item => (
        <ServiceCardSkeleton key={item} seed={seed + item.toString()} />
      ))}
    </StyledServiceGroupList>
  </StyledServiceGroupContainer>
);

export default ServiceGroupSkeleton;
