import { css, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';
import {
  CELL_HEIGHT,
  RESERVATION_ITEM_PADDING,
  RESERVATION_ITEM_BORDER,
  ROWS_COUNTS,
  TEXT_LINE_HEIGHT,
  CELL_PADDING,
} from '../constants';
import { ReservationCoordinates } from './types';

export const StyledHour = styled(
  Stack,
  preventForwardProps(['index']),
)<{ index: number }>(
  ({ theme, index }) => css`
    position: absolute;
    width: 30px;
    height: 12px;
    background: ${theme.palette.background.default};
    top: ${index * CELL_HEIGHT - 5}px;

    color: ${theme.palette.primary.dark2};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
  `,
);

export const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    width: 100%;
    height: ${CELL_HEIGHT * ROWS_COUNTS}px;
    position: relative;
    border: 1px solid ${theme.palette.primary.light3};
    border-left: none;
    border-top: none;

    &:last-child {
      border-radius: 0 0 8px 0;
    }
  `,
);

export const StyledRowDivider = styled(
  Stack,
  preventForwardProps(['index']),
)<{ index: number }>(
  ({ theme, index }) => css`
    position: absolute;
    background: ${theme.palette.primary.light3};
    width: 100%;
    height: 1px;
    top: ${index * CELL_HEIGHT}px;
  `,
);

export const StyledReservationItem = styled(
  Stack,
  preventForwardProps(['approved', 'coordinates']),
)<{ approved: boolean; coordinates: ReservationCoordinates }>(({ theme, approved, coordinates }) => {
  const height = CELL_HEIGHT * coordinates.height;
  const linesCount = Math.floor((height - (RESERVATION_ITEM_BORDER + RESERVATION_ITEM_PADDING) * 2) / TEXT_LINE_HEIGHT);

  return css`
    position: absolute;
    top: ${coordinates.start * CELL_HEIGHT}px;
    left: calc(
      ${(100 / coordinates.slotsCount) * coordinates.slotPosition}% +
        ${((CELL_PADDING * 2) / coordinates.slotsCount - RESERVATION_ITEM_BORDER) * coordinates.slotsCount -
        RESERVATION_ITEM_BORDER * coordinates.slotPosition}px
    );
    width: calc(
      ${100 / coordinates.slotsCount}% - ${(CELL_PADDING * 2) / coordinates.slotsCount - RESERVATION_ITEM_BORDER}px
    );
    height: ${height - RESERVATION_ITEM_PADDING}px;
    margin-top: ${RESERVATION_ITEM_PADDING}px;

    background: ${approved ? theme.palette.accent.main : theme.palette.background.default};
    border-radius: 8px;
    border: ${RESERVATION_ITEM_BORDER}px solid
      ${approved ? theme.palette.background.default : theme.palette.accent.main};
    padding: ${RESERVATION_ITEM_PADDING}px;

    cursor: pointer;

    span {
      font-family: Montserrat sans-serif;
      font-weight: 600;
      font-size: 6px;
      line-height: ${TEXT_LINE_HEIGHT}px;

      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: ${linesCount};
      -webkit-box-orient: vertical;
    }

    :hover {
      background: ${approved ? theme.palette.accent.light : theme.palette.primary.light2};
    }

    :active {
      background: ${approved ? theme.palette.accent.dark : theme.palette.primary.light3};
    }

    * {
      color: ${approved ? theme.palette.background.default : theme.palette.accent.main};
    }
  `;
});
