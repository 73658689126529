import React from 'react';
import { ClickAwayListener, Popper, Stack, Typography } from '@mui/material';
import { ReactComponent as ArrowRightSquareIcon } from 'assets/icons/arrowRightSquare.svg';
import { ReactComponent as ArrowLeftSquareIcon } from 'assets/icons/arrowLeftSquare.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import 'dayjs/locale/uk';
import dayjs, { Dayjs } from 'dayjs';
import IconButton from 'components/@shared/IconButton';

import { StyledMonthButton, StyledPopperContainer } from './styled';
import i18n from '../../../../localizations';

export type Props = {
  date: Dayjs;
  onChange: (date: Dayjs) => void;
  className?: string;
};

const getMonthList = (day: Dayjs) => {
  let currentMonth = day.startOf('month');
  const list = [currentMonth];

  for (let i = 0; i < 11; i++) {
    currentMonth = currentMonth.add(1, 'month');
    list.push(currentMonth);
  }

  return list;
};

const Header: React.FC<Props> = ({ date, onChange, className }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleClickAway = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const startOfDate = date.startOf('month');

  const isCurrentMonth = startOfDate.isSame(dayjs().startOf('month'));

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" className={className}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledMonthButton aria-describedby={id} direction="row" alignItems="center" gap="12px" onClick={handleClick}>
          <Typography variant="subtitle1">{date.locale('uk').format('MMMM YYYY')}</Typography>
          <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <StyledPopperContainer>
              {getMonthList(dayjs()).map(item => {
                const value = item.locale(i18n.language).format('MMMM YYYY');
                return (
                  <Typography
                    key={value}
                    variant="subtitle1"
                    color={startOfDate.isSame(item) ? 'accent.main' : 'primary.main'}
                    onClick={() => onChange(item)}
                  >
                    {value}
                  </Typography>
                );
              })}
            </StyledPopperContainer>
          </Popper>
        </StyledMonthButton>
      </ClickAwayListener>
      <Stack direction="row" alignItems="center" gap="8px">
        <IconButton disabled={isCurrentMonth} onClick={() => onChange(startOfDate.subtract(1, 'month'))}>
          <ArrowLeftSquareIcon />
        </IconButton>
        <IconButton onClick={() => onChange(startOfDate.add(1, 'month'))}>
          <ArrowRightSquareIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Header;
