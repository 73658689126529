import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import ReactPhoneInput, { CountryData as PhoneMetaData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { getCountryCode } from 'utils/geyCountryCode';

import { StyledDropdownBackground, StyledContainer, StyledUpperContainer, StyledHelperText } from './styled';

const NO_FLAG_COUNTRIES = ['AX', 'YT', 'EH'];

export interface PhoneNumber {
  phone: string;
  dialCode: string;
  raw: string;
  countryCode: string;
}

type Props = Omit<PhoneInputProps, 'onChange' | 'value' | 'onlyCountries'> & {
  name: string;
  value?: string;
  label: string;
  onChange?: (value: string, data: PhoneMetaData | {}) => void;
  onValidate?: (error: boolean) => void;
  error?: boolean;
  helperText?: string | ReactNode;
  maxLength?: number;
  defaultCountry?: string;
  country?: string;
};

const PhoneInput: React.FC<Props> = ({
  name,
  value,
  country,
  onChange,
  onValidate,
  error,
  helperText,
  inputProps,
  maxLength,
  placeholder,
  label,
  defaultCountry = getCountryCode(),
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLCollectionOf<Element>>(document.getElementsByClassName('flag-dropdown'));

  const [rawValue, setRawValue] = useState('');
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const calculatedMaxLength = useMemo(() => {
    if (maxLength && inputRef?.current) {
      return maxLength + inputRef?.current?.value?.length - rawValue?.length;
    }
  }, [rawValue, maxLength]);

  const handleToggleDropdown = () => setToggleDropdown(!toggleDropdown);

  const handleChange = (val: string, data: PhoneMetaData) => {
    setRawValue(val);

    if (!onChange) return;
    onChange(`+${val}`, data);

    onValidate?.(val.length !== 9);
  };

  useEffect(() => {
    const hasOpenClass = !!dropdownRef.current.item(0)?.classList.contains('open');

    setIsDropdownOpen(hasOpenClass);
  }, [toggleDropdown]);

  const countryValue =
    !country || NO_FLAG_COUNTRIES.includes(country) ? defaultCountry.toLowerCase() : country?.toLowerCase();

  return (
    <>
      {/*StyledDropdownBackground component prevents scrolling the content when dropdown is open*/}
      <StyledDropdownBackground
        data-testid="phone-input-backdrop"
        isDropdownOpen={isDropdownOpen}
        onClick={handleToggleDropdown}
      />

      <StyledUpperContainer ref={containerRef}>
        <StyledContainer error={error} onClick={handleToggleDropdown}>
          <ReactPhoneInput
            inputProps={{
              ref: inputRef,
              name,
              id: name,
              maxLength: calculatedMaxLength,
              ...inputProps,
            }}
            onChange={handleChange}
            country={countryValue}
            value={value}
            enableTerritories
            enableLongNumbers
            specialLabel={label}
            placeholder={placeholder || ''}
            {...props}
          />
          {helperText && (
            <StyledHelperText variant="subtitle2" error={error}>
              {helperText}
            </StyledHelperText>
          )}
        </StyledContainer>
      </StyledUpperContainer>
    </>
  );
};

export default PhoneInput;

export type { PhoneMetaData };
