import React, { useEffect, useRef, useState } from 'react';
import { getInitials } from 'utils/getInitials';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
import useResize from 'hooks/useResize';
import { StyledDefaultAvatar, StyledImg, StyledImgContainer } from './styled';

type Props = {
  profileImageUri?: string;
  name?: string;
  contactName?: string;
  email?: string;
  size?: string;
};

const Avatar: React.FC<Props> = ({ profileImageUri, name, contactName, email, size = '32px' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [fontSize, setFontSize] = useState<null | number>(null);

  const { height } = useResize(ref);

  useEffect(() => {
    if (ref.current?.offsetHeight) {
      setFontSize(Math.round(ref.current.offsetHeight * 0.5));
    }
  }, [height]);

  const imageStatus = useLoadImage(profileImageUri || '');

  return (
    <StyledImgContainer ref={ref} size={size}>
      {!!profileImageUri && imageStatus === ImageStatus.Loaded && (
        <StyledImg src={profileImageUri} alt={profileImageUri} />
      )}
      {!profileImageUri && fontSize && (
        <StyledDefaultAvatar hash={`${email || ''}${name || ''}${contactName || ''}`} fontSize={fontSize}>
          {getInitials(name || contactName || '')}
        </StyledDefaultAvatar>
      )}
    </StyledImgContainer>
  );
};

export default Avatar;
