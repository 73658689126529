import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { CategoryEnum } from 'constatns';
import { CategoryItem, InputServiceFilterDtoInput } from 'types/service';
import { MAX_PRICE } from 'api/requests/service';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import useCategories from 'hooks/useCategories';

import { FiltersCommonType, Price } from './types';

const useFilters = (): FiltersCommonType => {
  const [fetchMaxPrice] = useLazyQuery<{ maxPrice: number }, { filter: InputServiceFilterDtoInput }>(MAX_PRICE, {
    fetchPolicy: 'no-cache',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCategoryId = searchParams.get('categoryId') as CategoryEnum | null;
  const townId = searchParams.get('townId');
  const name = searchParams.get('name');
  const priceFrom = searchParams.get('priceFrom');
  const priceTo = searchParams.get('priceTo');

  const [maxPrice, setMaxPrice] = useState(0);
  const [price, setPrice] = useState<Price>({
    priceFrom: priceFrom ? +priceFrom : null,
    priceTo: priceTo ? +priceTo : null,
  });

  const getMaxPrice = (categoryId: CategoryEnum | null = selectedCategoryId) =>
    fetchMaxPrice({
      variables: {
        filter: {
          ...(categoryId && { categoryId: categoryId }),
          ...(townId && { townId: +townId }),
          ...(name && { name }),
        },
      },
    }).then(res => {
      if (res.data?.maxPrice) {
        setMaxPrice(res.data.maxPrice);
      }
    });

  useEffect(() => {
    getMaxPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryId, townId, name]);

  const debouncedPrice = useDebounce(price);

  useEffect(() => {
    setSearchParams(params => {
      if (debouncedPrice.priceFrom === null) {
        params.delete('priceFrom');
      } else {
        params.set('priceFrom', debouncedPrice.priceFrom.toString());
      }
      if (debouncedPrice.priceTo === null) {
        params.delete('priceTo');
      } else {
        params.set('priceTo', debouncedPrice.priceTo.toString());
      }
      return params;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPrice]);

  const categories = useCategories();

  const selectedCategory = useMemo(() => {
    return Object.values(categories).find(item => item.id === selectedCategoryId) || null;
  }, [categories, selectedCategoryId]);

  const onChange = ({ category, price }: { category?: CategoryItem | null; price?: Price }) => {
    if (category !== undefined) {
      setSearchParams(params => {
        if (category) {
          params.set('categoryId', category.id);
        } else {
          params.delete('categoryId');
        }
        return params;
      });
    }
    if (price !== undefined) {
      setPrice(price);
    }
  };

  return {
    categoryList: Object.values(categories),
    selectedCategory,
    price,
    maxPrice,
    getMaxPrice,
    onChange,
  };
};

export default useFilters;
