import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import CategoryLabel from 'components/@common/CategoryLabel';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import TruncatedText from 'components/@shared/TruncatedText';
import IconButton from 'components/@shared/IconButton';
import Image from 'components/@shared/Image';
import ContactsSection from 'components/@common/ContactsSection';
import ExamplesSection from 'components/@common/ExamplesSection';
import mapServicesToExamplesViewModel from 'utils/mapServicesToExamplesViewModel';
import { useBookServiceModal, BookServiceModal } from 'components/@common/BookButton';
import LoadingState from 'components/@common/LoadingState';

import mapServiceModelToContactsSectionViewModel from 'utils/mapServiceModelToContactsSectionViewModel';
import getUniqueCategories from 'utils/getUniqeCategories';

import useCompanyDetailsPage from './useCompanyDetailsPage';
import { StyledMainContainer, StyledAttributeRow, StyledInfoSection, StyledServicesSection } from './styled';
import { useBreadcrumbs } from './useBreadcrumbs';
import AllServicesSection from './AllServicesSection';
import mapServiceListModelToAllServicesSectionViewModel from './mapServiceListModelToAllServicesSectionViewModel';

type Props = {
  id: number;
};

const CompanyDetailsPage: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { companyDetails, loading, handleServiceClick, handleMasterClick } = useCompanyDetailsPage(id);
  const breadcrumbs = useBreadcrumbs(companyDetails);
  const { onBook, ...bookServiceModalProps } = useBookServiceModal();

  const shareData = {
    title: companyDetails?.company?.name || '',
    text:  companyDetails?.company?.about || '',
    url: window.location.href,
  };

  const handleShare = () => {
    if (navigator.share && navigator.canShare(shareData)) {
      navigator.share(shareData);
    }
  };

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  if (loading) {
    return <LoadingState />;
  }

  if (!companyDetails) {
    return null;
  }

  return (
    <>
      <Container>
        <Breadcrumbs {...breadcrumbs} />
        <StyledMainContainer>
          <StyledInfoSection>
            <Box display="flex" flexDirection="column" gap="12px">
              <Box display="flex" gap="12px" justifyContent="space-between" alignItems="start">
                <Box display="flex" gap="12px" flexWrap="wrap">
                  {getUniqueCategories(companyDetails.services).map(item => (
                    <CategoryLabel key={item.id} category={item.id} variant="grey" />
                  ))}
                </Box>
                {!upMedium && (
                  <IconButton onClick={handleShare}>
                    <ShareIcon height="40px" width="40px" />
                  </IconButton>
                )}
              </Box>
              <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
                {companyDetails.company?.name}
              </Typography>
            </Box>

            <Image src={companyDetails.company?.profileImageUri} alt={companyDetails.company?.name} />

            {!!companyDetails?.company?.town && (
              <StyledAttributeRow>
                <Typography variant="subtitle1" color="primary.dark2">
                  {t('town')}:
                </Typography>
                <TruncatedText variant="subtitle1" color="primary.main">
                  {companyDetails.company?.town?.localizedName}
                </TruncatedText>
              </StyledAttributeRow>
            )}

            {!!companyDetails?.company?.about && (
              <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                <Typography variant="body1" color="primary.main" sx={{ opacity: 0.7 }}>
                  {companyDetails?.company?.about}
                </Typography>
              </pre>
            )}

            <ContactsSection {...mapServiceModelToContactsSectionViewModel(companyDetails)} />
          </StyledInfoSection>

          <StyledServicesSection>
            <AllServicesSection
              {...mapServiceListModelToAllServicesSectionViewModel(companyDetails.services)}
              onBookService={onBook}
              onOpenService={handleServiceClick}
              onMasterClick={handleMasterClick}
            />
          </StyledServicesSection>
        </StyledMainContainer>
      </Container>
      <ExamplesSection {...mapServicesToExamplesViewModel(companyDetails.services)} />
      <BookServiceModal {...bookServiceModalProps} />
    </>
  );
};

export default CompanyDetailsPage;
