import { css, styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const StyledContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const StyledButton = styled(Button)(({ theme }) => {
  return css`
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: none;
    padding: 0;

    background: none;
    color: ${theme.palette.accent.main};

    &:hover {
      color: ${theme.palette.accent.light};
      background: none;
    }

    &:active {
      color: ${theme.palette.accent.dark};
      background: none;
    }
  `;
});
