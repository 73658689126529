import { useEffect } from 'react';
import { ACTIVATE_MASTER, DEACTIVATE_MASTER } from 'api/requests/master';
import { Master } from 'types';
import useMutation from 'hooks/useMutation';
import { useMasterState } from 'contexts/master';
import { setMaster } from 'contexts/master/actions';

const useSwitchActivateMaster = () => {
  const {
    state: { master },
    dispatch,
  } = useMasterState();

  const [activateMaster, { data: activateMasterData, loading: activateLoading }] = useMutation<
    { activateMaster: Master },
    { masterId: number }
  >(ACTIVATE_MASTER);
  const [deactivateMaster, { data: deactivateMasterData, loading: deactivateLoading }] = useMutation<
    { deactivateMaster: Master },
    { masterId: number }
  >(DEACTIVATE_MASTER);

  useEffect(() => {
    if (activateMasterData?.activateMaster) {
      dispatch(setMaster(activateMasterData?.activateMaster));
    }
  }, [activateMasterData, dispatch]);

  useEffect(() => {
    if (deactivateMasterData?.deactivateMaster) {
      dispatch(setMaster(deactivateMasterData?.deactivateMaster));
    }
  }, [deactivateMasterData, dispatch]);

  const onSwitchActivateMaster = () => {
    if (!master.id) return;

    const request = master.activated ? deactivateMaster : activateMaster;

    request({ variables: { masterId: master.id } });
  };

  return {
    onSwitchActivateMaster,
    switchActivateMasterLoading: activateLoading || deactivateLoading,
  };
};

export default useSwitchActivateMaster;
