import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
    gap: 16px;
    padding: 36px 0 16px;

    ${theme.breakpoints.up('md')} {
      padding: 40px 0 0;
      max-width: 528px;
      align-items: center;
    }
  `,
);
