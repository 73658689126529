import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import IconButton from '../@shared/IconButton';

export const StyledForm = styled('form')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 20px;

    ${theme.breakpoints.up('md')} {
      max-width: 632px;
    }
  `,
);

export const StyledInput = styled('input')`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const StyledImgsGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
`;

export const StyledImgContainer = styled(Box)`
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const StyledImg = styled('img')`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
`;
