import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Tabs from 'components/@shared/Tabs';
import { PATHS } from 'navigation/constants';
import getRouteWithParams from 'utils/getRouteWithParams';
import { useMasterState } from 'contexts/master';

import InfoTab from './InfoTab';
import ServicesTab from './ServicesTab';
import useMasterCabinetPage from './useMasterCabinetPage';
import Breadcrumbs from '../@common/Breadcrumbs';
import { useBreadcrumbs } from './useBreadcrumbs';
import Button from '../@shared/Button';
import WestIcon from '@mui/icons-material/West';

const MasterCabinetPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const {
    state: { master },
  } = useMasterState();

  const breadcrumbs = useBreadcrumbs(master.contactName);

  const MASTER_CABINET_INFO_TAB = useMatch(PATHS.MASTER_CABINET_INFO_TAB);
  const COMPANY_CABINET_CREATE_MASTER_INFO_TAB = useMatch(PATHS.COMPANY_CABINET_CREATE_MASTER_INFO_TAB);
  const COMPANY_CABINET_MASTER_INFO_TAB = useMatch(PATHS.COMPANY_CABINET_MASTER_INFO_TAB);

  const MASTER_CABINET_SERVICES_TAB = useMatch(PATHS.MASTER_CABINET_SERVICES_TAB);
  const COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB = useMatch(PATHS.COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB);
  const COMPANY_CABINET_MASTER_SERVICES_TAB = useMatch(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB);

  const isMasterAccount = MASTER_CABINET_INFO_TAB || MASTER_CABINET_SERVICES_TAB;
  const isCompanyMasterAccount = COMPANY_CABINET_MASTER_INFO_TAB || COMPANY_CABINET_MASTER_SERVICES_TAB;

  const { handleBackToCompanyPage } = useMasterCabinetPage(
    !!COMPANY_CABINET_CREATE_MASTER_INFO_TAB || !!COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB,
  );

  const activeTabIndex =
    MASTER_CABINET_INFO_TAB || COMPANY_CABINET_CREATE_MASTER_INFO_TAB || COMPANY_CABINET_MASTER_INFO_TAB ? 0 : 1;

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const tabList = [
    {
      label: t(isMasterAccount ? 'masterCabinetPage.infoTab' : 'masterCabinetPage.companyMasterInfoTab'),
      content: <InfoTab />,
    },
    {
      label: t(isMasterAccount ? 'masterCabinetPage.servicesTab' : 'masterCabinetPage.companyMasterServicesTab'),
      content: <ServicesTab />,
    },
  ];

  const handleTabChange = () => {
    if (MASTER_CABINET_INFO_TAB) {
      navigate(PATHS.MASTER_CABINET_SERVICES_TAB);
    }

    if (MASTER_CABINET_SERVICES_TAB) {
      navigate(PATHS.MASTER_CABINET_INFO_TAB);
    }

    if (COMPANY_CABINET_CREATE_MASTER_INFO_TAB) {
      navigate(PATHS.COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB);
    }

    if (COMPANY_CABINET_CREATE_MASTER_SERVICES_TAB) {
      navigate(PATHS.COMPANY_CABINET_CREATE_MASTER_INFO_TAB);
    }

    if (COMPANY_CABINET_MASTER_INFO_TAB && id) {
      navigate(getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_SERVICES_TAB, { id }));
    }

    if (COMPANY_CABINET_MASTER_SERVICES_TAB && id) {
      navigate(getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_INFO_TAB, { id }));
    }
  };

  const getTitle = () => {
    if (isMasterAccount) {
      return t('masterCabinetPage.title');
    }

    if (isCompanyMasterAccount && master.contactName) {
      return master.contactName;
    }

    if (isCompanyMasterAccount && master.id) {
      return t('masterCabinetPage.companyMasterTitle', { id: master.id });
    }

    return t('masterCabinetPage.companyCreateMasterTitle');
  };

  return (
    <Container>
      {isCompanyMasterAccount && (
        <>
          <Breadcrumbs {...breadcrumbs} sx={{ marginBottom: '16px!important' }} />
          <Button variant="text" color="info" startIcon={<WestIcon />} sx={{ marginBottom: '16px!important' }} onClick={handleBackToCompanyPage}>
            {t('masterCabinetPage.backButtonLabel')}
          </Button>
        </>
      )}
      <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main">
        {getTitle()}
      </Typography>
      <Gap size={upMedium ? 56 : 32} />
      <Tabs tabList={tabList} activeTabIndex={activeTabIndex} onTabChange={handleTabChange} />
    </Container>
  );
};

export default MasterCabinetPage;
