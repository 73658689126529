import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    flex: 0 0 305px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 20px 20px;
    border: 1px solid ${theme.palette.primary.light3};
    border-radius: 16px;
  `,
);

export const StyledFilterListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
