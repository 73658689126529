import { css, styled, Theme } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

const getVariantStyles = (
  theme: Theme,
  color: 'primary' | 'error' | 'info',
  variant: 'text' | 'outlined' | 'contained' = 'text',
) => {
  if (variant === 'outlined') {
    return css`
      background: transparent;
      color: ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};
      border: 2px solid ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};

      @media (hover: hover) {
        &:hover {
          color: ${color === 'error' ? theme.palette.error.light : theme.palette.accent.light};
          border: 2px solid ${color === 'error' ? theme.palette.error.light : theme.palette.accent.light};
          background: transparent;
          box-shadow: unset;
          cursor: pointer;
        }
      }

      @media (hover: none) {
        &:hover {
          background: transparent;
          color: ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};
          border: 2px solid ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};
        }
      }

      &:active {
        background: transparent;
        color: ${color === 'error' ? theme.palette.error.dark : theme.palette.accent.dark};
        border: 2px solid ${color === 'error' ? theme.palette.error.dark : theme.palette.accent.dark};
      }

      &.MuiButtonBase-root.MuiButton-root.Mui-disabled {
        background: transparent;
        color: ${theme.palette.primary.dark2};
        border: 2px solid ${theme.palette.primary.dark2};
      }
    `;
  }

  if (variant === 'contained') {
    return css`
      background: ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};
      color: ${theme.palette.primary.light};
      border: 2px solid ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};

      @media (hover: hover) {
        &:hover {
          background: ${color === 'error' ? theme.palette.error.light : theme.palette.accent.light};
          border: 2px solid ${color === 'error' ? theme.palette.error.light : theme.palette.accent.light};
          box-shadow: unset;
          cursor: pointer;
        }
      }

      @media (hover: none) {
        &:hover {
          background: ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};
          border: 2px solid ${color === 'error' ? theme.palette.error.main : theme.palette.accent.main};
        }
      }

      &:active {
        background: ${color === 'error' ? theme.palette.error.dark : theme.palette.accent.dark};
        border: 2px solid ${color === 'error' ? theme.palette.error.dark : theme.palette.accent.dark};
      }

      &.MuiButtonBase-root.MuiButton-root.Mui-disabled {
        background: ${theme.palette.primary.dark2};
        border: 2px solid ${theme.palette.primary.dark2};
        color: ${theme.palette.primary.light};
      }
    `;
  }

  let textColor = theme.palette.primary.main;
  let hoverColor = theme.palette.accent.light;
  let activeColor = theme.palette.accent.dark;

  if (color === 'error') {
    textColor = theme.palette.error.main;
    hoverColor = theme.palette.error.light;
    activeColor = theme.palette.error.dark;
  }

  if (color === 'info') {
    textColor = theme.palette.accent.main;
  }

  return css`
    background: none;
    color: ${textColor};
    padding: 0;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background: none;
        color: ${hoverColor};
      }
    }

    @media (hover: none) {
      &:hover {
        background: none;
        color: ${textColor};
      }
    }

    &:active {
      background: none;
      color: ${activeColor};
    }

    &.MuiButtonBase-root.MuiButton-root.Mui-disabled {
      background: none;
      border: none;
      color: ${theme.palette.primary.light};
    }
  `;
};

const getSizeStyles = (theme: Theme, size: 'small' | 'medium' | 'large' = 'medium') => {
  if (size === 'large') {
    return css`
      padding: 18px 64px;
    `;
  }

  return css`
    padding: 12px 40px;
  `;
};

export const StyledButton = styled(
  Button,
  preventForwardProps(['customStyles', 'color', 'loading']),
)<{ customStyles?: string; color: 'primary' | 'error' | 'info'; loading?: boolean }>(
  ({ theme, variant, size, color, loading }) => css`
    border-radius: 12px;
    white-space: nowrap;
    text-transform: none;
    box-shadow: unset;

    ${getSizeStyles(theme, size)};
    ${getVariantStyles(theme, color, variant)};

    & .MuiButton-startIcon {
      opacity: ${loading ? 0 : 1};

      transition: opacity 0.3s;
    }

    & .MuiTypography-root {
      opacity: ${loading ? 0 : 1};

      transition: opacity 0.3s;
    }
  `,
);

export const StyledCircularProgress = styled(
  CircularProgress,
  preventForwardProps(['show']),
)<{ show?: boolean }>(
  ({ theme, show }) => css`
    color: ${theme.palette.primary.dark3};
    margin: -10px 0;
    position: absolute;

    opacity: ${show ? 1 : 0};

    transition: opacity 0.3s;
  `,
);
