const filterMasters = <T extends { contactName?: string }>(masters: Array<T>, searchQuery: string): Array<T> =>
  searchQuery
    ? masters.reduce<Array<T>>((acc, master) => {
        const { contactName } = master;

        if (contactName?.toLowerCase().includes(searchQuery.toLowerCase())) {
          return [...acc, master];
        }
        return acc;
      }, [])
    : masters;

export default filterMasters;
