import { ACTION } from './constants';
import { ActionType, StateType } from './types';

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ACTION.SET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };

    default:
      return state;
  }
};
