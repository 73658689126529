import { useTranslation } from 'react-i18next';
import { PATHS } from 'navigation/constants';

export const useBreadcrumbs = (masterName?: string) => {
  const { t } = useTranslation();

  const getBreadcrumbsSegments = () => [
    {
      path: PATHS.MAIN_PAGE,
      label: t('breadcrumbs.home'),
    },
    {
      path: PATHS.COMPANY_CABINET_INFO_TAB,
      label: t('breadcrumbs.cabinet'),
    },
  ];

  return {
    breadcrumbsSegments: getBreadcrumbsSegments(),
    currentPageLabel: masterName || t('breadcrumbs.addMaster'),
  };
};
