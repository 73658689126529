import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { APIProvider } from '@vis.gl/react-google-maps';
import './index.css';
import client from 'api/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d125877282a5aee73aab18a680cd358e@o4507629237764096.ingest.de.sentry.io/4507629243400272',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      // Sentry.captureConsoleIntegration({ levels: ['log', 'info', 'error', 'debug', 'assert'] }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

if (gaMeasurementId) {
  ReactGA.initialize(gaMeasurementId);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_API_KEY!}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_PLATFORM_API_KEY!}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </APIProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
