export default {
  comingSoon: 'Незабаром !!!',
  loadingText: 'Триває пошук...',
  noOptionsText: 'Cпівпадінь не знайдено',
  loadingStateMessage: 'Будь ласка, зачекайте...',
  discard: 'Відмінити',
  saveChanges: 'Зберегти зміни',
  scheduleTitle: 'Графік роботи',
  from: 'з',
  to: 'до',
  moreDetails: 'Детальніше',
  bookButtonLabel: 'Записатися',
  allServices: 'Послуги',
  examples: 'Приклади робіт',
  name: 'Назва',
  back: 'Назад',
  continue: 'Продовжити',
  week: 'Тиждень',
  schedule: 'Розклад',
  filters: 'Фільтри',
  priceLabel: 'Ціна',
  price: '{{val, number}} грн',
  priceFree: 'Безкоштовно',
  toFavoritesButtonLabel: 'В обране',
  master: 'Майстер',
  duration: 'Тривалість',
  day: 'День',
  date: 'Дата',
  time: 'Час',
  phone: 'Телефон',
  town: 'Місто',
  notSelected: 'Не вибрано',
  delete: 'Видалити',
  confirm: 'Підтвердити',
  showMore: 'Показати більше',
  service: 'Послуга',
  cancel: 'Скасувати',
  confirmDelete: 'Так, видалити',
  hoursShort: 'год',
  minutesShort: 'хв',
  confirmCancelReservationTitle: 'Ви впевнені, що хочете відмінити бронювання?',
  confirmCancelReservationButtonLabel: 'Так, відмінити',

  slug: {
    title: 'Унікальний URL',
    errorMessage: 'Цей URL вже зайнятий. Введіть інший, або виберіть із запропонованих:',
    reservedErrorMessage: 'Цей URL зарезервований. Введіть будь ласка інший',
  },

  validation: {
    fieldIsRequired: "Поле є обов'язковим",
    mobileNumberIsInvalid: 'Некоректний мобільний номер',
    emailNumberIsInvalid: 'Некоректний E-mail',
    scheduleCountInvalid: 'Графік роботи має бути доданий',
    activitiesCountInvalid: 'Діяльність та досвід мають бути додані',
  },

  socialMedia: {
    instagram: 'Instagram',
    facebook: 'Facebook',
    telegram: 'Telegram',
    connect: "Підв'язати",
    confirm: 'Підтвердити',
    disconnect: "Від'єднати",
    linkInputLabel: 'Посилання',
    linkInputPlaceholder: 'Введи посилання',
  },

  breadcrumbs: {
    home: 'Головна',
    services: 'Послуги',
    serviceFallback: 'Постачальник послуги',
    authenticate: 'Авторизація',
    favorites: 'Обране',
    about: 'Про палтформу',
    'for-companies': 'Для компаній та мастерів',
    termsAndConditions: 'Умови користування',
    'privacy-policy': 'Політика конфіденційності',
    cabinet: 'Кабінет',
    examples: 'Приклади робіт',
    addService: 'Додати послугу',
    bookService: 'Забронювати послугу',
    calendar: 'Календар',
    reservationDetails: 'Деталі бронювання',
    addMaster: 'Додати майстра',
  },

  category: {
    notSelected: 'Не вибрана',
    art: 'Творчість',
    auto: 'Авто',
    beauty: 'Краса',
    consultations: 'Консультації',
    medicine: 'Медицина',
    pets: 'Тварини',
    sport: 'Спорт',
    learning: 'Навчання',
  },

  accountType: {
    client: 'Клієнт',
    company: 'Компанія',
    privateSpecialist: 'Приватний фахівець',
  },

  dayOfWeek: {
    sun: 'Неділя',
    mon: 'Понеділок',
    tue: 'Вівторок',
    wed: 'Середа',
    thu: 'Четвер',
    fri: "П'ятниця",
    sat: 'Субота',
  },

  feedback: {
    1: '({{count}} відгук)',
    2: '({{count}} відгуки)',
    3: '({{count}} відгуки)',
    4: '({{count}} відгуки)',
    unspecific: '({{count}} відгуків)',
  },

  result: {
    1: '{{count}} результат',
    2: '{{count}} результати',
    3: '{{count}} результати',
    4: '{{count}} результати',
    unspecific: '{{count}} результатів',
  },

  mainPage: {
    title: 'Зручний пошук послуг та допомоги під час війни',
    subtitle:
      'Ми онлайн платформа, що покликана швидко та безпечно об’єднувати тих, хто в пошуці необхідних послуг і тих, хто готовий допомагати.',
    popularTitle: 'Популярні послуги',
    allServicesButtonLabel: 'Всі послуги',
    signupButtonLabel: 'Зареєструватись',
    overviewSectionTitle: 'Я хочу допомагати',
    overviewSectionSubtitle:
      'Якщо ти хочеш і маєш можливість запропонувати свої послуги – створи свою сторінку та опублікуй їх.',
    overviewSectionActionButtonLabel: 'Хочу стати партнером',
  },

  servicesPage: {
    title: 'Послуги',
    filtersTitle: 'Фільтри',
    filtersSubtitle: 'Обери категорію',
    filterByPriceLabel: 'Ціна, грн',
    filterByPriceFromTo: 'від {{from}} до {{to}}',
    filterByPriceFreeLabel: 'Безкоштовно',
    filtersButtonLabelLabel: 'Застосувати фільтри',
    sortButtonLabel: 'Сортування',
  },

  serviceDetailsPage: {
    allServicesTitle: 'Послуги',
    examplesTitle: 'Приклади робіт',
  },

  contactsSection: {
    title: 'Контакти',
    phoneContactLabel: 'Телефон',
    phoneContactShowButtonLabel: 'Показати номер',
    emailContactLabel: 'E-mail',
    scheduleContactLabel: 'Графік роботи',
    addressContactLabel: 'Адреса',
  },
  termsAndConditionsPage: {
    title: 'Умови користування платформою KeepSer',
    item1Title: 'Загальні положення',
    item1Details: `
<ul>
  <li>Умови користування (надалі — «Умови») регулюють порядок користування платформою KeepSer, яку розробляє та підтримує ТОВ «SkilledBit» (надалі — «Компанія»).</li>
  <li>Користуючись платформою, ви погоджуєтеся з цими Умовами, а також з Політикою конфіденційності. У разі незгоди, будь ласка, утримайтеся від користування платформою.</li>
  <li>Компанія має право змінювати Умови в будь-який час. Нова редакція Умов набирає чинності з моменту її публікації на платформі.</li>
</ul>
    `,
    item2Title: 'Послуги, що надаються платформою',
    item2Details: `
<ul>
  <li>KeepSer надає користувачам послуги з пошуку, огляду та моніторингу різноманітних торгових стратегій.</li>
  <li>Умови використання послуг визначаються Компанією та можуть бути змінені залежно від розвитку функціональності платформи.</li>
</ul>
    `,
    item3Title: 'Обліковий запис користувача',
    item3Details: `
<ul>
  <li>Для доступу до повного функціоналу платформи необхідна реєстрація облікового запису.</li>
  <li>Користувач зобов’язаний забезпечити точність інформації в профілі, дотримуватись вимог безпеки і не передавати дані доступу третім особам.</li>
</ul>
    `,
    item4Title: 'Відповідальність користувачів',
    item4Details: `
<ul>
  <li>Користувач зобов'язується використовувати платформу тільки відповідно до чинного законодавства та Умов користування.</li>
  <li>Компанія не несе відповідальності за шкоду, завдану користувачу в результаті недотримання ним цих Умов або вимог законодавства.</li>
</ul>
    `,
    item5Title: 'Припинення доступу до платформи',
    item5Details: `
<ul>
  <li>Компанія має право призупинити або припинити доступ користувача до платформи в разі порушення ним Умов або на вимогу відповідних органів влади.</li>
</ul>
    `,
    item6Title: 'Інтелектуальна власність',
    item6Details: `
<ul>
  <li>Всі права інтелектуальної власності на платформу KeepSer, включаючи тексти, графіку, логотипи та програмне забезпечення, належать Компанії.</li>
  <li>Користувачам забороняється копіювати, модифікувати або розповсюджувати контент платформи без письмової згоди Компанії.</li>
</ul>
    `,
  },

  privacyPolicyPage: {
    title: 'Політика конфіденційності платформи KeepSer',
    description:
      'Ця Політика конфіденційності описує, як ТОВ «SkilledBit» (надалі — «Компанія») збирає, використовує, зберігає та розкриває персональні дані користувачів, що взаємодіють з платформою KeepSer.',
    item1Title: 'Дані, які ми збираємо',
    item1Details: `
<ul>
  <li><b>Інформація користувача:</b> дані, які ви вводите при реєстрації, включаючи ваше ім'я, електронну адресу та номер телефону.</li>
  <li><b>Технічні дані:</b> IP-адреса, дані про ваш пристрій, файли Cookies для покращення роботи платформи та забезпечення безпеки.</li>
</ul>
    `,
    item2Title: 'Мета збору даних',
    item2Details: `
<ul>
  <li>Дані збираються для надання послуг, поліпшення якості платформи, персоналізації контенту та забезпечення технічної підтримки.</li>
  <li>Інформація може використовуватись для аналітики, що допомагає вдосконалити платформу, а також для маркетингових цілей (з вашої згоди).</li>
</ul>
    `,
    item3Title: 'Передача даних третім особам',
    item3Details: `
<ul>
  <li>Ми не розкриваємо ваші дані третім особам без вашої згоди, за винятком випадків, визначених законодавством.</li>
  <li>Партнери, що забезпечують роботу платформи, також зобов’язані дотримуватись принципів конфіденційності відповідно до чинних договорів.</li>
</ul>
    `,
    item4Title: 'Захист даних',
    item4Details: `
<ul>
  <li>.Ми вживаємо всіх необхідних заходів для захисту ваших персональних даних від несанкціонованого доступу, використання або розголошення.</li>
</ul>
    `,
    item5Title: 'Права користувачів',
    item5Details: `
<ul>
  <li>Ви маєте право на доступ до своїх даних, виправлення або видалення даних, обмеження обробки, а також відкликання згоди на їх обробку.</li>
  <li>Для реалізації цих прав ви можете звернутися до нашої служби підтримки.</li>
</ul>
    `,
    item6Title: 'Файли Cookies та подібні технології',
    item6Details: `
<ul>
  <li>Ми використовуємо файли Cookies для аналізу трафіку, налаштування роботи платформи та забезпечення ефективності роботи користувацьких сесій.</li>
  <li>Ви можете обмежити використання файлів Cookies, змінивши налаштування браузера.</li>
</ul>
    `,
    item7Title: 'Зміни до Політики конфіденційності',
    item7Details: `
<ul>
  <li>Ми можемо періодично оновлювати цю Політику, і в разі внесення змін повідомимо вас через електронну пошту або через платформу.</li>
</ul>
    `,
  },
  forCompaniesPage: {
    title: 'KeepSer — Ваша платформа для ефективного управління бізнесом',
    description:
      'Вітаємо у KeepSer! Наша платформа створена для того, щоб допомогти компаніям підвищити продуктивність, оптимізувати процеси бронювання послуг, організації роботи, керування командою та просування ваших послуг.',
    item1Title: 'Просування вашого бізнесу',
    item1Details: `
Ми автоматично надаємо можливість просування вашого бізнесу на платформі KeepSer. Це дозволяє вам залучити нових клієнтів і розширити вашу аудиторію без додаткових витрат.
    `,
    item2Title: 'Бронювання послуг',
    item2Details: `
KeepSer надає зручну систему бронювання, яка дозволяє вашим клієнтам самостійно записуватися на послуги, що зменшує навантаження на ваш персонал. Інтуїтивно зрозумілий інтерфейс забезпечує легкість у використанні, а автоматичні оновлення статусів бронювання дозволяють вам завжди бути в курсі.
    `,
    item3Title: 'Календар та управління розкладом',
    item3Details: `
Наш інтегрований календар допомагає вам планувати зайнятість, переглядати розклад послуг і управляти бронюваннями. Завдяки синхронізації з особистими календарями співробітників, ви завжди будете в курсі змін у розкладі та зможете ефективно управляти часом.
    `,
    item4Title: 'Керування командою',
    item4Details: `
KeepSer пропонує інструменти для управління вашим персоналом. Ви можете додавати працівників, визначати їхні ролі та завдання, а також моніторити їхню зайнятість. Це дозволяє зосередитися на важливих аспектах бізнесу, залишаючи рутинні завдання автоматизованими.
    `,
    item5Title: 'Переваги для компаній',
    item5Details: `
<ul>
  <li><b>Покращена продуктивність:</b> Автоматизація рутинних процесів дозволяє зосередитися на розвитку бізнесу.</li>
  <li><b>Зручність для клієнтів:</b> Простота бронювання послуг робить ваш бізнес більш привабливим для клієнтів.
</li>
  <li><b>Безпека даних:</b> Ми дотримуємося високих стандартів конфіденційності, захищаючи дані вашої компанії та клієнтів.</li>
</ul>
    `,
    callToAction: 'Готові змінити свій бізнес на краще? Зареєструйтесь на KeepSer безкоштовно або запишіться на демонстрацію, щоб ознайомитися з усіма функціями платформи!\n',
  },

  aboutPage: {
    title: 'Про платформу',
    description:
      "KeepSer – це онлайн сервіс пошуку послуг. Платформа об'єднує замовників послуг, яким необхідно виконати яку-небудь роботу або необхідна будь-яка послуга, та компетентних фахівців або компанії, які шукають клієнтів або просто хочуть допомогти.",
    goal: 'Наразі, наша основна мета – допомога людям та бізнесу під час війни! Щоб кожен знаходив один одного простіше.',
    actionSectionTitle: 'Шукайте або пропонуйте свої послуги на KeepSer!',
    searchButtonLabel: 'Шукати послуги',
    offerButtonLabel: 'Розміщати послуги',
  },

  nav: {
    servicesLabel: 'Послуги',
    aboutLabel: 'Про платформу',
    forCompaniesLabel: 'Для компаній',
    termsAndConditionsLabel: 'Умови користування',
    privacyPolicy: 'Політика конфіденційності',
  },

  appBar: {
    actionBtnLabel: 'Хочу допомогти',
    logOutBtnLabel: 'Вйти з аккаунту',
  },

  searchSection: {
    searchPlaceholder: 'Послуга...',
    citySelectorPlaceholder: 'Обери місто...',
    categoryListTitle: 'Або обирай категорію:',
  },

  overviewSection: {
    title1: 'Підтримка економіки країни під час війни',
    title2: 'Поява нових клієнтів',
    title3: 'Допомога біженцям та постраждалим людям',
  },

  companyCabinetPage: {
    title: 'Особистий кабінет',
    infoTab: 'Дані компанії',
    mastersTab: 'Майстри',
    companyInfoTitle: 'Дані компанії',
    notActiveAccountTitle: 'Акаунт не активний',
    activeAccountTitle: 'Акаунт активний',
    notActiveAccountDescription: 'Для активації акаунта заповніть всю необхідну інформацію',
    avatarTitle: 'Ваше фото / логотип',
    avatarDescription: 'Фото має бути .jpg чи .png формату. Розмір не більше 10 Мб.',
    avatarAddButtonLabel: 'Обрати фото',
    avatarChangeButtonLabel: 'Змінити фото',
    nameFieldLabel: 'Назва',
    nameFieldPlaceholder: 'Вкажіть назву компанії',
    contactNameFieldLabel: 'Прізвище ім’я представника',
    contactNameFieldPlaceholder: 'Вкажіть прізвище ім’я представника',
    cityFieldLabel: 'Місто, в якому працюєте',
    cityFieldPlaceholder: 'Оберіть місто',
    addressFieldLabel: 'Адреса',
    addressFieldPlaceholder: 'Вкажіть адресу',
    phoneNumberLabel: 'Номер телефону',
    emailLabel: 'E-mail',
    emailPlaceholder: 'Ваш e-mail',
    aboutLabel: 'Про компанію',
    aboutPlaceholder: 'Розкажіть про себе',
    scheduleTitle: 'Графік роботи',
    scheduleSubtitle: 'День',
    scheduleSelectLabel: 'День',
    scheduleAddButtonLabel: 'Додати',
    scheduleChipListTitle: 'Графік',
    fromLabel: 'Час (з)',
    toLabel: 'Час (до)',
    from: 'з',
    to: 'до',
    addMasterButtonLabel: '+ Додати майстра',
    masterSearchPlaceholder: `Введіть ім'я`,
    masterStatusActive: 'Active',
    masterStatusDraft: 'Draft',
    mastersEmptyStateMessage: 'Ви ще не додали жодного майстра',
  },

  masterCabinetPage: {
    backButtonLabel: 'Назад до Кабінету',
    title: 'Особистий кабінет',
    companyCreateMasterTitle: 'Додати майстра',
    companyMasterTitle: 'Майстер {{id}}',
    infoTab: 'Мої дані',
    companyMasterInfoTab: 'Дані майстра',
    servicesTab: 'Мої послуги',
    companyMasterServicesTab: 'Послуги майстра',
    notActiveAccountTitle: 'Акаунт не активний',
    activeAccountTitle: 'Акаунт активний',
    notActiveAccountDescription: 'Для активації акаунта заповніть всю необхідну інформацію',
    infoTitle: 'Мої дані',
    companyMasterInfoTitle: 'Мої дані',
    avatarTitle: 'Моє фото',
    companyMasterAvatarTitle: 'Фото',
    avatarDescription: 'Фото має бути .jpg чи .png формату. Розмір не більше 10 Мб.',
    avatarAddButtonLabel: 'Обрати фото',
    avatarChangeButtonLabel: 'Змінити фото',
    contactNameFieldLabel: "Прізвище Ім'я",
    contactNameFieldPlaceholder: "Ваше прізвище ім'я",
    companyMasterContactNameFieldPlaceholder: "Прізвище ім'я майстра",
    cityFieldLabel: 'Місто, в якому працюєте',
    companyMasterCityFieldLabel: 'Місто, в якому працює',
    cityFieldPlaceholder: 'Оберіть місто',
    phoneNumberLabel: 'Номер телефону',
    emailLabel: 'E-mail',
    emailPlaceholder: 'Ваш e-mail',
    companyMasterEmailPlaceholder: 'E-mail',
    aboutLabel: 'Про себе',
    companyMasterAboutLabel: 'Про себе',
    aboutPlaceholder: 'Розкажіть про себе...',
    companyMasterAboutPlaceholder: 'Розкажіть про себе...',
    addMasterButton: 'Додати майстра',
    deleteMasterButton: 'Видалити майстра',
    scheduleTitle: 'Графік роботи',
    scheduleSubtitle: 'День',
    scheduleSelectLabel: 'День',
    scheduleAddButtonLabel: 'Додати',
    scheduleChipListTitle: 'Графік',
    fromLabel: 'Час (з)',
    toLabel: 'Час (до)',
    from: 'з',
    to: 'до',
    activitiesTitle: 'Діяльність та досвід',
    activitiesDescription: 'Оберіть категорію, щоб вказати свій досвід та розмістити свої послуги.',
    companyMasterActivitiesDescription: 'Оберіть категорію, щоб вказати досвід та розмістити послуги майстра.',
    chooseCategoryLabel: 'Обери категорію',
    addExperienceButtonLabel: 'Додати',
    deleteExperienceTitle: 'Ви впевнені, що хочете видалити?',
    deleteExperienceDescription:
      'При видаленні субкатегорії автоматично видаляться усі послуги та приклади робіт, що були додані до неї.',
    servicesTabEmptyStateMessage:
      'Щоб додати свої послуги необхідно заповнити інформацію про свою діяльність та рівень досвіду в основній анкеті.',
    companyMasterServicesTabEmptyStateMessage:
      'Щоб додати послуги необхідно заповнити інформацію про діяльність майстра та рівень досвіду в основній анкеті.',
    servicesTabEmptyStateButton: 'Обрати діяльність',
    servicesTabNoServicesTitle: 'Ви ще не додали жодної послуги',
    addServiceButtonLabel: 'Додати послугу',
    addExamplesButtonLabel: 'Додати приклади робіт',
    extraImagesLabel: '+ {{count}} фото',
    confirmDeleteTitle: 'Ви впевнені, що хочете видалити послугу?',
    confirmDeleteMasterTitle: 'Ви впевнені, що хочете видалити майстра?',
  },

  clientCabinetPage: {
    title: 'Особистий кабінет',
    infoTab: 'Мої дані',
    reservationsTab: 'Мої бронювання',
    infoTitle: 'Мої дані',
    avatarTitle: 'Моє фото',
    avatarDescription: 'Фото має бути .jpg чи .png формату. Розмір не більше 10 Мб.',
    avatarAddButtonLabel: 'Обрати фото',
    avatarChangeButtonLabel: 'Змінити фото',
    contactNameFieldLabel: 'Прізвище ім’я',
    contactNameFieldPlaceholder: 'Вкажіть ваші прізвище та ім’я',
    phoneNumberLabel: 'Номер телефону',
    emailLabel: 'E-mail',
    emailPlaceholder: 'Ваш e-mail',
    activeReservationsLabel: 'Активні',
    archivedReservationsLabel: 'Минулі',
    cancelReservationButtonLabel: 'Відмінити бронювання',
    bookAgainButtonLabel: 'Забронювати знову',
    noReservationsLabel: 'У вас ще немає бронювань',
  },

  addServicePage: {
    backButtonLabel: 'Назад до Кабінету',
    title: `Додати послугу`,
    infoTitle: `Загальна інформація`,
    nameLabel: 'Введіть назву',
    namePlaceholder: 'Назва послуги',
    durationLabel: 'Тривалість',
    priceLabel: 'Ціна ({{currency}})',
    pricePlaceholder: 'Вартість послуги',
    descriptionLabel: 'Про послугу (опціонально)',
    descriptionPlaceholder: 'Розкажіть про послугу...',
    examplesTitle: `Приклади робіт`,
    examplesSubtitle: `Ви можете додати максимум 20 фото з прикладами своїх робіт для однієї субкатегорії. Формат фото: .jpg, .png. Розмір одного фото не більше 10Мб.`,
    addPhotoButtonLabel: 'Завантажити фото',
    addButtonLabel: 'Додати послугу',
    editButtonLabel: 'Редагувати послугу',
  },

  footer: {
    subtitle: 'Якщо є питання – ти зажди можеш звернутися до нас за електронною адресою!',
    email: 'E-Mail',
    button: 'Написати нам',
    copyright: 'Всі права захищені, 2022',
  },

  authenticateModal: {
    title: 'Авторизація',
    subtitle: 'Наразі доступна тільки авторизація через аккаунт Google',
    button: 'Google',
    termsAndConditionsDescription: 'Натискаючи кнопку, ти погоджуєшся з нашими',
    termsAndConditionsButtonLabel: 'Умовами користування',
    selectAccountTypeModalTitle: 'Оберіть тип акаунта',
    selectAccountTypeModalSubTitle: 'Ваш акаунт ще не зареєстрований в нашій системі',
    cancel: 'Скасувати',
    createAccount: 'Створити акаунт',
  },

  bookServiceModal: {
    title: 'Забронювати послугу',
    subtitle:
      'Щоб забронювати послугу, зареєструйтеся в нашій системі. Наразі доступна тільки авторизація через аккаунт Google',
    buttonLabel: 'Google',
    termsAndConditionsDescription: 'Натискаючи кнопку, ти погоджуєшся з нашими',
    termsAndConditionsButtonLabel: 'Умовами користування',
    phoneSubtitle: 'Вкажіть свій номер телефону для бронювання',
    phoneNumberLabel: 'Номер телефону',
    submitButtonLAbel: 'Обрати дату',
  },

  bookServicePage: {
    title: 'Забронювати послугу',
    firstStepTitle: 'Обери дату',
    secondStepTitle: 'Ви обрали',
    timestampsTitle: 'Доступний час',
    submitButtonLabel: 'Забронювати',
    successModalTitle: 'Ваша послуга успішно заброньована!',
    successModalButtonLabel: 'На головну',
  },

  calendarPage: {
    title: 'Календар',
    scheduleTab: 'Розклад',
    newOrdersTab: 'Нові заявки',
    scheduleVariantTitle: 'Період',
  },

  reservationDetailsPage: {
    backButtonLabel: 'Назад до Календарю',
    title: 'Деталі бронювання',
    client: 'Клієнт',
    cancelButton: 'Скасувати бронювання',
    submitButton: 'Підтвердити бронювання',
  },

  currency: {
    uah: 'грн',
    usd: 'дол',
  },
};
