import { ApolloError, FetchResult, MutationFunctionOptions, useMutation as useApolloMutation } from '@apollo/client';
import { ApolloCache, DefaultContext, OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql/index';
// eslint-disable-next-line import/no-unresolved
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { MutationHookOptions, MutationTuple, NoInfer } from '@apollo/client/react/types/types';
import { useEffect, useState } from 'react';
import useErrorHandler from '../useErrorHandler';

type OptionsType<TData, TVariables, TContext, TCache extends ApolloCache<any> = ApolloCache<any>> = MutationHookOptions<
  NoInfer<TData>,
  NoInfer<TVariables>,
  TContext,
  TCache
> & {
  withErrorBanner?: boolean;
};

const useMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { withErrorBanner = true, ...apolloOptions }: OptionsType<TData, TVariables, TContext, TCache> = {},
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const [error, setError] = useState<ApolloError | undefined>();

  const [callback, result] = useApolloMutation<TData, TVariables, TContext, TCache>(mutation, apolloOptions);

  useErrorHandler(error);

  useEffect(() => {
    if (withErrorBanner) {
      setError(result.error);
    }
  }, [result.error, withErrorBanner]);

  const callbackWithErrorHandler = async (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>,
  ): Promise<FetchResult<TData>> => {
    try {
      return await callback(options);
    } catch (err) {
      if (withErrorBanner) {
        setError(err as ApolloError);
      } else {
        throw err;
      }
    }
    return {};
  };

  return [callbackWithErrorHandler, result];
};

export default useMutation;
