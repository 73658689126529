import { css, styled } from '@mui/material/styles';
import { Stack, Modal } from '@mui/material';

import IconButton from '../IconButton';

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(2px);
  z-index: 13000;
`;

export const StyledContainer = styled(Stack)(
  ({ theme }) => css`
    position: relative;
    background: ${theme.palette.primary.light};
    padding: 20px;
    border-radius: 12px;
    gap: 24px;

    ${theme.breakpoints.up('md')} {
      padding: 32px;
    }
  `,
);

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
