import React from 'react';
import useLoadImage, { ImageStatus } from 'hooks/useLoadImage';
import { Stack, Typography } from '@mui/material';
import { Category } from 'types';
import { getGroupedCategoryList } from 'utils/getGroupedCategoryList';
import ChipsSection from 'components/@common/ChipsSection';
import CategoryLabel from 'components/@common/CategoryLabel';

import { useTranslation } from 'react-i18next';
import { StyledCard, StyledImg, StyledImgContainer, StyledImgPlaceholder, StyledStatusContainer } from './styled';

type Props = {
  activated?: boolean;
  profileImageUri?: string;
  contactName?: string;
  categories: Category[];
  onClick: () => void;
};

const MasterCard: React.FC<Props> = ({ activated, profileImageUri, contactName, categories, onClick }) => {
  const { t } = useTranslation();

  const imageStatus = useLoadImage(profileImageUri || '');

  return (
    <StyledCard onClick={onClick}>
      <StyledImgContainer>
        <StyledStatusContainer active={activated}>
          <Typography variant="subtitle3">
            {t(activated ? 'companyCabinetPage.masterStatusActive' : 'companyCabinetPage.masterStatusDraft')}
          </Typography>
        </StyledStatusContainer>
        {profileImageUri && imageStatus === ImageStatus.Loaded ? (
          <StyledImg src={profileImageUri} alt="avatar" />
        ) : (
          <StyledImgPlaceholder />
        )}
      </StyledImgContainer>
      <Stack gap="8px" flexGrow={1}>
        {contactName && (
          <Typography variant="subtitle1" color="primary.main">
            {contactName}
          </Typography>
        )}
        {getGroupedCategoryList(categories).map(({ id, attributeGroupList }) => {
          return (
            <ChipsSection
              key={attributeGroupList.map(({id}) => id).join('__')}
              header={<CategoryLabel key={id} category={id} variant="transparent" />}
              chipList={attributeGroupList.map(i => ({ id: i.id, chipSegmentList: i.attributeList }))}
              white
            />
          )
        })}
      </Stack>
    </StyledCard>
  );
};

export default MasterCard;
