import { ACTION, initialState } from './constants';
import { ActionType, StateType } from './types';

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ACTION.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    case ACTION.RESET_ACCOUNT:
      return {
        ...state,
        account: initialState.account,
      };

    case ACTION.SET_BANNER:
      return {
        ...state,
        banner: action.payload,
      };

    case ACTION.RESET_BANNER:
      return {
        ...state,
        banner: null,
      };

    case ACTION.SET_FILTER_CITY_LIST:
      return {
        ...state,
        filterCityList: action.payload,
      };

    case ACTION.SET_FILTER_DEFAULT_CITY_LIST:
      return {
        ...state,
        defaultFilterCityList: action.payload,
      };

    case ACTION.SET_AUTH_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };

    case ACTION.RESET_AUTH_CREDENTIALS:
      return {
        ...state,
        credentials: initialState.credentials,
      };

    case ACTION.SET_NEW_RESERVATIONS_COUNT:
      return {
        ...state,
        newReservationsCount: action.payload,
      };

    default:
      return state;
  }
};
