import { css, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Select from 'components/@shared/Select';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledActivateContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    ${theme.breakpoints.up('md')} {
      border: 1px solid ${theme.palette.primary.light3};
      border-radius: 16px;
      padding: 20px 24px;
    }
  `,
);

export const StyledActivateDescription = styled(
  Typography,
  preventForwardProps(['active']),
)<{ active?: boolean }>(
  ({ active }) => css`
    max-height: ${active ? '50px' : 0};
    opacity: ${active ? 1 : 0};
    transition:
      max-height ease-in-out 0.5s,
      opacity ease-in-out 0.5s;
  `,
);

export const StyledMainContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 20px 40px;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
    }
  `,
);

export const StyledLeftContainer = styled(Box)`
  flex: 0 0 440px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledRightContainer = styled(Box)`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledSelect = styled(Select)`
  width: auto;
  flex: 1 1 40%;
  min-width: 200px;
`;

export const StyledInput = styled('input')`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;
