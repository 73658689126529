import dayjs, { Dayjs } from 'dayjs';

const convertDayjsToTimespan = (date: Dayjs): string => {
  if (!dayjs.isDayjs(date)) {
    throw new Error('Invalid date object');
  }

  const hours = date.hour();
  const minutes = date.minute();

  return `PT${hours}H${minutes}M`;
};

export default convertDayjsToTimespan;
