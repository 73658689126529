import { Master } from 'types';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { PATHS } from 'navigation/constants';
import { GET_COMPANY_MASTERS } from 'api/requests/company';
import { useCompanyState } from 'contexts/company';
import getRouteWithParams from 'utils/getRouteWithParams';
import { useMasterState } from 'contexts/master';
import { resetMaster } from 'contexts/master/actions';

const useMastersTab = () => {
  const navigate = useNavigate();

  const {
    state: { company },
  } = useCompanyState();

  const { dispatch: dispatchMaster } = useMasterState();

  const { data, loading } = useQuery<{
    companyMasters: Pick<Master, 'id' | 'activated' | 'contactName' | 'profileImageUri' | 'categories'>[];
  }>(GET_COMPANY_MASTERS, {
    skip: company.id === undefined,
  });

  const addMaster = () => {
    dispatchMaster(resetMaster());
    navigate(PATHS.COMPANY_CABINET_CREATE_MASTER_INFO_TAB);
  };

  const onMasterClick = (id: number) =>
    navigate(
      getRouteWithParams(PATHS.COMPANY_CABINET_MASTER_INFO_TAB, {
        id: id.toString(),
      }),
    );

  return {
    masters: data ? data.companyMasters : [],
    loading,
    addMaster,
    onMasterClick,
  };
};

export default useMastersTab;
