import { useEffect } from 'react';
import { useAppState } from 'contexts/app';
import { useLazyQuery } from '@apollo/client';
import { COUNT_OF_RESERVATIONS } from 'api/requests/reservation';
import { ReservationStatus } from 'types/reservation';
import { setNewReservationsCount } from 'contexts/app/actions';

export const useNewReservationsCount = (initialFetch?: boolean) => {
  const {
    state: { account, newReservationsCount },
    dispatch,
  } = useAppState();
  const [getCountOfReservations] = useLazyQuery<
    {
      countOfReservations: number;
    },
    { status: ReservationStatus }
  >(COUNT_OF_RESERVATIONS, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (!!account && initialFetch) {
      fetchNewReservationsCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, initialFetch]);

  const fetchNewReservationsCount = () =>
    getCountOfReservations({ variables: { status: ReservationStatus.WAITING_FOR_APPROVE } }).then(data => {
      dispatch(setNewReservationsCount(data.data?.countOfReservations ?? null));
    });

  return { count: newReservationsCount, fetchNewReservationsCount };
};
