import { css, styled } from '@mui/material/styles';
import { Box, Accordion } from '@mui/material';

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledAccordion = styled(Accordion)(
  ({ theme }) => css`
    box-shadow: none;
    border-radius: 16px;
    border: 1px solid ${theme.palette.primary.light3};
    padding: 20px;

    &.Mui-expanded {
      margin: 0;
    }

    &:before {
      content: none;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 16px;
    }

    & .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 0 0;
    }

    & .MuiButtonBase-root {
      min-height: 0;
    }

    & .MuiAccordionSummary-root {
      padding: 0;
    }

    & .MuiAccordionSummary-content {
      margin: 0;
    }

    & .MuiSvgIcon-root {
      color: ${theme.palette.primary.main};
    }

    ${theme.breakpoints.up('md')} {
      padding: 24px;

      & .MuiAccordionDetails-root {
        gap: 12px;
        padding: 20px 0 0;
      }
    }
  `,
);

export const StyledServiceList = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;

      & > * {
        flex: 0 0 calc(50% - 10px);
      }
    }
  `,
);
