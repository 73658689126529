import { ACTION, initialState } from './constants';
import { ActionType, StateType } from './types';

export const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case ACTION.SET_COMPANY:
      return {
        ...state,
        company: {
          ...action.payload,
          ...(action.payload.profileImageUri && {
            profileImageUri: `${action.payload.profileImageUri}?${Date.now()}`,
          }),
        },
      };

    case ACTION.RESET_COMPANY:
      return {
        ...state,
        company: initialState.company,
      };

    case ACTION.MERGE_COMPANY:
      return {
        ...state,
        company: {
          ...state.company,
          ...action.payload,
          ...(action.payload.profileImageUri && {
            profileImageUri: `${action.payload.profileImageUri}?${Date.now()}`,
          }),
        },
      };

    default:
      return state;
  }
};
