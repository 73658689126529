import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import getLastDigits from 'utils/getLastdigits';
import mapServiceModelToViewModel from 'utils/mapServiceModelToViewModel';
import { OutputServiceGroupDto } from 'types/service';
import ServiceCard, { ServiceCardSkeleton } from 'components/@common/ServiceCard';
import Button from 'components/@shared/Button';
import generateIncrementalArray from 'utils/generateIncrementalArray';

import getServiceGroupData from './getServiceGroupData';
import { StyledAvatar, StyledContainer, StyledServiceGroupContainer, StyledServiceGroupList } from './styled';
import ServiceGroupSkeleton from './ServiceGroupSkeleton';

type Props = {
  hasNextPage?: boolean;
  serviceGroupsPerFetchCount: number;
  servicesPerFetchCount: number;
  serviceGroups: OutputServiceGroupDto[];
  serviceGroupsLoading?: boolean;
  showMoreServiceGroups: () => void;
  showMoreServices: (params: { masterId?: number; companyId?: number }) => Promise<void>;
  onPartnerClick: (slug?: string) => void;
  onServiceClick: (id: number) => void;
  onBookService: (id: number) => void;
};
const CardListSection: React.FC<Props> = ({
  hasNextPage,
  serviceGroupsPerFetchCount,
  servicesPerFetchCount,
  serviceGroups,
  serviceGroupsLoading,
  showMoreServiceGroups,
  showMoreServices,
  onPartnerClick,
  onServiceClick,
  onBookService,
}) => {
  const { t } = useTranslation();

  const [servicesLoading, setServicesLoading] = useState<number[]>([]);

  return (
    <StyledContainer>
      {serviceGroups.map((group, index) => {
        const { slug, imgUrl, name, count } = getServiceGroupData(group);

        const handleShowMore = async () => {
          setServicesLoading(prev => [...prev, index]);
          await showMoreServices({ masterId: group.master?.id, companyId: group.company?.id });
          setServicesLoading(prev => prev.filter(item => item !== index));
        };

        return (
          <StyledServiceGroupContainer key={slug}>
            <Stack flexDirection="row" gap="8px" justifyContent="space-between" alignItems="center" height="40px">
              <Stack
                flexDirection="row"
                gap="8px"
                alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => onPartnerClick(slug)}
              >
                {!!imgUrl && <StyledAvatar imgUrl={imgUrl} />}
                {!!name && (
                  <Typography variant="h6" color="primary.main">
                    {name}
                  </Typography>
                )}
              </Stack>
              <Typography variant="body2" color="primary.main">
                {/* @ts-ignore */}
                {t([`result.${getLastDigits(count)}`, `result.unspecific`], { count })}
              </Typography>
            </Stack>
            <StyledServiceGroupList>
              {group.services.map(service => (
                <ServiceCard
                  {...mapServiceModelToViewModel(service, group.distanceMeters, !!group.company)}
                  key={service.id}
                  onClick={onServiceClick}
                  onBookService={onBookService}
                />
              ))}
              {servicesLoading.includes(index) &&
                generateIncrementalArray(servicesPerFetchCount).map(item => <ServiceCardSkeleton key={item} />)}
            </StyledServiceGroupList>
            {group.services.length < count && (
              <Button variant="outlined" onClick={handleShowMore}>
                {t('showMore')}
              </Button>
            )}
          </StyledServiceGroupContainer>
        );
      })}
      {serviceGroupsLoading &&
        generateIncrementalArray(serviceGroupsPerFetchCount).map(item => (
          <ServiceGroupSkeleton key={item} seed={item.toString()} servicesPerFetchCount={servicesPerFetchCount} />
        ))}
      {hasNextPage && (
        <Button size="large" variant="contained" onClick={showMoreServiceGroups}>
          {t('showMore')}
        </Button>
      )}
    </StyledContainer>
  );
};

export default CardListSection;
