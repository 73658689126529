import React, { useRef } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Breadcrumbs from 'components/@common/Breadcrumbs';
import Button from 'components/@shared/Button';
import Input from 'components/@shared/Input';
import TimePicker from 'components/@shared/TimePicker';
import InlineList from 'components/@shared/InlineList';
import ContainerWithBorder from 'components/@shared/ContainerWithBorder';
import { ABOUT_MAXIMUM_LENGTH, MAXIMUM_SERVICE_LENGTH, useYupRules } from 'hooks/useYupRules';
import useForm from 'hooks/useForm';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import { StyledForm, StyledIconButton, StyledImg, StyledImgContainer, StyledImgsGrid, StyledInput } from './styled';
import { useBreadcrumbs } from './useBreadcrumbs';
import { useManageServicePage } from './useManageServicePage';
import { ServiceFormType } from './types';
import WestIcon from '@mui/icons-material/West';
import getLocaleCurrencyLabel from 'utils/getLocaleCurrencyLabel';
import convertTimeSpanToDayjs from '../../utils/convertTimeSpanToDayjs';

const ManageServicePage: React.FC = () => {
  const { t } = useTranslation();

  const {
    currentService,
    imgList,
    setImgList,
    master,
    loading,
    attributeGroups,
    handleManageService,
    handleBack,
    removePhoto,
  } = useManageServicePage();

  const breadcrumbs = useBreadcrumbs(master);

  const ref = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  const { serviceNameRule, durationRule, priceRule, aboutRule } = useYupRules();

  const initialValues = {
    name: currentService?.name || '',
    duration: currentService?.duration ? convertTimeSpanToDayjs(currentService.duration) : null,
    price: currentService?.price.toString() || '',
    description: currentService?.description || '',
  };

  const {
    formik,
    handleSubmit,
    setFieldValue,
    formikHelpers: { getFormikProps },
  } = useForm<ServiceFormType>({
    initialValues,
    yupSchema: {
      name: serviceNameRule,
      duration: durationRule,
      price: priceRule,
      description: aboutRule,
    },
    enableReinitialize: true,
    onSubmit: values => {
      handleManageService(values);
    },
  });

  const handleDurationChange = (value: Dayjs | null) => setFieldValue('duration', value);

  const handleAddImgClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setImgList(prev => [...prev, ...files]);
    }
  };

  return (
    <Container>
      <Breadcrumbs {...breadcrumbs} sx={{ marginBottom: '16px!important' }} />
      <Button
        variant="text"
        color="info"
        startIcon={<WestIcon />}
        sx={{ marginBottom: '16px!important' }}
        onClick={handleBack}
      >
        {t('addServicePage.backButtonLabel')}
      </Button>
      {attributeGroups && (
        <Box>
          <InlineList
            list={attributeGroups.map(item => ({ item: item.attribute.localizedName, key: item.attribute.id }))}
            variant="subtitle1"
          />
        </Box>
      )}
      <Gap size={12} />
      <Typography variant={upMedium ? 'h1' : 'h3'} component="h1" color="primary.main" maxWidth="840px">
        {t('addServicePage.title')}
      </Typography>
      <Gap size={upMedium ? 40 : 32} />
      <StyledForm onSubmit={handleSubmit}>
        <ContainerWithBorder gap="24px">
          <Typography variant="h6" color="primary.main">
            {t('addServicePage.infoTitle')}
          </Typography>
          <Input
            {...getFormikProps('name')}
            label={t('addServicePage.nameLabel')}
            placeholder={t('addServicePage.namePlaceholder')}
            multiline
            inputProps={{ maxLength: MAXIMUM_SERVICE_LENGTH }}
          />
          <TimePicker
            {...getFormikProps('duration')}
            label={t('addServicePage.durationLabel')}
            format={`HH [${t('hoursShort')}] mm [${t('minutesShort')}]`}
            onChange={handleDurationChange}
            slotProps={{
              textField: {
                error: !!formik.errors.duration,
                helperText: formik.errors.duration,
              },
            }}
          />
          <Input
            {...getFormikProps('price')}
            label={t('addServicePage.priceLabel', { currency: getLocaleCurrencyLabel() })}
            placeholder={t('addServicePage.pricePlaceholder')}
            type="number"
          />
          <Input
            {...getFormikProps('description')}
            label={t('addServicePage.descriptionLabel')}
            placeholder={t('addServicePage.descriptionPlaceholder')}
            multiline
            rows={4}
            inputProps={{ maxLength: ABOUT_MAXIMUM_LENGTH }}
          />
        </ContainerWithBorder>

        <ContainerWithBorder gap="24px">
          <Stack gap="12px">
            <Typography variant="h6" color="primary.main">
              {t('addServicePage.examplesTitle')}
            </Typography>
            <Typography variant="body1" color="primary.main">
              {t('addServicePage.examplesSubtitle')}
            </Typography>
          </Stack>
          <StyledInput type="file" multiple onChange={handleInputChange} ref={ref} accept="image/png, image/jpeg" />
          <Button
            variant="outlined"
            loading={loading}
            sx={{ width: upMedium ? '270px' : '100%' }}
            onClick={handleAddImgClick}
          >
            {t('addServicePage.addPhotoButtonLabel')}
          </Button>
          <StyledImgsGrid>
            {currentService?.photos.map(i => (
              <StyledImgContainer key={i.id}>
                <StyledIconButton
                  onClick={() => {
                    removePhoto(i.id);
                  }}
                >
                  <DeleteIcon />
                </StyledIconButton>
                <StyledImg src={i.uri} alt="" />
              </StyledImgContainer>
            ))}
            {imgList.map(i => {
              const url = URL.createObjectURL(i);
              return (
                <StyledImgContainer key={i.name}>
                  <StyledIconButton onClick={() => setImgList(prev => prev.filter(item => item.name !== i.name))}>
                    <DeleteIcon />
                  </StyledIconButton>
                  <StyledImg src={url} alt="" />
                </StyledImgContainer>
              );
            })}
          </StyledImgsGrid>
        </ContainerWithBorder>

        <Button variant="contained" type="submit" loading={loading}>
          {t(currentService ? 'addServicePage.editButtonLabel' : 'addServicePage.addButtonLabel')}
        </Button>
      </StyledForm>
    </Container>
  );
};
export default ManageServicePage;
