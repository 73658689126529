import { ReactComponent as MeetingIcon } from 'assets/icons/meeting.svg';
import { ReactComponent as CustomerSegmentIcon } from 'assets/icons/customerSegment.svg';

import { AttributesGroupId } from 'constatns/actiivity';
import { SVGProps } from 'react';

const mapAttributesGroupIdToIcon = (attributesGroup: AttributesGroupId): React.FC<SVGProps<any>> => {
  switch (attributesGroup) {
    case AttributesGroupId.MedicineReceptionType:
      return MeetingIcon;

    case AttributesGroupId.BeautyGender:
      return CustomerSegmentIcon;

    default:
      return () => null;
  }
};

export default mapAttributesGroupIdToIcon;
