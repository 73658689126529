import { useEffect } from 'react';
import { ACTIVATE_COMPANY, DEACTIVATE_COMPANY } from 'api/requests/company';
import { Company } from 'types';
import useMutation from 'hooks/useMutation';
import { useCompanyState } from 'contexts/company';
import { setCompany } from 'contexts/company/actions';

const useSwitchActivateCompany = () => {
  const {
    state: { company },
    dispatch,
  } = useCompanyState();

  const [activateCompany, { data: activateCompanyData, loading: activateCompanyLoading }] = useMutation<
    { activateCompany: Company },
    { companyId: number }
  >(ACTIVATE_COMPANY);
  const [deactivateCompany, { data: deactivateCompanyData, loading: deactivateCompanyLoading }] = useMutation<
    { deactivateCompany: Company },
    { companyId: number }
  >(DEACTIVATE_COMPANY);

  useEffect(() => {
    if (activateCompanyData?.activateCompany) {
      dispatch(setCompany(activateCompanyData?.activateCompany));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateCompanyData]);

  useEffect(() => {
    if (deactivateCompanyData?.deactivateCompany) {
      dispatch(setCompany(deactivateCompanyData?.deactivateCompany));
    }
  }, [deactivateCompanyData, dispatch]);

  const onSwitchActivateCompany = () => {
    if (!company.id) return;

    const request = company.activated ? deactivateCompany : activateCompany;

    request({ variables: { companyId: company.id } });
  };

  return {
    switchAccountLoading: activateCompanyLoading || deactivateCompanyLoading,
    onSwitchActivateCompany,
  };
};

export default useSwitchActivateCompany;
