import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from 'components/@shared/Container';
import Gap from 'components/@shared/Gap';
import Button from 'components/@shared/Button';
import map from 'assets/icons/overview/map.svg';
import people from 'assets/icons/overview/people.svg';
import heart from 'assets/icons/overview/heart.svg';

import { StyledOverviewCardList, StyledBgContainer, StyledOverviewCard, StyledButtonContainer } from './styled';

type Props = {
  title: string;
  subtitle: string;
  actionButtonLabel: string;
  onActionButtonClick: () => void;
  className?: string;
};

const OverviewSection: React.FC<Props> = ({ title, subtitle, actionButtonLabel, className, onActionButtonClick }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const upMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledBgContainer className={className}>
      <Container>
        <Box>
          <Typography variant="h2" color="primary.main">
            {title}
          </Typography>
          <Gap size={upMedium ? 24 : 16} />
          <Typography variant="body1" color="primary.main" sx={{ opacity: 0.7 }}>
            {subtitle}
          </Typography>
          <Gap size={upMedium ? 38 : 14} />
          <StyledOverviewCardList>
            <StyledOverviewCard>
              <img src={map} alt="map" />
              <Typography variant="h4" color="primary.main">
                {t('overviewSection.title1')}
              </Typography>
            </StyledOverviewCard>
            <StyledOverviewCard>
              <img src={people} alt="people" />
              <Typography variant="h4" color="primary.main">
                {t('overviewSection.title2')}
              </Typography>
            </StyledOverviewCard>
            <StyledOverviewCard>
              <img src={heart} alt="heart" />
              <Typography variant="h4" color="primary.main">
                {t('overviewSection.title3')}
              </Typography>
            </StyledOverviewCard>
          </StyledOverviewCardList>
          <Gap size={upMedium ? 40 : 20} />
          <StyledButtonContainer>
            <Button variant="contained" size="large" onClick={onActionButtonClick}>
              {actionButtonLabel}
            </Button>
          </StyledButtonContainer>
        </Box>
      </Container>
    </StyledBgContainer>
  );
};
export default OverviewSection;
