import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import preventForwardProps from 'utils/preventForwardProps';

export const StyledContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid ${theme.palette.primary.light3};
    padding: 20px;

    & svg {
      color: ${theme.palette.accent.main};
      flex: 0 0 auto;
    }
  `,
);

export const StyledInputContainer = styled(
  Box,
  preventForwardProps(['expanded']),
)<{ expanded: boolean }>(
  ({ theme, expanded }) => css`
    width: 100%;
    height: ${expanded ? '48px' : '0'};
    opacity: ${expanded ? 1 : 0};

    & svg {
      color: ${theme.palette.accent.main};
      flex: 0 0 auto;
    }

    transition: all 0.3s;
  `,
);
