import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import uk from './uk';
import en from './en';

export const defaultNS = 'translation';
export enum Locale {
  En = 'en',
  Uk = 'uk',
}

export const defaultLocale = Locale.Uk;
export const defaultCountryCode = 'UA';


export const mapCountryCodeToLanguage = (locale: string): string => {
  switch (locale) {
    case 'US':
    case 'GB': {
      return Locale.En;
    }

    case 'UA': {
      return Locale.Uk;
    }

    default:
      return defaultLocale;
  }
};

export const resources = {
  [Locale.Uk]: {
    [defaultNS]: uk,
  },
  [Locale.En]: {
    [defaultNS]: en,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Locale.Uk,
    detection: {
      order: ['navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
