import { css, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledBgContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light2};
    border-radius: 16px;
    width: 100%;
    padding: 40px 0;

    ${theme.breakpoints.up('sm')} {
      padding: 80px 0;
    }
  `,
);

export const StyledOverviewCardList = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;

    ${theme.breakpoints.up('md')} {
      flex-direction: row;
      gap: 20px;
      justify-content: space-between;
    }
  `,
);

export const StyledOverviewCard = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.primary.light};
    padding: 20px;
    gap: 20px;
    display: flex;
    align-items: center;

    flex: 1 1 33.3%;

    border-radius: 16px;

    ${theme.breakpoints.up('md')} {
      flex-direction: column;
      text-align: center;
    }

    ${theme.breakpoints.up('lg')} {
      flex-direction: row;
      text-align: start;
    }
  `,
);

export const StyledButtonContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    ${theme.breakpoints.up('md')} {
      margin: 17px auto 0;
      max-width: 325px;
    }
  `,
);
