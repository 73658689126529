type Options = {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
};

export const formatNumber = (
  num: number,
  { minimumFractionDigits, maximumFractionDigits }: Options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
) =>
  new Intl.NumberFormat(undefined, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(num);
